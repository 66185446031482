import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { PortalAPI } from 'src/app/constants/api.constant';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  public options: any = {};
  public tenantId: string = '';

  constructor(
    private http: HttpClient,
    private behaviourSubjectService: BehaviorSubjectService

  ) {
 
    let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    reqHeader.append('Access-Control-Allow-Origin', '*');
    reqHeader.append('accept', 'text/plain');
    reqHeader.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    reqHeader.append('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE');
    if (this.tenantId) {
      reqHeader.append('TenantId:', this.tenantId);
    }
    this.options = { headers: reqHeader };
  }


  getExtenalAPI(url: string): Observable<any> {
     return this.http.get(url);
  }

  get(url: string, params?: any): Observable<any> {
    if (params) {
      return this.http.get(url + `/${params}`, this.options);
    }
    else {
      return this.http.get(url, this.options);
    }
  }

  post(url: string, data?: any): Observable<any> {
    if(data){
      return this.http.post(url, data, this.options);
    }
    else{
      return this.http.post(url,this.options);
    }
  }

  postJob(url: string, data: any, params?: any ): Observable<any> {
    return this.http.post(url, data, params);
  }

  put(url: string, params?: any): Observable<any> {
    if (params) {
      return this.http.put(url, params);
    }
    else {
      return this.http.put(url, this.options);
    }
  }

  delete(url: string, params?: any): Observable<any> {
    if(params) {
      return this.http.delete(url, this.options);
    }
    else {
      return this.http.delete(url);
    }
  }

  RemoveAssignUserForJob(data: any) {
    return this.http.post(PortalAPI.REMOVE_USERPERMISSION_FOR_JOB, data, this.options);
  }

  ResetUserPasswordByAdmin(data: any) {
    return this.http.post(PortalAPI.RESET_USERPSSWRD_BYADMIN, data, this.options);
  }

  ActiveInactiveUsersByAdmin(data: any) {
    return this.http.post(PortalAPI.USER_ACTIVE_INACTIVE_BYADMIN, data, this.options);
  }

  AssignJobToUserByAdmin(data: any) {
    return this.http.post(PortalAPI.ASSIGN_JOB_TO_USER, data, this.options);
  }

  fetchUserRoleBased(roleId: number){
      return this.http.get(`${PortalAPI.GET_USERS_FOR_ROLE}/?roleId=${roleId}`);
  }
  
  ngOnDestroy() {
  }
}
