import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin, Observable, Subject, BehaviorSubject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { CommonMapService } from 'src/app/common-component/common-map-service';
import { PortalAPI } from 'src/app/constants/api.constant';
import { FeatureTypeStyleMapping } from 'src/app/constants/featureTypeIdStylingMapping';
import { ConnectionService } from 'src/app/modules/sitemapadmin/services/connection-service';
import { AzureBlobStorageServiceVideo } from 'src/app/services/azure-blob-storage.service';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { MapViewerJobFeaturesService } from 'src/app/services/mapviewerJobFeatures/map-viewer-job-features.service';
import { MapEditService } from '../../services/mapedit.service';
import { MapSiteService } from '../../services/mapsite.service';
import { MapSubSiteService } from '../../services/mapsubsite.service';
import { MapViewerService } from '../../services/mapviewer.service';
import * as turf from '@turf/turf';
import { ApiService } from 'src/app/services/api.service';

// import * as exampleDataImport from '../../../../../assets/js/ImportGetSchemaMapper.json';
import * as exampleDataImportCAD from '../../../../../assets/js/ImportGetSchemaMapperCAD.json';
import { SubscriptionEditAccess } from 'src/app/constants/subscriptionEditAccess';
import { PermissionService } from 'src/app/services/common/permission.service';
import { permissionEnum, UserPermissionConstant } from 'src/app/constants/userPermissionAccess';
import { MetadataService } from 'src/app/services/metadata.service';
import { CommitChanges, deepClone, Feature, FeatureGroup, FeatureType, ReferenceGISTilesData } from 'src/app/constants/common-model';
import { IRasterImage } from 'src/app/common-component/common-map-service.types';
import { EventType, FeatureGroupItem, FeatureItem, FeatureTypeItem, FileGroup, RasterImageItem } from '../feature-tree/feature-tree.component.types';
import applyFilterOnLayer from './functions/apply-filter-on-layer.function';
import referenceLayerDataToMenuItems from './functions/reference-layer-to-menu-tem-data';
import { LayerMenuItemData } from '../map-layer-menu-item/map-layer-menu-item.types';
import toFeatureItem from '../feature-tree/functions/to-feature-item.function';
import hideFeature from './functions/hide-feature.function';
import { visibility } from 'html2canvas/dist/types/css/property-descriptors/visibility';
import getJobIdFromFile from '../feature-tree/functions/get-job-id-from-file.function';
import { virtualTourListTableDataDC } from 'src/app/constants/virtualTour.constants';

@Component({
  selector: 'app-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['../mapalljoblist/mapalljoblist.component.css', './layer.component.css'],
})
export class LayerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() featureTypeStyleMap: any;
  @Input() map!: mapboxgl.Map;
  @Input() draw: any;
  @Input() slideMenuOpenTools: boolean = false;
  @Input() slideMenuOpenLayerDetails: boolean = false;
  @Input() slideMenuOpenJobDescription: boolean = false;
  @Input() slideMenuOpenSiteDetails: boolean = false;
  @Input() slideMenuOpenFolder: boolean = false;
  @Input() slideMenuOpenSiteAdminDetails: boolean = false;
  @Input() showToggleSideBar: boolean = false;
  @Input() zoomedFeaturesObservableArray: any[] = [];
  @Input() zoomedImportedImageObservableArray: any[] = [];
  @Input() zoomedJobIds: any = [];
  @Input() loginUserId: any;
  @Input() enableEditModelOnDblClick: any;
  @Input() clickedFeatures: any[] = [];
  @Input() clickedFeatureLat: any;
  @Input() clickedFeatureLng: any;
  @Input() selectedFeature: any;
  @Input() dragMap: boolean = false;
  @Input() zoomMap: boolean = false;
  @Input() addGroupFeatureTypeObj: any;
  @Input() isDrawing: any;
  @Input() disablePointInteractions: any;
  @Input() addVertexLineId: any;
  @Input() isSnappingToVertex: any;
  @Input() isSnappingToLine: any;
  @Input() editingState: any;
  @Input() mergeParentLineId: any;
  @Input() mergeChildLineId: any;
  @Input() firstSecondLineFeatureFrMergedArr: any[] = [];
  @Input() iframeExternalContentLink: boolean =  true;
  @Output() ontoggleClicked = new EventEmitter<any>();
  @Output() updateEditMode = new EventEmitter<any>();
  @Output() updateNavigatedFeatures = new EventEmitter<any>();
  @Output() onFeaturePropertyUpdate = new EventEmitter<any>();
  @Output() measeureLength = new EventEmitter<any>();
  @Output() removeExtraPopup = new EventEmitter<any>();
  @Output() measureCalcArea = new EventEmitter<any>();
  @Output() onRefreshDescriptionData = new EventEmitter<any>();
  @Output() onUpdateDblClick = new EventEmitter<any>();
  @Output() onSaveSingleFeature = new EventEmitter<any>();
  @Output() toggleEditingState = new EventEmitter<any>();
  @Output() parentOrChildMerge = new EventEmitter<any>();
  @Output() onExitEditing = new EventEmitter<{isCancel: boolean}>();
  @Output() siteToggleApplied = new EventEmitter<any>();

  toggleApiLoaded = false;
  isGroupMapCompleted: boolean = false;
  isFeatureTypeClicked: any = {};
  previousZoomJobId: any[] = [];
  forkJoinMapFeaturesSubscription: Subscription | null = null;
  forkJoinImportImageSubscription: Subscription | null = null;
  navigatedFeatures: any[] = [];
  siteLayerData: any;
  zoomedJobIdLayerIdMap: any = new Map();
  // featureGroupMap: any = new Map();
  // featureGroupToggleStateMap: any = new Map();
  // featureGroupTypeToggleStateMap: any = new Map();
  // featureGroupTypeFeatureNameToggleStateMap: any = new Map();
  // isLayerEyeVisible: any = {
  //   SITE: true,
  //   GPRS: true,
  //   CLIENT: true,
  //   IMPORTED: true,
  //   EXTERNAL: true,
  //   REFERENCE: false
  // };

  // For editing
  isEditLayer: boolean = true;
  isGprsLayerCanEdit: boolean = false;
  loginUserEmailId: any;
  loginUserRole: any;
  loginUserTierId: any;
  isClientLayerCanEdit: boolean = false;
  isExternalLayerCanEdit: boolean = false;
  isReferenceLayerCanEdit: boolean = false;
  isImportedLayerCanEdit: boolean = false;
  editLayerName: any;
  currentEditinglayerId: any;
  isEditingModeIsOn: boolean = false;
  // isLayerEditVisible: any = [
    //   {
      //     SITE: true,
      //     GPRS: true,
      //     CLIENT: true,
      //     IMPORTED: true,
      //     EXTERNAL: true,
      //     REFERENCE: true,
      //   },
      // ];
      // group=> feature type dropdown related
      groupToSelect: any;
      groupDropDownData: any;
      featureTypeToSelect: any;
      featureTypeDropDownData: any;
      featureImg: any;
      featureTypeForBtnVisible: any;
      newAddedFeatureName: any;
      newAddedFeatureInLayer: any;
      newlyAddedFeature: any[] = [];
      editSelectedFeature: any;
      addNewFeatureGrpFrMapEditor: any;
      addNewFeatureTypeFrMapEditor: any;
      addNewFeatureGrpIdFrMapEditor: any;
      externalContentDropdownData: any;
      selectOptionsExternal: any;
      addNewFeatureTypeIdFrMapEditor: any;
      hiddenFeatures: any[] = [];
      showAddFeatureContainerFrMapEditor: boolean = false;
      showAddFeatureButton: any;
      selectOptionsExternalEnable: any;
      externalContentForm: FormGroup;
      isBasemapToggle: any;
      previousFeature: any;
      navigatedJobId: any;
      externalContentFormOriginal: any;
      currentDrawingState: any = 'static';
      featureDeleteSubscribe: Subscription | undefined;
      mapViewerJobFeatureSubscribe: Subscription | undefined;
      // isMapJobIdModalPopupOpen:boolean = false
      selectedJob: any;
      temporaryAddedFeature: any = {};
      temporaryAddedMultipleFeature: any[] = [];

  jobDetailsForGPRS: any[] = []
  newFeaturesLat: any;
  newFeaturesLng: any;
  newlyAddedFeatureProperties: any;
  isSingleFeatureSave: boolean = false

  // Import Functionality Variables Start
  importJobModalEnable : boolean = false;

  importedFileName = '';
  importedFileType = '';
  importedFileArr: any;
  importedIsPublic: boolean = false;
  @ViewChild('myImportFile', { static: false })
  myImportFileVariable!: ElementRef;
  importSubmitted: boolean = false;
  importedBlankCheck: boolean = true;
  // Comment , Uncomment will done when Chris will done with other file formats, or production build release
  // _validFileExtensions = [".swmz", ".pdf", ".xlsx",".kml",".kmz",".swamps",".gpkg",".zip",".geojson",".json",".dxf"];
  // _validFileExtensions = [".gpkg", ".zip", ".geojson", ".json", ".kml", ".kmz", ".swmaps", ".swmz", ".swm2", ".tiff", ".tif", ".geotiff", ".pdf", ".dwg", ".dxf", ".gif", ".png", ".jpg", ".jpeg"];
  // kml, kmz, swmaps, gpkg, zip, geojson, json, dxf
  // _validFileExtensions = [".gpkg",".zip",".geojson",".json", ".kml", ".kmz", ".swmaps", ".swm2", ".swmz", ".tiff", ".tif", ".geotiff"];
  _vectorFileExtensions = [".gpkg", ".zip", ".geojson", ".json", ".kml", ".kmz", ".swmaps", ".swm2", ".swmz", ".dwg", ".dxf"];
  _rasterFileExtensions = [".tiff", ".tif", ".geotiff", ".pdf", ".gif", ".png", ".jpg", ".jpeg"];
  _rasterFileExtensionsWithoutPNG = [".tiff", ".tif", ".geotiff", ".pdf"];
  _validFileExtensions = [...this._vectorFileExtensions, ...this._rasterFileExtensions];
  fileExtensions = this._validFileExtensions.join(",");
  nonGeoReferenceWithoutPNGCheck: any = '';
  _validFileExtensionsPointCloud = [".las", ".laz"];
  importedLayerFileName = '';
  importedLayerFileId :any;
  importedFileNameChangeCondition: boolean = false;
  importedLayerFileNameStore: any = '';
  importedContainer: string = '';
  importedContainerSAS: string = '';

  // Import basic Coustom Data Variables Start
  customImportFeaturesGroup: any = [];
  customImportFeaturesType: any = [];
  selectImportFeatureGroup = '';
  selectImportFeatureType = '';

  checkImportFileTypeRaster: boolean = false;
  isGeoReferencedRaster: boolean = false;
  // Import basic Coustom Data Variables End

  public importGISForm: any;

  isSubmitButtonVisible: boolean = false;
  isSelectButtonVisible: boolean = false;
  isSubmitButtonVisibleGISReference: boolean = false;
  isSubmitNonGeoReferenceImage: boolean = false;

  nonGeoreferenceImgDivActive: boolean = false;

  editGeoReferenceImageType : any ='';
  // rasterImageIdEdit :any = '';
  rasterImageFeature :any = '';

  // CAD Custom Form Start
  cadCustomForm!: FormGroup;
  filteredOptionsFeatureGroup: any = [];
  filteredOptionsFeatureType: any = [];
  importFeatureGroup: any[] = [];
  importFeatureType: any[] = [];
  importFeatureGroupSelectedIndex: any = null;
  importFeatureTypeSelectedIndex: any = null;
  previousfeatureGroupIdSe = 0;

  crsDropDownData: any[] = [];
  importCustomFileName: any = '';
  importUploadingFileName: any = ''
  importUploadingFileId: number = 0;
  // CAD Custom Form end

  referenceGISTilesData: ReferenceGISTilesData[] = [];
  geoReferenceGISImagesData: any[] = [];
  rangeSliderGeoReferenceImage :any = 100;
  getAllVectorsSubscribe: Subscription | undefined;
  getAllRasterSubscribe: Subscription | undefined;
  featureGroupSearchSubscribe: Subscription | undefined;
  featureTypeSearchSubscribe: Subscription | undefined;
  // Import Functionality Variables End

  // External Content layer Variables Start
  latitudeExternalContent: any;
  longitudeExternalContent: any;
  externalContentId: any;
  showExternalContentModal: boolean = false;

  extContentApiUrl: any;
  externalPayload: any;
  modalHeader: any;
  modalExternalAddHeader: any;
  externalLayerDatajobIdFeatures: any[] = [];
  externalLayerItems = ["POINTCLOUD", "MATTERPORT", "EXTERNALLINK", "ATTACHMENT", "VIRTUALTOUR"];

  externalContentTemplateData = {
    siteId: '',
    woNo: '',
    siteName: '',
    featureName: '',
    featureLink: '',
  };
  pointCloudFileName: any = '';
  // External Content layer Variables Start End
  externalContentIcon: any;
  siteData: any[] = [];
  selectedFeatureDetailDB: any;
  permissionListObj:any = new UserPermissionConstant().UserRoleAssignPermission();

  //undo redo
  editingHistoryUndoRedo : any = {};
  editingHistoryUndoArray : any[] = [];
  editingHistoryRedoArray : any[] = [];

  // commit changes en-mass
  isFromCommitChange:boolean = false;
  changesTobeCommitted:any[] = [];
  changesTobeReverted:any[] = [];
  isRefreshRequiredForCommittedChange:boolean = true;
  commitChangesSubscribe: Subscription | undefined;

  // HISTORY
  isHistoryOpend:boolean = false
  isHistoryBtnClicked:boolean = false
  historyMetaData:any[] = [];
  isHistoryGroupClicked:boolean = false;
  isHistoryTypeClicked:boolean = false;
  isHistoryFeatureClicked:boolean = false;
  featuresForRevision:any[] = [];
  externalLayerAddEdit: any = 'Add'
  alreadyLoadedData: boolean = false;
  editCheck = new SubscriptionEditAccess(this.commonService).getEditAccess();
  permissionEnumList:any = permissionEnum;

  jobIdToFeatures:any = {}
  layerIdToFeatures:any = {}
  groupIdToTypes:any = {}
  typeIdToFeatures:any = {}
  data: CommitChanges[] = [];
  groups: FeatureGroup[] = [];
  types: FeatureType[] = [];
  features: Feature[] = [];
  private readonly referenceLayerId = 999;
  isDataLoaded: boolean = false;
  referenceLayerStatusData: CommitChanges | undefined;
  referenceLayerData: CommitChanges | undefined;
  isFirstEditDetected:boolean = false;
  matterportLinkArray:any =[];
  matterportTableHeader= new virtualTourListTableDataDC().tblHeader;

  public isImportingReferenceLayer = false;

  public layerEvents = new Subject<EventType>();
  public referenceLayerItems: LayerMenuItemData[] = [];

  private fetchDataForZoomedJobIdsSubject: BehaviorSubject<{jobIds: any[], isNewJobIdPresent?:boolean}> = new BehaviorSubject<{jobIds: any[], isNewJobIdPresent?:boolean}>({jobIds: []  });
  private fetchDataForZoomedJobIdsSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private commonMapService: CommonMapService,
    public subSiteServices: MapSubSiteService,
    public siteServices: MapSiteService,
    private mapViewerJobFeatureService: MapViewerJobFeaturesService,
    private restService: ConnectionService,
    private mapViewerService: MapViewerService,
    private blobService: AzureBlobStorageServiceVideo,
    private mapEditService: MapEditService,
    private commonService: CommonFunctionService,
    private apiService: ApiService,
    private permissionService: PermissionService,
    private cdRef: ChangeDetectorRef,
    private metadataService:MetadataService

  ) {
    this.importedContainer = this.blobService.importedContainer;
    this.importedContainerSAS = this.blobService.importedContainerSAS;
    this.externalContentForm = this.fb.group({
      linkExternal: new FormControl('', Validators.compose([Validators.pattern(this.commonService.urlHttpValidation), Validators.required])),
      WANumber: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.maxLength(30), Validators.pattern(this.commonService.alphaNumericPattern)])),
      displayName: new FormControl('', Validators.compose([Validators.maxLength(30), Validators.required])),
      job: ['', [Validators.required]],
      editEnable: [false],
      featureId: [0],
      featureDetailEdit: [''],
      featureGroup: [''],
      fileExternal: ['']
    });
    this.externalContentFormOriginal = this.externalContentForm.value;
    this._listeningToCommitChangesForRevertOrSave();
  }

  private _listeningToCommitChangesForRevertOrSave() {
    this.commitChangesSubscribe = this.mapEditService.committedChangesRevertOrSaveList$.subscribe((commitChanges)=>{
      if(commitChanges.hasOwnProperty("save") && commitChanges?.data?.length) {
        this.isRefreshRequiredForCommittedChange = commitChanges.isRefreshRequired;
        this._saveCommitedChanges(commitChanges);
      } else if(commitChanges.hasOwnProperty("revert") && commitChanges?.data?.length) {
        this._revertFeature(commitChanges);
      }
    })
  }

  private _saveCommitedChanges(commitChanges: any) {
      this.changesTobeCommitted = [];
      this.selectedJob = {workOrderNumber:''};
      let selectBox: any = document.getElementById("feature-job-select");
      selectBox.value = this.selectedJob ? this.selectedJob.jobId : "default";
      this.isSingleFeatureSave = commitChanges.data?.length > 1 ? false : true;
      this._openJobMapModal();
      this.changesTobeCommitted = commitChanges.data;
  }

  private _revertFeature(commitChanges:any) {
    this.changesTobeReverted = [];
      if (confirm('Are you sure you want to Revert?')) {
        this.changesTobeReverted = commitChanges.data;
        commitChanges.data.forEach(({layer, group, type, feature}:any, index:any) => {
          setTimeout(() => {
            this._cancelSingleFeature(feature, layer, group, type);
            if(index === (commitChanges?.data?.length - 1) && commitChanges.isRefreshRequired) {
              this._refreshAfterSave();
            }
          }, 100);
        });
      }
  }

  private _openJobMapModal() {
    setTimeout(() => {
      this.isFromCommitChange = true;
      const saveBtn = document.getElementById("mapJobAndWOContent") as HTMLButtonElement;
      saveBtn.click();
    }, 200);
  }

  private async _saveMultipleCommits(commitChanges:any[]) {
    if (confirm('Are you sure you want to save?')) {
      for (const {layer, group, type, feature} of commitChanges) {
        this._mapIDsToFeature(feature.featureObj?.properties);
        this._saveAndDeleteNewlyAddedFeature(feature.featureObj);
        await this.waitforme(500);
      }
      if(this.isRefreshRequiredForCommittedChange){
        this._refreshAfterSave();
      }
    }
  }

  ngOnDestroy(): void {
    this.editingHistoryUndoArray = [];
    this.editingHistoryRedoArray = [];

    this.featureGroupSearchSubscribe?.unsubscribe();
    this.featureTypeSearchSubscribe?.unsubscribe();
    this.getAllVectorsSubscribe?.unsubscribe();
    this.isFromCommitChange = false;
    this.changesTobeCommitted = [];
    this.changesTobeReverted = [];
    this.isRefreshRequiredForCommittedChange = true;
    this.mapEditService.mapEditorChangesToBeCommitted = [];
    this.commitChangesSubscribe?.unsubscribe();
    this.getAllRasterSubscribe?.unsubscribe();
    this.fetchDataForZoomedJobIdsSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.showToggleSideBar?.currentValue === true && changes?.showToggleSideBar?.previousValue === false) {
      this.isEditLayer = true
    }
    if (changes?.zoomedFeaturesObservableArray?.isFirstChange() && changes?.zoomedFeaturesObservableArray?.currentValue?.length) {
      // this.spinner.show()
      // this.alreadyLoadedData = false;
      // this._fetchDataForZoomedJobIds();
      // this.reteriveVectorTilesbyUser();
    }
    if (changes?.zoomedImportedImageObservableArray?.isFirstChange() && changes?.zoomedImportedImageObservableArray?.currentValue?.length) {
      this.reteriveGeoReferenceImagebyUser();
    }

    if (changes?.selectedFeature?.currentValue && !this.compareArrays(changes?.selectedFeature?.currentValue, changes?.selectedFeature?.previousValue)) {
      if (this.isEditingModeIsOn) {
        this.addDrawEvents();
      }
    }
    if(changes?.zoomedJobIds?.currentValue) {
      // this.metadataService.loadJobsMetadata(this.zoomedJobIds);
      if(this.zoomMap) {
        this.verifyZoomedJobIdChange(changes)
      }
      else if(this.dragMap) {
        this.onZoomJobIdChanged()
      }
    }
    if( (changes?.zoomedJobIds?.currentValue !== changes?.zoomedJobIds?.previousValue) && !changes?.zoomedJobIds?.isFirstChange()) {
      if(!this.compareArrays(changes?.zoomedJobIds?.currentValue, changes?.zoomedJobIds?.previousValue)){
        console.log("Change in Reference ZoomedJobIds...!");
        this.reteriveGeoReferenceImagebyUser();
      }
    }
    if (changes?.enableEditModelOnDblClick?.currentValue === true && changes?.enableEditModelOnDblClick?.previousValue === false) {
      // this.loadFeature();
      setTimeout(() => {
        this._editOnDblClick();
      }, 500);
    }

    if(changes?.slideMenuOpenTools || changes?.slideMenuOpenLayerDetails ||
      changes?.slideMenuOpenJobDescription || changes?.slideMenuOpenSiteDetails || changes?.slideMenuOpenFolder ) {
        this.isHistoryOpend = false;
      }

    if (changes?.addGroupFeatureTypeObj?.currentValue) {
      this.addGroupFeatureType(this.getLayerName(this.addGroupFeatureTypeObj.layerId), this.addGroupFeatureTypeObj.mergedLine)
    }
  }


 verifyZoomedJobIdChange(changes:SimpleChanges) {
    if(!this.compareArrays(changes?.zoomedJobIds?.currentValue, changes?.zoomedJobIds?.previousValue)){ // && this.slideMenuOpenLayerDetails){
        console.log("Change in ZoomedJobIds...!");
        this.onZoomJobIdChanged();
    }
  }

  private compareArrays(arr1:any[], arr2:any[]) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  private onZoomJobIdChanged() {
    const newJobIds = [];
      for (let index = 0; index < this.zoomedJobIds.length; index++) {
        const jobId = this.zoomedJobIds?.[index];
        if(!this.jobIdToFeatures.hasOwnProperty(jobId)) {
          newJobIds.push(jobId);
        }
      }
     this.referenceLayerStatusData = this.data.find((data:CommitChanges)=> data.layerId === this.referenceLayerId);
      if(newJobIds?.length) {
        // For Panning
        this.metadataService.loadJobsMetadata(this.zoomedJobIds);
        this._resetData();
        this.fetchDataForZoomedJobIdsSubject.next({jobIds: newJobIds, isNewJobIdPresent: true});
      } else {
        // For ZoomIn/ZoomOut
        this._resetData();
        this._prepareStructure();
      }
  }

  initialSiteLayerData:any;

  consoleLog(group:any) {
    console.log("single group: ", group);
  }

  private _getNavigatedFeaturesArray(allData: any[], isNewJobIdPresent?:boolean): any {
    const navigate:any[] = [];
    const arr = this.groupItems(allData, 'navigatedId');
    arr['navigatedId']?.forEach((data: any) => {
      data.jobName = this.metadataService.jobIdToJobName[data.jobId];
      data.workOrderNumber = this.metadataService.jobIdToWONo[data.jobId];
      if (data.layerId === 5) {
        const ft = allData.filter((obj: any) => obj.layerId === 5);
        data.features = ft ? ft : [];
        navigate.push(data);
      } else {
        const fts = JSON.parse(JSON.stringify(this.jobIdToFeatures[data.jobId]));
        data.features = fts?.length ? fts : [];
        navigate.push(data);
      }
   })

  //console.log("navigate: ", navigate);
  // this.navigatedFeatures = isNewJobIdPresent? this.navigatedFeatures.concat(navigate) : navigate;
  navigate.forEach(nav => {
    const idx = this.navigatedFeatures.findIndex(x => x.layerId === nav.layerId && x.jobId === nav.jobId && x.workOrderNumber === nav.workOrderNumber);
    if(idx === -1) {
      this.navigatedFeatures.push(nav);
    } else if (nav.layerId === 3) {
      this.navigatedFeatures[idx].features = nav.features;
    }
  });
  this.initialSiteLayerData = this.navigatedFeatures.find((data:any)=> data?.layerId === 5);
  }


  groupItems(array:any[], property:any) {
    let reducer = function(groups:any, item:any) {
        let name = item[property] ? item[property] : property
        let group = groups[name] || (groups[name] = []);
        if(property === "featureGroupId") {
          if(group.findIndex((data:any)=> data.featureTypeId === item.featureTypeId) === -1 )
            group.push({featureTypeId:item.featureTypeId, featureType:item.featureType, features:[]});
        } else if(property === "featureTypeId"){
            group.push({featureId:item.featureId, feature:item.feature, jobId:item.jobId});
        } else if(property === "layerId"){
          if(group.findIndex((data:any)=> data.featureGroupId === item.featureGroupId) === -1 )
            group.push({featureGroupId:item.featureGroupId, featureGroup:item.featureGroup, types:[]});
        } else if(property === "navigatedId") {
          if(group.findIndex((data:any)=> data.jobId === item.jobId && data.layerId === item.layerId) === -1 ){
          group.push({layerId:item.layerId,
                      layerName:item.layerName,
                      jobId:item.jobId,
                      jobName:item.jobName,
                      workOrderNumber:item.workorderNumber? item.workorderNumber : item.workOrderNumber,
                      siteId:0,
                      siteName: "string",
                      features:[]
                    });
          }
        } else {
          if(item.layerId !== 5) {
            group.push(item);
          }
        }
        return groups;
    };
    return array.reduce(reducer, {});
}

private _prepareStructure() {
  for (let i = 0; i < this.navigatedFeatures.length; i++) {
    let featuresData = this.navigatedFeatures[i];
    if(this.zoomedJobIds.includes(featuresData?.jobId) || featuresData?.layerId === 5 ) {
        featuresData.layerName = this.getLayerName(featuresData.layerId);
        this.zoomedJobIdLayerIdMap.set(this.zoomedJobIds[i]?.toString(), []);
        this.setValuesInRequiredMaps(featuresData);
    }
  }

  this._validateAllLayersPresentOrNot();
  this.data.sort((a:any,b:any) => a.layerId - b.layerId);

  // Shifting Site and Reference layer to first and last position respectively
  this._moveSitelayerToTop();
  this._moveReferencelayerToLast();

  console.timeEnd("Structure Processing");
  console.log("THis.Data ", this.data);

  this.spinner.hide();
  console.log('this.navigatedFeatures', this.navigatedFeatures);
  this.updateNavigatedFeatures.emit(this.navigatedFeatures);

  this.jobDetailsForGPRS = this.navigatedFeatures.filter(
    (feature: any) => feature.layerId === 1
  );
  console.log('jobDetailsForGPRS : ', this.jobDetailsForGPRS);
  this.jobDetailsForGPRS.forEach((job:any)=> {
    if(job.jobId) {
      job.jobName = job.jobName ? job.jobName : this.metadataService.jobIdToJobName[job.jobId];
      job.workOrderNumber = job.workOrderNumber ? job.workOrderNumber : this.metadataService.jobIdToWONo[job.jobId];
      job['access'] = this.metadataService.jobIdToAccess[job.jobId] === 1 ? 'view' : 'edit';
    }
  })

  this.isGroupMapCompleted = true;
  this.siteLayerData = this.navigatedFeatures.filter(
    (feature: any) => feature.layerId === 5
  );
  if (this.siteLayerData.length > 0) {
    this.siteLayerData = this.siteLayerData[0];
  }
  this.toggleApiLoaded = true;
  this.siteToggleApplied.emit(false);

}

private _validateAllLayersPresentOrNot() {
  const layerNotPresent = [];
  for (let index = 1; index <= 5; index++) {
    if(this.data.findIndex((layer:CommitChanges)=> layer.layerId === index) === -1){
      layerNotPresent.push(index);
    }
  }
  console.log(layerNotPresent, "  =  ", this.data);

  for (let index = 0; index < layerNotPresent.length; index++) {
    const layer = layerNotPresent[index]
      this.data.push({
        layerId : layer,
        layerName : this.getLayerName(layer),
        groups: [],
        isEyeVisible: true,
        isLayerEditVisible:true
      })
  }

  if(this.data.findIndex((layer:CommitChanges)=> layer.layerId === this.referenceLayerId) === -1){
    if(this.referenceLayerStatusData){
      this.data.push(this.referenceLayerStatusData);
    } else {
      this.data.push({ layerId: this.referenceLayerId, layerName: "REFERENCE", isEyeVisible: false, isLayerEditVisible:true, groups: [] });
    }
  }

}

private _moveSitelayerToTop() {
  let index = this.data.findIndex((obj: any) => obj?.layerId === 5);
  this.data.unshift(this.data.splice(index, 1)[0]);
}

private _moveReferencelayerToLast() {
  let index = this.data.findIndex((obj: any) => obj?.layerId === this.referenceLayerId);
  this.data.push(this.data.splice(index, 1)[0]);
}

  private _editOnDblClick() {
    if (this.clickedFeatures.length > 0) {
      if (this.isGprsLayerCanEdit && this.clickedFeatures[0].properties.layerId === 1) {
        this._editLayer();
      }
      else if (this.isClientLayerCanEdit && this.clickedFeatures[0].properties.layerId === 2) {
        this._editLayer();
      }
      else if (this.isImportedLayerCanEdit && this.clickedFeatures[0].properties.layerId === 3) {
        this._editLayer();
      }
      else if (this.isExternalLayerCanEdit && this.clickedFeatures[0].properties.layerId === 4) {
        this._editLayer();
      }
      else {
        alert("You do not have access to edit features in " + this.getLayerName(this.clickedFeatures[0].properties.layerId) + " Layer");
      }
    }
  }

  private _editLayer() {
    let isEdit = true;
    const layer = this.getLayerName(this.clickedFeatures[0].properties.layerId);
    let potentialEditingFeatures = this._getPotentialEditingFeatures(this.clickedFeatures);
    isEdit = this._validateIsEditOption(potentialEditingFeatures, isEdit);

    if(isEdit) {
      if(!this.isEditingModeIsOn) {
        this.gotoMapEditor(layer);
      }
      if (this.isEditingModeIsOn && this.clickedFeatures?.length > 0) {
        this.editSelectedFeature = this.clickedFeatures[0];
        this.showHideToggleGroup(this.clickedFeatures[0]);
        this.handleEditingforPointorLine(this.editSelectedFeature);
      }
    } else if(!this.isEditingModeIsOn) {
      this.ontoggle(false,false, false, false, false, false);
      setTimeout(() => {
        this._cancelFeatureEditing(layer);
        return;
      }, 500);
    }
  }

  private _validateIsEditOption(potentialEditingFeatures: any, isEdit: boolean) {
    if (potentialEditingFeatures.length > 0) {
      let editingFeature = potentialEditingFeatures[0];
      let editingFeatureName = editingFeature.properties.featureName;
      let editingFeatureType = editingFeature.properties.featureType;
      let editingFeatureGroup = editingFeature.properties.featureGroup;
      if (potentialEditingFeatures.length > 1) {
        let confirmMessage: string = `You have selected multiple features. Would you like to edit ${editingFeatureGroup} - ${editingFeatureType} - ${editingFeatureName}?`;
        if (editingFeature.geometry.type === 'Point') {
          if (!this.disablePointInteractions && !this.isDrawing) {
            if (!confirm(confirmMessage)) {
              isEdit = false;
            }
          }
        } else {
          if (this.editingState?.editingModes.isAddingVertex) {
            if (this.addVertexLineId === '') {
              if (!confirm(confirmMessage)) {
                isEdit = false;
              }
            }
          } else if (!this.isDrawing) {
            if (!confirm(confirmMessage)) {
              isEdit = false;
            }
          }
        }
      }
    } else {
      // ???
    }
    return isEdit;
  }

  private _getPotentialEditingFeatures(clickedFeatures: any) {
    // Valid features are in current editing layer and are not being edited already
   // Get all Draw feature names
   const drawFeatureCollection = this.draw.getAll();
   let currentDrawFeatureNames = drawFeatureCollection.features.map((feature: any) => feature.properties.featureName);
   // Filter from the clicked features only that which are in the correct editing layer
   // and are not already in being edited - i.e. the feature name does not exist in the
   // drawFeatureCollection
   let potentialEditingFeatures = clickedFeatures.filter(
     (feature: any) => feature.properties.layerId === parseInt(this.currentEditinglayerId) &&
       currentDrawFeatureNames.indexOf(feature.properties.featureName) === -1
   );
   return potentialEditingFeatures;
 }

 private _cancelFeatureEditing(layer:any){
    this.spinner.hide();
    this.toggleEditingState.emit({editState:false, layer:this.getLayerID(layer)});
    this.newAddedFeatureInLayer = { layerName: String };
    this.newlyAddedFeature = [];
    this.isEditLayer = true;
    this.editLayerName = null;
    this.editSelectedFeature = null;
    this.showAllLayer();
    this.isEditingModeIsOn = false;
    this.updateEditMode.emit(false);
    this.showAddFeatureContainerFrMapEditor = false;
    this.showAddFeatureButton = true;
    // this.isLayerEyeVisible[layer] = false;
    // this.toggleGPRSLayer('GPRS');
    // this.toggleGPRSLayer('CLIENT');
    // this.toggleGPRSLayer('IMPORTED');
    this.data.forEach((layer:CommitChanges)=> {
      if([1,2,3].includes(+layer.layerId)) {
        this.testToggleLayer(layer)
      }
    })
    // this.isLayerEyeVisible = {
    //   SITE: true,
    //   GPRS: true,
    //   CLIENT: true,
    //   IMPORTED: true,
    //   EXTERNAL: true,
    //   REFERENCE: false
    // };
    this.selectOptionsExternalEnable = false;
    this.externalContentForm?.reset(this.externalContentFormOriginal);
}

  // When user clicks a feature to edit on the map and it is "valid"
  handleEditingforPointorLine(editingFeature: any) {
      if (editingFeature.geometry.type === 'Point') {
        // Don't allow Point interactions on MVT features when in Line editing modes (this.disablePointInteractions = true)
        // or when in a drawing mode (sitemap_draw_point, sitemap_draw_line_string, etc.)
        if (!this.disablePointInteractions &&
          !this.isDrawing) {
          this.getFeatureDetailsByFeatureIdForMapEditor(editingFeature);
        }
      } else {
        // Disallow Line interactions in "Add Vertex" mode, if a Line is already selected for the process.
        if (this.editingState?.editingModes.isAddingVertex) {
          if (this.addVertexLineId === '') {
            this.getFeatureDetailsByFeatureIdForMapEditor(editingFeature);
          }
        }
        // Disable MVT Line interactions when in a drawing mode (sitemap_draw_point, sitemap_draw_line_string, etc.)
        else if (!this.isDrawing) {
          this.getFeatureDetailsByFeatureIdForMapEditor(editingFeature);
        }
      }
  }

  handleGetFeatureDetails(retrievedFeature: any, geomType: string) {

    let geometry: any = retrievedFeature.geometry;
    let drawFeature: any = {};
    drawFeature.id = '999' + Math.random().toString().slice(2, 10)//Math.random().toString(36).slice(2) //; //retrievedFeature.properties.featureName.replace('.', '_'); //Math.random().toString().slice(2, 10); //
    drawFeature.type = 'Feature';
    // coords variable for manage External layer and other layers data seperately
    let coords = '';
    // coords = JSON.parse(geometry.coordinates);


    if (this.externalLayerItems.includes(retrievedFeature.properties.featureGroup.toUpperCase().replace(/\s/g, ""))) {
      coords = geometry.coordinates;
    } else {
      if (typeof(geometry) === 'string') {
        geometry = JSON.parse(geometry);
        coords = geometry.coordinates;
      } else {
        geometry.coordinates = geometry.coordinates.replace('STRING, ', '');
        geometry.coordinates = geometry.coordinates.replace('MULTI, ', '');
        coords = JSON.parse(geometry.coordinates);
      }
    }

    if (geomType === 'Point') {
      //  drawFeature.geometry variable for manage External layer and other layers data seperately

      // drawFeature.geometry = { type: 'Point', coordinates: coords[0] }
      if (this.externalLayerItems.includes(retrievedFeature.properties.featureGroup.toUpperCase().replace(/\s/g, ""))) {
        drawFeature.geometry = { type: 'Point', coordinates: coords }
      } else {
        drawFeature.geometry = { type: 'Point', coordinates: coords[0] }
      }
      // this.draw.changeMode('draw_point');
      // this.handleUpdatePoint()
    } else {
      drawFeature.geometry = { type: 'LineString', coordinates: coords }
      // this.draw.changeMode('draw_line_string');
      // this.handleUpdateLine()
    }
    let drawId = this.draw.add(drawFeature);
    this.draw.setFeatureProperty(
      drawId,
      'featureGroup',
      retrievedFeature.properties.featureGroup
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureType',
      retrievedFeature.properties.featureType
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureName',
      retrievedFeature.properties.featureName
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureClass',
      retrievedFeature.properties.featureClass
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureGroupId',
      retrievedFeature.properties.featureGroupId
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureTypeId',
      retrievedFeature.properties.featureTypeId ? retrievedFeature.properties.featureTypeId : retrievedFeature.properties.featureGroupId
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureId',
      retrievedFeature.properties.featureId
    );
    this.draw.setFeatureProperty(
      drawId,
      'mapJobId',
      retrievedFeature.properties.map_job_id ? retrievedFeature.properties.map_job_id : retrievedFeature.properties.mapJobId
    );
    this.draw.setFeatureProperty(
      drawId,
      'mapLayerId',
      retrievedFeature.properties.layerId
    );
    if (geomType === 'Point') {
     // this.draw.changeMode('simple_select', { featureIds: [drawId] });
      if (retrievedFeature.properties.featureClass === 'point') {
        this.draw.setFeatureProperty(
          drawId,
          'mapPointId',
          retrievedFeature.properties.featureId
        );
      } else if (retrievedFeature.properties.featureClass === 'annotation_point') {
        this.draw.setFeatureProperty(
          drawId,
          'mapAnnotationPointId',
          retrievedFeature.properties.featureId
        );
      } else if (this.externalLayerItems.includes(retrievedFeature.properties.featureClass.toUpperCase().replace(/\s/g, ""))) {
        this.draw.setFeatureProperty(
          drawId,
          'mapPointId',
          retrievedFeature.properties.featureId
        );
      }
      this.draw.changeMode('sitemap_simple_select', {
        featureIds: drawId,
        mapLayerId: this.currentEditinglayerId,
        navigatedFeatures: this.navigatedFeatures,
        isSnappingToVertex: this.isSnappingToVertex,
        isSnappingToLine: this.isSnappingToLine
        })
    } else if (geomType === 'LineString' || geomType === 'MultiLineString') {
      let lineFeature = this.draw.get(drawId);
      // this.draw.changeMode('direct_select', { featureId: lineFeature.id });
      if (retrievedFeature.properties.featureClass === 'line') {
        this.draw.setFeatureProperty(
          drawId,
          'mapLineId',
          retrievedFeature.properties.featureId
        );
      } else if (retrievedFeature.properties.featureClass === 'line_annotation') {
        this.draw.setFeatureProperty(
          drawId,
          'mapAnnotationLineId',
          retrievedFeature.properties.featureId
        );
      }


      let editingLineMode: any;
      let simpleSelectFeatureIds: Array<string> = [];
      if (this.editingState?.editingModes.isMergingLine) {
        editingLineMode = 'merge_line';
        // // Check for a selected Line - if there is one, then we can merge the pre-existing Line with this new Line
        if (this.mergeParentLineId !== '') {
          this.mergeChildLineId = lineFeature.id;
          this.parentOrChildMerge.emit({child: this.mergeChildLineId})
          this.draw.changeMode('sitemap_simple_select', {
            featureIds: [this.mergeParentLineId, this.mergeChildLineId],
          });
          // this.draw.changeMode('merge_line', {
          //   featureIds: [this.mergeParentLineId, this.mergeChildLineId],
          // });
        } else {
          this.mergeParentLineId = lineFeature.id;
          this.parentOrChildMerge.emit({parent:this.mergeParentLineId})
          this.draw.changeMode('sitemap_simple_select', {
            featureIds: [this.mergeParentLineId],
          });
        }
      } else if (this.editingState?.editingModes.isAddingVertex) {
        editingLineMode = 'add_vertex';
        simpleSelectFeatureIds = [lineFeature.id];
      } else if (this.editingState?.editingModes.isMovingVertex) {
        editingLineMode = 'move_vertex';
      } else if (this.editingState?.editingModes.isDeletingVertex) {
        editingLineMode = 'delete_vertex';
      } else {
        editingLineMode = 'sitemap_direct_select';
      }
      if (this.editingState?.editingModes.isAddingVertex) {
        // This is analagous to simple_select
        setTimeout(() => {
          this.draw.changeMode(editingLineMode, {
            featureIds: simpleSelectFeatureIds,
            mapLayerId: this.currentEditinglayerId,
            navigatedFeatures: this.navigatedFeatures,
            isSnappingToVertex: this.isSnappingToVertex,
            isSnappingToLine: this.isSnappingToLine
          });
        }, 500);

      } else if (!this.editingState?.editingModes.isMergingLine) {
        // This is analagous to direct_select
        setTimeout(() => {
          this.draw.changeMode((editingLineMode), {
            featureId: lineFeature.id,
            mapLayerId: this.currentEditinglayerId,
            navigatedFeatures: this.navigatedFeatures,
            isSnappingToVertex: this.isSnappingToVertex,
            isSnappingToLine: this.isSnappingToLine
          });
        }, 500);

      }
    }
    // Hide the associated feature from the MVT
    this.hiddenFeatures.push(retrievedFeature.properties.featureName);
    // Need to set the feature filter
    // this.setGroupFilter('on', retrievedFeature.properties.featureGroup);
    this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
  }

  //# get features details for map editor
  getFeatureDetailsByFeatureIdForMapEditor(editFeature: any, isUpdateDescription?: any) {

    if (editFeature.properties.matterportLinks) {
      this.matterportLinkArray = JSON.parse(editFeature.properties.matterportLinks);
    }

    // Handle external layers features seperate without fetch feature details from API
    if (this.externalLayerItems.includes(editFeature.properties.featureGroup.toUpperCase().replace(/\s/g, "")) && !isUpdateDescription) {
      this.handleGetFeatureDetails(editFeature, editFeature.geometry.type)
      return
    }
    let geomType = editFeature.geometry.type;
    let editFeatureId = editFeature.properties.featureId;

    switch (geomType) {
      case 'Point':
        this.spinner.show();
        this.mapViewerJobFeatureSubscribe =
          this.mapViewerJobFeatureService
            .fetchPointFeatureDetail(
              this.checkAnnotationPoint(editFeature.properties),
              parseInt(editFeatureId)
            ).subscribe((response: any) => {

              const linkaddedResponse: any = response;

              if (response.groups.features.length === 1) {
                // this.refreshDescriptionData(response);
                // this.onRefreshDescriptionData.emit(response);

                if (!isUpdateDescription) {
                  let retrievedFeature = response.groups.features[0];
                  this.selectedFeatureDetailDB = retrievedFeature;
                  this.handleGetFeatureDetails(retrievedFeature, geomType);
                }
              } else {
                // ???
              }
              this.spinner.hide();
            });
        break;
      case 'LineString':
        this.spinner.show();
        this.mapViewerJobFeatureSubscribe =
          this.mapViewerJobFeatureService
            .fetchLineFeatureDetail(
              this.checkAnnotationLine(editFeature.properties),
              parseInt(editFeatureId)
            ).subscribe((response: any) => {
              const linkaddedResponse: any = response;

              if (response.groups.features.length === 1) {
                // this.refreshDescriptionData(response);
                // this.onRefreshDescriptionData.emit(response);
                if (!isUpdateDescription) {
                  let retrievedFeature = response.groups.features[0];
                  this.selectedFeatureDetailDB = retrievedFeature;
                  this.handleGetFeatureDetails(retrievedFeature, geomType);
                }
              } else {
                // ???
              }
              this.spinner.hide();
            });
        break;
      case 'MultiLineString':
          this.spinner.show();
          this.mapViewerJobFeatureSubscribe =
            this.mapViewerJobFeatureService
              .fetchLineFeatureDetail(
                this.checkAnnotationLine(editFeature.properties),
                parseInt(editFeatureId)
              ).subscribe((response: any) => {
                const linkaddedResponse: any = response;
                if (response.groups.features.length === 1) {
                  // this.refreshDescriptionData(response);
                  // this.onRefreshDescriptionData.emit(response);
                  if (!isUpdateDescription) {
                    let retrievedFeature = response.groups.features[0];
                    this.selectedFeatureDetailDB = retrievedFeature;
                    this.handleGetFeatureDetails(retrievedFeature, geomType);
                  }
                } else {
                  // ???
                }
                this.spinner.hide();
              });
          break;
      default:
        break;
    }
  }

  checkAnnotationLine(feature: any) {
    if (feature.featureClass && feature.featureClass.includes('annotation')) {
      return 'GetAnnotationLineFeatureById';
    } else {
      return 'RetrieveLineDetailsByLineId';
    }
  }

  checkAnnotationPoint(feature: any) {
    if (feature.featureClass && feature.featureClass.includes('annotation')) {
      return 'GetAnnotationPointFeatureById';
    } else {
      return 'RetrievePointDetailsByPointId';
    }
  }

  isUndoDisabled(feature: any):boolean{
    const undoLength = this.editingHistoryUndoArray.filter((x:any) => x.properties?.featureName == feature.featureName);
    return undoLength.length == 1 ? true : false;
  }

  isRedoDisabled(feature: any):boolean{
    const redoLength = this.editingHistoryRedoArray.filter((x:any) => x.properties?.featureName == feature.featureName);
    return redoLength.length == 0 ? true : false;
  }

  handleUndo(layer: any, feature: any) {

    const featureHistoryData = this.editingHistoryUndoArray.filter((x:any) => x.properties?.featureName == feature.featureName);

    this.editingHistoryUndoRedo[feature.featureName] = featureHistoryData.length-1;

    if(this.editingHistoryUndoRedo[feature.featureName] > -1){
      this.draw.add({
        type: 'FeatureCollection',
        features: [featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]-1]]
      });
    }else if(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]]){
      //this.draw.delete(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]].id);
      // Unhide the saved feature
      const index = this.hiddenFeatures.indexOf(
        feature.featureName
      );
      // if (index > -1) {
      //   this.hiddenFeatures.splice(index, 1);
      //   // Need to set the feature filter
      //   this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
      // }
    }

    if(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]]){
      const undoIndex = this.editingHistoryUndoArray.indexOf(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]]);
      this.editingHistoryRedoArray.push(...this.editingHistoryUndoArray.splice(undoIndex, 1));
    }
}

handleRedo(layer: any, feature: any) {
  let featureHistoryData = this.editingHistoryRedoArray.filter((x:any) => x.properties?.featureName == feature.featureName);
  featureHistoryData = featureHistoryData.reverse()
  if(typeof this.editingHistoryUndoRedo[feature.featureName] == 'number')
  {
    if(featureHistoryData[0]){
      this.draw.add({
        type: 'FeatureCollection',
        features: [featureHistoryData[0]]
      });
      const redoIndex = this.editingHistoryRedoArray.indexOf(featureHistoryData[0]);
      this.editingHistoryUndoArray.push(...this.editingHistoryRedoArray.splice(redoIndex, 1));
      this.editingHistoryUndoRedo[feature.featureName] = this.editingHistoryUndoRedo[feature.featureName]+1;
    }
  }
}


  //getLayerName method is used to get the layer name basis on layer id
  getLayerName(layerId: any) {
    let layerName = '';
    if (layerId === 1) {
      layerName = 'GPRS';
    } else if (layerId === 2) {
      layerName = 'CLIENT';
    } else if (layerId === 3) {
      layerName = 'IMPORTED';
    } else if (layerId === 4) {
      layerName = 'EXTERNAL';
    } else if (layerId === 5) {
      layerName = 'SITE';
    } else if (layerId === this.referenceLayerId) {
      layerName = 'REFERENCE';
    }
    return layerName;
  }

  ngOnInit(): void {
    this._resetCommitChanges();
    this.isGprsLayerCanEdit = true;
    this.getSiteList();
    this.enabledEditOption();
    this.getExternalContentTypes();
    this.loginUserRole = this.loginUserRole ? this.loginUserRole : sessionStorage.getItem('loginUserRole');

    this.importGISForm = this.fb.group({
      importSelectFormControl: ['', Validators.required]
    });

    this.cadCustomForm = this.fb.group({
      cadFeatures: this.fb.array([]),
      coordinateCRSName: new FormControl(),
    });

    this.reteriveVectorTilesbyUser();

    this.reteriveGeoReferenceImagebyUser();
    this.fetchDataForZoomedJobIdsSubscription =  this.fetchDataForZoomedJobIdsSubject.pipe(debounceTime(300), distinctUntilChanged()).subscribe((data: any) => {
      this._fetchDataForZoomedJobIds(data);
    });
  }

  private _resetCommitChanges() {
    this.mapEditService.mapEditorChangesToBeCommitted = [];
    this.mapEditService.updateChangesCount();
  }


  addDrawEvents() {
    let selectedFeature = this.draw.getSelected().features[0];
    selectedFeature = selectedFeature ? selectedFeature : this.selectedFeature;
    console.log('selectedFeature Click', selectedFeature);
    if (selectedFeature?.properties) {
      if (!this.externalLayerItems.includes(selectedFeature?.properties?.featureGroup?.toUpperCase()?.replace(/\s/g, ""))) {
        this.editSelectedFeature = selectedFeature;
        if (selectedFeature?.properties?.featureGroupId) {
          this.groupToSelect = selectedFeature?.properties.featureGroupId.toString();
          this.bindFeatureTypeDropdown(parseInt(this.groupToSelect), this.editSelectedFeature);
        }
      }
      let tempModalExternalAddHeader = this.modalExternalAddHeader;
      if (this.modalExternalAddHeader?.toUpperCase() == "VIRTUAL TOUR") {
        tempModalExternalAddHeader = "Matterport";
      }

      if (this.selectOptionsExternalEnable && (tempModalExternalAddHeader === this.selectOptionsExternal)) {
        this.latitudeExternalContent = selectedFeature.geometry.coordinates[1];
        this.longitudeExternalContent = selectedFeature.geometry.coordinates[0];
        this.externalContentId = selectedFeature.id;
        let button = document.getElementById("btnExternalContent") as HTMLButtonElement;
        button.click();
      }
    }
    else {
      this.editSelectedFeature = null;
    }
    if (selectedFeature?.geometry?.type == 'LineString') {
      var coordinates = selectedFeature.geometry.coordinates[0].slice();
      const length = turf.length(selectedFeature);
      var rounded_length = Math.round(length * 3280.8398950131);
      if (!this.isEditingModeIsOn) {
        this.measeureLength.emit({ rounded_length, coordinates });
      }
      this.removeExtraPopup.emit();
    } else if (selectedFeature?.geometry?.type === 'Polygon') {
      var coordinates = selectedFeature.geometry.coordinates[0][0].slice();
      const area = turf.area(selectedFeature);
      var rounded_area = Math.round(area * 10.7639);
      this.measureCalcArea.emit({ rounded_area, coordinates });
      this.removeExtraPopup.emit();
    }
  }

  enabledEditOption() {
    if (sessionStorage.getItem('loginUserId')) {
      this.loginUserId = sessionStorage.getItem('loginUserId');
    }
    if (sessionStorage.getItem('loginUserEmailId')) {
      this.loginUserEmailId = sessionStorage.getItem('loginUserEmailId');
    }
    if (sessionStorage.getItem('loginUserRole')) {
      //Project Manager,Admin
      this.loginUserRole = sessionStorage.getItem('loginUserRole');
    }
    if (sessionStorage.getItem('tierId')) {
      //Project Manager,Admin
      this.loginUserTierId = sessionStorage.getItem('tierId');
    }
    // this.loginUserEmailId = 'nitin.rajput@gprsinc.com';
    let emailCheck = this.loginUserEmailId?.split('@');
    if (
      emailCheck[1] === 'gprsinc.com' ||
      this.loginUserRole === 'Admin' ||
      this.loginUserRole === 'Project Manager'
    ) {
      // for gprs employee
      this.isGprsLayerCanEdit = true;
      this.isClientLayerCanEdit = false;
      this.isExternalLayerCanEdit = true;
    } else {
      this.isGprsLayerCanEdit = false;
      this.isClientLayerCanEdit = true;
      this.isExternalLayerCanEdit = true;
    }

    if(this.loginUserRole.toLowerCase() === "admin") {
      this.isReferenceLayerCanEdit = true;
      this.isImportedLayerCanEdit = true;
    } else {
      this.isReferenceLayerCanEdit = false;
      this.isImportedLayerCanEdit = false;
    }
  }

  ontoggle(
    slideMenuOpenSiteAdminDetails:boolean,
    slideMenuOpenSiteDetails: boolean,
    slideMenuOpenLayerDetails: boolean,
    slideMenuOpenTools: boolean,
    slideMenuOpenJobDescription: boolean,
    slideMenuOpenFolder: boolean
  ) {
    this.slideMenuOpenSiteAdminDetails = slideMenuOpenSiteAdminDetails,
    this.slideMenuOpenSiteDetails = slideMenuOpenSiteDetails;
    this.slideMenuOpenLayerDetails = slideMenuOpenLayerDetails;
    this.slideMenuOpenTools = slideMenuOpenTools;
    this.slideMenuOpenJobDescription = slideMenuOpenJobDescription;
    this.slideMenuOpenFolder = slideMenuOpenFolder;
    this.onHistorySlideoutClose(false);
       this.ontoggleClicked.emit({
      slideMenuOpenSiteAdminDetails,
      slideMenuOpenSiteDetails,
      slideMenuOpenLayerDetails,
      slideMenuOpenTools,
      slideMenuOpenJobDescription,
      slideMenuOpenFolder,
    });
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: any) {
    if (event.target.className === 'mapboxgl-canvas') {
      this.clearAllFeature();
      this.slideMenuOpenTools = false;
      this.slideMenuOpenJobDescription = false;
      this.slideMenuOpenLayerDetails = false;
      event.preventDefault();
      event.stopPropagation();
    }
  }

  clearAllFeature() {
    // this.fetchLayerArray()?.forEach((layer: any) => {
    //   this.fetchFeatureGroupArray(layer)?.forEach((featureGroup: any) => {
    //     this.fetchFeatureTypeArray(featureGroup, layer)?.forEach(
    //       (featureType: any) => {
    //         if (
    //           this.isFeatureTypeClicked[layer]?.hasOwnProperty(featureGroup)
    //         ) {
    //           const id = featureType + '-btn' + layer;
    //           this.isFeatureTypeClicked[layer][featureGroup][featureType] =
    //             false;
    //           const htmlBtnTag = document.getElementById(
    //             id
    //           ) as HTMLButtonElement;
    //           const expendVal = htmlBtnTag.getAttribute('aria-expanded');
    //           if (expendVal === 'true') {
    //             htmlBtnTag.setAttribute('aria-expanded', 'false');
    //             let featureTypeBtn = document.querySelector(
    //               `.feature-group-container.${featureGroup} .feature-type-container.${featureType} .show`
    //             );
    //             if (featureTypeBtn) {
    //               featureTypeBtn.classList.remove('show');
    //             }
    //           }
    //         }
    //       }
    //     );
    //   });
    // });
  }

  private _resetData() {
    this.clickedFeatures = []
    this.data = [];
    this.importedLayerFileId = undefined;
    // this.featureGroupMap.clear();
    // this.featureGroupToggleStateMap.clear();
    // this.featureGroupTypeToggleStateMap.clear();
    // this.featureGroupTypeFeatureNameToggleStateMap.clear();
    // this.isLayerEyeVisible = {
    //   SITE: true,
    //   GPRS: true,
    //   CLIENT: true,
    //   IMPORTED: true,
    //   EXTERNAL: true,
    //   REFERENCE: false
    // };
  }

  loadFeature() {
    if(!this.alreadyLoadedData) {
      if (JSON.stringify(this.zoomedJobIds) == JSON.stringify(this.previousZoomJobId) &&
        this.data.length > 0
      ) {
        this.toggleApiLoaded = true;
        return;
      }
      this._loadFeatureData();
    }
  }

  private _loadFeatureData() {
    if (this.showToggleSideBar && this.slideMenuOpenLayerDetails) {
      // if (this.zoomedFeaturesObservableArray.length > 0) {
        // this.spinner.show();
        this.metadataService.loadJobsMetadata(this.zoomedJobIds);
        setTimeout(() => {
          // Added timeout so that jobs metadata get loaded
          this.fetchDataForZoomedJobIdsSubject.next({ jobIds: this.zoomedJobIds});
        }, 500);
      }
    // }
  }

  private _fetchDataForZoomedJobIds(data: {jobIds: any[], isNewJobIdPresent?:boolean}) {
    const arr = [];
    console.time("API processing");
    const jobIds = data.jobIds;
    const isNewJobIdPresent = data.isNewJobIdPresent;
    if(jobIds.length === 0) {
      return;
    }
    this.spinner.show();
    arr.push(this.restService.post(PortalAPI.RETRIEVE_FEATURES_N_LAYER, {
      "jobIds": jobIds,
      "userId": this.loginUserId,
      "layerId": 1
    }));

    arr.push(this.restService.post(PortalAPI.RETRIEVE_FEATURES_N_LAYER, {
      "jobIds": jobIds,
      "userId": this.loginUserId,
      "layerId": 2
    }));

    arr.push(this.restService.post(PortalAPI.RETRIEVE_FEATURES_N_LAYER, {
      "jobIds": jobIds,
      "userId": this.loginUserId,
      "layerId": 3
    }));

    arr.push(this.restService.post(PortalAPI.RETRIEVE_EXTERNALFEATURES_JOBIDS, {
      "jobIds": jobIds,
      "userId": this.loginUserId,
      "layerId": 4
    }));

    const params = {
      "jobIds": jobIds,
      "userId": this.loginUserId,
      }
    // arr.push(this.commonMapService.getSiteContent(params));
      arr.push(this.commonMapService.getSiteContentNewStructure(params));

    this.forkJoinMapFeaturesSubscription = forkJoin(arr).subscribe(
      (result: any[]) => {
        console.timeEnd("API processing");
        this.isDataLoaded = true
        this.previousZoomJobId = this.zoomedJobIds;
        this.spinner.hide();
        const allData:any[] = [].concat(...result);
        console.log("all features data", allData.length);
        this.extractData(allData, isNewJobIdPresent);
        isNewJobIdPresent ? this._getNavigatedFeaturesArray(allData, isNewJobIdPresent) : this._getNavigatedFeaturesArray(allData) ;
        this._prepareStructure();
      },
      (error: any[]) => {
        console.log(error);
        this.toggleApiLoaded = true;
        this.spinner.hide();
      }
    );
    this.reteriveGeoReferenceImagebyUser();
  }


  private extractData(result: any[], isNewJobIdPresent?:boolean) {
    console.time("jobIdToData");
    const jobIds = this.groupItems(result, 'jobId');
    console.log("JobId to features : ", jobIds);

    if(isNewJobIdPresent) {
      console.log("Existing : ", this.jobIdToFeatures);
      console.log("JObId new :  ", jobIds);
      console.log("Merged : ", {...this.jobIdToFeatures, ...jobIds});
      this.jobIdToFeatures = {...this.jobIdToFeatures, ...jobIds};
    } else {
      this.jobIdToFeatures = jobIds;
    }

   for (let index = 0; index < this.zoomedJobIds.length; index++) {
      const id = this.zoomedJobIds[index];
      if(!this.jobIdToFeatures.hasOwnProperty(id)) {
        this.jobIdToFeatures[id] = []
      }
   }
    console.timeEnd("jobIdToData");
    // this._extractGroupingData(allData);
  }

  //setValuesInRequiredMaps method is used set the data for toggle and map binding
  setValuesInRequiredMaps(data: any) {
    let featuresData = data.features;
    for (let i = 0; i < featuresData.length; i++) {
      if(data?.layerId === 5 && (this.zoomedJobIds.includes(featuresData[i].featureTypeId) || featuresData[i]?.featureGroup?.toLowerCase() === "site")) {
        this.dataCreationLayer(featuresData[i], data);
      }
      if(data?.layerId !== 5) {
        this.dataCreationLayer(featuresData[i], data);
      }

    }
  }

  // addMainFeatureData(data: any, feature: any, isValue: any) {
  //   let featureTypeMap = new Map();
  //   let featurePropertyArray: any[] = [];
  //   if (isValue === 'hasValue') {
  //     featureTypeMap = this.featureGroupMap.get(data.layerName);
  //     if (featureTypeMap.has(feature.properties.featureGroup)) {
  //       featureTypeMap = this.featureGroupMap
  //         .get(data.layerName)
  //         .get(feature.properties.featureGroup);
  //       if (featureTypeMap.has(feature.properties.featureType)) {
  //         featurePropertyArray = [] = featureTypeMap.get(
  //           feature.properties.featureType
  //         );
  //         let featureTypeArr = this.featureGroupMap.get(data.layerName);
  //         featurePropertyArray.push(feature.properties);
  //         featureTypeMap.set(
  //           feature.properties.featureType,
  //           featurePropertyArray
  //         );
  //         featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
  //         this.featureGroupMap.set(data.layerName, featureTypeArr);

  //         //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
  //         let temp = new Map();
  //         temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
  //           data.layerName
  //         );
  //         let toggleGroup = new Map();
  //         toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
  //           .get(data.layerName)
  //           .get(feature.properties.featureGroup);
  //         let featureNameMap = new Map();
  //         featureNameMap = toggleGroup.get(feature.properties.featureType);
  //         featureNameMap.set(feature.properties.featureName, true);
  //         toggleGroup.set(feature.properties.featureType, featureNameMap);
  //         temp.set(feature.properties.featureGroup, toggleGroup);
  //         this.featureGroupTypeFeatureNameToggleStateMap.set(
  //           data.layerName,
  //           temp
  //         );
  //       } else {
  //         featurePropertyArray = [];
  //         let featureTypeArr = this.featureGroupMap.get(data.layerName);
  //         featurePropertyArray.push(feature.properties);
  //         featureTypeMap.set(
  //           feature.properties.featureType,
  //           featurePropertyArray
  //         );
  //         featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
  //         this.featureGroupMap.set(data.layerName, featureTypeArr);

  //         //for featureGroupTypeToggleStateMap set true for toggle
  //         let temp = new Map();
  //         let toggleGroup = this.featureGroupTypeToggleStateMap.get(
  //           data.layerName
  //         );
  //         temp = this.featureGroupTypeToggleStateMap
  //           .get(data.layerName)
  //           .get(feature.properties.featureGroup);
  //         temp.set(feature.properties.featureType, true);
  //         toggleGroup.set(feature.properties.featureGroup, temp);
  //         this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

  //         //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
  //         temp = new Map();
  //         temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
  //           data.layerName
  //         );
  //         toggleGroup = new Map();
  //         toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
  //           .get(data.layerName)
  //           .get(feature.properties.featureGroup);
  //         let featureNameMap = new Map();
  //         featureNameMap.set(feature.properties.featureName, true);
  //         toggleGroup.set(feature.properties.featureType, featureNameMap);
  //         temp.set(feature.properties.featureGroup, toggleGroup);
  //         this.featureGroupTypeFeatureNameToggleStateMap.set(
  //           data.layerName,
  //           temp
  //         );
  //       }
  //     } else {
  //       featurePropertyArray = [];
  //       let featureTypeArr = new Map();
  //       featurePropertyArray.push(feature.properties);
  //       featureTypeArr.set(
  //         feature.properties.featureType,
  //         featurePropertyArray
  //       );
  //       featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
  //       this.featureGroupMap.set(data.layerName, featureTypeMap);

  //       //for featureGroupToggleStateMap set true fpr toggle
  //       let temp = this.featureGroupToggleStateMap.get(data.layerName);
  //       temp.set(feature.properties.featureGroup, true);
  //       this.featureGroupToggleStateMap.set(data.layerName, temp);

  //       //for featureGroupTypeToggleStateMap set true for toggle
  //       temp = new Map();
  //       temp = this.featureGroupTypeToggleStateMap.get(data.layerName);
  //       let toggleGroup = new Map();
  //       toggleGroup.set(feature.properties.featureType, true);
  //       temp.set(feature.properties.featureGroup, toggleGroup);
  //       this.featureGroupTypeToggleStateMap.set(data.layerName, temp);

  //       //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
  //       temp = new Map();
  //       temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
  //         data.layerName
  //       );
  //       toggleGroup = new Map();
  //       let featureNameMap = new Map();
  //       featureNameMap.set(feature.properties.featureName, true);
  //       toggleGroup.set(feature.properties.featureType, featureNameMap);
  //       temp.set(feature.properties.featureGroup, toggleGroup);
  //       this.featureGroupTypeFeatureNameToggleStateMap.set(
  //         data.layerName,
  //         temp
  //       );
  //     }
  //   } else {
  //     let featureTypeArr = new Map();
  //     featurePropertyArray.push(feature.properties);

  //     featureTypeArr.set(feature.properties.featureType, featurePropertyArray);
  //     featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
  //     this.featureGroupMap.set(data.layerName, featureTypeMap);

  //     //for featureGroupToggleStateMap set true for toggle
  //     let temp = new Map();
  //     temp.set(feature.properties.featureGroup, true);
  //     this.featureGroupToggleStateMap.set(data.layerName, temp);

  //     //for featureGroupTypeToggleStateMap set true for toggle
  //     temp = new Map();
  //     let toggleGroup = new Map();
  //     temp.set(feature.properties.featureType, true);
  //     toggleGroup.set(feature.properties.featureGroup, temp);
  //     this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

  //     //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
  //     temp = new Map();
  //     toggleGroup = new Map();
  //     let featureNameMap = new Map();
  //     featureNameMap.set(feature.properties.featureName, true);
  //     temp.set(feature.properties.featureType, featureNameMap);
  //     toggleGroup.set(feature.properties.featureGroup, temp);
  //     this.featureGroupTypeFeatureNameToggleStateMap.set(
  //       data.layerName,
  //       toggleGroup
  //     );
  //   }
  // }

  // fetchLayerArray(): string[] {
  //   // let tempArr: string[] = Array.from(this.featureGroupMap.keys());
  //   // if (tempArr.length > 0 && tempArr.find((it: any) => it.includes('SITE'))) {
  //   //   tempArr?.pop();
  //   //   tempArr.unshift('SITE');
  //   // }
  //   let tempArr = ['SITE','GPRS', 'CLIENT', 'IMPORTED', 'EXTERNAL', 'REFERENCE'];
  //   return tempArr;
  // }

  // isEyeViewVisibleForEmpty(layer: any) {
  //   //  const data = Array.from(this.featureGroupMap.get(layer).keys())?.filter(
  //   //    (elem) => elem
  //   //  );
  //   //  return data?.length > 0 ? true : false;
  //   const val = this.featureGroupMap.has(layer);
  //   return val;
  // }

  // toggleGPRSLayer(layer: any) {
  //   let layerID = this.getLayerID(layer);
  //   this.featureGroupMap.get(layer)?.delete('');
  //   const groupKeys: string[] = this.featureGroupMap.get(layer)?.keys() ? Array.from(this.featureGroupMap.get(layer)?.keys()) : []
  //   if (this.isLayerEyeVisible[layer]) {
  //     this._toggleGPRSLayerOnEyeVisibility(groupKeys, layerID, layer, false);
  //   } else {
  //     this._toggleGPRSLayerOnEyeVisibility(groupKeys, layerID, layer, true);
  //   }
  //   // this.isGPRSLayerVisible = !this.isGPRSLayerVisible;
  //   this.isLayerEyeVisible[layer] = !this.isLayerEyeVisible[layer];
  // }

  // private _toggleGPRSLayerOnEyeVisibility(
  //   groupKeys: string[],
  //   layerID: any,
  //   layer: any,
  //   isEyeVisible: boolean
  // ) {
  //   groupKeys.forEach((featureGroup) => {
  //     const pointLayerId = `${layerID}-${featureGroup}-point`;
  //     const lineLayerId = `${layerID}-${featureGroup}-line`;
  //     // old code for reference
  //     //if block
  //     // this.map.setFilter(pointLayerId, false);
  //     // this.map.setFilter(lineLayerId, false);
  //     // else block
  //     // this.map.setFilter(pointLayerId, this.getGroupFilterExpression(featureGroup));
  //     // this.map.setFilter(lineLayerId, this.getGroupFilterExpression(featureGroup));

  //     this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);
  //     this.featureGroupToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, isEyeVisible);
  //     let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .get(featureGroup);
  //     let featureGroupFeatureNameToggleStateMap: any;
  //     featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
  //       featureGroupFeatureTypeMap.set(key, isEyeVisible);
  //       featureGroupFeatureNameToggleStateMap =
  //         this.featureGroupTypeFeatureNameToggleStateMap
  //           .get(layer)
  //           .get(featureGroup)
  //           .get(key);
  //       featureGroupFeatureNameToggleStateMap.forEach(
  //         (value1: boolean, key1: string) => {
  //           this.featureGroupTypeFeatureNameToggleStateMap
  //             .get(layer)
  //             .get(featureGroup)
  //             .get(key)
  //             .set(key1, isEyeVisible);
  //         }
  //       );
  //     });
  //     this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);
  //   });
  //   if (layerID === 5) {
  //     setTimeout(() => {
  //       this.commonMapService.filterMVTFromToggle(
  //         this.zoomedJobIds,
  //         this.map,
  //         this.siteLayerData
  //       );
  //     }, 200);
  //   } else {
  //     setTimeout(() => {
  //       this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, this.siteLayerData);
  //     }, 200);
  //   }
  // }

  getLayerID(layer: any) {
    let layerID: any = null;
    if (layer === 'GPRS') {
      layerID = 1;
    } else if (layer === 'CLIENT') {
      layerID = 2;
    } else if (layer === 'IMPORTED') {
      layerID = 3;
    } else if (layer === 'EXTERNAL') {
      layerID = 4;
    } else if (layer === 'SITE') {
      layerID = 5;
    } else if (layer === 'REFERENCE') {
      layerID = this.referenceLayerId;
    }
    return layerID;
  }

  checkEyeLayerVisible(layer: any) {
    // return this.isLayerEyeVisible[layer];
  }

  // fetchFeatureGroupArray(layer: any): string[] {
  //   // return Array.from(this.featureGroupMap.get(layer)?.keys());
  //   const layerKeys = Array.from(this.featureGroupMap?.keys());
  //   let featureGroupMap: Map<string, any>;
  //   let data: any[] = [];
  //   if (layerKeys.find((it) => it === layer)) {
  //     featureGroupMap = this.featureGroupMap.get(layer);
  //     data = Array.from(featureGroupMap?.keys());
  //   }
  //   return data;
  // }

  blankReplace(className: any, replaceVal?: any) {
    if (!replaceVal) {
      return className.replaceAll(' ', '');
    } else {
      return className.replaceAll(replaceVal, '');
    }
  }

  // isGroupData(layer: any, group: any) {
  //   if (group) return this.featureGroupMap.get(layer).get(group);
  //   else {
  //     return false;
  //   }
  // }

  getFinalGrp(group: any) {
    let t = group?.split(' ').join('-');
    if (group?.includes('.')) {
      t = t.split('.').join('-');
    }
    if (group?.includes("'")) {
      t = t.split("'").join('-');
    }
    if (group?.includes('&')) {
      t = t.split('&').join('-');
    }
    if (group?.includes('#')) {
      t = t.split('#').join('-');
    }
    return t;
  }

  // checkEyeGroupVisible(featureGroup: any, layer: any) {
  //   const featureGroupVal = this.featureGroupToggleStateMap
  //     .get(layer)
  //     .get(featureGroup);
  //   return featureGroupVal;
  // }

  //toggleGroup method is used to show/hide the group on click on eye and eyelash
  // toggleGroup(featureGroup: any, layer: any, fromLocation?: any) {
  //   const layerID = this.getLayerID(layer);
  //   let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
  //     .get(layer)
  //     .get(featureGroup);
  //   if (this.featureGroupToggleStateMap.get(layer).get(featureGroup)) {
  //     const pointLayerId = `${layerID}-${featureGroup}-point`;
  //     const lineLayerId = `${layerID}-${featureGroup}-line`;
  //     // this.map.setFilter(pointLayerId, false);
  //     // this.map.setFilter(lineLayerId, false);
  //     this.featureGroupToggleStateMap.get(layer).set(featureGroup, false);
  //     let featureGroupFeatureNameToggleStateMap: any;
  //     featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
  //       featureGroupFeatureTypeMap.set(key, false);
  //       featureGroupFeatureNameToggleStateMap =
  //         this.featureGroupTypeFeatureNameToggleStateMap
  //           .get(layer)
  //           .get(featureGroup)
  //           .get(key);
  //       featureGroupFeatureNameToggleStateMap.forEach(
  //         (value1: boolean, key1: string) => {
  //           this.featureGroupTypeFeatureNameToggleStateMap
  //             .get(layer)
  //             .get(featureGroup)
  //             .get(key)
  //             .set(key1, false);
  //         }
  //       );
  //     });
  //     this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);
  //     this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);
  //     setTimeout(() => {
  //       if (fromLocation != 'toggleSite') {
  //         console.log("layer true:", layer);
  //         this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, this.siteLayerData);
  //       }
  //     }, 200);
  //   } else {
  //     const pointLayerId = `${layerID}-${featureGroup}-point`;
  //     const lineLayerId = `${layerID}-${featureGroup}-line`;
  //     // this.map.setFilter(
  //     //   pointLayerId,
  //     //   this.getGroupFilterExpression(featureGroup)
  //     // );
  //     // this.map.setFilter(
  //     //   lineLayerId,
  //     //   this.getGroupFilterExpression(featureGroup)
  //     // );
  //     this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);
  //     let featureGroupFeatureNameToggleStateMap: any;
  //     featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
  //       featureGroupFeatureTypeMap.set(key, true);
  //       featureGroupFeatureNameToggleStateMap =
  //         this.featureGroupTypeFeatureNameToggleStateMap
  //           .get(layer)
  //           .get(featureGroup)
  //           .get(key);
  //       featureGroupFeatureNameToggleStateMap.forEach(
  //         (value1: boolean, key1: string) => {
  //           this.featureGroupTypeFeatureNameToggleStateMap
  //             .get(layer)
  //             .get(featureGroup)
  //             .get(key)
  //             .set(key1, true);
  //         }
  //       );
  //     });
  //     this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);
  //     setTimeout(() => {
  //       if (fromLocation != 'toggleSite') {
  //         console.log("layer:", layer);
  //         this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, this.siteLayerData);
  //       }
  //     }, 200);
  //     //for layer
  //     this.isLayerEyeVisible[layer] = true;
  //   }
  // }

  // fetchFeatureTypeArray(groupKey: any, layer: any) {
  //   const featureGroupMap: Map<string, any> = this.featureGroupMap
  //     .get(layer)
  //     .get(groupKey);
  //   return Array.from(featureGroupMap.keys());
  // }

  removeAllSpaceAndSpecialChar(val: any) {
    if (val==undefined){
      return;
    }
    const noSpecialChars = val.replace(/[^a-zA-Z0-9_-]/g, '');
    return noSpecialChars.replaceAll(' ', '');
  }

  getJobIdFromJobName(featureType: string) {
    let jobIds = this.siteLayerData.features
      .filter((feature: any) => feature.featureType === featureType)
      .map((feature: any) => feature.featureTypeId);
    if (jobIds.length > 0) {
      return parseInt(jobIds[0]);
    } else {
      return -99;
    }
  }

  // checkEyeFeatureTypeVisible(layer: any, featureGroup: any, featureType: any) {
  //   const featureGroupVal = this.featureGroupTypeToggleStateMap
  //     .get(layer)
  //     .get(featureGroup)
  //     .get(featureType);
  //   return featureGroupVal;
  // }

  // toggleFeatureType(featureGroup: any, featureType: any, layer: any) {
  //   const layerID = this.getLayerID(layer);
  //   let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
  //     .get(layer)
  //     .get(featureGroup);
  //   let featureGroupFeatureNameToggleStateMap: any;
  //   if (featureGroupFeatureTypeMap.get(featureType)) {
  //     featureGroupFeatureTypeMap.set(featureType, false);
  //     let featureGroupFeatureNameToggleStateMap =
  //       this.featureGroupTypeFeatureNameToggleStateMap
  //         .get(layer)
  //         .get(featureGroup)
  //         .get(featureType);
  //     featureGroupFeatureNameToggleStateMap.forEach(
  //       (value1: boolean, key1: string) => {
  //         this.featureGroupTypeFeatureNameToggleStateMap
  //           .get(layer)
  //           .get(featureGroup)
  //           .get(featureType)
  //           .set(key1, false);
  //       }
  //     );
  //     this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);
  //   } else {
  //     featureGroupFeatureTypeMap.set(featureType, true);
  //     let featureGroupFeatureNameToggleStateMap =
  //       this.featureGroupTypeFeatureNameToggleStateMap
  //         .get(layer)
  //         .get(featureGroup)
  //         .get(featureType);
  //     featureGroupFeatureNameToggleStateMap.forEach(
  //       (value1: boolean, key1: string) => {
  //         this.featureGroupTypeFeatureNameToggleStateMap
  //           .get(layer)
  //           .get(featureGroup)
  //           .get(featureType)
  //           .set(key1, true);
  //       }
  //     );
  //     this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);

  //     //for group
  //     this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);

  //     //for layer
  //     this.isLayerEyeVisible[layer] = true;
  //   }
  //   // First get the featuregroup part of the filter
  //   // let groupFilterExpression = this.getGroupFilterExpression(featureGroup);
  //   // // Then get the featuretype part of the expressio
  //   // let typeFilterExpression = this.getTypeFilterExpression(featureGroup, featureType, layer);

  //   // // Only add featuretype expression if at least one is hidden
  //   // if (typeFilterExpression.length > 0) {
  //   //   groupFilterExpression.push(typeFilterExpression);
  //   // }
  //   // Apply the filter to the featuregroup layer
  //   // this.map.setFilter(layerID + "-" + featureGroup + "-point", groupFilterExpression);
  //   // this.map.setFilter(layerID + "-" + featureGroup + "-line", groupFilterExpression);
  //   if (layerID === 5 && featureGroup.toLowerCase() === 'site') {
  //     setTimeout(() => {
  //       this.commonMapService.filterMVTFromToggle(
  //         this.zoomedJobIds,
  //         this.map,
  //         this.siteLayerData
  //       );
  //     }, 200);
  //   } else {
  //     setTimeout(() => {
  //       this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
  //     }, 200);
  //   }
  // }

  // fetchFeaturesArray(groupKey: any, featureKey: any, layer: any) {
  //   console.log(groupKey, '  ',featureKey, '  ',layer );
  //   const featureGroupMap: Map<string, any> = this.featureGroupMap
  //     .get(layer)
  //     .get(groupKey);
  //   const array = featureGroupMap.get(featureKey);
  //      // For Imported Layer Name Open ImportR
  //   if (layer == 'IMPORTED') {
  //     if (
  //       featureGroupMap.get(featureKey)[0].fileName != null &&
  //       this.importedLayerFileName == '' && this.importedBlankCheck
  //     ) {
  //       this.importedLayerFileName =
  //         featureGroupMap.get(featureKey)[0].fileName;
  //       this.importedLayerFileNameStore =
  //         featureGroupMap.get(featureKey)[0].fileName;
  //       this.importedLayerFileId = featureGroupMap.get(featureKey)[0].fileId;
  //       this.cdRef.detectChanges();
  //     }
  //   }
  //   // For Imported Layer Name  Closed ImportR
  //   return array ? this._fetchUniqueArrayData(array, "featureId", "featureName"): [];
  // }

  // private _fetchUniqueArrayData(arr: any[], field1: any, field2: any) {
  //   return arr.filter((value, index, self) =>
  //     index === self.findIndex((t) =>
  //       t[field1] === value[field1] && t[field2] === value[field2]
  //     ))
  // }

  /*used in HTML to fetch line colors in toggle side bar*/
  fetchFeatureTypeColor(featureTypeId: any) {
    if (this.featureTypeStyleMap.get(featureTypeId) == undefined) {
      return '#808080';
    } else {
      return this.featureTypeStyleMap.get(featureTypeId).color;
    }
  }

  // toggleFeature(
  //   featureGroup: any,
  //   featureType: any,
  //   featureName: any,
  //   layer: any,
  //   fromLocation?: any
  // ) {
  //   let layerID = this.getLayerID(layer);
  //   this.featureGroupTypeFeatureNameToggleStateMap.get(layer).delete('');
  //   let featureGroupFeatureTypeMap =
  //     this.featureGroupTypeFeatureNameToggleStateMap
  //       .get(layer)
  //       .get(featureGroup);
  //   let featureTypeFeatureNameMap = featureGroupFeatureTypeMap.get(featureType);

  //   if (featureTypeFeatureNameMap.get(featureName)) {
  //     featureTypeFeatureNameMap.set(featureName, false);
  //     featureGroupFeatureTypeMap.set(featureType, featureTypeFeatureNameMap);
  //     this.featureGroupTypeFeatureNameToggleStateMap.set(
  //       featureGroup,
  //       featureGroupFeatureTypeMap
  //     );
  //   } else {
  //     //for feature
  //     featureTypeFeatureNameMap.set(featureName, true);
  //     featureGroupFeatureTypeMap.set(featureType, featureTypeFeatureNameMap);
  //     this.featureGroupTypeFeatureNameToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);

  //     //for feature type
  //     featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .get(featureGroup);
  //     featureGroupFeatureTypeMap.set(featureType, true);
  //     this.featureGroupTypeToggleStateMap
  //       .get(layer)
  //       .set(featureGroup, featureGroupFeatureTypeMap);

  //     //for group
  //     this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);

  //     //for layer
  //     this.isLayerEyeVisible[layer] = true;
  //   }
  //   // const allLayer = this.navigatedFeatures.filter((feature: any) => feature.layerName === layer);

  //   // let findJobId:any;
  //   // allLayer.forEach((element:any)=>{
  //   //   let temp:any;
  //   //   if(element?.features && element.features.length > 0) {
  //   //     temp = element.features.filter((it:any)=> it.feature === featureName);
  //   //     if(temp.length > 0) {
  //   //       console.log("temp: ", element.jobId, " : " ,temp);
  //   //       findJobId = element.jobId;
  //   //       return;
  //   //     }
  //   //   }
  //   // });

  //   // if(findJobId) {
  //   //   if(this.zoomedJobIds.length === 0 || this.zoomedJobIds.find((it:any)=> it != findJobId)) {
  //   //     this.zoomedJobIds.push(findJobId);
  //   //   }

  //   // }

  //   if (layerID === 5 && featureGroup.toLowerCase() === 'site') {
  //     setTimeout(() => {
  //       this.commonMapService.filterMVTFromToggle(
  //         this.zoomedJobIds,
  //         this.map,
  //         this.siteLayerData
  //       );
  //     }, 200);
  //   } else {
  //     setTimeout(() => {
  //       this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
  //     }, 200);
  //   }
  // }

  // checkEyeFeatureNameVisible(
  //   layer: any,
  //   featureGroup: any,
  //   featureType: any,
  //   featureName: any
  // ) {
  //   const featureGroupVal = this.featureGroupTypeFeatureNameToggleStateMap
  //     .get(layer)
  //     .get(featureGroup)
  //     .get(featureType)
  //     .get(featureName);
  //   return featureGroupVal;
  // }

  handleFeatureTypeClick(
    layer: string,
    featureGroup: string,
    featureType: string
  ) {
    console.log('handleFeatureTypeClick', featureGroup, featureType);
    if (!this.isFeatureTypeClicked.hasOwnProperty(layer)) {
      this.isFeatureTypeClicked[layer] = {};
    }
    if (!this.isFeatureTypeClicked[layer].hasOwnProperty(featureGroup)) {
      this.isFeatureTypeClicked[layer][featureGroup] = {};
    }
    // Need to toggle this true/false, maybe based on the button class "collapsed"
    this.featureTypeload(layer, featureGroup, featureType);
  }

  featureTypeload(layer: any, featureGroup: any, featureType: any) {
    let featureTypeBtn = document.querySelector(
      `.feature-group-container.${featureGroup} .feature-type-container._${this.removeAllSpaceAndSpecialChar(
        featureType
      )} button`
    );
    console.log(
      'featureTypeBtn: ',
      featureTypeBtn,
      ' : ',
      featureTypeBtn?.classList.contains('collapsed')
    );
    if (featureTypeBtn?.classList.contains('collapsed')) {
      // Only remove DOM if no features are hidden
      let anyHidden = false;
      let featuresInThisType = document.querySelectorAll(
        `.feature-group-container.${featureGroup} .feature-type-container._${this.removeAllSpaceAndSpecialChar(
          featureType
        )} .feature-name`
      );
      featuresInThisType.forEach((nodeList) => {
        if (nodeList.classList.contains('fa-eye-slash')) {
          anyHidden = true;
          return;
        }
      });
      if (anyHidden) {
        this.isFeatureTypeClicked[layer][featureGroup][featureType] = true;
      } else {
        this.isFeatureTypeClicked[layer][featureGroup][featureType] = false;
      }
      console.log(this.isFeatureTypeClicked);
    } else {
      this.isFeatureTypeClicked[layer][featureGroup][featureType] = true;
    }
  }

  // Editing related functions

  //gotoMapEditor method is used to enable edit feature's when user click on the edit icon
  gotoMapEditor(layer: any) {
    if (!this.editCheck) {
      this.permissionService.showErrormessageForPersonal();
      return;
    }
    if (this.zoomedJobIds.length > 0) {
      // this.isLayerEyeVisible = {
      //   GPRS: true,
      //   CLIENT: true,
      //   IMPORTED: true,
      //   EXTERNAL: true,
      //   REFERENCE: false,
      // };
      this.isEditLayer = false;
      this.editLayerName = layer;
      this.hideShowLayer(layer);
      if (
        layer.toUpperCase() !== 'EXTERNAL' ||
        layer.toUpperCase() !== 'REFERENCE'
      ) {
        this.bindDropdownData();
      }
      this.showAddFeatureContainerFrMapEditor = layer === "EXTERNAL" ? false : true;
      this.currentEditinglayerId = this.getLayerID(layer)?.toString();
      this.isEditingModeIsOn = true;
      this.updateEditMode.emit(true);
      if(this.isEditingModeIsOn) {
        this.onHistorySlideoutClose(false);
      }
      // this.isLayerEyeVisible[layer] = false;


      // this.toggleGPRSLayer('GPRS');
      // this.toggleGPRSLayer('CLIENT');
      // this.toggleGPRSLayer('IMPORTED');
      if (layer.toUpperCase() === 'EXTERNAL') {
        this.selectedJob = {}
        // this.getExternalContentTypes();
        this.viewExternalComponentsByJobId(true);
      }
      if (layer.toUpperCase() === 'REFERENCE') {
        this.reteriveVectorTilesbyUser();
      }

      this.data.forEach((element:CommitChanges) => {
        if(element.layerName !== layer && element.layerId !== 5) {
          this.testToggleLayer(element);
        }
        // if([1,2,3].includes(+element.layerId)) {
        //   this.testToggleLayer(element)
        // }
      });
    } else if (this.zoomedJobIds.length < 0) {
      this.toastr.error(
        'Please select at least single feature for editing.',
        '',
        {
          timeOut: 2000,
        }
      );
    }

    this.toggleEditingState.emit({editState:true, layer:this.getLayerID(layer)});
  }

  //hideShowLayer method is used to show/hide the layer when user in edit mode
  hideShowLayer(layer: any) {
    // for (const key of Object.keys(this.isLayerEditVisible[0])) {
    //   if (key != layer) {
    //     this.isLayerEditVisible[0][key] = false;
    //   } else {
    //     this.isLayerEditVisible[0][key] = true;
    //   }
    // }
    this.data.forEach((el:CommitChanges)=>{
      el.isLayerEditVisible = el.layerName === layer ? true : false;
    })
  }

  bindDropdownData() {
    this.groupDropDownData = [];
    this.groupDropDownData = this._constructDropdownData(
      FeatureTypeStyleMapping.featureTypeStyleJson,
      'featureGroupId',
      'featureGroup',
      'feature_group_id',
      'feature_group_name'
    );
    this.groupToSelect = this.groupDropDownData[0].featureGroupId;
    this.bindFeatureTypeDropdown(this.groupDropDownData[0].featureGroupId);
  }

  //bindFeatureTypeDropdown method is used to bind the add feature type dropdown
  bindFeatureTypeDropdown(groupId: any, isEditValue?: any) {
    this.featureTypeDropDownData = [];
    let featureTypeData = FeatureTypeStyleMapping.featureTypeStyleJson.filter(
      (it) => it.feature_group_id === groupId
    );
    this.featureTypeDropDownData = this._constructDropdownData(
      featureTypeData,
      'featureTypeId',
      'featureType',
      'feature_type_id',
      'feature_type_name'
    );

    let imgName = null;
    if (isEditValue) {
      this.featureTypeToSelect = isEditValue?.properties.featureTypeId;
      this.featureTypeForBtnVisible = isEditValue?.properties.featureType;
      imgName = isEditValue?.properties.featureType;
    } else {
      this.featureTypeToSelect = this.featureTypeDropDownData[0].featureTypeId;
      this.featureTypeForBtnVisible =
        this.featureTypeDropDownData[0].featureType;
      imgName = this.featureTypeDropDownData[0].featureType;
    }
    this._setFeatureImage(imgName);
  }

  private _constructDropdownData(
    data: any,
    id: any,
    name: any,
    keyID: any,
    keyName: any
  ) {
    let dropdowndata: any = [];
    data.forEach((obj: any) => {
      const val = {
        [id]: obj[keyID],
        [name]: obj[keyName],
      };
      if (dropdowndata?.length > 0) {
        if (dropdowndata?.find((it: any) => it[id] != obj[keyID]))
          dropdowndata.push(val);
      } else {
        dropdowndata.push(val);
      }
    });

    dropdowndata = dropdowndata.filter(
      (array: any, i: any, self: any) =>
        i === self.findIndex((t: any) => t[id] === array[id])
    );
    return dropdowndata;
  }

  onGroupChange(target: any) {
    if (target?.value) {
      this.bindFeatureTypeDropdown(parseInt(target.value));
      this.groupToSelect = this.groupDropDownData
        .find((it: any) => it.featureGroupId === parseInt(target.value))
        ?.featureGroupId?.toString();
    }
  }

  onFeatureTypeChange(target: any) {
    this.featureTypeToSelect = this.featureTypeDropDownData
      .find((it: any) => it.featureTypeId === parseInt(target.value))
      ?.featureTypeId?.toString();
    this.featureTypeForBtnVisible = this.featureTypeDropDownData.find(
      (it: any) => it.featureTypeId === parseInt(target.value)
    )?.featureType;
    const imgName = this.featureTypeDropDownData.find(
      (it: any) => it.featureTypeId === parseInt(target.value)
    )?.featureType;
    this._setFeatureImage(imgName);
  }

  private _setFeatureImage(imgName: any) {
    const featureGeometryType =
      FeatureTypeStyleMapping.featureTypeStyleJson.find(
        (it: any) => it.feature_type_id === parseInt(this.featureTypeToSelect)
      )?.geometry_type;
    if (featureGeometryType?.toLowerCase() === 'point') {
      this.featureImg =
        '../../../../../../assets/images/GPRS_PNG/' + imgName + '.png';
    } else {
      this.featureImg = '';
    }
  }

  //addGroupFeatureType method is used add selected feature whatever last user selected
  addGroupFeatureType(layer: any, mergedLine?: any) {
    let drawFeatures = this.draw?.getAll().features;
    this.selectedFeature = undefined;
    if (
      drawFeatures?.length > 0 &&
      !drawFeatures[drawFeatures?.length - 1].properties.featureName
    ) {

      alert('Please add the last selected feature to map before you proceed.');
    } else {
      let data = { layerName: layer };
      const featureGeometryType =
        FeatureTypeStyleMapping.featureTypeStyleJson.find(
          (it: any) => it.feature_type_id === parseInt(this.featureTypeToSelect)
        )?.geometry_type;
        if(mergedLine) {
          this.newAddedFeatureName = mergedLine.properties.featureName;
        } else {
          this.newAddedFeatureName = `temp_${this.featureTypeDropDownData.find(
            (it: any) => it.featureTypeId === parseInt(this.featureTypeToSelect)
          )?.featureType
            }_${new Date().toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            })}`;
            this.firstSecondLineFeatureFrMergedArr = []; //clear array
       }
      let feature: any = {
        type: 'Feature',
        properties: {
          featureGroupId: parseInt(this.groupToSelect),
          featureGroup: this.groupDropDownData.find(
            (it: any) => it.featureGroupId === parseInt(this.groupToSelect)
          )?.featureGroup,
          featureTypeId: parseInt(this.featureTypeToSelect),
          featureType: this.featureTypeDropDownData.find(
            (it: any) => it.featureTypeId === parseInt(this.featureTypeToSelect)
          )?.featureType,
          featureId: this.getLayerID(layer)?.toString(),
          featureName: this.newAddedFeatureName,
          featureGeometryType: featureGeometryType,
        },
        geometry: {
          type: null,
          coordinates: null,
        },
      };

      this.newAddedFeatureInLayer = data;
      this.newlyAddedFeature.push(feature);

      // if (this.featureGroupMap.has(data.layerName)) {
      //   this.addMainFeatureData(data, feature, 'hasValue');
      // } else {
      //   this.addMainFeatureData(data, feature, null);
      // }
      let navigatedData:any = this.navigatedFeatures.find((ft:any)=> ft.layerId === this.getLayerID(layer));

       if(!navigatedData) {
        navigatedData = {
          layerName:layer,
          layerId:this.getLayerID(layer)
        }
       }
      this.dataCreationLayer(feature?.properties, navigatedData);

      this.addFeatureForMapEditor(
        this.groupDropDownData.find(
          (it: any) => it.featureGroupId === parseInt(this.groupToSelect)
        ).featureGroup,
        this.featureTypeDropDownData.find(
          (it: any) => it.featureTypeId === parseInt(this.featureTypeToSelect)
        )?.featureType,
        layer
      );
      this.editSelectedFeature = feature;
      this.showHideToggleGroup(feature);
      if (featureGeometryType === 'POINT') {
        this.handleAddPoint();
      } else if (featureGeometryType === 'LINE') {
        this.handleAddLine();
      }
      this.updateNewFeature();
      const _layer = this.data.filter(item => item.layerId === feature.properties.layerId)[0];
      this.layerEvents.next({ type: 'AddFeature', data: { item: feature, layer: _layer } });
    }

  }

  private updateNewFeature() {
    this.newlyAddedFeatureProperties = {
      newAddedFeatureName: this.newAddedFeatureName,
      addNewFeatureTypeFrMapEditor: this.addNewFeatureTypeFrMapEditor,
      addNewFeatureGrpFrMapEditor: this.addNewFeatureGrpFrMapEditor,
      addNewFeatureGrpIdFrMapEditor: this.addNewFeatureGrpIdFrMapEditor,
      addNewFeatureTypeIdFrMapEditor: this.addNewFeatureTypeIdFrMapEditor,
      currentEditinglayerId: this.currentEditinglayerId,
      navigatedJobId: this.navigatedJobId,
    };

    this.onFeaturePropertyUpdate.emit(this.newlyAddedFeatureProperties);
  }

  //addFeatureForMapEditor method is used added new feature in edit mode
  addFeatureForMapEditor(group: any, featureType: any, layer: any) {
    let featureTypeData = FeatureTypeStyleMapping.featureTypeStyleJson.filter(
      (it) => it.feature_type_name === featureType
    );
    this.addNewFeatureGrpFrMapEditor = group;
    this.addNewFeatureTypeFrMapEditor = featureType ? featureType : group;
    this.addNewFeatureGrpIdFrMapEditor = featureType
      ? featureTypeData[0].feature_group_id
      : this.externalContentDropdownData.find(
        (it: any) => it.exernalComponentName === this.selectOptionsExternal
      ).externalComponentTypeId;
    this.addNewFeatureTypeIdFrMapEditor = featureType
      ? featureTypeData[0].feature_type_id
      : null;
  }

  showHideToggleGroup(feature: any) {
    // if(this.previousFeature?.properties?.featureType != feature?.properties?.featureType && this.previousFeature?.properties?.featureGroup != feature?.properties?.featureGroup) {
    if (
      this.previousFeature?.properties?.featureGroup !=
      feature?.properties?.featureGroup
    ) {
      this.refactorShowHideToggleGroup(feature);
      // if(this.previousFeature?.properties?.featureGroup != feature?.properties?.featureGroup) {
      if (this.previousFeature?.properties?.featureGroup) {
        this.refactorShowHideToggleGroup(this.previousFeature);
      }
    } else {
      if (
        this.previousFeature?.properties?.featureType !=
        feature?.properties?.featureType
      ) {
        this.showHideFeatureTypeToggle(feature);
        this.showHideFeatureTypeToggle(this.previousFeature);
      }
    }
  }

  refactorShowHideToggleGroup(layerData: any) {
    const btnGroup = document.getElementById(
      'btn' + this.getFinalGrp(layerData?.properties?.featureGroup)
    );

    if (btnGroup?.getAttribute('aria-expanded') == 'false') {
      btnGroup?.click();
      const ele = document.getElementById(this.getFinalGrp(layerData?.properties?.featureGroup)+'-content'+this.getLayerName(layerData?.properties?.layerId));
      if(ele) {
        ele.classList.add('show');
        // btnGroup.ariaExpanded = 'true';
        btnGroup?.setAttribute('aria-expanded','true')
      }
    }

    // added settimeout so that above exapand group create featureType DOM on expansion
    setTimeout(() => {
      this.showHideFeatureTypeToggle(layerData);
    }, 300);
  }

  showHideFeatureTypeToggle(layerData: any) {
    const btnFeatureTypeGroup = document.getElementById(
      'btn' + layerData?.properties?.featureType
    );

    if (btnFeatureTypeGroup?.getAttribute('aria-expanded') == 'false') {
      btnFeatureTypeGroup?.click();

      // const ele = document.getElementById('collapse-0-contentCLIENT-AIR_LINE');
      let featureClassCode = layerData?.properties?.featureClass==="point" ? 1 : 0
      // console.log('collapse-' + featureClassCode + '-content' + this.getLayerName(layerData?.properties?.mapLayerId) + '-' + layerData?.properties?.featureType)
      const ele = document.getElementById('collapse-' + featureClassCode + '-content' + this.getLayerName(layerData?.properties?.layerId) + '-' + layerData?.properties?.featureType);
      if(ele) {
        ele.classList.add('show');
        // btnFeatureTypeGroup.ariaExpanded = 'true';
        btnFeatureTypeGroup?.setAttribute('aria-expanded','true')
      }
    }
  }

  //# Map editor Code Start //

  // Points
  // Adding a new Point
  // Ask use if they want to create a "regular point" or an "annotation point"
  handleAddPoint() {
    // Set state
    // this.draw.changeMode('sitemap_draw_point');
    this.draw.changeMode('sitemap_draw_point', {
      mapLayerId: this.currentEditinglayerId,
      navigatedFeatures: this.navigatedFeatures,
      isSnappingToVertex: this.isSnappingToVertex,
      isSnappingToLine: this.isSnappingToLine});
  }
  // Updating a Point
  // Need to fetch the feature's GeoJSON from API
  // If more than one feature is selected, prompt user to confirm which feature they want to edit
  handleUpdatePoint() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'updating_point';
  }
  // Deleting a Point
  // The featureId and featureClass are in the data
  // Confirm deletion and then send POST request to API with featureId
  handleDeletePoint() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'deleting_point';
  }

  // Lines
  // Adding a new Line
  // Ask use if they want to create a "regular line" or an "annotation line"
  handleAddLine() {
    // Set drawing mode
    // this.draw.changeMode('draw_line_string');
    this.draw.changeMode('sitemap_draw_line_string', {
      mapLayerId: this.currentEditinglayerId,
      navigatedFeatures: this.navigatedFeatures,
      isSnappingToVertex: this.isSnappingToVertex,
      isSnappingToLine: this.isSnappingToLine});
    // alert('Would you like to create a "regular line" or an "annotation line?');
    let answer = 'line';
    if (answer === 'line') {
      this.currentDrawingState = 'adding_line';
    } else {
      this.currentDrawingState = 'adding_annotation_line';
    }
  }
  // Updating a Line
  // Need to fetch the feature's GeoJSON from API
  // If more than one feature is selected, prompt user to confirm which feature they want to edit
  handleUpdateLine() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'updating_line';
  }
  // Deleting a Line
  // The featureId and featureClass are in the data
  // Confirm deletion and then send POST request to API with featureId
  handleDeleteLine() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'deleting_line';
  }
  //showAllLayer method is used to show all layer when close/exit the edit layer
  showAllLayer() {
    // for (const key of Object.keys(this.isLayerEditVisible[0])) {
    //   this.isLayerEditVisible[0][key] = true;
    // }
    this.data.forEach((layer:CommitChanges)=> {
      layer.isLayerEditVisible = true;
    })
  }

  //checkEditLayerVisible method is used return the layer visble or not
  // checkEditLayerVisible(layer: any) {

  //   return this.isLayerEditVisible[0][layer];
  // }

  //cancelContainerFrEditor method is used to cancel the edit mode
  cancelContainerFrEditor(layer: any) {
    let isCancel = false;
    if (this.isEditingModeIsOn) {
      // Just need to trash the draw feature
      let drawFeatures = this.draw?.getAll().features;
      if (drawFeatures?.length > 0) {
        if (
          confirm(
            'If you leave the editing session now, you will lose any unsave work.'
          )
        ) {
          this._resetCommitChanges();
          drawFeatures?.forEach((element: any) => {
            this.draw.delete(element.id);
            // Unhide the saved feature
            const index = this.hiddenFeatures?.indexOf(
              element.properties.featureName
            );
            if (index > -1) {
              this.hiddenFeatures.splice(index, 1);
              // Need to set the feature filter
              // this.setGroupFilter('on', element.properties.featureGroup);
            }
          });
          isCancel = true;
        }
      } else {
        isCancel = true;
      }
      if (isCancel) {
        this.toggleEditingState.emit({editState:false, layer:this.getLayerID(layer)});
        this.newAddedFeatureInLayer = { layerName: String };
        this.newlyAddedFeature = [];
        this.isEditLayer = true;
        this.editLayerName = null;
        this.editSelectedFeature = null;
        this.isFirstEditDetected = false;
        this.showAllLayer();
        this.isEditingModeIsOn = false;
        this.updateEditMode.emit(false);
        this.showAddFeatureContainerFrMapEditor = false;
        this.showAddFeatureButton = true;
        // this.isLayerEyeVisible[layer] = false;
        // this.toggleGPRSLayer('GPRS');
        // this.toggleGPRSLayer('CLIENT');
        // this.toggleGPRSLayer('IMPORTED');
        // this.data.forEach((layer:CommitChanges)=> {
        //   if([1,2,3].includes(+layer.layerId)) {
        //     this._testToggleLayer(layer)
        //   }
        // })
        // this.isLayerEyeVisible = {
        //   SITE: true,
        //   GPRS: true,
        //   CLIENT: true,
        //   IMPORTED: true,
        //   EXTERNAL: true,
        //   REFERENCE: false
        // };
        this.selectOptionsExternalEnable = false;
        this.externalContentForm?.reset(this.externalContentFormOriginal);
        this.onExitEditing.emit({ isCancel: true })
        this._removeNewlyAddedFeature(this.temporaryAddedFeature);
        this._resetCommitChanges();
        this.previousFeature = null;
        this.editingHistoryUndoArray = [];
        this.editingHistoryRedoArray = [];
        this.hiddenFeatures = [];
      }
    }
  }

  private _refreshLayer() {
    this.isEditLayer = true;
    this.isEditingModeIsOn = false;
    this.selectedJob = null;
    let selectBox: any = document.getElementById("feature-job-select");
    selectBox.value = this.selectedJob ? this.selectedJob.jobId : "default";
    this.updateEditMode.emit(false);
    this.isDataLoaded = false;
    this.showAllLayer();
    this._resetData();
    this._loadFeatureData();
    this.reteriveVectorTilesbyUser();
    this.onExitEditing.emit({ isCancel: false });
    this.refreshMapStyle();
    this.reteriveGeoReferenceImagebyUser();
  }

  //getUpdatedFeatures method used to get latest feature from fetchFeaturesByJobId api
  getUpdatedFeatures(
    response: any,
    saveFeature: any,
    layer: any
  ): Observable<any> {
    this._removeNewlyAddedFeature(saveFeature);
    this.isBasemapToggle = false;
    // this.featureGroupMap = new Map();
    // this.featureGroupToggleStateMap = new Map();
    // this.featureGroupTypeToggleStateMap = new Map();
    // this.featureGroupTypeFeatureNameToggleStateMap = new Map();
    return this.mapViewerJobFeatureService.fetchFeaturesByJobId(
      this.navigatedJobId!,
      this.loginUserId
    );
  }

  private _removeNewlyAddedFeature(saveFeature: any) {
    if (saveFeature) {
      this.newlyAddedFeature?.splice(
        this.newlyAddedFeature?.findIndex(
          (it: any) => it?.properties?.featureName === saveFeature?.properties?.featureName
        ),
        1
      );
    }
  }


  public deleteFeatureFile(layer: CommitChanges, file: FileGroup) {
    const confirmMsg = 'Are you sure you want to delete?';
    this.navigatedJobId = getJobIdFromFile(file);
    if (this.deleteAccessCheck(layer)) {
      if (confirm(confirmMsg)) {
        this.deleteFeatureFileAPI(layer, file);
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }

  public deleteFeatureFileAPI(layer: CommitChanges, file: FileGroup) {
    this.spinner.show();
    this.featureDeleteSubscribe = this.mapViewerJobFeatureService
      .deleteFeatureFileByJobId(
        this.navigatedJobId,
        parseInt(layer.layerId as string),
        this.loginUserId,
        file.fileId!
      )
      .subscribe(
        (data: any) => {
          if (data.status == 'Success') {
            this.toastr.success('Deleted Successfully');
            this.layerEvents.next({ type: 'DeleteFile', data: { layer, file } });
            this.commonMapService.resetSourceForLayer(this.map, parseInt(layer.layerId as string));
          } else {
            this.toastr.error(data.status + ' : ' + data.message);
          }
          this.spinner.hide();
        },
        (err: any) => {
          this.toastr.error('Error');
          this.spinner.hide();
        }
      );
  }

  deleteFeatureGroup(layer: CommitChanges, featureGroup: FeatureGroup, fileId?: number) {
    const layerId = layer.layerId;
    this.navigatedJobId = featureGroup.mapJobId;
    // if (layer.toUpperCase() == 'GPRS') {
    //   layerId = 1;
    // } else if (layer.toUpperCase() == 'CLIENT') {
    //   layerId = 2;
    // } else if (layer.toUpperCase() == 'IMPORTED') {
    //   layerId = 3;
    // } else if (layer.toUpperCase() == 'EXTERNAL') {
    //   layerId = 4;
    // }
    let delMessage = 'Are you sure you want to delete?';
    if (layer?.layerName?.toUpperCase() === 'EXTERNAL') {
      delMessage = 'Are you sure you want to delete the component?';
    }
    //   Layer ID for GPRS layer is 1, for Client layer is 2, for Imported layer is 3
    if (this.deleteAccessCheck(layer, featureGroup) && layerId != '') {
      if (confirm(delMessage)) {
        this.deleteFeatureGroupAPI(layerId, featureGroup, layer, fileId);
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }


  deleteFeatureGroupAPI(layerId: number | string, featureGroup: FeatureGroup, layer: CommitChanges, fileId?: number) {
    let featureUrl = '';
    if (layer?.layerName?.toUpperCase() != 'EXTERNAL') {
      // let fetchFeaturesType = this.fetchFeatureTypeArray(featureGroup, layer);
      // let featuresArray = this.fetchFeaturesArray(
      //   featureGroup,
      //   fetchFeaturesType[0],
      //   layer
      // );
      let fetchFeaturesType = featureGroup.types;
      let featuresArray = fetchFeaturesType[0].features;
      if (featuresArray?.length != 0) {
        this.spinner.show();
        if (!featureGroup.groupName?.toLowerCase().includes('photo')) {
          this.featureDeleteSubscribe = this.mapViewerJobFeatureService
            .deleteFeatureGroupByJobId(
              parseInt(featureGroup.groupId as string),
              this.navigatedJobId,
              parseInt(layerId as string),
              this.loginUserId,
              fileId
            )
            .subscribe(
              (data: any) => {
                if (data.status == 'Success') {
                  this.toastr.success('Deleted Successfully');
                  this.layerEvents.next({ type: 'DeleteGroup', data: { group: featureGroup, layer } });
                  this.commonMapService.resetSourceForLayer(this.map, parseInt(layer.layerId as string));
                } else {
                  this.toastr.error(data.status + ' : ' + data.message);
                }
                this.spinner.hide();
              },
              (err: any) => {
                this.toastr.error('Error');
                this.spinner.hide();
              }
            );
        } else {
          const data = {
            userId: this.loginUserId,
            mapLayerId: layerId,
            jobId: this.navigatedJobId,
          };
          this.restService.options.body = data;
          this.restService.delete(PortalAPI.DELETE_PHOTO_BY_LAYER_ID, data)
            .subscribe(
              (data: any) => {
                if (data.status == 'Success') {
                  this.toastr.success('Deleted Successfully');
                  // this.getUpdatedFeatures('', '', '').subscribe(
                  //   (dataWithLayer: any) => {
                  //     this.navigatedFeatures = dataWithLayer;
                  //     for (const featureData of dataWithLayer) {
                  //       if (!this.isBasemapToggle) {
                  //         this.setValuesInRequiredMaps(featureData);
                  //       } else {
                  //         this.isBasemapToggle = false;
                  //       }
                  //     }

                  //     this.refreshMapStyle();
                  //   }
                  // );
                  this._refreshLayer();
                } else {
                  this.toastr.error(data.status + ' : ' + data.message);
                }
                this.spinner.hide();
              },
              (err: any) => {
                this.toastr.error('Error');
                this.spinner.hide();
              }
            );
        }
      }
    } else if (layer?.layerName.toUpperCase() == 'EXTERNAL') {
      if (featureGroup?.groupName == 'EXTERNAL LINK') {
        featureUrl = 'DeleteExternalLinkByJobId';
      } else if (featureGroup?.groupName == 'ATTACHMENT') {
        featureUrl = 'DeleteAttachmentByJobId';
      } else if (featureGroup?.groupName == 'MATTERPORT') {
        featureUrl = 'DeleteMatterportByJobId';
      } else if (featureGroup?.groupName == 'POINTCLOUD') {
        featureUrl = 'DeletePointCloudByJobId';
      } else if (featureGroup?.groupName == 'VIRTUAL TOUR') {
        featureUrl = 'DeleteVirtualTourByJobId';
      }

      if (featureUrl != '') {
        this.spinner.show();
        this.featureDeleteSubscribe = this.mapViewerJobFeatureService
          .deleteMultipleExternalLayer(
            featureUrl,
            this.navigatedJobId,
            this.loginUserId
          )
          .subscribe(
            (data: any) => {
              if (data.status == 'Success') {
                this.toastr.success('Deleted Successfully');
                // this.reloadCurrentRoute();
                // this.refreshMapStyle();
                // if(this.loginUserRole.toLowerCase() === 'client') {
                //   setTimeout(() => {
                //     this.trashFeatureName(layer,featureGroup,featureType,feature.featureName);
                //   }, 2000);
                // }
                // else {
                //   this.trashFeatureName(layer,featureGroup,featureType,feature.featureName);
                // }
                // this.getUpdatedFeatures('', '', '').subscribe(
                //   (dataWithLayer: any) => {
                //     this.commonMapService
                //       .getExternalContent(this.navigatedJobId, this.loginUserId)
                //       .subscribe(
                //         (item: any) => {
                //           if (item) {
                //             dataWithLayer = [...dataWithLayer, ...item];
                //           }
                //           this.navigatedFeatures = dataWithLayer;
                //           for (const featureData of dataWithLayer) {
                //             if (!this.isBasemapToggle) {
                //               this.setValuesInRequiredMaps(featureData);
                //             } else {
                //               this.isBasemapToggle = false;
                //             }
                //           }

                //           this.refreshMapStyle();
                //         },
                //         (err: any) => {
                //           this.toastr.error('Error in getExternalContent');
                //           this.spinner.hide();
                //         }
                //       );
                //   }
                // );
                this._refreshLayer();
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );
      }
    }
  }

  deleteFeatureType(layer: CommitChanges, featureGroup: FeatureGroup, featureType: FeatureType, fileId?: number) {
    let layerId: any = layer.layerId;
    this.navigatedJobId = featureType.mapJobId;
    // if (layer.toUpperCase() == 'GPRS') {
    //   layerId = 1;
    // } else if (layer.toUpperCase() == 'CLIENT') {
    //   layerId = 2;
    // } else if (layer.toUpperCase() == 'IMPORTED') {
    //   layerId = 3;
    // } else if (layer.toUpperCase() == 'EXTERNAL') {
    //   layerId = 4;
    // }
    let delMessage = 'Are you sure you want to delete?';
    if (layer?.layerName?.toUpperCase() === 'EXTERNAL') {
      delMessage = 'Are you sure you want to delete the component?';
    }
    //   Layer ID for GPRS layer is 1, for Client layer is 2, for Imported layer is 3
    if (this.deleteAccessCheck(layer) && layerId != '') {
      if (confirm(delMessage)) {
        this.deleteFeatureTypeAPI(layerId, featureGroup, featureType, layer, fileId);
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }

  deleteFeatureTypeAPI(
    layerId: any,
    featureGroup: FeatureGroup,
    featureType: FeatureType,
    layer: CommitChanges,
    fileId?: number
  ) {
    // let featuresArray = this.fetchFeaturesArray(
    //   featureGroup,
    //   featureType,
    //   layer
    // );
    let featuresArray = featureType.features;
    if (featuresArray?.length != 0) {
      this.spinner.show();
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteFeatureTypeByJobId(
          parseInt(featureType?.typeId as string),
          this.navigatedJobId,
          layerId,
          parseInt(featureGroup.groupId as string),
          this.loginUserId,
          fileId
        )
        .subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.toastr.success('Deleted Successfully');
              this.layerEvents.next({ type: 'DeleteType', data: { type: featureType, group: featureGroup, layer } });
              this.commonMapService.resetSourceForLayer(this.map, parseInt(layer.layerId as string));
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );
    }
  }

  deleteParticularFeatureAPI(
    layer: CommitChanges | null,
    featureGroup: any,
    featureType: any,
    feature: Feature,
    isFromDeleteMergedLined?:boolean
  ) {
    if (!(this.loginUserRole == 'Admin' ||
    this.loginUserRole == 'Project Manager')) {
      this.toastr.error("You do not have access to delete the following feature");
      return
    };


    let featureUrl = '';
    let featureTypeRequest = '';
    if (layer?.layerName?.toUpperCase() == 'EXTERNAL') {
      if (featureGroup?.groupName == 'EXTERNAL LINK') {
        featureUrl = 'DeleteExternalLink';
        featureTypeRequest = 'externalLinkId';
      } else if (featureGroup?.groupName == 'ATTACHMENT') {
        featureUrl = 'DeleteAttachment';
        featureTypeRequest = 'attachmentId';
      } else if (featureGroup?.groupName == 'MATTERPORT') {
        featureUrl = 'DeleteMatterport';
        featureTypeRequest = 'matterportId';
      } else if (featureGroup?.groupName == 'POINTCLOUD') {
        featureUrl = 'DeletePointCloud';
        featureTypeRequest = 'pointCloudId';
      } else if (featureGroup?.groupName == 'VIRTUAL TOUR') {
        featureUrl = 'DeleteVirtualTour';
        featureTypeRequest = 'virtualTourId';
      }
    } else {
      if (
        feature.featureGeometryType == 'LineString' && !feature.isAnnotation
      ) {
        featureUrl = 'DeleteLine';
        featureTypeRequest = 'lineId';
      } else if (
        feature.featureGeometryType == 'LineString' && feature.isAnnotation
      ) {
        featureUrl = 'DeleteAnnotationLine';
        featureTypeRequest = 'annotationLineId';
      } else if (
        feature.featureGeometryType == 'Point' && feature.isAnnotation
      ) {
        featureUrl = 'DeleteAnnotationPoint';
        featureTypeRequest = 'annotationPointId';
      } else if (
        feature.featureGeometryType == 'Point' && !feature.isAnnotation
      ) {
        featureUrl = 'DeletePoint';
        featureTypeRequest = 'pointId';
      }
    }
    if (featureUrl != '') {
      this.spinner.show();
      if (!featureGroup?.groupName?.toLowerCase().includes('photo') || isFromDeleteMergedLined) {
        this.featureDeleteSubscribe = this.mapViewerJobFeatureService
          .deleteMultipleFeaturesByFeatureId(
            featureUrl,
            parseInt(feature.featureId as string),
            this.navigatedJobId,
            this.loginUserId,
            featureTypeRequest
          )
          .subscribe(
            (data: any) => {
              if (data.status == 'Success') {
                this.toastr.success('Deleted Successfully');
                if (layer) {
                  this.layerEvents.next({ type: 'DeleteFeature', data: { item: feature, layer } });
                  this.commonMapService.resetSourceForLayer(this.map, parseInt(layer.layerId as string));
                }
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );
      } else {
        const data = {
          userId: this.loginUserId,
          photoId: parseInt(feature.featureId as string),
          jobId: this.navigatedJobId,
        };
        this.restService.options.body = data;
        this.restService
          .delete(PortalAPI.DELETE_PHOTO, data)
          .subscribe(
            (data: any) => {
              if (data.status == 'Success') {
                this.toastr.success('Deleted Successfully');
                // this.getUpdatedFeatures('', '', '').subscribe(
                //   (dataWithLayer: any) => {
                //     this.navigatedFeatures = dataWithLayer;
                //     for (const featureData of dataWithLayer) {
                //       if (!this.isBasemapToggle) {
                //         this.setValuesInRequiredMaps(featureData);
                //       } else {
                //         this.isBasemapToggle = false;
                //       }
                //     }

                //     this.refreshMapStyle();
                //   }
                // );
                this._refreshLayer();
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );
      }
    }
  }

  deleteParticularFeature(
    layer: CommitChanges,
    featureGroup: FeatureGroup,
    featureType: FeatureType | null,
    feature: Feature
  ) {
    this.navigatedJobId = feature.mapJobId;

    if (this.deleteAccessCheck(layer, featureGroup)) {
      if (confirm('Are you sure you want to delete?')) {
        this.deleteParticularFeatureAPI(
          layer,
          featureGroup,
          featureType,
          feature
        );
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }

  deleteAccessCheck(layer: CommitChanges, featureGroup?: FeatureGroup) {
    if (layer?.layerName?.toUpperCase() == 'GPRS') {
      if (this.loginUserRole == 'Admin' || this.loginUserRole == 'Project Manager') {
        return true;
      }
    } else if (layer?.layerName?.toUpperCase() == 'CLIENT') {
      if (this.loginUserRole == 'Client' && !featureGroup?.groupName?.toLowerCase().includes('photo')
      ) {
        return true;
      }
    } else if (
      layer?.layerName?.toUpperCase() == 'IMPORTED' && !featureGroup?.groupName?.toLowerCase().includes('photo')) {
      if (this.loginUserRole == 'Client' || this.loginUserRole == 'Admin') {
        return true;
      }
    } else if (layer?.layerName?.toUpperCase() == 'EXTERNAL') {
      if (this.loginUserRole == 'Client' || this.loginUserRole == 'Project Manager' || this.loginUserRole == 'Admin') {
        return true;
      }
    }
    return false;
  }

  waitforme(milisec: any) {
    return new Promise(resolve => {
      setTimeout(() => { resolve('') }, milisec);
    })
  }

  // Create new features for map editor
  saveFeaturesByFeatureType(group: any, featureType: any, layer: any) {
    this.temporaryAddedMultipleFeature = [];
    this.isSingleFeatureSave = false;
    const data = this.draw.getAll();
    let saveFeatures = data.features.filter(
      (feature: any) => feature.properties.featureType === featureType &&
        feature.properties.featureGroup === group
    );
    saveFeatures.forEach((saveFeature: any) => {
      this.temporaryAddedMultipleFeature.push(this._constructFeatureForSave(saveFeature.properties));
    })
  }

  private async saveFeatureByType(featureType: any, group: any) {
    if (confirm('Are you sure you want to save?')) {
      const data = this.draw.getAll();
      // Get all drawing features with same featureGroup and featureType
      var saveFeatures = data.features.filter(
        (feature: any) => feature.properties.featureType === featureType &&
          feature.properties.featureGroup === group
      );

      for (const saveFeature of saveFeatures) {
        if (saveFeature?.properties.featureName.startsWith("temp")) {
          this._mapIDsToFeature(saveFeature?.properties);
        }
        this._saveAndDeleteNewlyAddedFeature(saveFeature);
        await this.waitforme(500);
      }
      this._refreshAfterSave();
    }
  }

  private _saveAndDeleteNewlyAddedFeature(saveFeature: any) {
    this.saveFeature(saveFeature);
    // Remove the drawing feature from the map
    this.draw.delete(saveFeature.id);
    this.newlyAddedFeature?.splice(this.newlyAddedFeature.findIndex(it => it.properties.featureName === saveFeature.properties.featureName), 1);
    // Unhide the saved feature
    this.unhideEditingFeature(saveFeature);
  }

  private _refreshAfterSave() {
    this.navigatedFeatures = [];
    // this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
    //   this.navigatedFeatures = dataWithLayer;
    //   for (const featureData of dataWithLayer) {
    //     if (!this.isBasemapToggle) {
    //       this.setValuesInRequiredMaps(featureData);
    //     } else {
    //       this.isBasemapToggle = false;
    //     }
    //   }
      this.addNewFeatureInExisting();
      this._refreshLayer();
    // });
  }

  //#region saveSingleFeature: click on the save icon then save a single feature
  saveSingleFeature(layer: any, feature: any) {
    this.isSingleFeatureSave = true;
    this._constructFeatureForSave(feature);
    this.editingHistoryUndoArray = this.editingHistoryUndoArray.filter((x:any) => x.properties?.featureName != feature.featureName);
    this.editingHistoryRedoArray = this.editingHistoryRedoArray.filter((x:any) => x.properties?.featureName != feature.featureName);
  }

  private _constructFeatureForSave(feature: any) {
    this.selectedJob = null;
    const cord = this.draw.getSelected()?.features[0]?.geometry.coordinates;
    if (cord?.length) {
      if(feature?.featureGeometryType === "LineString" || feature?.featureGeometryType === "LINE") {
        if(cord[0]?.length === 3 && cord[1]?.length === 3 ) {
          cord[0].pop();
          cord[1].pop();
        }
      }
      this.newFeaturesLat = cord[0];
      this.newFeaturesLng = cord[1];
    }

    this.temporaryAddedFeature = { ...feature };
    if (this.clickedFeatures?.length) {
      const existingFeature = this.clickedFeatures.find((featureObj) => {
        return featureObj?.properties?.featureName === feature?.featureName;
      });

      this.temporaryAddedFeature = existingFeature ? { ...existingFeature?.properties } : this.temporaryAddedFeature;
      this.newFeaturesLat = this.newFeaturesLat ? this.newFeaturesLat : this.clickedFeatureLat;
      this.newFeaturesLng = this.newFeaturesLng ? this.newFeaturesLng : this.clickedFeatureLng;
      this.selectedJob = this.jobDetailsForGPRS.find((job) => {
        return existingFeature?.properties?.mapJobId == job.jobId;
      });
    }
    let selectBox: any = document.getElementById("feature-job-select");
    selectBox.value = this.selectedJob ? this.selectedJob.jobId : "default";
    this.externalContentForm.patchValue({ WANumber: this.selectedJob ? this.selectedJob?.workOrderNumber : null })
    this.externalContentForm.patchValue({ job: this.selectedJob });
    // converted time in EST
    this.temporaryAddedFeature["time"] = new Date().toLocaleString('en-US', { timeZone: 'America/New_York', });
    return this.temporaryAddedFeature
  }

  onSaveFeatureClick() {
    if (this.importJobModalEnable) {
      setTimeout(() => {
      this.importJobModalEnable = false;
      this.isFromCommitChange = false;
      }, 1000);
      this.navigatedJobId = this.selectedJob?.jobId;
      let button = document.getElementById("btnModalImport") as HTMLButtonElement;
      button.click();
    } else {
      let temp:any = {siteId:""};
      let checkBeforeMoveFeatureExist = true, checkAfterMoveFeatureExist = true, cord, findJobInSite;
      let cnfrmMsg = 'The feature being edited is out of bounds of the earlier associated Site. Do you wish to continue?';
      for (const ele of this.siteData) {
        if(ele.jobData && ele.jobData.length > 0) {
          findJobInSite = ele?.jobData?.find((it:any)=> it.jobId === this.temporaryAddedFeature.mapJobId);
          if(findJobInSite) {
            temp.siteId = ele.siteId;
            break;
          }
        }
      }
      cord = this.draw.getSelected()?.features[0]?.geometry.type === 'LineString' ? this.draw.getSelected()?.features[0]?.geometry.coordinates : [this.draw.getSelected()?.features[0]?.geometry.coordinates];
      const findSite = this.siteData.find((it:any)=> it.siteId === temp.siteId)
      if (findSite && findSite.subSiteData !=undefined && findSite.subSiteData && findSite.subSiteData.length > 0) {
        for (const subSite of findSite.subSiteData) {
          checkBeforeMoveFeatureExist = this.subSiteServices.isSubSiteInsideSite(this.siteData,temp, JSON.parse(this.selectedFeatureDetailDB.geometry.coordinates), JSON.parse(subSite.geom).coordinates[0]);
          checkAfterMoveFeatureExist = this.subSiteServices.isSubSiteInsideSite(this.siteData,temp, cord, JSON.parse(subSite.geom).coordinates[0]);
          if (checkBeforeMoveFeatureExist && !checkAfterMoveFeatureExist) {
            temp.subSiteId = subSite.subSiteId;
            cnfrmMsg = 'The feature being edited is out of bounds of the earlier associated Sub-Site. Do you wish to continue?';
            break;
          }
        }
      }
      if(temp.subSiteId === undefined && this.selectedFeatureDetailDB?.geometry) {
        let coord:any;
        if(this.isValidJSON(this.selectedFeatureDetailDB?.geometry?.coordinates)) {
          coord = JSON.parse(this.selectedFeatureDetailDB?.geometry?.coordinates);
        } else {
          coord = JSON.parse(this.selectedFeatureDetailDB?.geometry)?.coordinates;
        }
        checkBeforeMoveFeatureExist = this.subSiteServices.isSubSiteInsideSite(this.siteData,temp, coord);
        checkAfterMoveFeatureExist = this.subSiteServices.isSubSiteInsideSite(this.siteData,temp, cord);
      }
      if((!checkBeforeMoveFeatureExist && !checkAfterMoveFeatureExist) || (checkBeforeMoveFeatureExist && checkAfterMoveFeatureExist) || (!checkBeforeMoveFeatureExist && checkAfterMoveFeatureExist)) {
        if (this.isSingleFeatureSave && !this.isFromCommitChange) {
          this._mapIDsToFeature(this.temporaryAddedFeature);
          this._saveFeatureConfirmation(this.temporaryAddedFeature);
        } else if(this.isFromCommitChange) {
            this._saveMultipleCommits(this.changesTobeCommitted);
        } else {
          this.saveFeatureByType(this.temporaryAddedMultipleFeature?.[0].featureType, this.temporaryAddedMultipleFeature?.[0].featureGroup)
        }
      } else {
        if (confirm(cnfrmMsg)) {
          if (this.isSingleFeatureSave && !this.isFromCommitChange) {
            this._mapIDsToFeature(this.temporaryAddedFeature);
            this._saveFeatureConfirmation(this.temporaryAddedFeature);
          } else if(this.isFromCommitChange) {
            this._saveMultipleCommits(this.changesTobeCommitted);
          } else {
            this.saveFeatureByType(this.temporaryAddedMultipleFeature?.[0].featureType, this.temporaryAddedMultipleFeature?.[0].featureGroup)
          }
        }
      }
    }
  }

  isValidJSON(data:any) {
    let isValid = true;
    try {
      JSON.parse(data);
    } catch {
      isValid = false;
    }
    return isValid
  }

  onCancelFeatureSave() {
    this.selectedJob = null
  }

  private _mapIDsToFeature(feature: any) {
    feature['mapJobId'] = this.selectedJob?.jobId ? this.selectedJob?.jobId :  feature.mapJobId;
    this.navigatedJobId = this.selectedJob?.jobId;
    feature['layerId'] = this.getLayerID(this.editLayerName) ? this.getLayerID(this.editLayerName) : this.selectedJob?.layerId;
  }

  private _saveFeatureConfirmation(feature: any) {
    if (confirm('Are you sure you want to save?')) {

      const drawFeatures = this.draw.getAll();
      //Commented By Manjeet
      //drawFeatures.features[0].properties = feature;
      // Get the drawing feature by featureName
      var saveFeatures = drawFeatures.features.filter(
        (drawFeature: any) =>
          drawFeature.properties.featureName === feature.featureName
      );

      if (saveFeatures.length === 1) {
        if(saveFeatures?.length !== drawFeatures?.length) {
          // delete remaining features
          drawFeatures?.features?.forEach((feature:any)=>{
            if(Number.isNaN(Number(feature.id))) {
              this.draw.delete(feature.id);
              this.refreshMapStyle();
            }
          })
        }
        let saveFeature = saveFeatures[0];
        if(this.firstSecondLineFeatureFrMergedArr?.length > 0) {
          saveFeature.properties.layerId = feature.layerId;
        }
        // if(layer.toUpperCase()=="External"){
        //   // for External layer appi
        // }else{
        this.saveFeature(saveFeature);
        // }
      }

      // this.isEditingModeIsOn = false;
      // this.updateEditMode.emit(false);
    }
  }

  private _retriveAfterFeatureSave(saveFeature: any) {
    // Remove the drawing feature from the map
    this.draw.delete(saveFeature.id);
    // this.newlyAddedFeature?.splice(this.newlyAddedFeature?.findIndex(it => it?.properties?.featureName === saveFeature?.properties?.featureName), 1);
    // Unhide the saved feature
    this.unhideEditingFeature(saveFeature);

    // update features
    // this.getUpdatedFeatures("", saveFeature, "").subscribe((dataWithLayer: any) => {
    //   this.navigatedFeatures = dataWithLayer;
    //   this.isEditLayer = true;
    //   for (const featureData of dataWithLayer) {
    //     if (!this.isBasemapToggle) {
    //       this.setValuesInRequiredMaps(featureData);
    //     } else {
    //       this.isBasemapToggle = false;
    //     }
    //   }
    //   this.addNewFeatureInExisting();
    //   this._refreshLayer();
    // });
    this.addNewFeatureInExisting();
    this._refreshLayer();
  }

  //addNewFeatureInExisting method used to add the feature's in after saving add or edit map features
  addNewFeatureInExisting() {
    this.newlyAddedFeature?.forEach((feature: any) => {
      // if (this.featureGroupMap.has(this.newAddedFeatureInLayer.layerName)) {
      //   this.addMainFeatureData(
      //     this.newAddedFeatureInLayer,
      //     feature,
      //     'hasValue'
      //   );
      // } else {
      //   this.addMainFeatureData(this.newAddedFeatureInLayer, feature, null);
      // }
      // const navigatedData:any = this.navigatedFeatures.find((ft:any)=> ft.layerId === this.getLayerID(layer));
      // this.dataCreationLayer(feature?.properties,navigatedData);
    });
  }

  unhideEditingFeature(feature: any) {
    const index = this.hiddenFeatures?.indexOf(feature.properties.featureName);
    if (index > -1) {
      this.hiddenFeatures.splice(index, 1);
    }
  }

  //saveFeature method is used to save feature what ever we add or edit in map using savePointFeatureDetailFrMapEditor and saveLineFeatureDetailFrMapEditor api
  saveFeature(saveFeature: any) {
    let wktGeom: string = '';
    let payLoad: any = {
      mapJobId: this.selectedJob?.jobId ? this.selectedJob?.jobId : saveFeature.properties.mapJobId,
      mapFeatureTypeId: saveFeature.properties.featureTypeId,
      mapGroupId: saveFeature.properties.featureGroupId,
      mapLayerId: saveFeature.properties.layerId ? saveFeature.properties.layerId : saveFeature.properties.mapLayerId,
      userId: this.loginUserId,
      workorderNumber:this.selectedJob?.workOrderNumber,
      swmapsLayer: this.metadataService.featureType[saveFeature.properties.featureTypeId]
    };
    //Assigning/merging additional value for future reference
    Object.assign(saveFeature.properties, payLoad);
    // Need to handle annotations here too
    if (saveFeature.geometry.type === 'LineString') {
      this._setMapPointOrLineId(saveFeature, payLoad, "mapLineId");
      wktGeom = 'LINESTRING(';
      saveFeature.geometry.coordinates.forEach(function (p: any, i: number) {
        if (i < saveFeature.geometry.coordinates.length - 1)
          wktGeom = wktGeom + p[0] + ' ' + p[1] + ' 0, ';
        else wktGeom = wktGeom + p[0] + ' ' + p[1] + ' 0)';
      });
      wktGeom = payLoad.mapLineId ? wktGeom.replace("LINESTRING", "LINESTRING Z") : wktGeom;
      if(this.firstSecondLineFeatureFrMergedArr?.length > 0) {
        payLoad.mapLineId = 0;
        payLoad.isAnnotation = false;
      }
      else {
        payLoad.mapLineId = saveFeature.properties.mapLineId;
      }
    } else if (saveFeature.geometry.type === 'Point') {
      //console.log("Point++++++++",);
      this._setMapPointOrLineId(saveFeature, payLoad, "mapPointId");
      wktGeom = `POINT(${saveFeature.geometry.coordinates[0]} ${saveFeature.geometry.coordinates[1]} 0)`;
      wktGeom = payLoad.mapPointId ? wktGeom.replace("POINT", "POINT Z") : wktGeom;
    }
    payLoad.geom = wktGeom;
    // Save to REST
    switch (saveFeature.geometry.type) {
      case 'Point':
        if (!saveFeature.properties.isAnnotation) {
          this.spinner.show();
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .savePointFeatureDetailFrMapEditor(payLoad)
            .subscribe((response) => {

              if(this.isRefreshRequiredForCommittedChange) {
                this._removeCommitChanges();
                this.onSaveSingleFeature.emit(response);
                this.featureTypeForBtnVisible = null;
                this.editSelectedFeature = null;
                setTimeout(() => {
                  this._retriveAfterFeatureSave(saveFeature);
                }, 1000);
              } else {
                // this.toastr.success("Feature Saved Successfully...!");
                // this.mapEditService.committedChangesCountAndSaveSub.next({remove:true, data:this.changesTobeCommitted})
                this._removeCommitChanges();
                this.spinner.hide();
              }
            },
            (err: any) => {
              this._handleSaveError(saveFeature);
            }
            );
        } else {
          // REST to UpsertAnnotationPoint
        }
        break;
      case 'LineString':
        if (!saveFeature.properties.isAnnotation) {
          this.spinner.show();
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .saveLineFeatureDetailFrMapEditor(payLoad)
            .subscribe((response) => {
              this.deleteMergedlineFeatures(); // function call for delete merged lines
              if(this.isRefreshRequiredForCommittedChange) {
                // this.mapEditService.committedChangesCountAndSaveSub.next({remove:true, data:this.changesTobeCommitted})
                this._removeCommitChanges();
                const linkaddedResponse: any = response;
                this.onSaveSingleFeature.emit(response);
                this.featureTypeForBtnVisible = null;
                this.editSelectedFeature = null;
                setTimeout(() => {
                  this._retriveAfterFeatureSave(saveFeature);
                }, 1000);
              } else {
                // this.toastr.success("Feature Saved Successfully...!");
                // this.mapEditService.committedChangesCountAndSaveSub.next({remove:true, data:this.changesTobeCommitted})
                this._removeCommitChanges();
                this.spinner.hide();
              }


            },
            (err: any) => {
              this._handleSaveError(saveFeature);
            }
            );
        } else {
          // REST to UpsertAnnotationLine
        }
        break;
      default:
        break;
    }

    // We should receive the featureName as a result, so it can be displayed in the toggle
    // TODO: add feature to toggle
  }

  private _removeCommitChanges() {
    if (this.changesTobeCommitted?.length) {
      this.mapEditService.committedChangesCountAndSaveSub.next({ remove: true, data: this.changesTobeCommitted });
    } else {
      this.mapEditService.addOrUpdateCommitedChanges([]);
    }
    this.isFromCommitChange = false;
  }

  private _setMapPointOrLineId(saveFeature: any, payLoad: any, field: any) {
    const id = saveFeature.properties.featureId === "1" ? undefined : saveFeature.properties.featureId;
    if (id) {
      payLoad[field] = saveFeature.properties[field] ? saveFeature.properties[field] : id;
    }
  }

  private _handleSaveError(obj: any) {
    this.toastr.error('Error');
    this.spinner.hide();
    if(this.isRefreshRequiredForCommittedChange) {
      this._retriveAfterFeatureSave(obj);
    }
  }

  // click on the cancel icon then cancel a single feature what ever he did or not for particular cancel feature
  cancelFeature(layer: CommitChanges, group:FeatureGroup, featureType:FeatureType | null, feature: Feature) {
    if (confirm('Are you sure you want to cancel?')) {
      // this.isMapJobIdModalPopupOpen = false;
      this._cancelSingleFeature(feature, layer, group, featureType);
    }
  }

  private _cancelSingleFeature(feature: Feature, layer: CommitChanges, group:FeatureGroup, featureType:FeatureType | null) {
    if (this.isEditingModeIsOn) {
      // Just need to trash the draw feature
      let drawFeatures = this.draw
        .getAll()
        .features.filter(
          (drawFeature: any) => drawFeature.properties.featureName === feature.featureName
        );
      if (drawFeatures.length === 1) {
        this.draw.delete(drawFeatures[0].id);
        // Unhide the saved feature
        const index = this.hiddenFeatures?.indexOf(
          drawFeatures[0].properties.featureName
        );
        if (index > -1) {
          this.hiddenFeatures.splice(index, 1);
          // Need to set the feature filter
          // this.setGroupFilter('on', drawFeatures[0].properties.featureGroup);
          this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
        }
        const featureNameIndex = this.newlyAddedFeature?.findIndex(
          (it) => it?.properties?.featureName === feature.featureName
        );
        this.newlyAddedFeature?.splice(featureNameIndex, 1);
        this.editSelectedFeature = null;
        this.isFirstEditDetected = false;
        if (featureNameIndex > -1)
          this.trashFeatureName(
            layer,
            group,
            featureType,
            feature
          );
        }
        // this.updateEditMode.emit(false);
        this.editingHistoryUndoArray = this.editingHistoryUndoArray.filter((x:any) => x.properties?.featureId != feature.featureId);
        this.editingHistoryRedoArray = this.editingHistoryRedoArray.filter((x:any) => x.properties?.featureId != feature.featureId);
      if(this.changesTobeReverted?.length) {
        this.mapEditService.committedChangesCountAndSaveSub.next({remove:true, data:this.changesTobeReverted})
      } else if(this.changesTobeReverted?.length === 0) {
        this.changesTobeReverted = this._getCommitChangesObject(layer, group, featureType, feature);
        this.mapEditService.committedChangesCountAndSaveSub.next({remove:true, data:this.changesTobeReverted})
      }
    }
  }

  // getting object from commit change to remove feature from En-masse Commit Changes list
  private _getCommitChangesObject(layer: CommitChanges, group: FeatureGroup, featureType: FeatureType | null, feature: Feature): any[] {
    const changeObj:any = {};
    this.mapEditService.mapEditorChangesToBeCommitted.forEach((change:CommitChanges)=> {
      if(+change.layerId === layer.layerId) {
        changeObj['layer'] = layer;
        const grp = change?.groups.find((fgrp:FeatureGroup)=> fgrp.groupId === group.groupId);
        changeObj['group'] = grp ? grp : undefined;
        const typ = grp?.types.find((ftyp:FeatureType)=> ftyp.typeId === featureType?.typeId);
        changeObj['type'] = typ ? typ : undefined;
        const ft = typ?.features.find((ftr:Feature)=> ftr.featureName === feature.featureName);
        changeObj['feature'] = ft ? ft : undefined;
      }
    })
    return [changeObj];
  }

  // Handle featureGroup filter - turn off/on layers with Feature Group
  setGroupFilter(state: string, featureGroup: string) {
    let layerArr: any = [1, 2, 3];
    if (this.editLayerName) {
      const temp = this.getLayerID(this.editLayerName);
      layerArr = [temp];
    }
    for (const editingLayerId of layerArr) {
      for (let geomType of ['point', 'line']) {
        let layerId = `${editingLayerId}-${featureGroup}-${geomType}`;
        if (this.map.getLayer(layerId) === undefined) {

          break;
        }
        if (state === 'off') {
          this.map.setFilter(layerId, false);
        } else {
          let filterExpression = this.getGroupFilterExpression(featureGroup);
          this.map.setFilter(layerId, false);
          this.map.setFilter(layerId, filterExpression);
        }
      }
    }
  }

  //getGroupFilterExpression method is used to filter the featuregroupmap data
  getGroupFilterExpression(featureGroup: string) {
    const expression = [
      'all',
      ['==', ['get', 'featureGroup'], featureGroup],
      ['in', ['get', 'mapJobId'], ['literal', this.zoomedJobIds]],
      ['!', ['in', ['get', 'featureName'], ['literal', this.hiddenFeatures]]],
    ];
    return expression;
  }

  //trashFeatureName method is used to cancel whatever we add or edit in map
  trashFeatureName(layer: CommitChanges, group:FeatureGroup, featureType:FeatureType | null, feature: Feature) {

    const data = featureType ? featureType.features : group.features;
    if (data?.length && data.length > 1) {
      data.splice(data.findIndex((ft: Feature) => ft.featureName === feature.featureName &&
        ft.featureId === feature.featureId), 1);
    } else if (data?.length === 1) {
      if (group?.types.length > 1) {
        group?.types.splice(group?.types.findIndex((type: FeatureType) => type.typeId === featureType?.typeId &&
          type.typeName === featureType?.typeName), 1);
      } else if (group?.types?.length === 1) {
        layer.groups = [];
      }
      //   if (
      //     Array.from(this.featureGroupMap.get(layer).get(featureGroup)).length >
      //     1
      //   ) {
      //     this.featureGroupMap.get(layer).get(featureGroup).delete(featureType);
      //   } else {
      //     if (
      //       Array.from(this.featureGroupMap.get(layer).get(featureGroup))
      //         .length === 1
      //     ) {
      //       this.featureGroupMap.get(layer).delete(featureGroup);
      //     }
      //   }
      // }
    }
  }

  //trashFeatureType method is used to cancel whatever we add or edit in map
  trashFeatureType(layer: any, featureGroup: any, featureType: any) {
    // const data = Array.from(this.featureGroupMap.get(layer).get(featureGroup));
    // if (data?.length > 1) {
    //   this.featureGroupMap.get(layer).get(featureGroup).delete(featureType);
    // } else {
    //   if (data?.length === 1) {
    //     this.featureGroupMap.get(layer).delete(featureGroup);
    //   }
    // }
  }

  refreshMapStyle() {
    this.isBasemapToggle = true;
    // let currentMapStyle: mapboxgl.Style = this.map.getStyle();
    // let stylesheet = currentMapStyle.  .stylesheet.id
    // let currentMapStyle = `mapbox://styles/mapbox/${this.map.style.stylesheet.id}`;
    const style = this.map.getStyle();
    if(style){
      this.map.setStyle(style);
    }
    this.draw.changeMode('sitemap_simple_select');

    // this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
  }

  // toggleSiteGroup(featureGroup: any, layer: any) {
  //   const layerID = this.getLayerID(layer);
  //   if (layerID === 5) {
  //     this.toggleGPRSLayer('GPRS');
  //     this.toggleGPRSLayer('CLIENT');
  //     this.toggleGPRSLayer('IMPORTED');
  //     this.toggleGPRSLayer('EXTERNAL');
  //   }
  // }

  // toggleSiteType(featureGroup: any, featureType: any, layer: any) {
  //   const layerID = this.getLayerID(layer);
  //   if (layerID === 5) {
  //     const tempLayerData = this.navigatedFeatures.find((it: any) => it.layerId === layerID);
  //     const jobId = tempLayerData.features.find((it: any) => it.featureType === featureType);
  //     const temp = [jobId.featureTypeId];
  //     console.log("featureGroupId: ", temp);
  //     const siteLayerGroup = this.navigatedFeatures.filter((it: any) => it.jobId === temp[0]);
  //     siteLayerGroup?.forEach((feature: any) => {
  //       console.log("siteLayerGroup: ", feature);
  //       feature?.features?.forEach((element: any) => {
  //         this.toggleGroup(element.featureGroup, feature.layerName, 'toggleSite');
  //       });
  //     });

  //     console.log("test: ", siteLayerGroup);
  //     console.log("featureGroupToggleStateMap: ", this.featureGroupToggleStateMap.get(layer))
  //     this.commonMapService.filterMVTFromToggle(temp, this.map, this.siteLayerData);
  //   }
  // }

  //*********************** external layer

  //use to set External Content image on dropdown
  getSelectedExternalContentType() {
    this.externalContentIcon =
      '../../../../../assets/images/GPRS_PNG/' +
      this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") +
      '.png';

    if (this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") === 'POINTCLOUD') {
      // this.externalContentForm.controls['linkExternal'].setValidators([Validators.maxLength(200)]);

      this.externalContentForm.controls["linkExternal"].clearValidators();
      this.externalContentForm.controls["linkExternal"].updateValueAndValidity();
    }
  }

  //to get all external content type names
  getExternalContentTypes() {

    this.spinner.show();
    this.externalContentDropdownData = []
    this.mapViewerService.getExternalContentTypes().subscribe((response: any) => {
      if (response?.length > 0) {
        //this.externalContentDropdownData = response;
        this.externalContentDropdownData = response.filter((ec:any) => (ec.exernalComponentName === "Matterport" || ec.exernalComponentName === "External Link" || ec.exernalComponentName==="Pointcloud")); //response;

        // PAW adding an alias name to the externalContentDropdownData.  The alias name will be shown in the drop down.
        for (var i=0; i < this.externalContentDropdownData.length; i++){
          if (this.externalContentDropdownData[i].exernalComponentName.toUpperCase() == "MATTERPORT") {
            this.externalContentDropdownData[i].alias = "Virtual Tour";
          } else
          {
            this.externalContentDropdownData[i].alias = this.externalContentDropdownData[i].exernalComponentName;
          }
        }

        this.selectOptionsExternal = this.externalContentDropdownData[0].exernalComponentName;
        this.externalContentIcon =
          '../../../../../assets/images/GPRS_PNG/' +
          this.externalContentDropdownData[0].exernalComponentName.toUpperCase().replace(/\s/g, "") +
          '.png';
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  //to add external content when user click on Add button in External layer
  addExtenalContent(layer: any) {
    this.externalLayerAddEdit = 'Add';

    if (this.selectOptionsExternal.toUpperCase() == 'MATTERPORT')
    {
      this.modalExternalAddHeader = "Virtual Tour";
    } else {
      this.modalExternalAddHeader = this.selectOptionsExternal;
    }

    this.matterportLinkArray=[];

    this.selectOptionsExternalEnable = true;
    // this.externalContentForm.patchValue({
    //   featureGroup: this.selectOptionsExternal
    // });
    
    if (this.modalExternalAddHeader == "Virtual Tour") {
      this.externalContentForm.patchValue({
        linkExternal: 'https://www.google.com',
        featureGroup: this.selectOptionsExternal
      });
    } else {
      this.externalContentForm.patchValue({
        linkExternal: '',
        featureGroup: this.selectOptionsExternal
      });
    }

    // this.newAddedFeatureName = `temp_${this.selectOptionsExternal}_${new Date().toLocaleTimeString([], {
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   hour12: false,
    // })}`;
    // let data = { layerName: layer };
    // let feature: any = {
    //   type: 'Feature',
    //   properties: {
    //     featureGroupId: this.externalContentDropdownData.find((it: any) => it.exernalComponentName === this.selectOptionsExternal).externalComponentTypeId,
    //     featureGroup: this.selectOptionsExternal.toUpperCase().replace(/\s/g, ""),
    //     featureTypeId: null,
    //     featureType: null,
    //     featureId: this.getLayerID(layer)?.toString(),
    //     featureName: this.newAddedFeatureName,
    //     featureGeometryType: 'Point',
    //   },
    //   geometry: {
    //     type: null,
    //     coordinates: null,
    //   },
    // };

    // this.newAddedFeatureInLayer = data;
    // // this.newlyAddedFeature.push(feature);
    //need to work on this because feature type not available and not add in the featuregroupmap variable
    //  if (this.featureGroupMap.has(data.layerName)) {
    //   this.addMainFeatureData(data, feature, 'hasValue');
    // } else {
    //   this.addMainFeatureData(data, feature, null);
    // }

    this.addFeatureForMapEditor(
      this.selectOptionsExternal.toUpperCase().replace(/\s/g, ""),
      null,
      layer
    );
    this.updateNewFeature();

    // this.editSelectedFeature = feature;
    this.handleAddPoint();
    // commented as history is not part of current US
    // if (this.editingHistory.length > 1) {
    //   return;
    // }
  }

  onSelectJobChange(event: any, offset: any) {
    const job = this.jobDetailsForGPRS[event?.target?.selectedIndex - offset];
    if(this.metadataService?.jobIdToAccess[job?.jobId] === 1) {
      this.toastr.error(`You do not have "Edit Access" on jobid ${job?.jobId}`);
      this.selectedJob = null;
      let selectBox: any = document.getElementById("feature-job-select");
      selectBox.value = this.selectedJob ? this.selectedJob.jobId : "default";
      return;
    }
    this.selectedJob = this.jobDetailsForGPRS[event?.target?.selectedIndex - offset];
    // const formData = this.externalContentForm.value;
    // formData["WANumber"] = this.selectedJob?.workOrderNumber;
    this.navigatedJobId = this.selectedJob?.jobId
    this.externalContentForm.patchValue({ WANumber: this.selectedJob?.workOrderNumber })
    this.externalContentForm.patchValue({ job: this.selectedJob });
    // this.externalContentForm.value['WANumber']= this.selectedJob?.workOrderNumber;
  }

  // use to save content in both case of Add/Edit
  onExternalContentSave(form: FormGroup, type: any) {

    if (form.value.featureGroup.toUpperCase().replace(/\s/g, "") === 'MATTERPORT') {
      this.externalContentForm.patchValue({
        linkExternal: 'https://www.google.com',
        featureGroup: 'Matterport'
      });
    } 

    if (form.valid) {
      if (this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") === 'POINTCLOUD') {
        alert("Loading point cloud data is in process.  Depending on the file size and your internet connection, this could take some time.  The content will appear in the menu once the upload is complete.");
        this.uploadPointCloudFile(form, type);
      } else {
        this.onExternalContentSaveSubmit(form, type);
      }
    } else {
      this.toastr.warning("Please fill details correctly.")
    }
  }

  // use to submit data of external layer on database
  onExternalContentSaveSubmit(form: FormGroup, type: any) {
    this.spinner.show();
    // this.navigatedJobId = this.navigatedJobId ? this.navigatedJobId : this.selectedJob?.jobId;
    this.navigatedJobId = Array.isArray(this.selectedJob) && this.selectedJob?.length ? this.selectedJob[0].jobId : this.selectedJob.jobId;
    this.externalPayloadReturn(this.externalContentForm.value['featureGroup']);
    let payload = this.externalPayload;
    this.mapViewerService.upsertExternalContent(this.extContentApiUrl, payload).subscribe((response: any) => {
      if (response && response.processingStatus.status === "Success") {

        if (this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") === 'POINTCLOUD') {
          this.mapViewerJobFeatureService.importPointCloudAsyncApi('ImportPointCloudAsync').subscribe(response => {

          }, err => {
            console.log('err', err);
          });
        }
        if (this.externalContentForm.value.editEnable) {
          this.toastr.success("Update external content successfully.");
          if (type === "fromIconMove") {
            let featureDetailEdit = JSON.parse(this.externalContentForm.value.featureDetailEdit);
            // Remove the drawing feature from the map
            this.draw.delete(featureDetailEdit.id); // Unhide the saved feature
            this.unhideEditingFeature(featureDetailEdit);
            //update features
            // this.getUpdatedFeatures("", featureDetailEdit, "").subscribe((dataWithLayer: any) => {
            //   this.navigatedFeatures = dataWithLayer;
            //   for (const featureData of dataWithLayer) {
            //     if (!this.isBasemapToggle) {
            //       this.setValuesInRequiredMaps(featureData);
            //     } else {
            //       this.isBasemapToggle = false;
            //     }
            //   }

            //
            //   this.addNewFeatureInExisting();
            //   this.refreshMapStyle();
            // });
            this._removeCommitChanges();
            this._refreshLayer();

          }
          this.featureTypeForBtnVisible = null;
          this.editSelectedFeature = null;
        } else {
          this.toastr.success("Added external content successfully.");
        }
        this.spinner.hide();
        this.isEditLayer = true;
        this.modalExternalAddHeader = '';
        this.selectOptionsExternalEnable = false;
        this.externalContentForm.reset(this.externalContentFormOriginal);
        // this.refreshMapStyle();
        // this._loadFeatureData();
        this._removeCommitChanges();
        this._refreshLayer();
        // this.viewExternalComponentsByJobId();
        let button = document.getElementById("closeExtModal") as HTMLButtonElement;
        button.click();
        this.isEditingModeIsOn = false;
        this.updateEditMode.emit(false);
      }
      else {
        // this.toastr.error("Could not add selected external content.");
        this.toastr.error(response?.processingStatus?.message);
        this.modalExternalAddHeader = '';
        this.selectOptionsExternalEnable = false;

        this.externalContentForm.reset(this.externalContentFormOriginal);
        let button = document.getElementById("closeExtModal") as HTMLButtonElement;
        button.click();
        this.spinner.hide();
      }
      this._refreshLayer();
      this.isEditLayer = true;
      this.spinner.hide();
      this.isEditingModeIsOn = false;
      this.updateEditMode.emit(false);
    }, (err) => {
      this.spinner.hide();
      this.modalExternalAddHeader = '';
      this.selectOptionsExternalEnable = false;
      this.isEditLayer = true;
      this.externalContentForm.reset(this.externalContentFormOriginal);
      this.toastr.error("Error" + err);
      this._refreshLayer();
      let button = document.getElementById("closeExtModal") as HTMLButtonElement;
      button.click();
      this.isEditingModeIsOn = false;
      this.updateEditMode.emit(false);
    });


  }

  // use to cancel External layer edit data
  onExternalContentCancel() {
    let button = document.getElementById("closeExtModal") as HTMLButtonElement;
    button.click();
    this.modalExternalAddHeader = '';
    this.selectOptionsExternalEnable = false;
    this.externalContentForm.reset(this.externalContentFormOriginal);
    this.editSelectedFeature = null;
    let drawFeatures = this.draw.getAll().features;
    if (drawFeatures.length > 0) {

      drawFeatures?.forEach((element: any) => {
        this.draw.delete(element.id);
        // Unhide the saved feature
        const index = this.hiddenFeatures.indexOf(
          element.properties.featureName
        );
        if (index > -1) {
          this.hiddenFeatures.splice(index, 1);
          // Need to set the feature filter
          this.setGroupFilter('on', element.properties.featureGroup);
        }
      });
    }

  }

  // get payload for External Content
  externalPayloadReturn(externalTypeName: any) {
    //let externalPayload :any = '';
    let externalTypeId = this.externalContentDropdownData.find((it: any) => it.exernalComponentName.toUpperCase().replace(/\s/g, "") === externalTypeName.toUpperCase().replace(/\s/g, "")).externalComponentTypeId

    switch (externalTypeId) {
      case 6: {
        this.externalPayload = {
          mapAttachmentId: this.externalContentForm.value.featureId,
          attachmentDisplayName: this.externalContentForm.value.displayName,
          attachmentLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber ? this.externalContentForm.value.WANumber : this.selectedJob.workOrderNumber,
          mapJobId: this.navigatedJobId ? this.navigatedJobId : this.selectedJob.jobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertAttachment';
        break;
      }
      case 7: {
        this.externalPayload = {
          externalLinkId: this.externalContentForm.value.featureId,
          externalLinkDisplayName: this.externalContentForm.value.displayName,
          externalLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber ? this.externalContentForm.value.WANumber : this.selectedJob.workOrderNumber,
          mapJobId: this.navigatedJobId ? this.navigatedJobId : this.selectedJob.jobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertExternalLink';
        break;
      }
      case 8: {
        // need to remove any table entries without url or description
        this.matterportLinkArray =this.matterportLinkArray.filter((vtLink:any) => vtLink["url"].length > 0 && vtLink["displayName"].length > 0);
        this.externalPayload = {
          matterportId: this.externalContentForm.value.featureId,
          matterportDisplayName: this.externalContentForm.value.displayName,
         // matterportLink: this.externalContentForm.value.linkExternal,
          links: this.matterportLinkArray,
          externalWorkorderNumber: this.externalContentForm.value.WANumber ? this.externalContentForm.value.WANumber : this.selectedJob.workOrderNumber,
          mapJobId: this.navigatedJobId ? this.navigatedJobId : this.selectedJob.jobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertMatterport';
        break;
      }
      case 9: {
        this.externalPayload = {
          pointCloudId: this.externalContentForm.value.featureId,
          pointcloudDisplayName: this.externalContentForm.value.displayName,
          pointcloudLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber ? this.externalContentForm.value.WANumber : this.selectedJob.workOrderNumber,
          mapJobId: this.navigatedJobId ? this.navigatedJobId : this.selectedJob.jobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId,
          filename: this.pointCloudFileName,
        };
        this.extContentApiUrl = 'UpsertPointCloud';
        break;
      }
      case 10: {
        this.externalPayload = {
          virtualTourId: this.externalContentForm.value.featureId,
          virtualTourDisplayName: this.externalContentForm.value.displayName,
          virtualTourLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber ? this.externalContentForm.value.WANumber : this.selectedJob.workOrderNumber,
          mapJobId: this.navigatedJobId ? this.navigatedJobId : this.selectedJob.jobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertVirtualTour';
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  // External Layer Data based on Job Id
  viewExternalComponentsByJobId(isFromEdit?:boolean) {
  //   const observableArray = [];
  //  if(this.navigatedJobId) {
  //   observableArray.push(this.mapViewerService.getExternalComponentsByJobId(this.navigatedJobId, this.loginUserId));
  //  } else {
  //    for (let index = 0; index < this.zoomedJobIds.length; index++) {
  //      const id = this.zoomedJobIds[index];
  //      observableArray.push(this.mapViewerService.getExternalComponentsByJobId(id, this.loginUserId));
  //     }
  //   }
  //   // handling multiple jobIds for external content
  //   forkJoin(observableArray).subscribe((response: any) => {


      // if (response?.length > 0) {
      //   const res = [...response.filter((data:any[])=> data?.[0]?.features?.length).flat()];
      if(this.navigatedFeatures?.length){
        const externalData = this.navigatedFeatures.filter((data:any)=> data.layerId === 4);
        if(externalData){
          this.externalLayerDatajobIdFeatures = externalData;
        }
        if(isFromEdit) {
          this.selectedJob = this.jobDetailsForGPRS.find((job)=> job?.jobId === externalData[0]?.jobId)
        }
      }

        // for (const content of res) {
        //   this.setValuesInRequiredMaps(content);
        // }

        // this.addNewFeatureInExisting();
        // this.refreshMapStyle();
    //   }
    // }, err => {
    //   this.toastr.error(err);
    // });
  }

  //SaveExternalEditFeatures: On click on save icon from right slide out
  saveExternalLayerEdit(layer: any, feature: any) {



    if (confirm('Are you sure you want to save?')) {


      const drawFeatures = this.draw.getAll();
      // Get the drawing feature by featureName
      var saveFeatures = drawFeatures.features.filter(
        (drawFeature: any) =>
          drawFeature.properties.featureName === feature.featureName
      );

      if (saveFeatures.length === 1) {
        let saveFeature = saveFeatures[0];


        let clickExternalDetails: any = [];
        this.externalLayerDatajobIdFeatures?.forEach((featuresJob: any) => {

          let clickExternaldata = [];
          clickExternaldata = featuresJob.features.filter(
            (feature: any) =>
              feature.featureId === saveFeature.properties.featureId
          );
          clickExternalDetails = [...clickExternalDetails, ...clickExternaldata]
        });


        if (clickExternalDetails.length == 1) {
          this.modalExternalAddHeader = clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup.toLowerCase() : '';
          this.latitudeExternalContent = saveFeature.geometry.coordinates[1];
          this.longitudeExternalContent = saveFeature.geometry.coordinates[0];
          this.externalContentId = saveFeature.properties.featureId;
          const job = Array.isArray(this.selectedJob) && this.selectedJob?.length ? this.selectedJob[0] : this.selectedJob;
          this.externalContentForm.patchValue({
            linkExternal: clickExternalDetails[0]?.addedLink ? clickExternalDetails[0]?.addedLink : '',
            WANumber: clickExternalDetails[0]?.externalWorkorderNumber ? clickExternalDetails[0]?.externalWorkorderNumber : '',
            displayName: clickExternalDetails[0]?.feature ? clickExternalDetails[0]?.feature : '',
            editEnable: true,
            featureId: clickExternalDetails[0]?.featureId ? clickExternalDetails[0]?.featureId : 0,
            featureDetailEdit: saveFeature?.id ? JSON.stringify(saveFeature) : '',
            featureGroup: clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup : '',
            job: job
          });
          // let button = document.getElementById("btnExternalContent") as HTMLButtonElement;
          // button.click();

          this.onExternalContentSave(this.externalContentForm, 'fromIconMove');
        }
      }
    }
  }

  addVTRow() {
    let matterportObj = {displayName: '',
                         url: '',
                         matterportId: this.externalContentId,
                         action: new virtualTourListTableDataDC().actionButton};

    this.matterportLinkArray.push(matterportObj);
  }

  btnDeleteVTLinkUrl(event:any){
    console.log("delete vt link:",event);
    let linkIndex = this.matterportLinkArray.indexOf(event.data);
    if (linkIndex !== -1){
      this.matterportLinkArray.splice(linkIndex, 1);
    }
  }

  //SaveExternalEditFeatures: On click on save icon from right slide out
  saveExternalLayerEditInfo(layer: any, feature: any) {

    this.externalLayerAddEdit = 'Edit';
    let clickExternalDetails: any = [];
    this.externalLayerDatajobIdFeatures?.forEach((featuresJob: any) => {
      let clickExternaldata = [];
      clickExternaldata = featuresJob.features.filter(
        (featuredJob: any) =>
          featuredJob?.feature.toString() === feature.featureName
      );
      clickExternalDetails = [...clickExternalDetails, ...clickExternaldata]
    });

    if (clickExternalDetails[0].featureGroup === "MATTERPORT") {
      // PAW create the table for the vt links
      let matterportObj = clickExternalDetails[0].links;
      this.matterportLinkArray = [...matterportObj];

      if (this.matterportLinkArray.length > 0){
        this.matterportLinkArray.forEach((vtLink:any) => {
          vtLink['matterportId'] = vtLink.featureId;
          vtLink['action'] = new virtualTourListTableDataDC().actionButton;
        });
      }
    }

    if (clickExternalDetails.length == 1) {
      let cordinategeom = clickExternalDetails[0]?.geometry.coordinates;
      this.modalExternalAddHeader = clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup.toLowerCase() : '';
      if (this.modalExternalAddHeader.toUpperCase() == 'MATTERPORT')
      {
        this.modalExternalAddHeader = "Virtual Tour";
      }
      this.latitudeExternalContent = cordinategeom[1];
      this.longitudeExternalContent = cordinategeom[0];
      this.externalContentId = clickExternalDetails[0]?.featureId;
      const job = Array.isArray(this.selectedJob) && this.selectedJob?.length ? this.selectedJob[0] : this.selectedJob;
      this.externalContentForm.patchValue({
        linkExternal: clickExternalDetails[0]?.addedLink ? clickExternalDetails[0]?.addedLink : '',
        WANumber: clickExternalDetails[0]?.externalWorkorderNumber ? clickExternalDetails[0]?.externalWorkorderNumber : job?.workOrderNumber,
        displayName: clickExternalDetails[0]?.feature ? clickExternalDetails[0]?.feature : '',
        editEnable: true,
        featureId: clickExternalDetails[0]?.featureId ? clickExternalDetails[0]?.featureId : 0,
        featureDetailEdit: '',
        featureGroup: clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup : '',
        job: job
      });

      if (clickExternalDetails[0].featureGroup == "MATTERPORT")
      {
        // need to autofill the url, to pass form validation
        this.externalContentForm.patchValue({
          linkExternal: 'https://www.google.com',
          featureGroup: 'Matterport'
        });
      }

      let button = document.getElementById("btnExternalContent") as HTMLButtonElement;
      button.click();
    }
  }

  /** For popualte site list start */
  getSiteList(status: boolean = false) {
    // this.spinner.show();
    this.siteData = [];
    let userId = sessionStorage.getItem('loginUserId');
    let dataPost = this.zoomedJobIds;
    this.apiService.getSiteList(userId, dataPost).subscribe(
      (response: any) => {
        this.siteData = response;
        this.spinner.hide();
        this.siteData.forEach((site: any) => {
          if (site.siteId) {
            this.siteServices.getJobsBySiteId(site.siteId, site, this.loginUserId);
            this.subSiteServices.getSubSiteBySiteId(site.siteId, this.siteData);
          }
        });
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  /** For popualte site list end */


// ImportR function Starts

  importJobModalCall(layerName: string) {
    this.selectedJob = null;
    let selectBox: any = document.getElementById("feature-job-select");
    selectBox.value = this.selectedJob ? this.selectedJob.jobId : "default";

    if (!this.editCheck) {
      this.permissionService.showErrormessageForPersonal();
      return;
    }

    this.isImportingReferenceLayer = layerName == 'REFERENCE';
    this.importJobModalEnable = true;
    this.isFromCommitChange = true;
    this.isSelectButtonVisible = false;
    this.isSubmitButtonVisible = false;
    this.isSubmitButtonVisibleGISReference = false;
    this.isSubmitNonGeoReferenceImage = false;

    if (this.isImportingReferenceLayer) {
      this.selectedJob = null;
      this.navigatedJobId = -1;
      this.externalContentForm.patchValue({ WANumber: null })
      this.externalContentForm.patchValue({ job: null });
      this.onSaveFeatureClick();
    } else {
      const saveBtn = document.getElementById("mapJobAndWOContent") as HTMLButtonElement;
      saveBtn.click();
    }
  }
  //******************  import related code

  // Used to save the Imported Layer File name changed
  saveImportedLayerName() {
   if (this.importedLayerFileName) {
      if (confirm('Are you sure you want to save?')) {
        this.spinner.show();
        this.featureDeleteSubscribe = this.mapViewerJobFeatureService
          .saveImportedFileNameByJobId(
            parseInt(this.importedLayerFileId),
            this.importedLayerFileName,
            2,
            this.loginUserId
          )
          .subscribe(
            (data: any) => {
              if (data.processingStatus.status == 'Success') {
                this.toastr.success('Layer name successfully changed');
                this.importedFileNameChangeCondition = false;
                this.importedLayerFileNameStore = this.importedLayerFileName;
                this.isEditLayer = true;
                this.isEditingModeIsOn = false;
                this.updateEditMode.emit(false);
                this._refreshAfterSave();
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );
      }
    } else {
      this.toastr.error("Please enter valid layer name")
    }
  }

  cancelImportedLayerName() {
    this.importedFileNameChangeCondition = false;
    this.importedLayerFileName = this.importedLayerFileNameStore;
    this.isEditingModeIsOn = false;
    this.updateEditMode.emit(false);
  }

  importCustomFormData() {

  }

  // For Detect Import Layer File Name is change
  importedFileChange(event: any) {
    if (event != this.importedLayerFileNameStore) {
      this.importedFileNameChangeCondition = true;
    } else {
      this.importedFileNameChangeCondition = false;
    }
    if (event == '') {
      this.importedBlankCheck = false
    } else {
      this.importedBlankCheck = true
    }
  }

  //*********************** import end
  // Reterieve Vector Tiles by user id
  reteriveVectorTilesbyUser() {
    this.referenceGISTilesData = [];
    this.getAllVectorsSubscribe = this.mapViewerJobFeatureService.retrieveVectorTiles(this.loginUserId).subscribe((response: any) => {
      if (response?.vectorTileEntities?.length > 0) {
        this.referenceLayerDataLoad(response);
      } else {
        // this.toastr.warning("No Data in Reference GIS layer");
      }
    }, (err: any) => {
      console.log("Error", err);
    });
  }

  private referenceLayerDataLoad(response: any) {
    this.referenceGISTilesData = response.vectorTileEntities;

    let polygons = this.referenceGISTilesData.filter(item => /POLYGON/.test(item.geomType));
    polygons.sort((a, b) => (a.layerName.localeCompare(b.layerName)));
    let points = this.referenceGISTilesData.filter(item => /POINT/.test(item.geomType));
    points.sort((a, b) => (a.layerName.localeCompare(b.layerName)));
    let lines = this.referenceGISTilesData.filter(item => /LINE/.test(item.geomType));
    lines.sort((a, b) => (a.layerName.localeCompare(b.layerName)));
    let sortedArray = points.concat(lines).concat(polygons);

    this.referenceGISTilesData = sortedArray;

    setTimeout(() => {
      this.referenceGISTilesData.forEach((item: any) => {
        item.setEyeActive = false;
        this.commonMapService.setSourceForReferenceLayerVectorTiles(this.map, item);
        this.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'none');
      });
      this.referenceLayerItems = referenceLayerDataToMenuItems(this.referenceGISTilesData);
    }, 1000);

    // this.isLayerEyeVisible = {
    //   SITE: true,
    //   GPRS: true,
    //   CLIENT: true,
    //   IMPORTED: true,
    //   EXTERNAL: true,
    //   REFERENCE: false
    // };
  }

  // Toggle Reference Layer Header
  toggleReferenceLayer(layer: CommitChanges) {
    if (layer.isEyeVisible) {
      this.referenceGISTilesData.forEach((item: any) => {
        item.setEyeActive = false;
        this.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'none');
      });
    } else {
      this.referenceGISTilesData.forEach((item: any) => {
        item.setEyeActive = true;
        this.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'visible');
      });
    }
    this.referenceLayerItems = referenceLayerDataToMenuItems(this.referenceGISTilesData);
    layer.isEyeVisible = !layer.isEyeVisible;
    // this.isLayerEyeVisible[layer] = !this.isLayerEyeVisible[layer];
  }

  // Toggle Reference Particular layer Item
  toggleReferenceLayerItem(item: LayerMenuItemData) {
    const items = this.getRefernceLayerItemsFromLayerMenuItem(item);
    items.forEach(element => {
      const dataId = element.mvtId + '-reference';
      let visibility = "none";
      if (element.setEyeActive) {
        visibility = "visible";
      }
      this.map.setLayoutProperty(dataId, 'visibility', visibility);
    });
  }

  toggleReferenceLayerFeature(layer: any, data: any, index: any) {
    let dataId = data.mvtId + '-reference';
    if (data.setEyeActive) {
      this.map.setLayoutProperty(dataId, 'visibility', 'none');
    } else {
      this.map.setLayoutProperty(dataId, 'visibility', 'visible');
    }
    data.setEyeActive = !data.setEyeActive;
  }

  deleteReferenceLayerItem(item: LayerMenuItemData) {
    const items = this.getRefernceLayerItemsFromLayerMenuItem(item);
    // items.forEach(element => {

    // });
    // const referenceLayerItem = this.referenceGISTilesData.find(x => x.mvtId === item.id);
    if(items && items.length > 0) {
      this.deleteReferenceLayer(items);
    }
  }

  getRefernceLayerItemsFromLayerMenuItem(menuItem: LayerMenuItemData): ReferenceGISTilesData[] {
    let result: ReferenceGISTilesData[] = [];
    const referenceLayerItem = this.referenceGISTilesData.find(x => x.mvtId === menuItem.id);
    if(referenceLayerItem) {
      referenceLayerItem.setEyeActive = menuItem.isEyeVisible;
      result.push(referenceLayerItem);
    }
    if(menuItem.children){
      menuItem.children.forEach(child => {
        const children = this.getRefernceLayerItemsFromLayerMenuItem(child);
        if(children.length > 0) {
          result = result.concat(children);
        }
      });
    }
    return result;
  }

  deleteReferenceLayer(
    features: ReferenceGISTilesData[]
  ) {
    let delMessage = 'Are you sure you want to delete?';
    if (confirm(delMessage)) {
      const featureIds = features.map(x => x.mvtId);
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteMultipleFeaturesByFeatureId(
          'DeleteVectorTilesById',
          featureIds, -1, this.loginUserId, 'mvtIds'
        ).subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.toastr.success('Deleted Successfully');
              this.reteriveVectorTilesbyUser();

              this._refreshLayer();
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );

    }
    // } else {
    //   this.toastr.warning("You don't have access to edit this layer");
    // }
  }

  // Use for upload Potree file in ImportContainer
  uploadPointCloudFile(form: FormGroup, type: any) {
    const current = new Date();
    const timestamp = current.getTime();
    let match: any = '';
    if (this.importedFileType === 'las' || this.importedFileType === 'laz') {
      match = this.removeExtension(this.importedFileName) + '_' + timestamp + '_' + this.navigatedJobId +
        '_' + this.loginUserId + '.' + this.importedFileType;
    } else {
      this.toastr.warning("Please select only .las and .laz file formats for Point Cloud");
      return;
    }

    this.importUploadingFileName = match;
    this.spinner.show();
    // Azure Blob Method to upload file on blob
    this.blobService.uploadImage(this.importedContainerSAS, this.importedContainer, this.importedFileArr, match, () => {
      this.pointCloudFileName = match;
      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importedFileName = '';
      this.importedFileType = '';
      let div = document.getElementById("cancelImport") as HTMLDivElement;
      div.click();
      this.spinner.hide();
      this.onExternalContentSaveSubmit(form, type);
    });
  }

  // Use method for Remove Extension
  removeExtension(filename: any) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }

  // Reterieve Reference Image by user id
  reteriveGeoReferenceImagebyUser() {
    this.geoReferenceGISImagesData = [];
    this.forkJoinImportImageSubscription = forkJoin(
      this.zoomedImportedImageObservableArray
    ).subscribe(
      (result: any[]) => {
        console.log('4625 layer.components.ts',result);


        // this.previousZoomJobId = this.zoomedJobIds;
        this.spinner.hide();

        this.geoReferenceDataLoad(result);
        this.toggleApiLoaded = true;
        this.spinner.hide();
      },
      (error: any[]) => {
        console.log(error);
        this.toggleApiLoaded = true;
        this.spinner.hide();
      }
    );
    const getRasterObservables = [
      this.mapViewerJobFeatureService.retrieveGeoReferenceImageforMultipleJobs(this.loginUserId, this.zoomedJobIds),
      this.mapViewerJobFeatureService.retrieveRasterTileForMultipleJobs(this.zoomedJobIds)
    ];

    forkJoin(getRasterObservables).subscribe(([imagesResponse, rasterTilesResponse]) => {
      let response = [];
      if (imagesResponse?.length > 0) {
        response = imagesResponse.map((item: any) => {
          return {...item, type: "image"};
        });
      }

      if (rasterTilesResponse?.length > 0) {
        response = [...response, ...rasterTilesResponse.map((item: any) => {
          return {...item, type: "tile", rasterimageId: item.rastertileId};
        })
      ];
      }
      if (response?.length > 0) {
        this.geoReferenceDataLoadForMultipleJobs(response);
        this.toggleApiLoaded = true;
        this.spinner.hide();
      } else {
        // this.toastr.warning("No Data of Raster Images");
      }
    }, (err: any) => {
      console.log("Error", err);
      this.toggleApiLoaded = true;
      this.spinner.hide();
    });
  }

  private geoReferenceDataLoadForMultipleJobs(result: any[]) {
    if (result?.length > 0) {
      result?.forEach((item: any) => {
       let geoReferencceIndex =  this.geoReferenceGISImagesData.findIndex(itemList => itemList.rasterimageId == item.rasterimageId);
       if(geoReferencceIndex == -1){
          this.geoReferenceGISImagesData.push(item);
        }
      });
    }

    setTimeout(() => {
      this.geoReferenceGISImagesData.forEach((item: any) => {
        item.setEyeActive = true;
        if(item.type === 'image'){
          this.commonMapService.setSourceForGeoReferenceImages(this.map, item);
        }
        else if(item.type === 'tile'){
          this.commonMapService.addLayerForRasterTile(this.map, item);
        }
        if (this.map.getLayer(item.rasterimageId) != undefined) {
          this.map.setLayoutProperty(item.rasterimageId + '-rasterImage', 'visibility', 'visible');
        }
      });
    }, 2000);
  }

  private geoReferenceDataLoad(result: any[]) {
    if (result?.length > 0) {
      for (let i = 0; i < result?.length; i++) {
        if (result[i].rasterImageEntities?.length) {
          result[i].rasterImageEntities?.forEach((item: any) => {
            this.geoReferenceGISImagesData.push(item);
            // console.log("this.geoReferenceGISImagesData", this.geoReferenceGISImagesData);
          });
          setTimeout(() => {
            this.geoReferenceGISImagesData.forEach((item: any) => {
              item.setEyeActive = true;
              this.commonMapService.setSourceForGeoReferenceImages(this.map, item);
              this.map.setLayoutProperty(item.rasterimageId + '-rasterImage', 'visibility', 'visible');
            });
          }, 1000);
        }
      }
    }
  }

  // Toggle GeoReference Imported layer Images
  toggleGeoReferenceLayerImportedFeature(data: RasterImageItem) {
    let dataId = data.rasterimageId + '-rasterImage';
    if (data.setEyeActive) {
      this.map.setLayoutProperty(dataId, 'visibility', 'visible');
    } else {
      this.map.setLayoutProperty(dataId, 'visibility', 'none');
    }
  }

  // Toggle Raster tile layer Images
  toggleRasterTileImportedFeature(layer: any, data: any, index: any) {
    let dataId = data.rastertileId + '-rasterImage';
    if (data.setEyeActive) {
      this.map.setLayoutProperty(dataId, 'visibility', 'none');
    } else {
      this.map.setLayoutProperty(dataId, 'visibility', 'visible');
    }
    data.setEyeActive = !data.setEyeActive;
  }

  // Edit Geo Reference Image modal data loading
  editGeoReferenceImageLayer(feature: IRasterImage){
    this.editGeoReferenceImageType = 'edit';
    this.rasterImageFeature = feature;

    // https://sitemapsadev.blob.core.windows.net/importdata/translated_faa_utm18n_nad83_64178_99.png
    if (feature.fileLink) {
      const imageUrl = this.commonMapService.getRasterImageSourceUrl(feature);
      this.callNonGeoReferenceModal(imageUrl, feature);
    } else {
      this.toastr.warning("Error: Image is not loading");
    }

     // alert( feature.rasterimageId);
     // this.rasterImageIdEdit = feature.rasterimageId;
 }

 deleteRasterItem(feature: RasterImageItem  ) {
  if(feature.type === "image"){
    this.deleteGeoReferenceImageLayer(feature);
  }else if(feature.type === "tile"){
    this.deleteRasterTileLayer(null, feature);
  }
}

  deleteGeoReferenceImageLayer(
    feature: IRasterImage
  ) {
    let delMessage = 'Are you sure you want to delete?';
    if (confirm(delMessage)) {
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteMultipleFeaturesByFeatureId(
          'DeleteRasterImage',
          feature.rasterimageId, feature.mapJobId, this.loginUserId, 'rasterimageId'
        ).subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.geoReferenceGISImagesData = this.geoReferenceGISImagesData.filter(item => item.rasterimageId != feature.rasterimageId);
              const layerSourceName = `${feature.rasterimageId}-rasterImage`;
              const map = this.map;
              const source = map.getSource(layerSourceName);
              if (source !== undefined) {
                map.removeLayer(layerSourceName);
                map.removeSource(layerSourceName);
              }
              this.toastr.success('Deleted Successfully');
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );

    }
  }

  deleteRasterTileLayer(
    layer: any,
    feature: any
  ) {
    let delMessage = 'Are you sure you want to delete?';
    if (confirm(delMessage)) {
      this.spinner.show();
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteRasterTileByTileId(
          feature.rastertileId, feature.mapJobId, this.loginUserId
        ).subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.geoReferenceGISImagesData = this.geoReferenceGISImagesData.filter(item => item.rastertileId != feature.rastertileId);
              const layerSourceName = `${feature.rastertileId}-rasterImage`;
              const map = this.map;
              const source = map.getSource(layerSourceName);
              if (source !== undefined) {
                map.removeLayer(layerSourceName);
                map.removeSource(layerSourceName);
              }

              this.toastr.success('Deleted Successfully');
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );

    }
  }

  visibilityReferenceImages({ item, value }: { item: RasterImageItem, value: number}) {
    let dataId = item.rasterimageId + '-rasterImage';
    this.map.setPaintProperty(dataId, "raster-opacity", value / 100);
  }

  // File Selection for Imported file , and detect file type and name
  onFileSelected(type:any,event: any) {

    const file: File = event.target.files[0];
    this.importSubmitted = false;
    if (file) {
      this.importedFileArr = file;
      this.importedFileName = file.name;
      let fileExtension = this.importedFileName.substr((this.importedFileName.lastIndexOf('.') + 1));

      this.importedFileType = fileExtension.toLowerCase();
      let validFileCheck = false;
      if(type === 'importfile'){
        validFileCheck = this.validateFile();
      } else if(type === 'pointcloud'){
        validFileCheck = this.validatePointCloudFile();
      } else{
        validFileCheck = this.validateFile();
      }

      if (validFileCheck) {
        this.importSubmitted = true;
      } else {
        this.importSubmitted = false;
        this.myImportFileVariable.nativeElement.value = "";
        this.importedFileName = '';
        this.importedFileType = '';
      }
    }
  }

  validateFile() {
    let blnValid = false;
    for (let j = 0; j < this._validFileExtensions.length; j++) {
      let sCurExtension = this._validFileExtensions[j];
      if (this.importedFileName.substr(this.importedFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }

    if (!blnValid) {
      alert("Sorry, " + this.importedFileName + " is invalid, allowed extensions are: " + this._validFileExtensions.join(", "));
      return false;
    }
    return true;
  }

  validatePointCloudFile() {
    let blnValid = false;
    for (let j = 0; j < this._validFileExtensionsPointCloud.length; j++) {
      let sCurExtension = this._validFileExtensionsPointCloud[j];
      if (this.importedFileName.substr(this.importedFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }

    if (!blnValid) {
      alert("Sorry, " + this.importedFileName + " is invalid, allowed extensions are: " + this._validFileExtensionsPointCloud.join(", "));
      return false;
    }
    return true;
  }

  checkImportFileType() {
    if (this._rasterFileExtensions.includes('.' + this.importedFileType)) {
      this.checkImportFileTypeRaster = true;
    } else {
      this.checkImportFileTypeRaster = false;
    }

    if (this.isImportingReferenceLayer)
    {
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = true;
      this.isSubmitNonGeoReferenceImage = false;
      this.importGISForm.patchValue({
        importSelectFormControl: 'referenceGIS'
      });
      this.publishUploadFile('withoutCustomGISReference');
    } else {
      const btnImportPointsLines = document.getElementById('btnimportPointsLines') as HTMLButtonElement
      btnImportPointsLines.click();
    }
  }

   // Publish Code method for Import file
   publishUploadFile(customStatus: any) {
    this.isGeoReferencedRaster = false;
    this.customImportFeaturesType = [];
    this.customImportFeaturesGroup = [];
    this.selectImportFeatureType = '';
    this.selectImportFeatureGroup = '';

    let rasterImageCorrdinateObj = {};
    let rasterImageId = 0;
    let isRasterEdit = false;
    this.navigatedJobId = this.navigatedJobId || 0;
    if (this.importSubmitted) {
      this.spinner.show();
      let importedFileTypeURL = '';

      const current = new Date();
      const timestamp = current.getTime();
      let match: any = this.removeExtension(this.importedFileName) + '-' + timestamp + '_' + this.navigatedJobId +
        '_' + this.loginUserId + '.' + this.importedFileType.toLowerCase();
      if (this.importedFileType.toLowerCase() === 'las' || this.importedFileType.toLowerCase() === 'laz') {
        match = this.removeExtension(this.importedFileName) + '_' + timestamp + '_' + this.navigatedJobId +
          '_' + this.loginUserId + '.' + this.importedFileType.toLowerCase();
      }



      this.importUploadingFileName = match;
      if (customStatus === 'Custom') {
        importedFileTypeURL = 'ImportGetSchemaMapper'
        let frmArray = this.cadCustomForm.get('cadFeatures') as FormArray;
        frmArray.clear();
        // importedFileTypeURL = 'ImportGetSchemaMapperforTesting' // For Testing
      } else if (customStatus === 'withoutCustomGISReference') {
        importedFileTypeURL = 'ImportGISMVTReference'
      } else {
        if (this._rasterFileExtensions.includes('.' + this.importedFileType.toLowerCase())) {
          if (customStatus === 'nonGeoReferenceImageWithoutPNG') {
            importedFileTypeURL = 'ImportRasterTranslate';
          } else if (customStatus === 'nonGeoReferenceImage') {
            this.isGeoReferencedRaster = false;
            importedFileTypeURL = 'ImportRaster';
          } else {
            this.isGeoReferencedRaster = true;
            importedFileTypeURL = 'ImportGISRasterFile';
          }
        } else {
          importedFileTypeURL = 'ImportGISVectorFile'
        }
      }

      // Azure Blob Method to upload file on blob
      this.blobService.uploadImage(this.importedContainerSAS, this.importedContainer, this.importedFileArr, match, () => {

        this.importSubmitted = false;
        this.myImportFileVariable.nativeElement.value = "";
        this.importGISForm.patchValue({
          importSelectFormControl: ''
        });
        this.importedFileName = '';
        this.importedFileType = '';
        let div = document.getElementById("cancelImport") as HTMLDivElement;
        div.click();

        //  Non GeoReference Image Coordinate fetch start
        if (customStatus === 'nonGeoReferenceImage') {
          rasterImageId = 0;
          let imgElement = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
          let mapElement = document.getElementById("mapviewer") as HTMLDivElement;
          let imgRect = imgElement.getBoundingClientRect();
          let mapRect = mapElement.getBoundingClientRect();

          let pixelXMin = imgRect.left - mapRect.left;
          let pixelXMax = imgRect.right - mapRect.left;
          let pixelYMin = imgRect.top - mapRect.top;
          let pixelYMax = imgRect.bottom - mapRect.top;

          let nw = this.map.unproject([pixelXMin, pixelYMin]);
          let ne = this.map.unproject([pixelXMax, pixelYMin]);
          let se = this.map.unproject([pixelXMax, pixelYMax]);
          let sw = this.map.unproject([pixelXMin, pixelYMax]);

          rasterImageCorrdinateObj = {
            xMin: nw.lng,
            xMax: se.lng,
            yMin: se.lat,
            yMax: ne.lat,
          }

        }
        let importLog = {
          "fileName": match,
          "fileSize": this.importedFileArr.size
        }
        this.mapViewerJobFeatureService.generateImportLog(importLog).subscribe((responseimportLog: any) => {
          if (responseimportLog?.processingStatus?.status.toLowerCase() == 'success') {
            const fileId:number = responseimportLog.fileId;
            this.importUploadingFileId = fileId;
            //  Non GeoReference Image Coordinate fetch end
            this.mapViewerJobFeatureService.getFetchDataImport(match, importedFileTypeURL, this.isGeoReferencedRaster, rasterImageId, rasterImageCorrdinateObj, isRasterEdit, this.rangeSliderGeoReferenceImage, fileId, this.importedIsPublic).subscribe(response => {
              let completeImportLog = {
                "importExportJobId": responseimportLog?.importExportJobId,
                "isCompleted": true,
                "errorLog": "",
                "fileName": match
              }
              if (response.status) {
                this.toastr.success("File uploaded Successfully.");
                this.nonGeoreferenceImgDivActive = false;
                this.commonMapService.normalItMapZoom(this.map);
                this.spinner.hide();
                let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
                cancelImportGIS.click();

                this.mapViewerJobFeatureService.completeImportLogAPICall(completeImportLog);
                if (response?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
                  setTimeout(() => {
                    completeImportLog.isCompleted = false;
                    completeImportLog.errorLog = "Error : Can not import records that does not related to this Job";
                    this.mapViewerJobFeatureService.completeImportLogAPICall(completeImportLog);
                    this.toastr.error('Error : Can not import records that does not related to this Job');
                  }, 2000);
                }
                if (customStatus == 'Custom') {
                  let btnCustomCADData = document.getElementById("btnCustomCADData") as HTMLButtonElement;
                  btnCustomCADData.click();

                  let gisMapSchemadata: any = Object.keys(response.gisFileSchemaMapper);


                  for (let i = 0; i < gisMapSchemadata.length; i++) {
                    if (gisMapSchemadata[i].substr((gisMapSchemadata[i].lastIndexOf('.') + 1)).toLowerCase() === 'dxf' || true) {

                      this.importCustomFileName = gisMapSchemadata[i];


                      let layerEntities: any = Object.values(response.gisFileSchemaMapper)[i];
                      if (layerEntities?.entities?.layerValues.length > 0) {
                        this.crsDropDownData = layerEntities?.entities?.crsOptions;

                        if (this.crsDropDownData?.length > 0) {
                          this.crsDropDownData.sort(function (a: any, b: any) {
                            if (a.description.toLowerCase() < b.description.toLowerCase()) { return -1; }
                            if (a.description.toLowerCase() > b.description.toLowerCase()) { return 1; }
                            return 0;
                          });
                          this.cadCustomForm.patchValue({
                            coordinateCRSName: this.crsDropDownData[0]
                          });
                        }

                        let layerValuesCAD = layerEntities?.entities?.layerValues.sort(function (a: any, b: any) {
                          if (a.toLowerCase() < b.toLowerCase()) { return -1; }
                          if (a.toLowerCase() > b.toLowerCase()) { return 1; }
                          return 0;
                        });
                        this.featuresLoaded(layerValuesCAD);
                      } else {
                        this.toastr.warning("No layer entities in this layer");
                      }
                      break;
                    }

                  }

                } else if (customStatus === 'nonGeoReferenceImageWithoutPNG') {
                  if (response?.dataUrl) {
                    this.editGeoReferenceImageType = 'add';
                    this.rasterImageFeature = '';
                    this.callNonGeoReferenceModal(response?.dataUrl, '' as any);
                  } else {
                    this.toastr.warning("Error: Image is not loading");
                  }
                } else {
                  this.isEditLayer = true;
                  this.isEditingModeIsOn = false;
                  this.updateEditMode.emit(false);
                  this._refreshAfterSave();

                }
              } else {
                this.spinner.hide();
                completeImportLog.isCompleted = false;
                if (response?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
                  this.toastr.error('Error : Can not import records that does not related to this Job');
                  completeImportLog.errorLog = "Error : Can not import records that does not related to this Job";
                } else {
                  if (response?.errorMessages?.length > 0) {
                    this.toastr.error('Error' + response?.errorMessages[0]);
                    completeImportLog.errorLog = 'Error' + response?.errorMessages[0];
                  } else if (response?.warningMessages?.length > 0) {
                    this.toastr.error('Error' + response?.warningMessages[0]);
                    completeImportLog.errorLog = 'Error' + response?.warningMessages[0];
                  } else {
                    this.toastr.error('Error');
                    completeImportLog.errorLog = 'Error';
                  }
                }
                this.mapViewerJobFeatureService.completeImportLogAPICall(completeImportLog);
                let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
                cancelImportGIS.click();
              }


            }, (err) => {
              console.log(err);

              if (err?.error?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
                this.toastr.error('Error : Can not import records that does not related to this Job');
              } else {
                if (err?.error?.errorMessages?.length > 0) {
                  this.toastr.error('Error' + err?.error?.errorMessages[0]);
                } else if (err?.error?.warningMessages?.length > 0) {
                  this.toastr.error('Error' + err?.error?.warningMessages[0]);
                } else {
                  this.toastr.error('Error');
                }
              }
              console.log('Error retrieving Data ...');
              this.spinner.hide();
              this.importGISForm.patchValue({
                importSelectFormControl: ''
              });
              this.nonGeoreferenceImgDivActive = false;
              this.commonMapService.normalItMapZoom(this.map);
              let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
              cancelImportGIS.click();
            });
          } else {
            this.spinner.hide();
            if (responseimportLog?.processingStatus?.status?.toLowerCase() == 'failure') {
              this.toastr.error('Error' + responseimportLog?.processingStatus?.message);
            } else {
              this.toastr.error('Error');
            }
            let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
            cancelImportGIS.click();
          }
        }, (err) => {
          console.log(err);
          if (err?.error?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
            this.toastr.error('Error : Can not import records that does not related to this Job');
          } else {
            if (err?.error?.errorMessages?.length > 0) {
              this.toastr.error('Error' + err?.error?.errorMessages[0]);
            } else if (err?.error?.warningMessages?.length > 0) {
              this.toastr.error('Error' + err?.error?.warningMessages[0]);
            } else {
              this.toastr.error('Error');
            }
          }
          console.log('Error retrieving Data ...');
          this.spinner.hide();
          this.importGISForm.patchValue({
            importSelectFormControl: ''
          });
          this.nonGeoreferenceImgDivActive = false;
          this.commonMapService.normalItMapZoom(this.map);
          let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
          cancelImportGIS.click();
        });
      }).catch(() => this.spinner.hide());
    } else {
      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importedFileName = '';
      this.importedFileType = '';
      this.nonGeoreferenceImgDivActive = false;
      this.commonMapService.normalItMapZoom(this.map);
      this.spinner.hide();
      this.toastr.error("Please upload a valid file.");
    }
  }

  // used for loading different feature entities in form
  featuresLoaded(fetauresCad: any) {
    let fetauresCad1 = [];
    // Comment for get all data
    // fetauresCad1 = fetauresCad.slice(0,5);
    fetauresCad1 = fetauresCad;
    // console.log("fetaureCad");
    fetauresCad1.forEach((fetaureCad: any, index: any) => {
      // console.log("fetaureCad", fetaureCad);
      this.addCADFeature(fetaureCad, index);
    })
  }

  // Used for get CAD Form values
  cadFeatures(): FormArray {
    return this.cadCustomForm.get('cadFeatures') as FormArray;
  }

  // Used for bind Feature Group in CAD form
  featureGroupDataBind() {
    this.importFeatureGroup = [];
    FeatureTypeStyleMapping.featureTypeStyleJson.forEach((element) => {
      const val = {
        featureGroupId: element.feature_group_id,
        featureGroup: element.feature_group_name,
      };
      if (this.importFeatureGroup?.length > 0) {
        if (
          this.importFeatureGroup?.find(
            (it) => it.featureGroupId != element.feature_group_id
          )
        ) {
          this.importFeatureGroup.push(val);
        }

      } else {
        this.importFeatureGroup.push(val);
      }
    });

    this.importFeatureGroup = this.importFeatureGroup.filter(
      (array, i, self) =>
        i === self.findIndex((t) => t.featureGroupId === array.featureGroupId)
    );
  }

  // Used for bind feature type in CAD form based on Feature Group id
  featureTypeDataBind(groupId: any) {
    this.importFeatureType = [];
    this.featureTypeSearchSubscribe = this.mapViewerJobFeatureService.searchFeatureTypeApi(groupId).subscribe((data: any) => {
      // console.log("Data", data);
      this.importFeatureType = data;
    });
  }

  // used for set selected value of FeatureGroup details in respective field
  onselectFeatureGroupImport(item: any, cadIndex: any) {

    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(cadIndex).patchValue({
      featureGroup: item.mapGroupName,
      featureGroupId: item.mapGroupId,
      featureType: '',
      featureTypeId: '',
    });
    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(cadIndex)?.get("featureType")?.enable();
    this.importFeatureGroupSelectedIndex = null;
    this.importFeatureTypeSelectedIndex = null;

    if (item.mapGroupId !== this.previousfeatureGroupIdSe) {
      this.featureTypeDataBind(item.mapGroupId);
      this.previousfeatureGroupIdSe = item.mapGroupId;
    }
  }

  // used for set selected value of FeatureType details in respective field
  onselectFeatureTypeImport(item: any, cadIndex: any) {

    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(cadIndex).patchValue({
      featureType: item.mapFeatureTypeName,
      featureTypeId: item.mapFeatureTypeId,
    });
    this.importFeatureGroupSelectedIndex = null;
    this.importFeatureTypeSelectedIndex = null;
  }

  // Used for to create row for Entities layer
  newCADData(fetaureCad: any): FormGroup {
    return this.fb.group({
      featureName: this.fb.control({ value: fetaureCad, disabled: true }, [Validators.required]),
      featureGroup: this.fb.control('', Validators.required),
      featureType: this.fb.control({ value: '', disabled: true }, [Validators.required]),
      featureGroupId: this.fb.control('', Validators.required),
      featureTypeId: this.fb.control('', Validators.required),
    });
  }

  // On Submit of CAD Custom form
  onCADCustomSubmit() {

    let objCr = {
      crs: '',
      layerMappings: []
    }

    let layerrequest1: any = [];

    this.cadCustomForm.getRawValue().cadFeatures.forEach((number: any, index: any) => {
      let leter = {
        layerName: number.featureName,
        map_feature_type_id: number.featureTypeId,
        map_group_id: number.featureGroupId,
      }
      layerrequest1.push(leter);


    });
    objCr.layerMappings = layerrequest1;
    objCr.crs = this.cadCustomForm.getRawValue().coordinateCRSName;

    let layername3 = {
      "gisFileSchemaMapper": { [this.importCustomFileName]: { 'entities': objCr } }
    };

    let cadCustomRequest: any = layername3;

    // console.log("cadCustomForm", this.cadCustomForm.value);
    // console.log("cadCustomForm", this.cadCustomForm.getRawValue());
    // console.log("cadCustomForm Valid", this.cadCustomForm.valid);
    if (this.cadCustomForm.valid) {
      this.toastr.success('CAD Custom form value filled Successfully');
    } else {
      this.toastr.error('Please insert all values in form');
    }
    if (this.cadCustomForm.valid) {
      this.spinner.show();
      this.mapViewerJobFeatureService.submitCustomCADData(this.importUploadingFileName, 'ImportVectorCustom', cadCustomRequest, true, this.importUploadingFileId).subscribe(response => {
        console.log('response', response);
        if (response.status) {
          this.toastr.success("Success");
          this.spinner.hide();
          let cancelImportGIS = document.getElementById("cancelCustomCADForm") as HTMLDivElement;
          cancelImportGIS.click();
          this.mapRefreshAfterImport();
        } else {
          this.spinner.hide();
          this.toastr.error('Error' + response?.errorMessages[0]);
        }

      }, (err) => {
        console.log(err);
        this.toastr.error('Error' + err);
        console.log('Error retrieving Data ...');
        this.spinner.hide();
      });
    }

  }

  mapRefreshAfterImport() {
    // this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
    //   this.navigatedFeatures = dataWithLayer;
    //   for (const featureData of dataWithLayer) {
    //     if (!this.isBasemapToggle) {
    //       this.setValuesInRequiredMaps(featureData);
    //     } else {
    //       this.isBasemapToggle = false;
    //     }
    //   }
    //
    //   this.refreshMapStyle();
    // });
    this._refreshLayer();
  }

  addCADFeature(fetaureCad: any, index: any) {
    this.cadFeatures().push(this.newCADData(fetaureCad));
  }

  // Search feature group value on runtime
  searchFeatureGroup(index: number) {
    let arrayControll: any = this.cadCustomForm.get('cadFeatures') as FormArray;

    let valueFeatureGroup = arrayControll.at(index).get('featureGroup').value;
    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(index).patchValue({

      featureGroupId: '',
      featureType: '',
      featureTypeId: '',
    });
    arrayControll.at(index).get("featureType").disable();
    if (valueFeatureGroup.length > 1) {
      this._filterFeatureGroup(valueFeatureGroup, index);
      // this.filteredOptionsFeatureGroup = this._filterFeatureGroup(valueFeatureGroup);
      // if(this.filteredOptionsFeatureGroup.length !== 0){
      //   this.importFeatureGroupSelectedIndex  = index;
      // }
    } else {
      this.importFeatureGroupSelectedIndex = null;
    }
    // this.filteredOptionsFeatureGroup[index] = valueFeatureGroup
    // .pipe(
    // startWith<string>(''),
    // map((name:any) => name ? this._filterFeatureGroup(name) : this.groupDropDownData.slice())
    // );
  }

  // Search feature type value on runtime
  searchFeatureType(index: number) {
    let arrayControll: any = this.cadCustomForm.get('cadFeatures') as FormArray;

    let valueFeatureType = arrayControll.at(index).get('featureType').value;
    let featureGroupIdSe = arrayControll.at(index).get('featureGroupId').value;
    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(index).patchValue({
      featureTypeId: '',
    });
    if (featureGroupIdSe !== this.previousfeatureGroupIdSe) {
      this.featureTypeDataBind(featureGroupIdSe);
      this.previousfeatureGroupIdSe = featureGroupIdSe;
    }
    console.log('importFeatureType', this.importFeatureType);
    if (valueFeatureType.length > 1) {
      this.filteredOptionsFeatureType = this._filterFeatureType(valueFeatureType, featureGroupIdSe);
      if (this.filteredOptionsFeatureType.length !== 0) {
        this.importFeatureTypeSelectedIndex = index;
      }
    } else {
      this.importFeatureTypeSelectedIndex = null;
    }
  }


  ManageNameControl(index: number) {
    let arrayControll: any = this.cadCustomForm.get('cadFeatures') as FormArray;
    let valueFeatureGroup = arrayControll.at(index).get('featureGroup').valueChanges;

    // this.filteredOptionsFeatureGroup[index] = this.groupDropDownData;

    this.filteredOptionsFeatureGroup[index] = valueFeatureGroup
      .pipe(
        startWith<string>(''),
        map((name: any) => name ? this._filterFeatureGroup(name, index) : this.groupDropDownData.slice())
      );

  }

  // Used for filter feature group value
  private _filterFeatureGroup(searchText: string, index: number) {
    //   const filterValue = searchText.toLowerCase();
    //   return this.importFeatureGroup.filter((option:any) => option.featureGroup.toLowerCase().includes(filterValue));
    this.featureGroupSearchSubscribe = this.mapViewerJobFeatureService.searchFeatureGroupApi(searchText).subscribe((data: any) => {

      // return data
      this.filteredOptionsFeatureGroup = data;
      if (this.filteredOptionsFeatureGroup.length !== 0) {
        this.importFeatureGroupSelectedIndex = index;
      }
    });
  }

  // Used for filter feature type value
  private _filterFeatureType(name: string, featureGroupId: any) {
    const filterValue = name.toLowerCase();
    return this.importFeatureType.filter((option: any) => option.mapFeatureTypeName.toLowerCase().includes(filterValue));
  }

  // ImportR function End


  // Import radio button Function
  changeImportRadio(e: any) {
    if (this.importGISForm.value.importSelectFormControl === 'importDataGIS') {
      this.isSubmitButtonVisible = true;
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'customGIS') {
      this.isSubmitButtonVisible = false;
      this.isSelectButtonVisible = true;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'referenceGIS') {
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = true;
      this.isSubmitNonGeoReferenceImage = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'importGeoReferenceImage') {
      this.isSubmitButtonVisible = true;
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = false;
      // this.nonGeoreferenceImgDivActive = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'importGeoReferenceImageManually') {
      this.isSubmitButtonVisible = false;
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = true;
      // this.nonGeoreferenceImgDivActive = true;
    }
  }

  // Load NonGeoreference Image over the map
  loadingNonGeoReferenceImage() {

    // _rasterFileExtensions = [".tiff", ".tif", ".geotiff", ".pdf", ".gif", ".png", ".jpg", ".jpeg"];
    this.editGeoReferenceImageType = 'add';
    if (this._rasterFileExtensionsWithoutPNG.includes('.' + this.importedFileType.toLowerCase())) {
      this.nonGeoReferenceWithoutPNGCheck = 'nonGeoReferenceImageWithoutPNG';
      this.publishUploadFile('nonGeoReferenceImageWithoutPNG');
    } else {
      this.spinner.show();
      this.nonGeoreferenceImgDivActive = true;
      this.commonMapService.slowItMapZoom(this.map);
      this.nonGeoReferenceWithoutPNGCheck = 'nonGeoReferenceImageWithPNG';
      setTimeout(() => {

        let image = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;

        if (image) {
          image.src = URL.createObjectURL(this.importedFileArr);
          // image.draggable();
          this.rangeSliderGeoReferenceImage = 100;
        }
        let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
        cancelImportGIS.click();
      }, 0);
      this.spinner.hide();
    }


  }

  // Loading Image after conversion to PNG/JPG
  callNonGeoReferenceModal(imageUrl: string, feature: IRasterImage) {
    this.nonGeoreferenceImgDivActive = true;
    this.commonMapService.slowItMapZoom(this.map);
    this.spinner.show();
      setTimeout(() => {

        let image = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
        let nonGeoreference = document.getElementById("nonGeoreference") as HTMLDivElement;

        if (this.editGeoReferenceImageType == 'edit') {

        let topLeftPixel = this.map.project([feature.xMin, feature.yMin]);
        let bottomRightPixel = this.map.project([feature.xMax, feature.yMax]);
        let mapElement = document.getElementById("mapviewer") as HTMLDivElement;
        let mapRect = mapElement.getBoundingClientRect()
        image.style.width = 'auto';
        image.style.height = (topLeftPixel.y - bottomRightPixel.y).toString() + 'px';
        nonGeoreference.style.top = (bottomRightPixel.y + mapRect.top).toString() + 'px';
        nonGeoreference.style.left = topLeftPixel.x.toString() + 'px';

        } else {
          nonGeoreference.style.top = '200px';
          nonGeoreference.style.left = '100px';
        }
        if (image) {
          // image.src = URL.createObjectURL(image);
          image.src = imageUrl;
          this.rangeSliderGeoReferenceImage = (feature.opacity)*100;
          this.nonGeoReferenceImportedFile(imageUrl);
          // image.draggable();
        }
        let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
        cancelImportGIS.click();
        this.spinner.hide();
      }, 0);

  }

  // Save NonGeo Modal Image
  saveNonGeoReferenceImage(type:any,rasterImageFeature:any) {
    // alert(type);
    if(type == 'edit'){
      this.publishUploadNonGeoReferenceImage(type,rasterImageFeature,'nonGeoReferenceImage');
    } else{
      if(this.nonGeoReferenceWithoutPNGCheck === 'nonGeoReferenceImageWithoutPNG'){
        this.publishUploadNonGeoReferenceImage(type,rasterImageFeature,'nonGeoReferenceImage');
      }else{
        this.publishUploadFile('nonGeoReferenceImage');
      }
    }
  }

  // Non GeoReference File type
  nonGeoReferenceImportedFile(fileName: string){
    // var testStr = "sometext-20202"
    // var splitStr = testStr.substring(testStr.indexOf('-') + 1);
    if(fileName.includes('importdata')){
      this.importedFileName = fileName.split('importdata/')[1];
    } else if(fileName.includes('rasterimages')){
      this.importedFileName = fileName.split('rasterimages/')[1];
    } else{
      this.importedFileName = fileName
    }

    let fileExtension = this.importedFileName.substr((this.importedFileName.lastIndexOf('.') + 1));

    this.importedFileType = fileExtension.toLowerCase();
    let validFileCheck = this.validateFile();

    if (validFileCheck) {
      this.importSubmitted = true;
    } else {
      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importGISForm.patchValue({
        importSelectFormControl: ''
      });
      this.importedFileName = '';
      this.importedFileType = '';
      this.nonGeoreferenceImgDivActive = false;
      this.commonMapService.normalItMapZoom(this.map);
    }
  }

  // Publish nonGeoReference  Image
  publishUploadNonGeoReferenceImage(type:any,rasterImageFeature:any,customStatus: any){
    this.isGeoReferencedRaster = false;
    this.customImportFeaturesType = [];
    this.customImportFeaturesGroup = [];
    this.selectImportFeatureType = '';
    this.selectImportFeatureGroup = '';

    let rasterImageCorrdinateObj = {
      xMin: 0,
      xMax: 0,
      yMin: 0,
      yMax: 0,
    }
    let rasterImageId = 0;
    let isRasterEdit = false;
    if(type == 'edit'){
      rasterImageId = rasterImageFeature.rasterimageId;
      isRasterEdit = true;
    }
    if (this.importSubmitted) {
      this.spinner.show();
      let importedFileTypeURL: any = '';

      const current = new Date();
      let match: any = this.removeExtension(this.importedFileName) + '.' + this.importedFileType.toLowerCase();


      this.importUploadingFileName = match;

      if (this._rasterFileExtensions.includes('.' + this.importedFileType.toLowerCase())) {
        if (customStatus === 'nonGeoReferenceImageWithoutPNG') {
          importedFileTypeURL = 'ImportRasterTranslate';
        } else if (customStatus === 'nonGeoReferenceImage') {
          this.isGeoReferencedRaster = false;
          importedFileTypeURL = 'ImportRaster';
        } else {
          this.isGeoReferencedRaster = true;
          importedFileTypeURL = 'ImportGISRasterFile';
        }
      }

      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importGISForm.patchValue({
        importSelectFormControl: ''
      });
      this.importedFileName = '';
      this.importedFileType = '';




      // //  Non GeoReference Image Coordinate fetch start
      // if (customStatus === 'nonGeoReferenceImage') {


      //   let imgElement = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
      //   let mapElement = document.getElementById("mapviewer") as HTMLDivElement;
      //   let imgRect = imgElement.getBoundingClientRect();
      //   let mapRect = mapElement.getBoundingClientRect();

      //   let pixelXMin = imgRect.left - mapRect.left;
      //   let pixelXMax = imgRect.right - mapRect.left;
      //   let pixelYMin = imgRect.top - mapRect.top;
      //   let pixelYMax = imgRect.bottom - mapRect.top;

      //   let nw = this.map.unproject([pixelXMin, pixelYMin]);
      //   let ne = this.map.unproject([pixelXMax, pixelYMin]);
      //   let se = this.map.unproject([pixelXMax, pixelYMax]);
      //   let sw = this.map.unproject([pixelXMin, pixelYMax]);

      //   rasterImageCorrdinateObj = {
      //     xMin: nw.lng,
      //     xMax: se.lng,
      //     yMin: se.lat,
      //     yMax: ne.lat,
      //   }

      // }
      // //  Non GeoReference Image Coordinate fetch end
      // this.mapViewerJobFeatureService.getFetchDataImport(match, importedFileTypeURL, this.isGeoReferencedRaster, rasterImageId, rasterImageCorrdinateObj).subscribe(response => {

      //   if (response.status) {
      //     this.toastr.success("File uploaded Successfully.");
      //     this.nonGeoreferenceImgDivActive = false;
      //     this.spinner.hide();
      //     let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
      //     cancelImportGIS.click();
      //     this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
      //       this.navigatedFeatures = dataWithLayer;
      //       for (const featureData of dataWithLayer) {
      //         if (!this.isBasemapToggle) {
      //           this.setValuesInRequiredMaps(featureData);
      //         } else {
      //           this.isBasemapToggle = false;
      //         }
      //       }
      //
      //       this.refreshMapStyle();
      //     });

      //   } else {
      //     this.spinner.hide();
      //     this.toastr.error('Error');
      //   }

      // }, (err) => {



      //   //  this.toastr.error('Error');
      //   this.spinner.hide();
      //   this.importGISForm.patchValue({
      //     importSelectFormControl: ''
      //   });
      //   this.nonGeoreferenceImgDivActive = false;
      // });




        //  Non GeoReference Image Coordinate fetch start
        if (customStatus === 'nonGeoReferenceImage'){


          let imgElement = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
          let mapElement = document.getElementById("mapviewer") as HTMLDivElement;
          let imgRect = imgElement.getBoundingClientRect();
          let mapRect = mapElement.getBoundingClientRect();

          let pixelXMin = imgRect.left - mapRect.left;
          let pixelXMax = imgRect.right - mapRect.left;
          let pixelYMin = imgRect.top - mapRect.top;
          let pixelYMax = imgRect.bottom - mapRect.top;

          let nw = this.map.unproject([pixelXMin, pixelYMin]);
          let ne = this.map.unproject([pixelXMax, pixelYMin]);
          let se = this.map.unproject([pixelXMax, pixelYMax]);
          let sw = this.map.unproject([pixelXMin, pixelYMax]);

          rasterImageCorrdinateObj = {
            xMin: nw.lng,
            xMax: se.lng,
            yMin: se.lat,
            yMax: ne.lat,
          }

      }
        //  Non GeoReference Image Coordinate fetch end
        if (this.editGeoReferenceImageType == 'edit') {
          const upsertRaster =
          {
            rasterimageId: rasterImageFeature.rasterimageId,
            mapJobId: rasterImageFeature.mapJobId,
            userId: this.loginUserId,
            xMin: rasterImageCorrdinateObj?.xMin,
            xMax: rasterImageCorrdinateObj?.xMax,
            yMin: rasterImageCorrdinateObj?.yMin,
            yMax: rasterImageCorrdinateObj?.yMax,
            opacity: this.rangeSliderGeoReferenceImage / 100,
            fileName: rasterImageFeature.fileName,
            fileLink: rasterImageFeature.fileLink
          }

          this.getAllRasterSubscribe = this.mapViewerJobFeatureService.editRasterImage(upsertRaster).subscribe((response: any) => {
            console.log(":Response Raster Image",response);
            if (response?.processingStatus?.status.toLowerCase() == 'success'){
              this.commonMapService.setSourceForGeoReferenceImages(this.map, upsertRaster);
              this.nonGeoreferenceImgDivActive = false;
              this.commonMapService.normalItMapZoom(this.map);
              this.spinner.hide();
              let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
              cancelImportGIS.click();
            } else{
              this.spinner.hide();
              this.toastr.error('Error');
            }

          }, (err: any) => {
            this.spinner.hide();
            this.importGISForm.patchValue({
              importSelectFormControl: ''
            });
            this.nonGeoreferenceImgDivActive = false;
            this.commonMapService.normalItMapZoom(this.map);
          });
  } else {
        this.mapViewerJobFeatureService.getFetchDataImport(match, importedFileTypeURL, this.isGeoReferencedRaster, rasterImageId, rasterImageCorrdinateObj,isRasterEdit,this.rangeSliderGeoReferenceImage).subscribe(response => {
          console.log('response', response);
          if (response.status) {
            // this.toastr.success("File uploaded Successfully.");
            this.nonGeoreferenceImgDivActive = false;
            this.commonMapService.normalItMapZoom(this.map);
            this.reteriveGeoReferenceImagebyUser();
            this.spinner.hide();
            let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
            cancelImportGIS.click();
              // this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
              //   this.navigatedFeatures = dataWithLayer;
              //   for (const featureData of dataWithLayer) {
              //     if (!this.isBasemapToggle) {
              //       this.setValuesInRequiredMaps(featureData);
              //     } else {
              //       this.isBasemapToggle = false;
              //     }
              //   }
              //
              //   this.refreshMapStyle();
              // });
              this.isEditLayer = true;
              this.isEditingModeIsOn = false;
              this.updateEditMode.emit(false);
              this._refreshAfterSave();

          } else {
            this.spinner.hide();
            this.toastr.error('Error');
          }

        }, (err) => {
          console.log(err);

          console.log('Error retrieving Data ...');
          //  this.toastr.error('Error');
          this.spinner.hide();
          this.importGISForm.patchValue({
            importSelectFormControl: ''
          });
          this.nonGeoreferenceImgDivActive = false;
          this.commonMapService.normalItMapZoom(this.map);
        });
      }
    } else {
      // this.importSubmitted = false;
      // this.myImportFileVariable.nativeElement.value = "";
      this.importedFileName = '';
      // this.importedFileType = '';
      this.nonGeoreferenceImgDivActive = false;
      this.commonMapService.normalItMapZoom(this.map);
      this.spinner.hide();
      this.toastr.error("Please upload a valid file.");
    }
  }

  // cancel Import data in mid of process
  importMajorCancel() {
    this.nonGeoreferenceImgDivActive = false;
    this.commonMapService.normalItMapZoom(this.map);
    this.importSubmitted = false;
    this.myImportFileVariable.nativeElement.value = "";
    this.importedFileName = '';
    this.importedFileType = '';
    this.importGISForm.patchValue({
      importSelectFormControl: ''
    });
  }

  // Cancel Non GeoReference Image without save the data
  cancelNonGeoReferenceImage(type:any,rasterImageFeature:any) {
    this.nonGeoreferenceImgDivActive = false;
    this.commonMapService.normalItMapZoom(this.map);
    this.importSubmitted = false;
    this.myImportFileVariable.nativeElement.value = "";
    this.importedFileName = '';
    this.importedFileType = '';
    this.importGISForm.patchValue({
      importSelectFormControl: ''
    });
  }

  // Set Opacity level for Edit Non Georeference Image
  visibilityNonGeoReferenceImages(event: any) {
    let val = parseInt(event.target.value);
    let image = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
    image.style.opacity = ((val) / 100).toString();
  }

  checkImportedData(layer: CommitChanges) {
    if (layer?.layerName === 'IMPORTED') {
      if (layer?.groups?.length > 0) {
        return true
      } else {
        return false
      }
    }
    return true
  }



  //******************* HISTORY CODE ************************* */

  featureGroupHistoryClick(layer:CommitChanges, group:FeatureGroup){
    this.featuresForRevision = [];
    this.isHistoryOpend = true;
    this.isHistoryBtnClicked = true;
    this.isHistoryGroupClicked = true;
    this.isHistoryTypeClicked = layer.layerName === "EXTERNAL" ? false : true;
    this.historyMetaData = this._constructDataForHistory(layer, group);
  }

  featureTypeHistoryClick(layer:CommitChanges, group:FeatureGroup, featureType:FeatureType){
    this.featuresForRevision = [];
    this.isHistoryOpend = true;
    this.isHistoryBtnClicked = true;
    this.isHistoryTypeClicked = true;
    this.historyMetaData = this._constructDataForHistory(layer, group, featureType, undefined, true);
  }

  featureHistoryClick(layer:CommitChanges, group:FeatureGroup, featureType:FeatureType, feature:Feature){
    this.featuresForRevision = [];
    this.historyMetaData = [];
    this.isHistoryBtnClicked = true;
    this.isHistoryFeatureClicked = true;
    this.isHistoryOpend = true;
    this.historyMetaData = this._constructDataForHistory(layer, group, featureType, feature, false, true);
  }

  private _constructDataForHistory(layer:CommitChanges, group:FeatureGroup, featureType?:FeatureType, feature?:Feature, isFromType?:boolean, isFromFeature?:boolean) {
    const layerObj = deepClone(layer);
    const grouprObj = deepClone(group);
    const typerObj = deepClone(featureType);
    const featureObj = deepClone(feature);


    const data = [{
      layerName:layerObj.layerName,
      groupName:grouprObj.groupName,
      types: grouprObj.types
    }];

    if(isFromType) {
      data[0].types = typerObj ? [typerObj] : [];
    }
    // extract and set all features for revision history
    this._setRevisionFeatures(data[0].types);

    if(isFromFeature) {
      data[0].types = typerObj ? [typerObj] : [];
      data[0].types[0].features = featureObj ? [featureObj] : [];
      this.featuresForRevision = [featureObj];
    }

    return data;
  }

  private _setRevisionFeatures(types: FeatureType[]) {
    types.forEach((type:FeatureType)=>{
      this.featuresForRevision.push(...type.features);
    })
  }

  onHistorySlideoutClose(isHistorySlideOpen:boolean){
    this.isHistoryOpend = isHistorySlideOpen;
    this.isHistoryFeatureClicked = isHistorySlideOpen;
    this.isHistoryTypeClicked = isHistorySlideOpen;
    this.isHistoryGroupClicked = isHistorySlideOpen;
    this.isHistoryBtnClicked = isHistorySlideOpen;
  }

  onRevertFeatureToRevision(obj:any ){
    this.navigatedJobId = obj?.mapJobId;
    this._refreshAfterSave();
    setTimeout(() => {
      this.onHistorySlideoutClose(false);
    }, 200);
  }

  //******************* HISTORY CODE END ************************* */

  /** Delete merged lines features in DB */
  deleteMergedlineFeatures(){
    if (this.firstSecondLineFeatureFrMergedArr?.length > 0) {
      const firstlineArr = this.firstSecondLineFeatureFrMergedArr[0];
      const secondlineArr = this.firstSecondLineFeatureFrMergedArr[1];
      var firstlineFeatureGrp = { featureId: '', featureGeometryType: '', isAnnotation: false } as Feature;
      firstlineFeatureGrp.featureId = firstlineArr.properties.featureId;
      firstlineFeatureGrp.featureGeometryType = firstlineArr.type;
      var secondlineFeatureGrp = { featureId: '', featureGeometryType: '', isAnnotation: false } as Feature;
      secondlineFeatureGrp.featureId = secondlineArr.properties.featureId;
      secondlineFeatureGrp.featureGeometryType = secondlineArr.type;
      setTimeout(() => {
        this.deleteParticularFeatureAPI(null, '', '', firstlineFeatureGrp, true);
      }, 500);
      setTimeout(() => {
        this.deleteParticularFeatureAPI(null, '', '', secondlineFeatureGrp, true);
      }, 500);
      this.firstSecondLineFeatureFrMergedArr = []; //clear array
    }
}

  dataCreationLayer(featureData: any, data?:any) {
    featureData.layerId = featureData?.layerId ? featureData.layerId : data.layerId;
    featureData.layerName = this.getLayerName(featureData.layerId);
    featureData.featureGroup = featureData.featureGroup ? featureData.featureGroup : this.metadataService.featureGroup[featureData.featureGroupId],
    featureData.featureType = featureData.featureType ? featureData.featureType : this.metadataService.featureType[featureData.featureTypeId];

    // PAW if the featureGroup == PHOTO then the featureType will be 'Photo'.  This is needed to show the photos in the layerlist
    let layerCheck: any = this.data.findIndex(item => item.layerId === featureData.layerId);
    if (layerCheck != -1) {
      let groupCheck: any = this.data[layerCheck].groups.findIndex(item => item.groupId === featureData.featureGroupId && item.groupName === featureData.featureGroup);
      if (groupCheck != -1) {
        // console.log("featureData.layerId",featureData.layerId);

        if(featureData.layerId == 4){
          // console.log("featureData",featureData);

          let datatest = this.data[layerCheck].groups[groupCheck].features;
          datatest = datatest ? datatest : [];
          if(this.isFeatureExist(datatest, featureData)){
            datatest?.push(this._makeFeature(featureData));
          }
        } else {
        let typeCheck:any = this.data[layerCheck].groups[groupCheck].types?.findIndex((item:FeatureType) => item.typeId === featureData.featureTypeId);
        if (typeCheck != -1 && this.data[layerCheck].groups[groupCheck].types) {
          let temp:any = this.data[layerCheck].groups[groupCheck]?.types;

          let datatest = temp[typeCheck]?.features;
          datatest = datatest ? datatest : [];
          if(this.isFeatureExist(datatest, featureData)){
            temp[typeCheck]?.features.push(this._makeFeature(featureData));
          }
        }
        else {

          this.data[layerCheck].groups[groupCheck].types?.push(this._makeFeatureType(featureData));
          // For Imported Layer Name
          if (this.data[layerCheck].layerId === 3 && !this.importedLayerFileId) {
            let importedFeature = this.data[layerCheck].groups[0].types[0].features[0];
            if (this.data[layerCheck].layerId === 3 && this.data[layerCheck].groups[0].types[0].features[0].fileId) {
              if (importedFeature.fileName != null && this.importedBlankCheck
              ) {

                this.importedLayerFileName =
                  importedFeature.fileName;
                this.importedLayerFileNameStore =
                  importedFeature.fileName;
                this.importedLayerFileId = importedFeature.fileId;
                this.cdRef.detectChanges();
              }
            }
          }
        }
      }
      } else {
        this.data[layerCheck].groups.push(this._makeFeatureGroup(featureData));
      }

    } else {
      this.data.push({ layerId: featureData.layerId, layerName: featureData.layerName, mapJobId:featureData?.jobId, isEyeVisible: true, isLayerEditVisible:true, groups: [this._makeFeatureGroup(featureData)] })
    }

    if (featureData.layerId === 3)
    {
      const layer = this.data.find(item => item.layerId === 3);
      if (layer) {
        this.layerEvents.next( { type: 'LayerChanged', data: { layer } })
      }
    }

    // if (this.data.findIndex(item => item.layerId === this.referenceLayerId) == -1) {
    //   this.data.push({ layerId: this.referenceLayerId, layerName: "REFERENCE", isEyeVisible: false, isLayerEditVisible:true, groups: [] });
    // }
  }

  isFeatureExist(arr:Feature[], featureData:any) {
    const index = arr.findIndex((ft:any)=> ft.featureId === featureData.featureId && ft.featureName === featureData.feature);
    return index === -1 ? true : false;
  }

  _makeFeatureGroup(featureData: any) {
    let featuregroup: FeatureGroup = {
      groupId: featureData?.featureGroupId,
      groupName: featureData.featureGroup,
      isEyeVisible: true,
      isGroupExpanded:false,
      types:  featureData.layerId == 4 ? [] : [this._makeFeatureType(featureData)],
      features : [],
      mapJobId:featureData?.jobId,
    }
    if(featureData.layerId == 4){
      featuregroup.features = [this._makeFeature(featureData)];
    }
    return featuregroup;
  }

  _makeFeatureType(featureData: any) {
    let featuretype: FeatureType = {
      typeId: featureData.featureTypeId,
      typeName: featureData.featureType,
      featureTypeId: featureData.featureTypeId,
      featureType: featureData.featureType,
      isEyeVisible: true,
      isTypeExpanded:false,
      features: (featureData?.feature || featureData?.featureName) ? [this._makeFeature(featureData)] : [],
      mapJobId:featureData?.jobId,
    }
    if(featureData.layerId == 5 && featureData.featureGroup.toLowerCase() == 'site') {
      featuretype.siteJobIds = featureData?.siteJobIds;
    }
    return featuretype;
  }

  _makeFeature(featureData: any) {
    let typeGeom : any = featureData.type? featureData.type : (featureData?.geometry?.type ? featureData.geometry.type : "");
    typeGeom = featureData?.featureGeometryType ? featureData.featureGeometryType : typeGeom
    let ft:any = {
      featureId: featureData.featureId,
      featureMode: "",
      featureName: featureData.feature ? featureData.feature : featureData.featureName,
      id: featureData.featureId,
      type: typeGeom?.toLowerCase().includes('line') ? 'LineString' : 'Point',
      featureObj: "",
      isEyeVisible: true,
      mapJobId: featureData?.jobId,
      isAnnotation: featureData?.isAnnotation,
      fileId: featureData?.fileId,
      fileName: featureData?.fileName,
      importedByUserId: featureData?.importedByUserId,
      geometry: {
        type: typeGeom?.toLowerCase().includes('line') ? 'LineString' : 'Point',
        coordinates: null,
      },
      featureGeometryType: typeGeom?.toLowerCase().includes('line') ? 'LineString' : 'Point',
    }
    if(featureData.layerId == 5 && featureData.featureGroup.toLowerCase() == 'site') {
      ft.subsiteJobIds = featureData?.subsiteJobIds;
    }
    let feature: Feature = {
      ...ft,
      properties: {
        ...ft
      }
    }
    return feature;
  }

  public onToggleLayerGroup(layerId: number | string, $event: { hiddenFeatures: FeatureItem[] }) {
    const _layerId = parseInt(layerId as string);
    applyFilterOnLayer(this.map, _layerId, this.zoomedJobIds, $event.hiddenFeatures);

    for (const image of this.geoReferenceGISImagesData || []) {
      this.toggleGeoReferenceLayerImportedFeature(image);
    }
  }

  testToggleLayer(layer: CommitChanges) {
    console.log("Toggling layer : ", layer.layerName);
    let arrayTestFilter = [];
    layer.isEyeVisible = !layer.isEyeVisible;
    if (layer.layerId === 3) {
      this.layerEvents.next({ type: 'ChangeLayerVisibility', data: { visible: layer.isEyeVisible } });
      return;
    }
    if(layer.layerId === 5) {
      this.layerEvents.next({ type: 'ChangeLayerVisibility', data: { visible: layer.isEyeVisible } });
      this._SiteLayerToggle(layer.isEyeVisible);
    } else {
      //#region hide/unhide all groups/types/features from toggle or eyevisible
      if(layer.layerId != 4) {
        for (let featureGroup of layer.groups) {
          featureGroup.isEyeVisible = layer.isEyeVisible;
          for (let featureType of featureGroup.types) {
            featureType.isEyeVisible = featureGroup.isEyeVisible;
            for (let features of featureType.features) {
              features.isEyeVisible = featureGroup.isEyeVisible
              arrayTestFilter.push(features.featureName);
            }
          }
        }

        if(layer.layerId === 3){
          if(this.geoReferenceGISImagesData){
            this.geoReferenceGISImagesData.forEach((item: any) => {
              item.setEyeActive =  !layer.isEyeVisible;
              this.toggleGeoReferenceLayerImportedFeature(item);
            });
          }
        }
        for (const featureGroup of layer.groups) {
          this._jobToggleClickOnLayer(featureGroup, 'types', featureGroup.isEyeVisible);
        }
      } else {
        for (let featureGroup of layer.groups) {
          featureGroup.isEyeVisible = layer.isEyeVisible;
          this._exteralFeatureToggle(featureGroup);
          this._jobToggleClickOnLayer(featureGroup,'features',layer.isEyeVisible);
        }
      }
      this._allLayerToggle(layer.isEyeVisible);
    //#endregion
    }
    this._mapFilter('');
  }

  testToggleFeatureGroup(featureGroup: FeatureGroup, layer: CommitChanges) {
    featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
    if(layer.layerId === 5) {
      this._SiteGroupToggle(featureGroup, featureGroup.isEyeVisible);
    }
    //#region for hide/unhide eye toggle
    if(layer.layerId != 4) {
      for (let featureType of featureGroup.types) {
        featureType.isEyeVisible = featureGroup.isEyeVisible;
        for (let features of featureType.features) {
          features.isEyeVisible = featureGroup.isEyeVisible
        }
      }
      if(layer.layerId != 5)
      this._jobToggleClickOnLayer(featureGroup,'types',featureGroup.isEyeVisible);
    } else {
      this._exteralFeatureToggle(featureGroup);
      this._jobToggleClickOnLayer(featureGroup, 'features', featureGroup.isEyeVisible);
    }
    //#region set layer eyevisible as per all group hide
    if (layer?.groups?.length === layer?.groups?.filter((it: any) => !it.isEyeVisible).length || featureGroup.isEyeVisible && !layer.isEyeVisible) {
      layer.isEyeVisible = !layer.isEyeVisible;
    }
    //#endregion
    this._mapFilter(featureGroup);
  }

  testToggleFeatureType(featureType: any, layer: any, featureGroup: any, from: boolean = false) {
    featureType.isEyeVisible = !featureType.isEyeVisible;
    if(layer.layerId === 5) {
      this._SiteTypeToggle(featureType, featureGroup, featureType.isEyeVisible);
    }
    if (!featureGroup.isEyeVisible && featureType.isEyeVisible) {
      if (!layer.isEyeVisible) {
        layer.isEyeVisible = !layer.isEyeVisible;
      }
      featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
    }
    for (let features of featureType.features) {
      features.isEyeVisible = featureType.isEyeVisible;
    }
    this._jobToggleClickOnLayer(featureGroup, 'types', featureType.isEyeVisible);
    if (featureGroup?.types?.length === featureGroup?.types?.filter((it: any) => !it.isEyeVisible).length) {
      featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
      if (layer?.groups?.length === layer?.groups?.filter((it: any) => !it.isEyeVisible).length) {
        layer.isEyeVisible = !layer.isEyeVisible;
      }
    }
    this._mapFilter(featureGroup);
  }

  testToggleParticularFeatureExternal(feature: Feature, featureGroup: FeatureGroup, layer: any) {
    feature.isEyeVisible = !feature.isEyeVisible;
    if (!featureGroup.isEyeVisible && feature.isEyeVisible) {
      featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
      if (!layer.isEyeVisible) {
        layer.isEyeVisible = !layer.isEyeVisible;
      }
    }


    if (featureGroup?.features?.length === featureGroup?.features?.filter((it: any) => !it.isEyeVisible).length) {
      featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
      if (layer?.groups?.length === layer?.groups?.filter((it: any) => !it.isEyeVisible).length) {
        layer.isEyeVisible = !layer.isEyeVisible;
      }
    }

    this._jobToggleClickOnLayer(featureGroup, 'features', feature.isEyeVisible);
    this._mapFilter(featureGroup);
  }

  testToggleParticularFeature(feature: Feature, featureType: FeatureType, featureGroup: FeatureGroup, layer: any) {
    feature.isEyeVisible = !feature.isEyeVisible;
    if(layer.layerId === 5) {
      this._SiteFeatureToggle(feature, featureGroup, feature.isEyeVisible);
    }
    if (featureGroup.groupName=='PHOTO') {
      featureType = featureGroup.types[0];
    }
    if (!featureType?.isEyeVisible && feature?.isEyeVisible) {
      featureType.isEyeVisible = !featureType.isEyeVisible;
      if (!featureGroup.isEyeVisible && featureType.isEyeVisible) {
        featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
        if (!layer.isEyeVisible) {
          layer.isEyeVisible = !layer.isEyeVisible;
        }
      }
    }

    if(featureType?.features?.length === featureType?.features?.filter((it: any) => !it?.isEyeVisible).length) {
      featureType.isEyeVisible = !featureType.isEyeVisible;
      if (featureGroup?.types?.length === featureGroup?.types?.filter((it: any) => !it.isEyeVisible).length) {
        featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
        if (layer?.groups?.length === layer?.groups?.filter((it: any) => !it.isEyeVisible).length) {
          layer.isEyeVisible = !layer.isEyeVisible;
        }
      }
    }
    this._jobToggleClickOnLayer(featureGroup, 'types', feature.isEyeVisible);
    this._mapFilter(featureGroup);
  }

  // Photo toggle is used when there is a photo type in the layer list.  This will toggle an individual photo icon that is displaying on the map.
  photoToggleParticularFeature(feature: Feature,  featureGroup: FeatureGroup, layer: any) {
    feature.isEyeVisible = !feature.isEyeVisible;
    var featureType: any = null;

    if(layer.layerId === 5) {
      this._SiteFeatureToggle(feature, featureGroup, feature.isEyeVisible);
    }
    if (featureGroup.groupName=='PHOTO') {
      featureType = featureGroup.types[0];
    }
    if (!featureType?.isEyeVisible && feature?.isEyeVisible) {
      featureType.isEyeVisible = !featureType.isEyeVisible;
      if (!featureGroup.isEyeVisible && featureType.isEyeVisible) {
        featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
        if (!layer.isEyeVisible) {
          layer.isEyeVisible = !layer.isEyeVisible;
        }
      }
    }

    if(featureType?.features?.length === featureType?.features?.filter((it: any) => !it?.isEyeVisible).length) {
      featureType.isEyeVisible = !featureType.isEyeVisible;
      if (featureGroup?.types?.length === featureGroup?.types?.filter((it: any) => !it.isEyeVisible).length) {
        featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
        if (layer?.groups?.length === layer?.groups?.filter((it: any) => !it.isEyeVisible).length) {
          layer.isEyeVisible = !layer.isEyeVisible;
        }
      }
    }
    this._jobToggleClickOnLayer(featureGroup, 'types', feature.isEyeVisible);
    this._mapFilter(featureGroup);
  }

  private _exteralFeatureToggle(featureGroup: FeatureGroup) {
    if(featureGroup.features?.length) {
      for (let features of featureGroup.features) {
        features.isEyeVisible = featureGroup.isEyeVisible
      }
    }
  }

  private _SiteLayerToggle(parentIsEyeVisible:any) {
    this.data.forEach((layer: any) => {
      layer.isEyeVisible = parentIsEyeVisible;
      //#region hide/unhide all groups/types/features from toggle or eyevisible
      for (let featureGroup of layer.groups) {
        featureGroup.isEyeVisible = layer.isEyeVisible;
        if(layer.layerId != 4) {
          for (let featureType of featureGroup.types) {
            featureType.isEyeVisible = featureGroup.isEyeVisible;
            for (let features of featureType.features) {
              features.isEyeVisible = featureGroup.isEyeVisible
            }
          }
        } else {
          this._exteralFeatureToggle(featureGroup);
        }
      }
    //#endregion
    });
  }

  _SiteGroupToggle(featureGroup:any, parentIsEyeVisible:any) {
    if(featureGroup.types.length > 0) {
      featureGroup.types.forEach((types:any) => {
        if(featureGroup.groupName.toLowerCase() == 'site') {
          types?.siteJobIds?.forEach((element:any) => {
            this._filterDataOnJobId(element,this.data, featureGroup, parentIsEyeVisible);
          });
        } else {
          this._filterDataOnJobId(types.featureTypeId,this.data, featureGroup, parentIsEyeVisible);
        }
      });
    }
  }

  _SiteTypeToggle(featureType: any, clickedFeatureGroup:any, parentIsEyeVisible:any) {
    if(featureType?.siteJobIds != undefined) {
      featureType?.siteJobIds?.forEach((element:any) => {
        this._filterDataOnJobId(element,this.data, clickedFeatureGroup, parentIsEyeVisible);
      });
    } else {
      this._filterDataOnJobId(featureType.featureTypeId,this.data, clickedFeatureGroup, parentIsEyeVisible);
    }
  }

  _SiteFeatureToggle(feature: any, clickedFeatureGroup:any, parentIsEyeVisible:any) {
    if(feature.subsiteJobIds != undefined) {
      feature?.subsiteJobIds?.forEach((element:any) => {
        this._filterDataOnJobId(element,this.data, clickedFeatureGroup,parentIsEyeVisible);
      });
    } else {
      const tempData = clickedFeatureGroup.types.find((it:any)=> {return it.features?.find((item:any) => item.featureName == feature.featureName)});
      if(tempData) {
        this._filterDataOnJobId(tempData.featureTypeId,this.data, clickedFeatureGroup, parentIsEyeVisible);
      }
    }
  }

  private _jobToggleClickOnLayer(featureGroup:any,key:any = 'types', isEyeVisible:any) {
    if(isEyeVisible) {
      featureGroup[key].forEach((ft:any)=>{
        let feature:any;
        if(key == 'types') {
          feature = ft.features.filter((it:any)=> it.isEyeVisible);
        } else {
          feature = featureGroup[key].filter((it:any)=> it.isEyeVisible);
        }
        if(feature?.length > 0){
          feature.forEach((feat:any) => {
            let { mapJobId } = feat;
            let findJob = this.data[0].groups.find((it:any)=> it.groupName.toLowerCase() == 'job')?.types.find((it:any)=> it.featureTypeId == mapJobId);
            if(findJob) {
              findJob.isEyeVisible = true;
              findJob.features.map(it => it.isEyeVisible = true);
              let layerIsEyeVisible = this.data[0].groups.find((it:any)=> it.groupName.toLowerCase() == 'job');
              if(layerIsEyeVisible){
                layerIsEyeVisible.isEyeVisible = true;
                this.data[0].isEyeVisible = true;
              }
            }
          });
        }
      });
    } else {
      let ftJobs = this.data[0].groups.filter((it:any)=> it.groupName.toLowerCase() == 'job')[0].types;
      ftJobs.forEach((ft:any)=>{
        let temp:any = this._featuresBasedOnJobId(ft.featureTypeId,this.data);
        if(temp.length && temp.length == temp.filter((it:any) => !it.isEyeVisible).length) {
          ft.isEyeVisible = false;
          ft.features.map((it:any) => it.isEyeVisible = false);
        }
      });
      const jobGroupIndex = this.data[0].groups.findIndex((it:any)=> it.groupName.toLowerCase() == 'job');
      if(jobGroupIndex > -1 && (ftJobs.length == ftJobs.filter((ft:any)=> !ft.isEyeVisible).length)) {
        this.data[0].groups[jobGroupIndex].isEyeVisible = false;
      }
      if((this.data[0].groups.length == this.data[0].groups.filter((gr:any)=> !gr.isEyeVisible).length)) {
        this.data[0].isEyeVisible = false;
      }
    }
  }

  _allLayerToggle(isEyeVisible:any) {
    let temp = JSON.stringify(this.data);
    let tempComp = JSON.parse(temp);
    tempComp.splice(0,1);
    let filterLayer = tempComp.filter((it:any)=> it.isEyeVisible == isEyeVisible && it.groups.length);
    if(((filterLayer?.length == (this.data.filter((it:any)=> it.groups.length).length - 1) ) || (isEyeVisible && filterLayer.length))) {
      if(this.data[0].groups.length == 1) {
        this.data[0].isEyeVisible = isEyeVisible;
      }
      const jobGroupIndex = this.data[0].groups.findIndex((it:any)=> it.groupName.toLowerCase() == 'job');
      if(jobGroupIndex > -1) {
        this.data[0].groups[jobGroupIndex].isEyeVisible = isEyeVisible;
        // this.data[0].groups[jobGroupIndex].types.map((types:any) => types.isEyeVisible = isEyeVisible);
        // this.data[0].groups[jobGroupIndex].types.forEach(element => {
        //   element.features.map((ft:any) => ft.isEyeVisible = isEyeVisible);
        // });
      }
    }
  }

  // data is data structure and jobId is inpute args on which filter the data

  _featuresBasedOnJobId = (jobId: number, data: any) => {
    let featuresBasedOnJobId: any[] = [];
    data.forEach((lyr: any) => {
      if(lyr.layerId != 5) {
        let groups = lyr.groups;
        groups.forEach((featureGroup: any) => {
          if(lyr.layerId != 4) {
            let featureType = featureGroup.types;
            featureType.forEach((featureType: any) => {
              let features = featureType.features;
              features.forEach((feat: any) => {
                let { mapJobId } = feat;
                if (mapJobId == jobId) {
                  featuresBasedOnJobId.push(feat);
                }
              });
            });
          } else {
            featureGroup.features.forEach((feat: any) => {
              let { mapJobId } = feat;
              if (mapJobId == jobId) {
                featuresBasedOnJobId.push(feat);
              }
            });
          }
        });
      }
    });
    return featuresBasedOnJobId;
  }

  private _filterDataOnJobId = (jobId: number, data: any, clickedFeatureGroup: any, parentIsEyeVisible:any) => {
    let featuresBasedOnJobId: any[] = [];
    data.forEach((lyr: any) => {
      let groups = lyr.groups;
      groups.forEach((featureGroup: any) => {
        let ft = featureGroup.types;
        if (lyr.layerId != 4) {
          ft.forEach((featureType: any) => {
            let features = featureType.features;
            features.forEach((feat: any) => {
              let { mapJobId } = feat;
              if (((mapJobId == jobId) || (featureGroup.groupName.toLowerCase() == 'job' && featureType.featureTypeId == jobId)) && clickedFeatureGroup.groupName.toLowerCase() != featureGroup.groupName.toLowerCase()) {
                featuresBasedOnJobId.push(feat);
                feat.isEyeVisible = parentIsEyeVisible;
                if (!featureType.isEyeVisible && feat.isEyeVisible) {
                  featureType.isEyeVisible = feat.isEyeVisible;
                  if (!featureGroup.isEyeVisible && featureType.isEyeVisible) {
                    featureGroup.isEyeVisible = feat.isEyeVisible;
                    if (!lyr.isEyeVisible) {
                      lyr.isEyeVisible = feat.isEyeVisible;
                    }
                  }
                }

                if (featureType?.features?.length === featureType?.features?.filter((it: any) => !it.isEyeVisible).length) {
                  featureType.isEyeVisible = !featureType.isEyeVisible;
                  if (featureGroup?.types?.length === featureGroup?.types?.filter((it: any) => !it.isEyeVisible).length) {
                    featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
                    if (lyr?.groups?.length === lyr?.groups?.filter((it: any) => !it.isEyeVisible).length) {
                      lyr.isEyeVisible = !lyr.isEyeVisible;
                    }
                  }
                }
              }
            })
          })
        } else {
          featureGroup?.features?.forEach((feat: any) => {
            let { mapJobId } = feat;
            if (((mapJobId == jobId) || (featureGroup.groupName.toLowerCase() == 'job' && ft.featureTypeId == jobId)) && clickedFeatureGroup.groupName.toLowerCase() != featureGroup.groupName.toLowerCase()) {
              featuresBasedOnJobId.push(feat);
              feat.isEyeVisible = parentIsEyeVisible;
              // if (!featureType.isEyeVisible && feat.isEyeVisible) {
                // featureType.isEyeVisible = !featureType.isEyeVisible;
              if (!featureGroup.isEyeVisible && feat.isEyeVisible) {
                featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
                if (!lyr.isEyeVisible) {
                  lyr.isEyeVisible = !lyr.isEyeVisible;
                }
              }
              // }

              // if (featureType?.features?.length === featureType?.features?.filter((it: any) => !it.isEyeVisible).length) {
                // featureType.isEyeVisible = !featureType.isEyeVisible;
              if (featureGroup?.features?.length === featureGroup?.features?.filter((it: any) => !it.isEyeVisible).length) {
                featureGroup.isEyeVisible = !featureGroup.isEyeVisible;
                if (lyr?.groups?.length === lyr?.groups?.filter((it: any) => !it.isEyeVisible).length) {
                  lyr.isEyeVisible = !lyr.isEyeVisible;
                }
              }
              }
            // }
          })
        }
      });
    });
    return featuresBasedOnJobId;
  }

  private _mapFilter(clickedFeatureGroup:any) {
    this.data.forEach((layer:any) => {
      const mapPointId = layer.layerId + '-point';
      const mapLineId = layer.layerId + '-line';
      const externalLayer = ['photo', 'matterport', 'pointcloud', 'virtualtour', 'attachment', 'externallink'];
      if (!layer.isEyeVisible) {
        // apply filter and quit
        if(layer.layerId == 1 || layer.layerId == 2 || layer.layerId == 3) {
          if (this.map.getLayer(mapPointId) != undefined) {
            this.map.setFilter(mapPointId, false);
          }
          if (this.map.getLayer(mapLineId) != undefined) {
            this.map.setFilter(mapLineId, false);
          }
        }
        if(layer.layerId == 4) {
          externalLayer.forEach((it:any)=>{
            if (this.map.getLayer('4-' + it) != undefined) {
              this.map.setFilter('4-' + it, false);
            }
          });
        }
        if(layer.layerId == 5) {
          this.siteDetailsToggle(false, layer, clickedFeatureGroup);
        }
          //#endregion
        return;
      }

      if (layer.layerId != 5) {
        // Start with base condition filter expression
        let filterExpression: any = ['all'];
        // Add "any" condition for MapJobId filter expressions
        filterExpression.push(['any'])
        // Add MapJobId filter components
        this.zoomedJobIds.forEach((jobId: number) => {
          filterExpression[1].push(['==', ['get', 'mapJobId'], jobId])
        });
        layer.groups.forEach((fg:any) => {
          if (!fg.isEyeVisible) {
            // building filter
            filterExpression.push(['!=', ['get', 'featureGroup'], fg.groupName]);
          } else {
            if (layer.layerId != 4) {
              fg.types.forEach((ft:any) => {
                if (!ft.isEyeVisible) {
                  // building filter
                  filterExpression.push(['!=', ['get', 'featureType'], ft.typeName]);
                } else {
                  ft.features.forEach((feat:any) => {
                    if (!feat.isEyeVisible) {
                      // building filter
                      filterExpression.push(['!=', ['get', 'featureName'], feat.featureName]);
                    }
                  })
                }
              })
            } else {
                fg.features.forEach((ft:any) => {
                  if (!ft.isEyeVisible) {
                    // building filter
                    filterExpression.push(['!=', ['get', 'featureName'], ft.featureName]);
                  }
                })
            }
          }
        });
        if(layer.layerId == 1 || layer.layerId == 2 || layer.layerId == 3) {
          this.map.setFilter(mapPointId,filterExpression);
          this.map.setFilter(mapLineId,filterExpression);
          if (filterExpression.length === 1) {
            if (this.map.getLayer(mapPointId) !== undefined) {
              this.map.setFilter(mapPointId, null);
            }
            if (this.map.getLayer(mapLineId) !== undefined) {
              this.map.setFilter(mapLineId, null);
            }
          } else {
            if (this.map.getLayer(mapPointId) !== undefined) {
              this.map.setFilter(mapPointId, filterExpression);
            }
            if (this.map.getLayer(mapLineId) !== undefined) {
              this.map.setFilter(mapLineId, filterExpression);
            }
          }
        }
        if(layer.layerId == 4) {
          externalLayer.forEach((it:any)=>{
            if (this.map.getLayer(('4-' + it)) != undefined) {
              this.map.setFilter(('4-' + it), filterExpression);
            }
          });
        }
      } else {
        this.siteDetailsToggle(null,layer, clickedFeatureGroup);
      }
      // apply filter
    })
  }

  siteDetailsToggle(filterExp: any, layerData:any, clickedFeatureGroup:any) {

    this.siteToggleApplied.emit(true);
    let filterExpression:any = filterExp;
    let jobIds:any[] = [];
    layerData.groups.forEach((fg:any) => {
      if (!fg.isEyeVisible) {
        // building filter
          filterExpression = false;
      } else {
        fg.types.forEach((ft:any) => {
          if (!ft.isEyeVisible) {
            if(fg.groupName == 'Site') {
              let siteLayerId = this.removeAllSpaceAndSpecialChar(ft.featureType) + '-site-' + ft.featureTypeId;
              this.map.setLayoutProperty(siteLayerId, 'visibility', 'none');
              ft.features.forEach((feat:any) => {
                let subsiteLayerId = this.removeAllSpaceAndSpecialChar(feat.featureName) + '-subsite-' + feat.featureId;
                this.map.setLayoutProperty(subsiteLayerId, 'visibility', 'none');
              })
            } else if(!clickedFeatureGroup || clickedFeatureGroup.groupName.toLowerCase() == 'job') {
              jobIds.push(ft.featureTypeId);
            }
          } else {
            if(fg.groupName == 'Site') {
              let siteLayerId = this.removeAllSpaceAndSpecialChar(ft.featureType) + '-site-' + ft.featureTypeId;
              this.map.setLayoutProperty(siteLayerId, 'visibility', 'visible');
            }
            ft.features.forEach((feat:any) => {
              if(fg.groupName == 'Site') {
                let subsiteLayerId = this.removeAllSpaceAndSpecialChar(feat.featureName) + '-subsite-' + feat.featureId;
                if (!feat.isEyeVisible) {
                  this.map.setLayoutProperty(subsiteLayerId, 'visibility', 'none');
                } else {
                  this.map.setLayoutProperty(subsiteLayerId, 'visibility', 'visible');
                }
              } else if(!clickedFeatureGroup || clickedFeatureGroup.groupName.toLowerCase() == 'job') {
                if (!feat.isEyeVisible) {
                  jobIds.push(ft.featureTypeId);
                }
              }
            })
          }
        })
      }
    });
    if((!clickedFeatureGroup && layerData.groups[0].groupName.toLowerCase() == 'site') || (clickedFeatureGroup.groupName != undefined && clickedFeatureGroup?.groupName?.toLowerCase() == 'site')) {
      if (layerData.groups[0].isEyeVisible && layerData.groups[0].isEyeVisible != layerData.groups[1].isEyeVisible) {
        this._siteDetailsLayerToggles(filterExp);
      } else {
        this._siteDetailsLayerToggles(filterExpression);
      }
      let siteJobIds = layerData.groups.filter((data: any) => data.groupName == 'Job')[0].types.filter((out: any) => !out.isEyeVisible).map((output: any) => output.typeId)
      this._jobDetailsToggle(false, siteJobIds);
    } else {
      if(jobIds.length > 0) {
        filterExpression = jobIds;
      }
      this._jobDetailsToggle(false, filterExpression);
    }
  }

  _siteDetailsLayerToggles(filterExpresson:any){
    let siteInfo = this.data.filter((lyr: any) => lyr.layerName == 'SITE')[0].groups[0].types;
    let siteJobsIds:any[] = [];
    siteInfo.forEach((siteType: any) => {
      let siteLayerId = this.removeAllSpaceAndSpecialChar(siteType.featureType) + '-site-' + siteType.featureTypeId;
      // toggle off the site
      this.map.setFilter(siteLayerId, filterExpresson);
      siteJobsIds = siteJobsIds.concat(siteType.siteJobIds);
      // toggle off the subsite
      siteType.features.forEach((feat: any) => {
        let subsiteLayerId = this.removeAllSpaceAndSpecialChar(feat.featureName) + '-subsite-' + feat.featureId;
        this.map.setFilter(subsiteLayerId, filterExpresson);
      })
    });
    this._jobDetailsToggle(filterExpresson, siteJobsIds);
  }

  _jobDetailsToggle(filterExpresson:any, jobIds:any) {
    if(filterExpresson === null) {
      this.map.setFilter('unclustered-point', filterExpresson);
    } else {
      this.map.setFilter('unclustered-point',['!', ['in', ['get', 'jobId'], ['literal', jobIds]]]);
    }
  }

  public onDeleteFeature(layer: CommitChanges, $event: {feature: FeatureItem, featureType: FeatureTypeItem, group: FeatureGroupItem, file: FileGroup}) {
    this.deleteParticularFeature(layer, $event.group, $event.featureType, $event.feature);
  }

  public onDeleteType(layer: CommitChanges, $event: {featureType: FeatureTypeItem, group: FeatureGroupItem, file: FileGroup}) {
    this.deleteFeatureType(layer, $event.group, $event.featureType, $event.file.fileId);
  }

  public onDeleteGroup(layer: CommitChanges, $event: {group: FeatureGroupItem, file: FileGroup}) {
    this.deleteFeatureGroup(layer, $event.group, $event.file.fileId);
  }

  public onDeleteFile(layer: CommitChanges, $event: FileGroup) {
    this.deleteFeatureFile(layer, $event);
  }

}
