import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CredentialessLoginService } from 'src/app/services/credentialess-login/credentialess-login.service';
import { CredentialessLogin } from './credentialess-login';
import { link } from 'fs';
import { BehaviorSubjectService } from 'src/app/services/behaviorsubject.service';


@Component({
  selector: 'credentialess-login-component',
  templateUrl: './credentialess-login.component.html',
  styleUrls: ['./credentialess-login.component.css']
})
export class CredentialessLoginComponent implements OnInit {

  routeParamSubscription: Subscription | null = null;
  guid:string = '';
  linkMessage:string = '';
  credentialessLogin:CredentialessLogin={userLoggedIn:false};
  
  constructor (private route: ActivatedRoute, private credentialessLoginService: CredentialessLoginService, private router: Router,
    private behaviourSubjectService: BehaviorSubjectService
    ){}
  
    ngOnInit(): void {
      
      this.routeParamSubscription = this.route.params.subscribe((params) => {        
        if (params.guid) {        
          // if there is a guid, get the information associated with it from the Microservice
          this.guid = params.guid;
          console.log('guid ' + this.guid);
          this.credentialessLoginService.getJobInfoByGUID(this.guid).subscribe((item: any) => {                       
            this.credentialessLogin.guid = this.guid?.toString();
            var jobid = item.features[0].properties['jobId'];
            this.behaviourSubjectService.changeCredentialessLogin(this.credentialessLogin);
            this.router.navigate([`/mapviewerjobs/${this.guid}/${jobid}`]);
          }, (err) => {      
            console.log("GUID Has Expired or Job Not Available.");            
             // redirect to sitemap login
             this.router.navigate([""]);
          });
        }
      });
  }

}