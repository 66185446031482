import { IEnvironment } from "./environment.types";

export const environment: IEnvironment = {
  production: false,
  name:'qa',
  CLIENT_ID: '71fb214f-d71d-4d06-b40b-61d1ad2cb86e',
  B2C_LOGIN:'https://login-qa.sitemap.com/gprssitemapngqa.onmicrosoft.com/B2C_1_SignIn-SignUp-SiteMapNG-QA',
  SIGNUP_SIGNIN:'B2C_1_SignIn-SignUp-SiteMapNG-QA',
  AUTHORITY_DOMAIN: 'login-qa.sitemap.com',
  AZURE_URL_CONTAINER : 'https://straccsitemapngqa.blob.core.windows.net/',
  IP_STACK : 'https://api.ipstack.com/check?access_key=564ee495a0a99851e8f4b498e19d24b4',
  TILES_MVT_BASE_URL : 'https://tileserver-qa.sitemap.com/',

  // Portal APIs
   DPR_API: 'https://digitalplanroomqa.sitemap.com/api/DigitalPlanRoom',
   HELP_API: 'https://helpqa.sitemap.com/api/Help',
   IMPORT_EXPORT_API: 'https://importexportqa.sitemap.com/api/ImportExport',
   JOBS_API:'https://jobsqa.sitemap.com/Jobs',
   MAP_SCREEN_API: 'https://mapscreenqa.sitemap.com/api/MapScreen',
   NOTIFICATION_API: 'https://notificationqa.sitemap.com/api/Notification',
   PROJECTS_API: 'https://projectsqa.sitemap.com/Projects',
   SECURITY_API: 'https://securityqa.sitemap.com/api/Security',
   SETTINGS_API: 'https://settingsqa.sitemap.com/Settings',
   SITES_API: 'https://sitesqa.sitemap.com/Sites',
   USER_MANAGEMENT_API: 'https://usermanagementqa.sitemap.com/api/UserManagement',

   TILES_MVT_API: 'https://tileserver-qa.sitemap.com/api/v1/mvttileserver',
   TILES_IMPORT_API: 'https://tileserver-qa.sitemap.com/api/v1/importserver',
   TILES_PRINT_API: 'https://tileserver-qa.sitemap.com/api/v1/printserver',
   TILES_IMAGE_SERVER: 'https://tileserver-qa.sitemap.com/api/v1/imageserver',
   RASTER_TILES_SERVER: 'https://tileserver-qa.sitemap.com/api/v1/rastertileserver',


   picturesContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-10-26T01:39:07Z&st=2023-10-25T17:39:07Z&spr=https,http&sig=XyV4kqgGwJvbYowJqc5LTaQiIFvdq%2FhIpX2TydVAQgQ%3D',
   dprFilesContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-10-26T01:39:07Z&st=2023-10-25T17:39:07Z&spr=https,http&sig=XyV4kqgGwJvbYowJqc5LTaQiIFvdq%2FhIpX2TydVAQgQ%3D',
   importedContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-10-26T01:39:07Z&st=2023-10-25T17:39:07Z&spr=https,http&sig=XyV4kqgGwJvbYowJqc5LTaQiIFvdq%2FhIpX2TydVAQgQ%3D',
   helpDocumentContainerSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-10-26T01:39:07Z&st=2023-10-25T17:39:07Z&spr=https,http&sig=XyV4kqgGwJvbYowJqc5LTaQiIFvdq%2FhIpX2TydVAQgQ%3D',
   dprFileSWAPSDownloadSAS : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-10-26T01:39:07Z&st=2023-10-25T17:39:07Z&spr=https,http&sig=XyV4kqgGwJvbYowJqc5LTaQiIFvdq%2FhIpX2TydVAQgQ%3D',
   CUSTOME_LINES : 'https://tileserver-qa.sitemap.com/api/v1/mvttileserver/custom_lines/{z}/{x}/{y}.mvt',
   CUSTOME_POINTS :'https://tileserver-qa.sitemap.com/api/v1/mvttileserver/custom_points/{z}/{x}/{y}.mvt',
   CUSTOME_ANNOTATION_LINES : 'https://tileserver-qa.sitemap.com/api/v1/mvttileserver/custom_lines/{z}/{x}/{y}.mvt',
   CUSTOME_ANNOTATION_POINTS : 'https://tileserver-qa.sitemap.com/api/v1/mvttileserver/custom_annotation_lines/{z}/{x}/{y}.mvt',
   POINT_TILES:'https://tileserver-qa.sitemap.com/api/v1/mvttileserver/active_points_annotations_by_layerid/',
   LINE_TILES:'https://tileserver-qa.sitemap.com/api/v1/mvttileserver/active_lines_annotations_by_layerid/',
   NEW_MAP : 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
   STATIC_PHOTO_IMAGE:'https://tileserver-qa.sitemap.com/static/MPO4326.png',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZ3Bycy1iYXNpYy1tYXAtdmlld2VyIiwiYSI6ImNrYzJjZzNydjExeGoyenVyNHI5ZzJvcXMifQ.NZbTl5X2Ii6zMz9GIPpZRw'
  }
};
