export class FeatureTypeStyleMapping {

  public static featureTypeStyleJson = [
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_COMPRESSOR",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 113,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 114,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 115,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 111,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 116,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 117,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_PUMP",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 118,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_RISER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 119,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "AIR_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 112,
      "feature_group_id": 9
    },
    // {
    //   "feature_group_name": "PRODUCT",
    //   "feature_type_name": "BUILDING_CORNER",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFFF00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 119,
    //   "feature_group_id": 9
    // },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_AST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 121,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 122,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 123,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 124,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 125,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_PUMP",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 126,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_TANK",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 128,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEM_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 129,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEMICAL_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 130,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "CHEMICAL_TANK_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 131,
      "feature_group_id": 9
    },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_1_CONDUIT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 31,
    //   "feature_group_id": 3
    // },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_2_CONDUIT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 31,
    //   "feature_group_id": 3
    // },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_3_CONDUIT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 31,
    //   "feature_group_id": 3
    // },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_4_CONDUIT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 31,
    //   "feature_group_id": 3
    // },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_5_CONDUIT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 31,
    //   "feature_group_id": 3
    // },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_BOX",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 31,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_CAMERA",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 32,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 33,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 34,
      "feature_group_id": 3
    },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_HANDHOLE",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 34,
    //   "feature_group_id": 3
    // },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_LOOP",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 34,
    //   "feature_group_id": 3
    // },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 35,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 36,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_PEDESTAL",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 37,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_POLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 38,
      "feature_group_id": 3
    },
    // {
    //   "feature_group_name": "COMMUNICATION",
    //   "feature_type_name": "COMM_SPLICE",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFAA00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 38,
    //   "feature_group_id": 3
    // },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMM_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 40,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMMUNICATION_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 41,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMMUNICATION_LINE_DUCT",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 5,
      "feature_type_id": 42,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "COMMUNICATION",
      "feature_type_name": "COMMUNICATION_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFAA00",
      "fill_color": "#FFFFFF",
      "line_width": 5,
      "feature_type_id": 43,
      "feature_group_id": 3
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "EDGE_OF_ASPHALT",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 200,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "EDGE_OF_CONCRETE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 201,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "EDGE_OF_CURB",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 202,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "EDGE_OF_GRAVEL",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 203,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_BOX",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 44,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_CABINET",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 45,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 46,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 47,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_EQUIPMENT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 48,
      "feature_group_id": 4
    },
    // {
    //   "feature_group_name": "ELECTRICAL",
    //   "feature_type_name": "ELEC_GROUND_CONTINUES",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FF0000",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 48,
    //   "feature_group_id": 4
    // },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_GROUND_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 67,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_LANDSCAPELIGHT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 49,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 50,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_METER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 51,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 52,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_PANEL",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#F30127",
      "fill_color": "#FFFFFF",
      "line_width": 5,
      "feature_type_id": 53,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_POWERPOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 54,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_SIGN",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 55,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_SITELIGHT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 56,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_TRANSFORMER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 58,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_UTILITYPOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 59,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELEC_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 60,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELECTRICAL_GROUND_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#7FFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 65,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELECTRICAL_GROUND_WIRE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#7FFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 61,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELECTRICAL_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 62,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELECTRICAL_LINE_DUCT",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 5,
      "feature_type_id": 63,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELECTRICAL_SITE_LIGHT_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 64,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "ELECTRICAL",
      "feature_type_name": "ELECTRICAL_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 1,
      "feature_type_id": 66,
      "feature_group_id": 4
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "FENCE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 204,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_BACKFLOWPREVENTOR",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 68,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 69,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 70,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_HYDRANT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 71,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 72,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_METER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 73,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 74,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_POSTINDICATORVALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 75,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_RISER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 76,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIRE_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 78,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIREWATER_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 79,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "FIREWATER",
      "feature_type_name": "FIREWATER_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 80,
      "feature_group_id": 5
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "FLAGPOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 205,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_AST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 134,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 135,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 136,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 132,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 137,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 138,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_PUMP",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 139,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_RISER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 140,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_TANK_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 133,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_UST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 142,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 143,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 144,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "FUEL_VENT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 145,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_AST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 3,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 4,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 5,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 1,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 6,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_METER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 7,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 8,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_PUMP",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 9,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_RISER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 10,
      "feature_group_id": 1
    },
    //PNG NOT PRESENT 
    // {
    //   "feature_group_name": "GAS",
    //   "feature_type_name": "GAS_SNAPPOINT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFFF00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 11,
    //   "feature_group_id": 1
    // },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_TANK_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 2,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_UST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 12,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 13,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 14,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "GAS",
      "feature_type_name": "GAS_VENT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 15,
      "feature_group_id": 1
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "GRAVE_WITH_HEADSTONE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 206,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "GRAVE_WITHOUT_HEADSTONE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 207,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "HEADSTONE_NO_GRAVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 208,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRR_BACKFLOWPREVENTOR",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 81,
      "feature_group_id": 6
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRR_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 82,
      "feature_group_id": 6
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRR_CONTROL_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 83,
      "feature_group_id": 6
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRR_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 84,
      "feature_group_id": 6
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRR_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 85,
      "feature_group_id": 6
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRR_RISER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 86,
      "feature_group_id": 6
    },
    //PNG NOT PRESENT
    // {
    //   "feature_group_name": "IRRIGATION",
    //   "feature_type_name": "IRR_SNAPPOINT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#AA55FF",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 87,
    //   "feature_group_id": 6
    // },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRR_SPRINKLER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 88,
      "feature_group_id": 6
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRRIGATION_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 89,
      "feature_group_id": 6
    },
    {
      "feature_group_name": "IRRIGATION",
      "feature_type_name": "IRRIGATION_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#AA55FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 90,
      "feature_group_id": 6
    },
    // {
    //   "feature_group_name": "IRRIGATION",
    //   "feature_type_name": "LD_HYDRANT_LEAK",
    //   "geometry_type": "LINE",
    //   "point_shape": "Circle",
    //   "color": "#AA55FF",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 90,
    //   "feature_group_id": 6
    // },
    // {
    //   "feature_group_name": "IRRIGATION",
    //   "feature_type_name": "LD_MAIN_BREAK",
    //   "geometry_type": "LINE",
    //   "point_shape": "Circle",
    //   "color": "#AA55FF",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 90,
    //   "feature_group_id": 6
    // },
    // {
    //   "feature_group_name": "IRRIGATION",
    //   "feature_type_name": "LD_SERVICE_LEAK",
    //   "geometry_type": "LINE",
    //   "point_shape": "Circle",
    //   "color": "#AA55FF",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 90,
    //   "feature_group_id": 6
    // },
    // {
    //   "feature_group_name": "IRRIGATION",
    //   "feature_type_name": "LD_VALVE_LEAK",
    //   "geometry_type": "LINE",
    //   "point_shape": "Circle",
    //   "color": "#AA55FF",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 90,
    //   "feature_group_id": 6
    // },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "MAILBOX",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 209,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_AREA_LIMITS",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 92,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_AREA_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 93,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_BLACK_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#000000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 94,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_BROWN_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#A52A2A",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 95,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 102,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_DEPTH",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 103,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 104,
      "feature_group_id": 7
    },
    //png NOT PRESENT
    // {
    //   "feature_group_name": "SCAN INFO",
    //   "feature_type_name": "MISC_GREY",
    //   "geometry_type": "Point",
    //   "point_shape": "Circle",
    //   "color": "#808080",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 96,
    //   "feature_group_id": 7
    // },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_GREY_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#808080",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 96,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#808080",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 97,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_LINE_POINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#808080",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 98,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 105,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_POINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 106,
      "feature_group_id": 7
    },
    //PNG NOT PRESENT
    // {
    //   "feature_group_name": "MISC",
    //   "feature_type_name": "MISC_SNAPPOINT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FF00FF",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 106,
    //   "feature_group_id": 7
    // },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_TANK_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 99,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_UTILITY_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 100,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF00FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 108,
      "feature_group_id": 7
    },
    //PNG NOT PRESENT
    // {
    //   "feature_group_name": "SCAN INFO",
    //   "feature_type_name": "MISC_WHITE",
    //   "geometry_type": "Point",
    //   "point_shape": "Circle",
    //   "color": "#FFFFFF",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 108,
    //   "feature_group_id": 7
    // },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "MISC_WHITE_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFFFF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 101,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "NO_SCAN",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 186,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "NO_SCAN_SNAPPOINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 187,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "OBSTRUCTION_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 188,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "OBSTRUCTION_SNAPPOINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 189,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 148,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 149,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 146,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 150,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 151,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_PUMP",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 152,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_RISER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 153,
      "feature_group_id": 9
    },
    // {
    //   "feature_group_name": "PRODUCT",
    //   "feature_type_name": "OIL_SNAPPOINT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FFFF00",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 154,
    //   "feature_group_id": 9
    // },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_TANK",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 155,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_TANK_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 147,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_UST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 156,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 157,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 158,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "OIL_VENT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 159,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "POST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 210,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "PROPOSED_BY_OTHERS",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 211,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "PROPOSED_LINE_BY_OTHERS",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 212,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_CLEANOUT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 168,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 169,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 170,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_GREASETRAP",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 171,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_INVERT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 172,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_LATERAL",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 173,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_LIFTSTATION",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 174,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 175,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_MARKER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 176,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 177,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_SEPTICTANK",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 178,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_SNAPPOINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 179,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 180,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SAN_VENT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 181,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SANITARY_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 182,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SANITARY",
      "feature_type_name": "SANITARY_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#00FF99",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 183,
      "feature_group_id": 10
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "SATELLITE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 213,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "SB",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 190,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "SB_RED",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 191,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "SCAN_AREA",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFFFF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 192,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "SCAN_LIMIT_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFFFF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 193,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "SCAN_SNAPPOINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFFFF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 194,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "SIGN",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 214,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "SOIL_BORING",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 109,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "SOIL_BORING_MARKER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 215,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "SOIL_BORING_MARKER_RED",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 216,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "MISC",
      "feature_type_name": "SOIL_BORING_RED",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 110,
      "feature_group_id": 7
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 162,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 163,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 160,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 164,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 165,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_SNAPPOINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 166,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 161,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "PRODUCT",
      "feature_type_name": "STEAM_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FFFF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 167,
      "feature_group_id": 9
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STORM_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 219,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STORM_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 220,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_CATCHBASINROUND",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 221,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_CATCHBASINSQUARE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 222,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_CLEANOUT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 223,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 224,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_CURBINLET",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 1,
      "feature_type_id": 225,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_ENDPIPE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 226,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 227,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_LIFTSTATION",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 228,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 229,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 230,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_ROOFDRAIN",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 231,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_SNAPPOINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 232,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_TRENCHDRAIN",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 233,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_UST",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 234,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 235,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_VENT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 236,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "STORM",
      "feature_type_name": "STRM_YARDBASIN",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#00FF00",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 237,
      "feature_group_id": 13
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_BOX",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 238,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_CABINET",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 239,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 240,
      "feature_group_id": 14
    },
    //PNG NOT PRESENT
    // {
    //   "feature_group_name": "TRAFFIC",
    //   "feature_type_name": "TRAF_DUCT",
    //   "geometry_type": "POINT",
    //   "point_shape": "Circle",
    //   "color": "#FF0000",
    //   "fill_color": "#FFFFFF",
    //   "line_width": 2,
    //   "feature_type_id": 241,
    //   "feature_group_id": 14
    // },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 242,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 243,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 244,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_PARKINGMETER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 245,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_SIGN",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 246,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_SIGNAL",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 247,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAF_STREETLIGHT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 249,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAFFIC_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 250,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAFFIC_LINE_DUCT",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 5,
      "feature_type_id": 251,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "TRAFFIC",
      "feature_type_name": "TRAFFIC_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 5,
      "feature_type_id": 252,
      "feature_group_id": 14
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "TREE_CONIFEROUS",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 217,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "SITE FEATURE",
      "feature_type_name": "TREE_DECIDUOUS",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#C0C0C0",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 218,
      "feature_group_id": 12
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKN_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 253,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKN_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 254,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKN_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 255,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKN_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 256,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKN_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 258,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKNOWN_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 259,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKNOWN_LINE_DUCT",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 260,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "UNKNOWN",
      "feature_type_name": "UNKNOWN_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF99FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 261,
      "feature_group_id": 15
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "VOID_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 195,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "SCAN INFO",
      "feature_type_name": "VOID_SNAPPOINT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#FF0000",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 196,
      "feature_group_id": 11
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WATER_LINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 16,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WATER_STRUCTURE_OUTLINE",
      "geometry_type": "LINE",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 17,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_BACKFLOWPREVENTOR",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 18,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_CONTINUES",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 19,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_EOI",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 20,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_HYDRANT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 21,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_MANHOLE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 22,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_METER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 23,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_MISC",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 24,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_POSTINDICATORVALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 25,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_RISER",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 26,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_VALVE",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 28,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_VAULT",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 30,
      "feature_group_id": 2
    },
    {
      "feature_group_name": "WATER",
      "feature_type_name": "WTR_WELLHEAD",
      "geometry_type": "POINT",
      "point_shape": "Circle",
      "color": "#0000FF",
      "fill_color": "#FFFFFF",
      "line_width": 2,
      "feature_type_id": 29,
      "feature_group_id": 2
    }
  ];

  public static iconsPresent = [
    'AIR_COMPRESSOR',
    'AIR_CONTINUES',
    'AIR_EOI',
    'AIR_MANHOLE',
    'AIR_MISC',
    'AIR_PUMP',
    'AIR_RISER',
    'AIR_SNAPPOINT',
    'ATTACHMENT',
    'ATTACHMENTS',
    'BOLLARD',
    'BOULDER',
    'BUILDING_CORNER',
    'CHEM_AST',
    'CHEM_CONTINUES',
    'CHEM_EOI',
    'CHEM_MANHOLE',
    'CHEM_MISC',
    'CHEM_PUMP',
    'CHEM_SNAPPOINT',
    'CHEM_TANK',
    'CHEM_VALVE',
    'COMM_1_CONDUIT',
    'COMM_2_CONDUIT',
    'COMM_3_CONDUIT',
    'COMM_4_CONDUIT',
    'COMM_5_CONDUIT',
    'COMM_BOX',
    'COMM_CAMERA',
    'COMM_CONTINUES',
    'COMM_EOI',
    'COMM_HANDHOLE',
    'COMM_LOOP',
    'COMM_MANHOLE',
    'COMM_MISC',
    'COMM_PEDESTAL',
    'COMM_POLE',
    'COMM_SNAPPOINT',
    'COMM_SPLICE',
    'COMM_VAULT',
    'ELEC_BOX',
    'ELEC_CABINET',
    'ELEC_CONTINUES',
    'ELEC_EOI',
    'ELEC_EQUIPMENT',
    'ELEC_GROUND_CONTINUES',
    'ELEC_GROUND_EOI',
    'ELEC_LANDSCAPELIGHT',
    'ELEC_MANHOLE',
    'ELEC_METER',
    'ELEC_MISC',
    'ELEC_PANEL',
    'ELEC_POWERPOLE',
    'ELEC_SIGN',
    'ELEC_SITELIGHT',
    'ELEC_SNAPPOINT',
    'ELEC_TRANSFORMER',
    'ELEC_UTILITYPOLE',
    'ELEC_VAULT',
    'EXTERNALLINK',
    'FIRE_BACKFLOWPREVENTOR',
    'FIRE_CONTINUES',
    'FIRE_EOI',
    'FIRE_HYDRANT',
    'FIRE_MANHOLE',
    'FIRE_METER',
    'FIRE_MISC',
    'FIRE_POSTINDICATORVALVE',
    'FIRE_RISER',
    'FIRE_VALVE',
    'FLAGPOLE',
    'FUEL_AST',
    'FUEL_CONTINUES',
    'FUEL_EOI',
    'FUEL_MANHOLE',
    'FUEL_MISC',
    'FUEL_PUMP',
    'FUEL_RISER',
    'FIRE_SNAPPOINT',
    'FUEL_SNAPPOINT',
    'FUEL_UST',
    'FUEL_VALVE',
    'FUEL_VAULT',
    'FUEL_VENT',
    'GAS_AST',
    'GAS_CONTINUES',
    'GAS_EOI',
    'GAS_MANHOLE',
    'GAS_METER',
    'GAS_MISC',
    'GAS_PUMP',
    'GAS_RISER',
    'GAS_SNAPPOINT',
    'GAS_UST',
    'GAS_VALVE',
    'GAS_VAULT',
    'GAS_VENT',
    'GENERIC_BRIAN',
    'GRAVE_WITH_HEADSTONE',
    'GRAVE_WITHOUT_HEADSTONE',
    'HEADSTONE_NO_GRAVE',
    'IRR_BACKFLOWPREVENTOR',
    'IRR_CONTINUES',
    'IRR_CONTROL_VALVE',
    'IRR_EOI',
    'IRR_MISC',
    'IRR_RISER',
    'IRR_SNAPPOINT',
    'IRR_SPRINKLER',
    'LD_HYDRANT LEAK',
    'LD_MAIN BREAK',
    'LD_SERVICE LEAK',
    'LD_VALVE LEAK',
    'LIMITATION_SNAPPOINT',
    'MAILBOX',
    'MATTERPORT',
    'MISC_CAD',
    'MISC_CONTINUES',
    'MISC_DEPTH',
    'MISC_EOI',
    'MISC_MANHOLE',
    'MISC_POINT',
    'MISC_SNAPPOINT',
    'MISC_VALVE',
    'NoAccess',
    'NO_SCAN_SNAPPOINT',
    'OBSTRUCTION_SNAPPOINT',
    'OIL_CONTINUES',
    'OIL_EOI',
    'OIL_MANHOLE',
    'OIL_MISC',
    'OIL_PUMP',
    'OIL_RISER',
    'OIL_SNAPPOINT',
    'OIL_TANK',
    'OIL_UST',
    'OIL_VALVE',
    'OIL_VAULT',
    'OIL_VENT',
    'PHOTO',
    'POINTCLOUD',
    'POST',
    'SAN_CLEANOUT',
    'SAN_CONTINUES',
    'SAN_EOI',
    'SAN_GREASETRAP',
    'SAN_INVERT',
    'SAN_LATERAL',
    'SAN_LIFTSTATION',
    'SAN_MANHOLE',
    'SAN_MARKER',
    'SAN_MISC',
    'SAN_SEPTICTANK',
    'SAN_SNAPPOINT',
    'SAN_VAULT',
    'SAN_VENT',
    'SATELLITE',
    'SB',
    'SB_RED',
    'SCAN_SNAPPOINT',
    'SIGN',
    'SOIL_BORING_MARKER',
    'SOIL_BORING_MARKER_RED',
    'SOIL_BORING',
    'SOIL_BORING_RED',
    'STEAM_CONTINUES',
    'STEAM_EOI',
    'STEAM_MANHOLE',
    'STEAM_MISC',
    'STEAM_SNAPPOINT',
    'STEAM_VALVE',
    'STRM_CATCHBASINROUND',
    'STRM_CATCHBASINSQUARE',
    'STRM_CLEANOUT',
    'STRM_CONTINUES',
    'STRM_CURBINLET',
    'STRM_ENDPIPE',
    'STRM_EOI',
    'STRM_LIFTSTATION',
    'STRM_MANHOLE',
    'STRM_MISC',
    'STRM_ROOFDRAIN',
    'STRM_SNAPPOINT',
    'STRM_TRENCHDRAIN',
    'STRM_UST',
    'STRM_VAULT',
    'STRM_VENT',
    'STRM_YARDBASIN',
    'TRAF_BOX',
    'TRAF_CABINET',
    'TRAF_CONTINUES',
    'TRAF_EOI',
    'TRAF_MANHOLE',
    'TRAF_MISC',
    'TRAF_PARKINGMETER',
    'TRAF_PEDESTAL',
    'TRAF_SIGN',
    'TRAF_SIGNAL',
    'TRAF_SNAPPOINT',
    'TRAF_STREETLIGHT',
    'TREE_CONIFEROUS',
    'TREE_DECIDUOUS',
    'UNKN_CONTINUES',
    'UNKN_EOI',
    'UNKN_MANHOLE',
    'UNKN_MISC',
    'UNKN_SNAPPOINT',
    'UNKN_VALVE',
    'VIRTUALTOUR',
    'VOID_SNAPPOINT',
    'WTR_BACKFLOWPREVENTOR',
    'WTR_CONTINUES',
    'WTR_EOI',
    'WTR_HYDRANT',
    'WTR_MANHOLE',
    'WTR_METER',
    'WTR_MISC',
    'WTR_POSTINDICATORVALVE',
    'WTR_RISER',
    'WTR_SNAPPOINT',
    'WTR_VALVE',
    'WTR_VAULT',
    'WTR_WELLHEAD'
  ]
}