import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubjectService } from "../../services/behaviorsubject.service";
import { DashboardService } from './services/dashboard/dashboard.service';
import { PaginationService } from '../../services/pagination.service';
import { FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionService } from 'src/app/services/common-function-service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {

  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];
  userDataObj: any = {};
  username: any;
  logData: any = [];
  sharedJobObj: any = {};
  sharedDocumentObj: any = {};
  isDocumentShared: boolean = false;
  isJobShared: boolean = false;
  sharedObjResponse: any = {};
  timeout: any;
  fetchedEmails: any = [];
  objSharedUserList: any = [];
  shouldFetchedEmailsBeDisplayed: boolean = false;
  isEmailSelectedFromFetchedEmails: boolean = false;
  emailFormControl: FormControl = new FormControl('', [Validators.email, Validators.required]);
  accessFormControl: FormControl = new FormControl('1');
  userDetailsFromDb: any = {};
  azureAdb2cUserDetails: any = {};
  azureAdb2cUserDetailsSubscription: Subscription | null = null;
  fetchUserDetailsFRomDBSubscription: Subscription | null = null;
  isJobOwner: boolean = true;


  private readonly _destroying$ = new Subject<void>();
  tierIDData: any=[];
  allowShare: boolean=false;
  documentLogData: any = [];
  objectTypeId:any="";
  shareId:any="";
  userInteractionTypeId: any
  userId: any;
  loginUserRoleId: any;

  constructor(
    private behaviourSubjectService: BehaviorSubjectService,
    private spinner: NgxSpinnerService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private dashboardservice: DashboardService,
    private pagination: PaginationService,
    private toastr: ToastrService,
    private commonService: CommonFunctionService,
    private router: Router,

  ) {
  }

  ngOnInit(): void {
// Get Login User Details
    
    if (sessionStorage.getItem("loginCheck") === undefined || !sessionStorage.getItem("loginCheck")) {
      this.behaviourSubjectService.currentUserDetailsFromDB.subscribe(userData => {
        this.userDataObj = userData;
      });
      this.behaviourSubjectService.loginStatusObservable.subscribe((loginStatus) => {
        if (loginStatus) {
          this.setLoginDisplay(loginStatus);
          this.checkAndSetActiveAccount();
          this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)
        }
      });

      this.behaviourSubjectService.logDataObservable.subscribe((logData) => {
        let payLoad = {
          "objectId": logData.jobId,
          "userInteractionObjectTypeId": 1
        }
        this.dashboardservice.getJobLogByJobId(payLoad).subscribe((data) => {
          this.logData = data;
        });
      });

      // Fetch Shared Job Details
      this.behaviourSubjectService.jobSharedObservable.subscribe((jobObj) => {
        if (Object.keys(jobObj).length != 0) {
          this.sharedJobObj = jobObj;
          this.isJobShared = true;
          this.isDocumentShared = false;
          this.dashboardservice.fetchSharedWithUserList(this.sharedJobObj.jobId, 1).subscribe((data) => {
            this.objSharedUserList = data;
          });
          if (this.sharedJobObj.isShared.toString() == 'false') {  //isShared = true if user is the owner. not assinged (not owner)
            this.isJobOwner = false;
          } else {
            this.isJobOwner =  true;
          }
         
          if (this.userDataObj.role == 'Admin' || this.userDataObj.role =='Project Manager') {
            this.isJobOwner = true;
          }

        }
      });

// Fetch Share Documents Details
      this.behaviourSubjectService.documentSharedObservable.subscribe((documentObj) => {
        console.log(documentObj)
        console.log(Object.keys(documentObj).length)
        if (Object.keys(documentObj).length != 0) {
          this.sharedDocumentObj = documentObj;
          this.isJobShared = false;
          this.isDocumentShared = true;
          this.dashboardservice.fetchSharedWithUserList(this.sharedDocumentObj.id, this.sharedDocumentObj.objectTypeId).subscribe((data) => {
            this.objSharedUserList = data;
          });

          if (this.sharedDocumentObj.isShared) {  // not assinged (not owner)
            this.isJobOwner = false;
          }else{
            this.isJobOwner =  true;
          }

          if (this.userDataObj.role == 'Admin' || this.userDataObj.role =='Project Manager') {
            this.isJobOwner = true;
          }
        }
      });

      if (this.userDataObj.role == 'Admin' || 'Project Manager') {
        this.isJobOwner = true;
      }

      this.emailFormControl.valueChanges.subscribe((value) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.fetchedEmails = []
          this.allowShare = false;
          if (value != "" && !this.fetchedEmails.includes(value)) {
            this.tierIDData =[];
            this.dashboardservice.fetchEmails(value).subscribe((data) => {
              
              if (data.length != 0) {
                
                data.forEach((val: any, key) => {
                  this.fetchedEmails.push(val.emailId);
                });

              }
              if (this.fetchedEmails.length != 0) {
                this.shouldFetchedEmailsBeDisplayed = true;
              } else {
                this.shouldFetchedEmailsBeDisplayed = false;
              }
            })
           
          } else {
            this.fetchedEmails = [];

            this.shouldFetchedEmailsBeDisplayed = false;
          }
        }, 1750)
      });
      this.azureAdb2cUserDetailsSubscription = this.behaviourSubjectService.currentUserDetailsFromAzureADB2C.subscribe((userDetails) => {
        this.azureAdb2cUserDetails = userDetails;
        // this.fetchUserDetails();
      });

      if (sessionStorage.getItem('loginUserRoleId')) {
        this.loginUserRoleId = sessionStorage.getItem('loginUserRoleId');
      }
    }
    this.spinner.hide();
    this.accessFormControl.patchValue('1');
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay(status: boolean) {
    this.loginDisplay = status;
  }

  getClaims(claims: any) {
    this.behaviourSubjectService.changeMessage(claims['given_name']);

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  //# Share jobs/documents to concerned person with view/edit access 
  shareObj() {
  console.log(this.sharedDocumentObj, this.sharedJobObj)
  //  alert(this.allowShare)
  if(Object.keys(this.sharedDocumentObj).length > 0 && this.isDocumentShared){
    this.objectTypeId = this.sharedDocumentObj.objectTypeId;
    this.shareId = this.sharedDocumentObj.id;
  }
  if(Object.keys(this.sharedJobObj).length > 0 && this.isJobShared){
    this.objectTypeId = 1;
    this.shareId = this.sharedJobObj.jobId;
  }
  console.log(this.objectTypeId);
  this.spinner.show();
  this.dashboardservice.fetchEditEmails(this.emailFormControl.value).subscribe((data) => {
   // console.log('888888', data) 
    this.spinner.hide();
     this.tierIDData = data;
     let userTierID = "";
     let loginUserRole = "";
    if(this.tierIDData.length > 0){
      console.log(this.accessFormControl.value, this.tierIDData[0].tierId)
      userTierID = this.tierIDData[0].tierId;
      loginUserRole = this.tierIDData[0].roleName;
    }

    if(this.tierIDData.length === 0 && this.accessFormControl.value =='2'){
      userTierID = "";
      this.toastr.error("User Id entered does not have edit access. Only view access can be shared");
      return;
    }
    let tierId = sessionStorage.getItem('tierId');
    if(loginUserRole !="Project Manager" && loginUserRole!="Admin" ){
      
      if(tierId =='2' && this.accessFormControl.value =='2' && userTierID == '1'){
        this.toastr.error("Free account User does not have access to edit the content");
        return;
        }
        if(tierId =='1' && this.accessFormControl.value =='2' && userTierID == '2'){
          this.toastr.error("Free account User does not have access to edit the content");
          return;
        }
        if(tierId =='1' && this.accessFormControl.value =='2' && userTierID == '1'){
          this.toastr.error("Free account User does not have access to edit the content");
          return;
        }
    }
   

    if (!this.isJobOwner) {  // not owner for job/document
      this.toastr.error("You must be the owner of the job in order to share content");
    } else {
      if (this.emailFormControl.value == "") {
        this.toastr.error("Please enter email address");

      } else {

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailFormControl.value)) {
          this.spinner.show();
          let payLoad = {
            "sharedWithEmailId": this.emailFormControl.value,
            "sharedByUserId": this.userDataObj.userId,
            "objectId": this.shareId,
            "objectTypeId": this.objectTypeId,
            "securableObjectPermissionTypeId": parseInt(this.accessFormControl.value),
          }

          this.dashboardservice.shareObject(payLoad).subscribe((response) => {
            this.sharedObjResponse = response;
            this.spinner.hide();
            this.emailFormControl.reset();
            this.accessFormControl.patchValue('1');
            this.emailFormControl.patchValue('');
            if (this.sharedObjResponse.processingStatus.status === "Failure") {
              this.toastr.error(this.sharedObjResponse.processingStatus.message);
            } else {
              if (this.isJobShared) {
                this.toastr.success("Job shared successfully");
               
              } else {
                this.toastr.success("Document shared successfully");
              }
            }
          });
          this.closeShareObjModal();
        } else {
          this.toastr.error("Please enter a valid Email Address.");
        }
      }
    
    }
   })
  }

  closeShareObjModal() {
    this.accessFormControl.patchValue('1');
    this.objSharedUserList = [];
    this.emailFormControl.setValue("");
  }

  selectEmailFromFetchedEmails(obj: any) {
    this.emailFormControl.setValue(obj);
    this.shouldFetchedEmailsBeDisplayed = false;
  }

  onCancelModal() {
    this.accessFormControl.patchValue('1');
   
    this.commonService.setloggedInOROutApi(sessionStorage.getItem("loginUserId"), false).subscribe((result: any) => {
      if (result && result.processingStatus?.status.includes('Success')) {
        sessionStorage.clear();
        sessionStorage.clear();
        this.authService?.logout();
        this.router.navigate([""]);
      }
      else if (result && result.processingStatus?.status.includes("Failure")) {
        this.toastr.error("App component error in Logout!", "", {
          timeOut: 3000
        });
      }
    }, (err: any) => {
      console.log("app component logout setloggedInOROutApi: ", err);
      this.toastr.error(err, "", {
        timeOut: 3000
      });
    });
    
    //sessionStorage.clear();
    //sessionStorage.clear();
    //this.authService.logout();
  }

}
  /***** RECENT PROJECTS END ****/
