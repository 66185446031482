import { Component, OnInit, HostListener, AfterViewInit, Output, Input, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { feature, flattenReduce } from '@turf/turf';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  NgForm,
  FormArray,
} from '@angular/forms';
import * as exampleDataImport from '../../assets/js/ImportGetSchemaMapper.json';
import * as exampleDataImportCAD from '../../assets/js/ImportGetSchemaMapperCAD.json';
import '@watergis/mapbox-gl-export/css/styles.css';
import * as mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// @ts-ignore
import { bringLineLayersToTop, bringPointLayersToTop, deselectPointsForVertexEditing, getSelectedLineFeature, setSiteMapDrawMode } from '../../assets/js/sitemap_draw_modes/editing_utils';
// @ts-ignore
import siteMapSimpleSelect from '../../assets/js/sitemap_draw_modes/sitemap_simple_select';
// @ts-ignore
import siteMapDirectSelect from '../../assets/js/sitemap_draw_modes/sitemap_direct_select';
// @ts-ignore
import siteMapDrawPoint from '../../assets/js/sitemap_draw_modes/sitemap_draw_point';
// @ts-ignore
import siteMapDrawLineString from '../../assets/js/sitemap_draw_modes/sitemap_draw_line_string';
// @ts-ignore`
import moveVertexMode from '../../assets/js/sitemap_draw_modes/move_vertex';
// @ts-ignore
import addVertexMode from '../../assets/js/sitemap_draw_modes/add_vertex';
// @ts-ignore
import deleteVertexMode from '../../assets/js/sitemap_draw_modes/delete_vertex';
// @ts-ignore
import mergeLineMode from '../../assets/js/sitemap_draw_modes/merge_line';
// @ts-ignore
import mapboxDrawDefaultTheme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import { ApiService } from '../services/api.service';
import { MapViewerJobFeaturesService } from '../services/mapviewerJobFeatures/map-viewer-job-features.service';
import { MapViewerService } from '../modules/map/services/mapviewer.service';
import { DashboardService } from '../modules/dashboards/services/dashboard/dashboard.service';

import { FeatureTypeStyleMapping } from '../constants/featureTypeIdStylingMapping';

import { Subscription, of, Observable, Subject } from 'rxjs';
import { BehaviorSubjectService } from '../services/behaviorsubject.service';
import { forkJoin } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { JobService } from '../modules/projects/service/job.service';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { fileListTableDataDC, IFileListModelDC } from '../constants/documentsConstant';
import { AzureBlobStorageServiceVideo } from '../services/azure-blob-storage.service';
import { CommonFunctionService } from '../services/common-function-service';
import { CommonMapService } from '../common-component/common-map-service';
import { ConnectionService } from '../modules/sitemapadmin/services/connection-service';
import { PortalAPI } from '../constants/api.constant';
// Printing
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ThrowStmt } from '@angular/compiler';
import { SubscriptionEditAccess } from '../constants/subscriptionEditAccess';
import { MapEditService } from '../modules/map/services/mapedit.service';
import { PermissionService } from '../services/common/permission.service';
import { permissionEnum, UserPermissionConstant } from '../constants/userPermissionAccess';
import { MetadataService } from '../services/metadata.service';
import { CommitChanges, Feature, FeatureGroup, FeatureType, ReferenceGISTilesData } from '../constants/common-model';
import { CredentialessLoginService } from '../services/credentialess-login/credentialess-login.service';
import { CredentialessLogin } from '../common-component/credentialess-login/credentialess-login';
import { IRasterImage } from '../common-component/common-map-service.types';
import { RenderScaleService } from '../common-component/render-scale-service';
import { EventType, FeatureGroupItem, FeatureItem, FeatureTypeItem, FileGroup, LayerInfo, RasterImageItem } from '../modules/map/component/feature-tree/feature-tree.component.types';
import applyFilterOnLayer from '../modules/map/component/layer/functions/apply-filter-on-layer.function';
import { LayerMenuItemData } from '../modules/map/component/map-layer-menu-item/map-layer-menu-item.types';
import referenceLayerDataToMenuItems from '../modules/map/component/layer/functions/reference-layer-to-menu-tem-data';
import getJobIdFromFile from '../modules/map/component/feature-tree/functions/get-job-id-from-file.function';
import { virtualTourListTableDataDC } from '../constants/virtualTour.constants';


@Component({
  selector: 'app-mapviewerjobs',
  templateUrl: './mapviewerjobs.component.html',
  styleUrls: ['./mapviewerjobs.component.css'],
})
export class MapviewerjobsComponent implements OnInit, OnDestroy {
  lineSymbology: any[] = [];
  pointSymbology: any[] = [];
  featureTypeSymbologyMap: { [key: string]: string[] } = {};

  editDescriptionValue: any;
  editFormFeatureDescriptionView: boolean = true;
  updateFeatureDescriptionForm!: FormGroup;

  dprFileDownloadSAS: string = '';
  dprFileSWAPSDownloadSAS: string = '';
  map!: mapboxgl.Map;

  printState: boolean = false;
  // style = 'mapbox://styles/mapbox/satellite-v9';
  currentStyle: any = "satellite-streets-v11";
  style = 'mapbox://styles/mapbox/satellite-streets-v11';
  currentRenderScale:any = "1000";

  lat = 38.5243; //Latitude
  lng = -90.4723; //Longitude
  firstcordinate: any = [];
  sharedJobObj: any = {};
  layerList = document.getElementById('menu');
  public MapviewerSiteForm: any;
  public RenderScaleForm: any;
  jobMarker: any;
  draw: any = null;
  lineDynamicData: any;
  markupData: any;
  isBasemapToggle: boolean = false;
  pointLayerIds: string[] = [];
  lineLayerIds: string[] = [];

  public isImportingReferenceLayer = false;
  public layerEvents = new Subject<EventType>();
  public importedLayerInfo?: LayerInfo;
  importedIsPublic: boolean = false;
  public referenceLayerItems: LayerMenuItemData[] = [];

  /* Draw-related variables */
  // Mapbox Default Draw Styles with SiteMap filter
  drawDefaultstylesCustom = mapboxDrawDefaultTheme.map((style: any) => {
    style.filter.push(['!has', 'user_featureType']);
    style.filter.push(['!has', 'user_isPrintBox']);
    return style
  });
  // All Sitemap styles applied to Sitemap Draw features
  drawSiteMapStyles = [
    {
      id: 'sitemap-points-highlight-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      paint: {
        'circle-color': 'yellow',
        'circle-radius': 0
      },
    },
    {
      id: 'sitemap-points-symbol-inactive',
      type: 'symbol',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      layout: {
        'icon-allow-overlap': true,
        visibility: 'visible',
        'icon-image': ['get', 'user_featureType'], // expects "featureType" to have same name as the loaded icon
        'icon-size': 0.3,
      },
    },
    {
      id: 'sitemap-points-highlight-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      paint: {
        'circle-color': 'yellow',
        'circle-radius': 14
      },
    },
    {
      id: 'sitemap-points-symbol-active',
      type: 'symbol',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      layout: {
        'icon-allow-overlap': true,
        visibility: 'visible',
        'icon-image': ['get', 'user_featureType'], // expects "featureType" to have same name as the loaded icon
        'icon-size': 0.3,
      },
    },
    {
      id: 'sitemap-lines-highlight-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'yellow',
        'line-width': 0,
      },
    },
    {
      id: 'sitemap-lines-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': this.getLineStyle('line-color'),
        'line-width': 3,
      },
    },
    {
      id: 'sitemap-lines-highlight-active',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'yellow',
        'line-width': 5,
      },
    },
    {
      id: 'sitemap-lines-active',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_featureType']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': this.getLineStyle('line-color'),
        'line-width': 3,
      },
    },
    {
      id: 'sitemap-vertexes-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'vertex'],
        ['==', 'active', 'false'],
        // ['has', 'user_featureType'] This does not seemt to work on "vertex"
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': 'blue',
        'circle-stroke-color': 'lightblue',
        'circle-stroke-width': 2
      },
    },
    {
      id: 'sitemap-vertexes-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'vertex'],
        ['==', 'active', 'true'],
        // ['has', 'user_featureType'] This does not seemt to work on "vertex"
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': 'blue',
        'circle-stroke-color': 'darkblue',
        'circle-stroke-width': 2
      },
    },
    {
      id: 'sitemap-midpoints',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint'],
        // ['has', 'user_featureType'] This does not seemt to work on "midpoint"
      ],
      paint: {
        'circle-radius': 2,
        'circle-color': 'blue',
      },
    }
  ];
  // Style for the Print Box
  drawPrintBoxStyles = [
    {
      'id': 'gl-draw-polygon-fill-inactive-print-box',
      'type': 'fill',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'fill-color': 'blue',
        'fill-outline-color': 'blue',
        'fill-opacity': 0.01
      }
    },
    {
      'id': 'gl-draw-polygon-fill-active-print-box',
      'type': 'fill',
      'filter': ['all',
        ['==', 'active', 'true'],
        ['==', '$type', 'Polygon'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'fill-color': 'darkblue',
        'fill-outline-color': 'darkblue',
        'fill-opacity': 0.1
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-inactive-print-box',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': 'blue',
        'line-width': 4
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-active-print-box',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'true'],
        ['==', '$type', 'Polygon'],
        ['has', 'user_isPrintBox']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': 'darkblue',
        'line-width': 6
      }
    },
    {
      'id': 'gl-draw-polygon-midpoint-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint'],
        ['has', 'user_isPrintBox']],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-inactive-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-active-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['has', 'user_isPrintBox']
      ],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    },
    {
      'id': 'gl-draw-point-active-print-box',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
        ['has', 'user_isPrintBox']],
      'paint': {
        'circle-radius': 50,
        'circle-color': 'red'
      }
    }
  ];
  // Line Start/End Point Features
  drawLineStartEndStyles = [
    {
      id: 'line-start-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_isStartPoint']
      ],
      paint: {
        'circle-color': 'red',
        'circle-radius': 5,
      },
    },
    {
      id: 'line-start-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_isStartPoint']
      ],
      paint: {
        'circle-color': 'red',
        'circle-radius': 5,
      },
    },
    {
      id: 'line-end-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_isEndPoint']
      ],
      paint: {
        'circle-color': 'green',
        'circle-radius': 5,
      },
    },
    {
      id: 'line-end-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_isEndPoint']
      ],
      paint: {
        'circle-color': 'green',
        'circle-radius': 5,
      },
    },
  ];
  // Style for temporary "snap" feature
  drawSnapFeatureStyles = [
    {
      id: 'snap-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'false'],
        ['has', 'user_isSnapFeature']
      ],
      paint: {
        'circle-color': 'orange',
        'circle-radius': 5,
        'circle-stroke-color': 'steelblue',
        'circle-stroke-width': 2
      },
    },
    {
      id: 'snap-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['==', 'active', 'true'],
        ['has', 'user_isSnapFeature']
      ],
      paint: {
        'circle-color': 'orange',
        'circle-radius': 5,
        'circle-stroke-color': 'steelblue',
        'circle-stroke-width': 2
      },
    },
  ];

  // Draw Layer Ids
  drawLayerPointIds: any = [
    'sitemap-points-symbol-inactive.cold',
    // 'sitemap-points-circle-inactive.cold',
    'sitemap-points-symbol-active.cold',
    // 'sitemap-points-circle-active.cold',
    'sitemap-points-symbol-inactive.hot',
    // 'sitemap-points-circle-inactive.hot',
    'sitemap-points-symbol-active.hot',
    // 'sitemap-points-circle-active.hot',
  ];
  drawLayerLineIds: any = [
    'sitemap-lines-inactive.cold',
    'sitemap-lines-active.cold',
    'sitemap-lines-inactive.hot',
    'sitemap-lines-active.hot',
  ];
  drawLayerVertexIds: any = [
    'sitemap-vertexes-inactive.cold',
    'sitemap-vertexes-active.cold',
    'sitemap-vertexes-inactive.hot',
    'sitemap-vertexes-active.hot',
  ];
  drawLayerMidpointIds: any = [
    'sitemap-midpoints-inactive.cold',
    'sitemap-midpoints-active.cold',
    'sitemap-midpoints-inactive.hot',
    'sitemap-midpoints-active.hot',
  ];

  // Editing State
  editingState: any = {};
  disablePointInteractions: boolean = false;
  isSnapping: boolean = false;
  isSnappingToVertex: boolean = false;
  isSnappingToLine: boolean = false;
  snapFeatureLayerId: string = 'snap-feature';
  isDrawing: boolean = false;
  mergeParentLineId: string = '';
  mergeChildLineId: string = '';
  addVertexLineId: string = '';

  // Draw Options
  drawOptions = {
    displayControlsDefault: false,
    userProperties: true,
    controls: {
      polygon: true,
      line_string: true,
      // point: true,
      trash: true,
    },
    modes: {
      ...MapboxDraw.modes,
      sitemap_simple_select: siteMapSimpleSelect(),
      sitemap_direct_select: siteMapDirectSelect(),
      sitemap_draw_point: siteMapDrawPoint(),
      sitemap_draw_line_string: siteMapDrawLineString(),
      move_vertex: moveVertexMode(),
      delete_vertex: deleteVertexMode(),
      add_vertex: addVertexMode(),
      merge_line: mergeLineMode()
    },
    styles: [
      ...this.drawDefaultstylesCustom,
      ...this.drawPrintBoxStyles,
      ...this.drawSiteMapStyles,
      ...this.drawLineStartEndStyles,
      ...this.drawSnapFeatureStyles
    ],
  };

  // Printing State
  drawHandlePrint: any

  // Editing LayerIds
  siteMapMVTPointLayerIds: any;
  siteMapMVTLineLayerIds: any;
  siteMapDrawPointIds: any;
  siteMapDrawLineIds: any;
  snapPointLayerIds: any;
  siteMapDrawVertexIds: any;
  siteMapDrawMidpointIds: any;
  siteMapDrawPointHighlightIds: any;
  siteMapDrawLineHighlightIds: any;

  mergeLineSelectedArray: any = [];
  isMergedState: boolean = false;
  isPrintSession = false;
  jobFeaturesData: any;
  navigatedJobId: number = 0;
  navigatedJobFeatureGeoJson: any = null;
  centerCoordinatesForMap: any = [];
  zoomedLayerIdArray: any = [];
  featureTypeStyleMap: any;
  openedFeatureAttributes: any;
  openedFeatureProperties:any;
  openedFeatureAttributesAddedLink: any = [];
  openedFeatureName: string = '';
  featureGroupMap: any = new Map();
  isGroupMapCompleted: boolean = false;
  loginUserId: any;
  loginUserRole: any;
  loginUserTierId: any;
  navigatedJobName: string | null = null;
  pointFeaturesNameSet = new Set<string>();
  matterportAddLinkObj: any;
  clickedJobItemId: number | null = null;
  clickedJobItemCoordinates: any | null = null;
  clickedJobName: string | null = null;
  pointFeaturesImagesNotPresent: any = [];
  pointFeatureImageLoaded: any = [];
  pointFeaturesNameArray: string[] = [];
  apiAllJobResponse: any;
  geoJsonFromResponse: any;
  showToggleSideBar: boolean = true;
  mapEyeVisible: boolean = false;

  dragging: boolean = false;
  dragStart: any;
  dragEnd: any;
  actionCrop: boolean = true;
  printAction: boolean = true;
  getAllJObSubscribe: Subscription | undefined;

  featureDeleteSubscribe: Subscription | undefined;
  mapViewerJobFeatureSubscribe: Subscription | undefined;
  mapViewerDocumentsSubscribe: Subscription | undefined;
  mapViewerSubscribe: Subscription | undefined;
  headerLayerButtonStateSubscription: Subscription;
  headerFolderButtonStateSubscription: Subscription;
  headerMapToolButtonStateSubscription: Subscription;
  commitChangesSubscription: Subscription | undefined;

  pointFeaturesIconsAvailableSet: any;
  public exportForm: any;
  //isQAenv: string = '';
  //qaEnvValSubscription: Subscription;
  exportDrawPolygonCordinates: any;
  polygonGeoJson: any;
  routeParamSubscription: Subscription | null = null;
  showHeaderLayerZoomWarning: boolean = false;
  featureGroupToggleStateMap: any = new Map();
  featureGroupTypeToggleStateMap: any = new Map();
  featureGroupTypeFeatureNameToggleStateMap: any = new Map();
  zoomedJobIds: any = [];
  searchedJobGeoJson: any;
  forkJoinMapFeaturesSubscription: Subscription | null = null;
  mapZoomLevelGreaterThanThreshold: boolean = false;
  searchMapZoomLevelGreaterThanThreshold: boolean = false;
  isSearchAppliedOnJobData: boolean = false;

  slideMenuOpenLayerDetails: boolean = false;
  editMenuDisplay: Boolean = false;

  slideMenuOpenTools: boolean = false;
  slideMenuOpenJobDescription: boolean = false;
  isClientLayerVisible: boolean = true;
  isGPRSLayerVisible: boolean = true;

  isClientElecMeterGroupVisible: boolean = true;
  isClientElecLineGroupVisible: boolean = true;

  isGPRSElecMeterGroupVisible: boolean = true;
  isGPRSElecLineGroupVisible: boolean = true;

  propertyToShowInSideBar: any = null;

  enableToggleOnZoom: boolean = false;
  imgSrc: any = '';
  currentZoomLevel: any = 17;
  previousZoomLevel: any = null;
  zoomedJobIdLayerIdMap: any = new Map();
  searchInputSubscription: Subscription | null = null;
  searchTypeSubscription: Subscription | null = null;
  headerSearchSubscrition: Subscription | null = null;
  shareJobByHeaderIcon: Subscription | null = null;
  searchStringInput: string = '';
  latitudeSearhStringInput: string = '';
  longitudeSearhStringInput: string = '';
  selectedSearchType = 0;
  isHeaderSearch: boolean = false;
  searchMarker: any;
  finJobsSubscription: Subscription | null = null;
  searchJobList: any = {};
  searchJobIds: any = [];
  isJobOwner: boolean = true;
  isMapViewerJobsZoomedOut: boolean = false;
  navigatedFeatures: any;
  jobDocumentsList: any = [];
  slideMenuOpenFolder: boolean = false;
  currentDrawingState: any = 'static';
  loginUserEmailId: any;
  isGprsLayerCanEdit: boolean = false;
  isClientLayerCanEdit: boolean = false;
  isImportedLayerCanEdit: boolean = false;
  isExternalLayerCanEdit: boolean = false;
  isReferenceLayerCanEdit: boolean = false;
  isEditLayer: boolean = true;
  groupDropDownData: any[] = [];
  featureTypeDropDownData: any[] = [];
  editLayerName: any;
  isLayerEditVisible: any = [
    {
      SITE: true,
      GPRS: true,
      CLIENT: true,
      IMPORTED: true,
      EXTERNAL: true,
      REFERENCE: true
    },
  ];
  isLayerEyeVisible: any = {
    SITE: true,
    GPRS: true,
    CLIENT: true,
    IMPORTED: true,
    EXTERNAL: true,
    REFERENCE: false
  };
  hiddenFeatures: any = [];
  groupToSelect: any = '0';
  featureTypeToSelect: any = '0';
  featureImg: any;
  externalContentIcon: any;
  currentEditinglayerId: any;
  isEditingModeIsOn: boolean = false;

  // Keep an array of actions for undo/redo
  // This is reset to slice(0, editingHistoryIndex + 1) on all creates, updates and deletes
  editingHistory: any = [];
  // The index into editingHistory = current state
  editingHistoryIndex: any = -1;
  addNewFeatureGrpFrMapEditor: any;
  addNewFeatureTypeFrMapEditor: any;
  addNewFeatureGrpIdFrMapEditor: any;
  addNewFeatureTypeIdFrMapEditor: any;
  showAddFeatureContainerFrMapEditor: boolean = false;
  showAddFeatureButton: boolean = true;
  editSelectedFeature: any;
  selectedDrawFeature: any;
  featureTypeForBtnVisible: any;
  previousFeature: any;
  newAddedFeatureName: any;
  firstSecondLineFeatureFrMergedArr:any = []

  @Output() folderClickedEmitter = new EventEmitter();
  @Input() fileTableData: IFileListModelDC[] = [];
  fileTableHeader = new fileListTableDataDC().tblHeader;

  IsMeasurmentToolActive: any;
  myPreferenceSubscription: Subscription;
  newlyAddedFeature: any[] = [];
  newAddedFeatureInLayer: any = { layerName: String };

  // Import Functionality Variables Start

  importedFileName = '';
  importedFileType = '';
  importedFileArr: any;
  @ViewChild('myImportFile', { static: false })
  myImportFileVariable!: ElementRef;
  importSubmitted: boolean = false;
  importedBlankCheck: boolean = true;
  // Comment , Uncomment will done when Chris will done with other file formats, or production build release
  // _validFileExtensions = [".swmz", ".pdf", ".xlsx",".kml",".kmz",".swamps",".gpkg",".zip",".geojson",".json",".dxf"];
  // _validFileExtensions = [".gpkg", ".zip", ".geojson", ".json", ".kml", ".kmz", ".swmaps", ".swmz", ".swm2", ".tiff", ".tif", ".geotiff", ".pdf", ".dwg", ".dxf", ".gif", ".png", ".jpg", ".jpeg"];
  // kml, kmz, swmaps, gpkg, zip, geojson, json, dxf
  // _validFileExtensions = [".gpkg",".zip",".geojson",".json", ".kml", ".kmz", ".swmaps", ".swm2", ".swmz", ".tiff", ".tif", ".geotiff"];
  _vectorFileExtensions = [".gpkg", ".zip", ".geojson", ".json", ".kml", ".kmz", ".swmaps", ".swm2", ".swmz", ".dwg", ".dxf"];
  _rasterFileExtensions = [".tiff", ".tif", ".geotiff", ".pdf", ".gif", ".png", ".jpg", ".jpeg"];
  _rasterFileExtensionsWithoutPNG = [".tiff", ".tif", ".geotiff", ".pdf"];
  _validFileExtensions = [...this._vectorFileExtensions, ...this._rasterFileExtensions];
  fileExtensions = this._validFileExtensions.join(",");
  nonGeoReferenceWithoutPNGCheck: any = '';
  _validFileExtensionsPointCloud = [".las", ".laz"];
  importedLayerFileName = '';
  importedLayerFileId :any;
  importedFileNameChangeCondition: boolean = false;
  importedLayerFileNameStore: any = '';
  importedContainer: string = '';
  importedContainerSAS: string = '';

  // Import basic Coustom Data Variables Start
  customImportFeaturesGroup: any = [];
  customImportFeaturesType: any = [];
  selectImportFeatureGroup = '';
  selectImportFeatureType = '';
  permissionListObj:any = new UserPermissionConstant().UserRoleAssignPermission();
  checkImportFileTypeRaster: boolean = false;
  isGeoReferencedRaster: boolean = false;
  // Import basic Coustom Data Variables End

  public importGISForm: any;

  isSubmitButtonVisible: boolean = false;
  isSelectButtonVisible: boolean = false;
  isSubmitButtonVisibleGISReference: boolean = false;
  isSubmitNonGeoReferenceImage: boolean = false;

  nonGeoreferenceImgDivActive: boolean = false;

  editGeoReferenceImageType: any = '';
  // rasterImageIdEdit :any = '';
  rasterImageFeature :any = '';

  // CAD Custom Form Start
  cadCustomForm!: FormGroup;
  filteredOptionsFeatureGroup: any = [];
  filteredOptionsFeatureType: any = [];
  importFeatureGroup: any[] = [];
  importFeatureType: any[] = [];
  importFeatureGroupSelectedIndex: any = null;
  importFeatureTypeSelectedIndex: any = null;
  previousfeatureGroupIdSe = 0;

  crsDropDownData: any[] = [];
  importCustomFileName: any = '';
  importUploadingFileName: any = ''
  importUploadingFileId: number =  0;
  // CAD Custom Form end

  referenceGISTilesData: any[] = [];
  geoReferenceGISImagesData: any[] = [];
  rangeSliderGeoReferenceImage :any = 100;
  getAllVectorsSubscribe: Subscription | undefined;
  getAllRasterSubscribe: Subscription | undefined;
  featureGroupSearchSubscribe: Subscription | undefined;
  featureTypeSearchSubscribe: Subscription | undefined;

  // Import Functionality Variables End

  clickedFeatures: number = 0;

  objSharedUserList: any = [];
  emailFormControl: FormControl = new FormControl('', [Validators.email, Validators.required]);
  accessFormControl: FormControl = new FormControl('1');

  sharedObjResponse: any = {};
  modalJobdata: any;
  dashboardSubscribe: Subscription | null = null;
  shouldFetchedEmailsBeDisplayed: boolean = false;
  fetchedEmails: any = [];
  emailFormControlSubscription: Subscription | null = null;
  timeout: any;
  isImportLayerImageVisible: any = {
    'JSMC-layer': true,
    'MPO-layer': true
  };

  screenHeight: any;
  screenWidth: any;
  tierIDData: any = [];
  allowShare: boolean = false;

  isJobShared: boolean = false;
  isDocumentShared: boolean = false;

  zoomedFeaturesObservableArray: any[] = [];




  printForm!: FormGroup;

  isEditable = false;
  toggleActionButton = true;
  previousLableValue: any = '';
  featureDeleteSubscribeforExternal: Subscription | undefined;
  externalLinkId: any = '';
  labelvalue = 'feature.featureName';

  // External Content layer Variables Start
  latitudeExternalContent: any;
  longitudeExternalContent: any;
  externalContentId: any;
  showExternalContentModal: boolean = false;

  extContentApiUrl: any;
  externalPayload: any;
  modalHeader: any;
  modalExternalAddHeader: any;
  externalContentDropdownData: any = [];
  selectOptionsExternal: any = '';
  selectOptionsExternalEnable: boolean = false;
  editAddCompanyDetailsTable : boolean[] = [];
  counter: any=0;
  previousZoomJobId: any[] = [];
  credentialessLogin: CredentialessLogin = {};
  isCredentialessLogin:boolean=false;

  externalContentForm: FormGroup;
  externalContentFormOriginal: any = '';
  iframeExternalContentLink: boolean = true;

  externalLayerDatajobIdFeatures: any[] = [];
  externalLayerItems = ["POINTCLOUD", "MATTERPORT", "EXTERNALLINK", "ATTACHMENT", "VIRTUALTOUR"];

  externalContentTemplateData = {
    siteId: '',
    woNo: '',
    siteName: '',
    featureName: '',
    featureLink: '',
  };
  pointCloudFileName: any = '';
  // External Content layer Variables Start End
  currentDate: any = "";
  editCheck = new SubscriptionEditAccess(this.commonService).getEditAccess();
  subscriptionEditAccess = new SubscriptionEditAccess(this.commonService);
  externalLayerAddEdit: any = 'Add'
  jobId: any;
  scaleError: string="";
  mergeFirstLine: any;
  mergeSecondLine: any;

   // commit changes en-mass
   changesTobeCommitted:any[] = [];
   changesTobeReverted:any[] = [];
   isRefreshRequiredForCommittedChange:boolean = true;
   isSingleFeatureSave: boolean = false


  // HISTORY
  isHistoryOpend:boolean = false
  isHistoryBtnClicked:boolean = false
  historyMetaData:any[] = [];
  isHistoryGroupClicked:boolean = false;
  isHistoryTypeClicked:boolean = false;
  isHistoryFeatureClicked:boolean = false;
  featuresForRevision:any[] = [];
  jobDetailsForGPRS: any[] = [];
  printTypeSubscription: Subscription | null = null;
  currentEvent: any;
  printType: string="";
  permissionEnumList:any = permissionEnum;
  currentFeaturesLayer:number = -1;
  isCurrentUserHasEditAccessOnJob:boolean = true
  clickedDocFeatures: any[] = [];
  dprClass = 'table-colored table-striped table-breakAllWord table-dpr tbr-none';
  jobDocumentsListApiLoaded: boolean = false;
  isFirstEditDetected:boolean = false;
  matterportLinkArray:any =[];
  matterportTableHeader= new virtualTourListTableDataDC().tblHeader;
  userRenderScale: number = 0;

  constructor(
    private behaviourSubjectService: BehaviorSubjectService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private mapViewerJobFeatureService: MapViewerJobFeaturesService,
    private spinnerService: NgxSpinnerService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private mapViewerService: MapViewerService,
    private dashboardservice: DashboardService,
    private router: Router,
    private beahviourSubjectService: BehaviorSubjectService,
    private jobservice: JobService,
    private blobService: AzureBlobStorageServiceVideo,
    private cdRef: ChangeDetectorRef,
    private commonService: CommonFunctionService,
    private commonMapService: CommonMapService,
    private restService: ConnectionService,
    private renderer: Renderer2,
    private permissionService: PermissionService,
    private mapEditService:MapEditService,
    private metadataService:MetadataService,
    private renderScaleService:RenderScaleService
  ) {

    this.updateFeatureDescriptionFormGenerate();
    this.printFormGenerate();

     /**Print subscription start */
     this.counter = 0;
     this.printTypeSubscription = this.behaviourSubjectService.printTriggerHeader.subscribe((printType) => {
     // console.log(printType, '000000000000')
      this.printType = printType;
      if(this.printType =='print'){
         this.onPrintShowPrintBox(this.currentEvent);
         this.printState = false;
         if(this.counter == 0){
           this.counter = 1;
         }
       }else{
        this.counter = 0;
       }
     });
    /**Print subscription end */


    this.importedContainer = this.blobService.importedContainer;
    this.importedContainerSAS = this.blobService.importedContainerSAS;
    this.dprFileDownloadSAS = this.blobService.dprFilesContainerSAS;
    this.dprFileSWAPSDownloadSAS = this.blobService.dprFileSWAPSDownloadSAS;
    // this.sas = this.importedContainerSAS;

    this.externalContentForm = this.fb.group({
      linkExternal: new FormControl('', Validators.compose([Validators.pattern(this.commonService.urlHttpValidation), Validators.required])),
      WANumber: new FormControl('', Validators.compose([Validators.maxLength(30), Validators.pattern(this.commonService.alphaNumericPattern)])),
      displayName: new FormControl('', Validators.compose([Validators.maxLength(30), Validators.required])),
      editEnable: [false],
      featureId: [0],
      featureDetailEdit: [''],
      featureGroup: [''],
      fileExternal: ['']
    });
    this.externalContentFormOriginal = this.externalContentForm.value;
    this.myPreferenceSubscription = this.behaviourSubjectService.myPreferencesObservable.subscribe((Obj) => {
      this.IsMeasurmentToolActive = Obj.MEASUREMENT_TOOLS;
      if (this.IsMeasurmentToolActive === 'FALSE') {
        this.drawOptions.controls = {
          polygon: false,
          line_string: false,
          // point: false,
          trash: false,
        }
      } else {
        this.drawOptions.controls = {
          polygon: true,
          line_string: true,
          // point: true,
          trash: true,
        }
      }
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.MapviewerSiteForm = this.fb.group({
      mapType: 'satellite-v9',
    });
    this.firstcordinate = [this.lng, this.lat];
    this.featureTypeStyleMap = new Map(
      JSON.parse(sessionStorage.featureTypeStyleMap)
    );
    this.pointFeaturesNameArray = JSON.parse(
      sessionStorage.pointFeaturesNameArray
    );

    this.pointFeaturesIconsAvailableSet = new Set(this.pointFeaturesNameArray);

    this.headerLayerButtonStateSubscription =
      this.beahviourSubjectService.headerLayerButtonClickStateObservable.subscribe(
        (val) => {
          if (this.map?.getZoom() >= 14) {
            if (val != null) {
              if (this.slideMenuOpenLayerDetails) {
                this.slideMenuOpenLayerDetails = false;
              } else {
                this.slideMenuOpenJobDescription = false;
                this.slideMenuOpenTools = false;
                this.slideMenuOpenFolder = false;
                this.slideMenuOpenLayerDetails = true;
              }
            }
          } else {
            if (val != null) {
              if (this.showHeaderLayerZoomWarning) {
                this.toastr.warning(
                  'Layers become available once features are visible. Please zoom in to view.',
                  '',
                  {
                    timeOut: 1500,
                  }
                );
              } else {
                this.showHeaderLayerZoomWarning = true;
              }
            }
          }
        }
      );
    this.headerFolderButtonStateSubscription =
      this.beahviourSubjectService.headerFolderButtonClickStateObservable.subscribe(
        (val) => {
          if (val != null) {
            if (this.slideMenuOpenFolder) {
              this.slideMenuOpenFolder = false;
            } else {
              this.slideMenuOpenJobDescription = false;
              this.slideMenuOpenTools = false;
              this.slideMenuOpenLayerDetails = false;
              this.slideMenuOpenFolder = true;
            }
          }
        }
      );
    this.headerMapToolButtonStateSubscription =
      this.beahviourSubjectService.headerMapToolButtonClickStateObservable.subscribe(
        (val) => {
          if (val != null) {
            if (this.slideMenuOpenTools) {
              this.slideMenuOpenTools = false;
            } else {
              this.slideMenuOpenJobDescription = false;
              this.slideMenuOpenTools = true;
              this.slideMenuOpenLayerDetails = false;
              this.slideMenuOpenFolder = false;
            }
          }
        }
      );
    this.slideMenuOpenFolder = false;
    this.slideMenuOpenTools = false;
    this._listeningToCommitChangesForRevertOrSave();
  }

  private _resetCommitChanges() {
    this.mapEditService.mapEditorChangesToBeCommitted = [];
    this.mapEditService.updateChangesCount();
  }

  private _listeningToCommitChangesForRevertOrSave() {
    this.commitChangesSubscription = this.mapEditService.committedChangesRevertOrSaveList$.subscribe((commitChanges)=>{
      if(commitChanges.hasOwnProperty("save") && commitChanges?.data?.length) {
        this.isRefreshRequiredForCommittedChange = commitChanges.isRefreshRequired;
        this._saveCommitedChanges(commitChanges);
      } else if(commitChanges.hasOwnProperty("revert") && commitChanges?.data?.length) {
        this._revertFeature(commitChanges);
      }
    })
  }

  private _saveCommitedChanges(commitChanges: any) {
      this.changesTobeCommitted = [];
      this.isSingleFeatureSave = commitChanges.data?.length > 1 ? false : true;
      this.changesTobeCommitted = [...commitChanges.data];
      this._saveMultipleCommits([...this.changesTobeCommitted]);
  }

  private _revertFeature(commitChanges:any) {
    this.changesTobeReverted = [];
      if (confirm('Are you sure you want to Revert?')) {
        this.changesTobeReverted = [...commitChanges.data];
        [...commitChanges.data].forEach(({layer, group, type, feature}:any, index:any) => {
          setTimeout(() => {
            this._cancelSingleFeature(feature.featureObj.properties, layer.layerName);
            if(index === (commitChanges?.data?.length - 1) && commitChanges.isRefreshRequired) {
              this._refreshAfterSave();
            }
          }, 300);
        });
      }
  }

  private async _saveMultipleCommits(commitChanges:any[]) {
    if (confirm('Are you sure you want to save?')) {
      const length = commitChanges.length;
      let index = 0;
      for (const {layer, group, type, feature} of commitChanges) {
        index++;
        this._mapIDsToFeature(feature.featureObj?.properties);
        this._saveAndDeleteNewlyAddedFeature(feature.featureObj);
        await this.waitforme(100);
        if(index === length) {
          setTimeout(() => {
            if(this.isRefreshRequiredForCommittedChange){
              this._refreshAfterSave();
            } else {
              this._removeCommitChanges();
            }
          }, 500);
        }
      }
    }
  }

  private _mapIDsToFeature(feature: any) {
    feature['mapJobId'] = this.navigatedJobId ? this.navigatedJobId :  feature.mapJobId;
    feature['layerId'] = this.getLayerID(this.editLayerName) ? this.getLayerID(this.editLayerName) : feature?.layerId;
  }

  private _saveAndDeleteNewlyAddedFeature(saveFeature: any) {
    this.saveFeature(saveFeature);
    // Remove the drawing feature from the map
    this.draw.delete(saveFeature.id);
    this.newlyAddedFeature?.splice(this.newlyAddedFeature.findIndex(it => it.properties.featureName === saveFeature.properties.featureName), 1);
    // Unhide the saved feature
    this.unhideEditingFeature(saveFeature);
  }

  private _refreshAfterSave() {
    this.navigatedFeatures = null;
    this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
      this.navigatedFeatures = dataWithLayer;
      for (const featureData of dataWithLayer) {
        if (!this.isBasemapToggle) {
          this.setValuesInRequiredMaps(featureData);
        } else {
          this.isBasemapToggle = false;
        }
      }
      this.addNewFeatureInExisting();
      this.refreshMapStyle();
      this._removeCommitChanges();
    });
  }

  private _removeCommitChanges() {
    if (this.changesTobeCommitted?.length) {
      this.mapEditService.committedChangesCountAndSaveSub.next({ remove: true, data: this.changesTobeCommitted });
    } else {
      this.mapEditService.addOrUpdateCommitedChanges([]);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

  }

  //   @HostListener('document:keydown.delete', ['$event'])
  // onDeleteComponent(event: KeyboardEvent) {

  // }

  ngOnInit(): void {
    this._resetCommitChanges();
    this.featureGroupDataBind();
    this.onResize();


    let cdate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.currentDate = cdate.getDate() + " " + monthNames[cdate.getMonth()] + " " + cdate.getFullYear();

    this.exportForm = this.fb.group({
      filetype: 1,
      filename: ['', Validators.required],
    });

    this.behaviourSubjectService.credentialessLoginObservable.subscribe((credentialessLogin:CredentialessLogin) => {
      this.credentialessLogin = credentialessLogin;
      if (this.credentialessLogin?.guid) {
        this.isCredentialessLogin = true;
      } else {
        this.isCredentialessLogin=false;
      }
    });

    this.importGISForm = this.fb.group({
      importSelectFormControl: ['', Validators.required]
    });

    this.cadCustomForm = this.fb.group({
      cadFeatures: this.fb.array([]),
      coordinateCRSName: new FormControl(),
    });
    if (sessionStorage.getItem('loginUserId')) {
      this.loginUserId = sessionStorage.getItem('loginUserId');
    }
    if (sessionStorage.getItem('loginUserEmailId')) {
      this.loginUserEmailId = sessionStorage.getItem('loginUserEmailId');
    }
    if (sessionStorage.getItem('loginUserRole')) {
      //Project Manager,Admin
      this.loginUserRole = sessionStorage.getItem('loginUserRole');
    }
    if (sessionStorage.getItem('tierId')) {
      //Project Manager,Admin
      this.loginUserTierId = sessionStorage.getItem('tierId');
    }
    // this.loginUserEmailId = 'nitin.rajput@gprsinc.com';
    let emailCheck = this.loginUserEmailId?.split('@');
    if (
      (emailCheck !== undefined  && emailCheck[1] === 'gprsinc.com') ||
      this.loginUserRole === 'Admin' ||
      this.loginUserRole === 'Project Manager'
    ) {
      // for gprs employee, Admin or PM
      this.isGprsLayerCanEdit = false;
      this.isClientLayerCanEdit = false;
      this.isExternalLayerCanEdit = true;
    } else {
      this.isGprsLayerCanEdit = false;
      this.isClientLayerCanEdit = true;
      // if (this.loginUserTierId == 2 && this.loginUserRole.toLowerCase() == 'client') {
      this.isExternalLayerCanEdit = false;
    }
    if(!this.isCredentialessLogin && this.loginUserRole.toLowerCase() === "admin") {
      this.isReferenceLayerCanEdit = true;
      this.isImportedLayerCanEdit = true;
    } else {
      this.isReferenceLayerCanEdit = false;
      this.isImportedLayerCanEdit = false;
    }
    this.spinnerService.show();
    this.routeParamSubscription = this.route.params.subscribe((params) => {

      if (params.guid !== undefined){
        // redirected here after logging in via a credentialess link
        this.credentialessLogin = {userLoggedIn:false, userHasAccessToJob:false};
        this.credentialessLogin.guid = params.guid?.toString();
        if (this.loginUserId) {
          // we have a logged in user
          this.credentialessLogin.userLoggedIn=true;
        }
        this.behaviourSubjectService.changeCredentialessLogin(this.credentialessLogin);
        this.isCredentialessLogin = true;
      }
      this.navigatedJobId = params.id;

      // if the jobid is in the session assignedSharedJobs list, then remove it from the list.
      let sessionAssignedShared = JSON.parse(sessionStorage.getItem("assignedSharedJobs") || '{}');
      sessionAssignedShared.shared = (sessionAssignedShared.shared || []).filter((job:any)=> {return this.navigatedJobId === job.jobId});
      sessionStorage.setItem("assignedSharedJobs", JSON.stringify(sessionAssignedShared));


      this.validateJobAccess();
      this.zoomedJobIds.push(parseInt(params.id));
      this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
        .fetchFeaturesByJobId(this.navigatedJobId!, this.loginUserId)
        .subscribe((dataWithLayer: any) => {
          if (dataWithLayer.length != 0) {
            this.navigatedJobName = dataWithLayer[0].jobName;
            this.centerCoordinatesForMap.push(
              parseFloat(dataWithLayer[0].geometry.coordinates[0])
            );
            this.centerCoordinatesForMap.push(
              parseFloat(dataWithLayer[0].geometry.coordinates[1])
            );
            // let featuresData = dataWithLayer[0].features;
            this.navigatedJobFeatureGeoJson = {
              type: 'FeatureCollection',
              features: [],
            };

            this.zoomedJobIdLayerIdMap.set(this.navigatedJobId!.toString(), []);
            this.commonMapService.getExternalContent(this.navigatedJobId, this.loginUserId).subscribe((item: any) => {
              var params={};
              if (this.isCredentialessLogin) {
                params = {
                  "jobIds": this.zoomedJobIds,
                  "guid": this.credentialessLogin?.guid
                };
              } else {
                params = {
                  "jobIds": this.zoomedJobIds,
                  "userId": this.loginUserId
                };
              }
              this.commonMapService.getSiteContent(params).subscribe((siteItem:any)=>{
                if (item) {
                  if (dataWithLayer.length === 5) {
                    dataWithLayer.splice(4, 1);
                    dataWithLayer.splice(3, 1);
                  }
                  if (dataWithLayer.length === 4) {
                    dataWithLayer.splice(3, 1);
                  }
                  dataWithLayer = [...dataWithLayer, ...item];
                }
                if (siteItem) {
                  siteItem[0].layerName = "SITE";
                  dataWithLayer = [...siteItem, ...dataWithLayer];
                }
                // const temp = Object.entries(this.jobsD);
                // dataWithLayer = [...this.jobsD, ...dataWithLayer];

                this.navigatedFeatures = dataWithLayer;
                this.createMap();

                this.pageLoadFunctionCall();
              });
            });

            if (this.navigatedJobFeatureGeoJson.features.length > 0) {
              if (
                this.navigatedJobFeatureGeoJson.features[0].geometry.type ===
                'LineString'
              ) {
                this.centerCoordinatesForMap = this.navigatedJobFeatureGeoJson
                  .features[0].geometry.coordinates[0] as any;
              } else {
                this.centerCoordinatesForMap = this.navigatedJobFeatureGeoJson
                  .features[0].geometry.coordinates as any;
              }
            }
            // this.jobDocumentsListCall(); // Comment Due to map is not created initial and throw error
            this.spinnerService.hide();
          } else {
            if (this.isCredentialessLogin) {
              this.credentialessLogin.userHasAccessToJob =false;
              this.behaviourSubjectService.changeCredentialessLogin(this.credentialessLogin);
            }

            // client user does not have access to the job.  If this is a credentialess login redirect then try to get the features without the user permission.
             this.mapViewerJobFeatureService.fetchFeaturesByJobId(this.navigatedJobId!, this.loginUserId).subscribe((dataWithLayer:any) => {});
          }
        });
    });

    this.searchInputSubscription =
      this.behaviourSubjectService.headerMapSearchStringObservable.subscribe(
        (searchString) => {
          this.searchStringInput = searchString;
        }
      );
    this.searchTypeSubscription =
      this.behaviourSubjectService.headerMapSearchTypeObservable.subscribe(
        (searchType) => {
          this.selectedSearchType = searchType;
        }
      );
    this.headerSearchSubscrition =
      this.behaviourSubjectService.isHeaderMapSearchObservable.subscribe(
        (result) => {
          this.isHeaderSearch = result;
          const latlong = this.searchStringInput.split(',');
          this.latitudeSearhStringInput = latlong[0];
          this.longitudeSearhStringInput = latlong[1];
          if (this.isHeaderSearch) {
            if (this.selectedSearchType == 4) {
              if (this.searchMarker) {
                this.searchMarker.remove();
              }
              if (this.commonService.checkRangeValueForLatLong(this.latitudeSearhStringInput, 'lat')
                && this.commonService.checkRangeValueForLatLong(this.longitudeSearhStringInput, 'long')) {
                if (this.searchMarker) {
                  this.searchMarker.remove();
                }
                this.searchJobsData();
              } else {
                alert(
                  'Latitude in range between -90 to 90 & Longitude in range between -180 to 180'
                );
              }
            } else {
              if (this.searchMarker) {
                this.searchMarker.remove();
              }
              this.searchJobsData();
            }
          }
        }
      );

    this.emailFormControlSubscription = this.emailFormControl.valueChanges.subscribe((value) => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchedEmails = [];
        this.tierIDData = [];
        this.allowShare = false;
        if (value != "" && !this.fetchedEmails.includes(value)) {
          this.dashboardSubscribe = this.dashboardservice.fetchEmails(value).subscribe((data) => {
            if (data.length != 0) {
              data.forEach((val: any, key) => {
                this.fetchedEmails.push(val.emailId);
              });

            }
            if (this.fetchedEmails.length != 0) {
              this.shouldFetchedEmailsBeDisplayed = true;
            } else {
              this.shouldFetchedEmailsBeDisplayed = false;
            }
          })

        } else {
          this.fetchedEmails = [];

          this.shouldFetchedEmailsBeDisplayed = false;
        }
      }, 1750)
    });
    this.accessFormControl.patchValue('1');
    this.viewExternalComponentsByJobId();

    this.behaviourSubjectService.updateMapIntegrationCenterAndZoomLevelData(this.map?.getZoom());
    this.commonMapService.mapToolTitleChange();
    this.currentRenderScale = sessionStorage.getItem("userRenderingScale");
    this.userRenderScale = this.renderScaleService.getMinLevelForScale();
  }


  ngAfterViewInit(): void {
    if (
      !sessionStorage.getItem('loginCheck') &&
      sessionStorage.getItem('profileIncomplete') &&
      sessionStorage.getItem('profileIncomplete') === 'true'
    ) {
      sessionStorage.removeItem('profileIncomplete');
      let button = document.getElementById(
        'btnModalProfile'
      ) as HTMLButtonElement;
      button.click();
    }
    let print: any = document.querySelector(
      '.mapboxgl-export-control'
    ) as HTMLButtonElement;
    print?.setAttribute('title', 'Print');

    setTimeout(() => {
      this.commonMapService.mapToolTitleChange();
    }, 3000);
  }

  fetchMapFeatures() {
    this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
        .fetchFeaturesByJobId(this.navigatedJobId!, this.loginUserId)
        .subscribe((dataWithLayer: any) => {
          if (dataWithLayer.length != 0) {
            this.navigatedJobName = dataWithLayer[0].jobName;
            this.centerCoordinatesForMap.push(
              parseFloat(JSON.parse(dataWithLayer[0].geometry).coordinates[0])
            );
            this.centerCoordinatesForMap.push(
              parseFloat(JSON.parse(dataWithLayer[0].geometry).coordinates[1])
            );
            // let featuresData = dataWithLayer[0].features;
            this.navigatedJobFeatureGeoJson = {
              type: 'FeatureCollection',
              features: [],
            };

            this.zoomedJobIdLayerIdMap.set(this.navigatedJobId!.toString(), []);
            this.commonMapService.getExternalContent(this.navigatedJobId, this.loginUserId).subscribe((item: any) => {
              var params={};
              if (this.isCredentialessLogin) {
                params = {
                  "jobIds": this.zoomedJobIds,
                  "guid": this.credentialessLogin?.guid
                };
              } else {
                params = {
                  "jobIds": this.zoomedJobIds,
                  "userId": this.loginUserId
                };
              }

              this.commonMapService.getSiteContent(params).subscribe((siteItem:any)=>{
                if (item) {
                  if (dataWithLayer.length === 5) {
                    dataWithLayer.splice(4, 1);
                    dataWithLayer.splice(3, 1);
                  }
                  if (dataWithLayer.length === 4) {
                    dataWithLayer.splice(3, 1);
                  }
                  dataWithLayer = [...dataWithLayer, ...item];
                }
                if(siteItem) {
                  siteItem[0].layerName = "SITE";
                  dataWithLayer = [...siteItem,...dataWithLayer];
                }
                // const temp = Object.entries(this.jobsD);
                // dataWithLayer = [...this.jobsD, ...dataWithLayer];
                console.log("dataWithLayer: ", dataWithLayer);
                this.navigatedFeatures = dataWithLayer;
                this.createMap();
                this.pageLoadFunctionCall();
              });
            });

            if (this.navigatedJobFeatureGeoJson.features.length > 0) {
              if (
                this.navigatedJobFeatureGeoJson.features[0].geometry.type ===
                'LineString'
              ) {
                this.centerCoordinatesForMap = this.navigatedJobFeatureGeoJson
                  .features[0].geometry.coordinates[0] as any;
              } else {
                this.centerCoordinatesForMap = this.navigatedJobFeatureGeoJson
                  .features[0].geometry.coordinates as any;
              }
            }
            this.jobDocumentsListCall();
            this.spinnerService.hide();
          } else {
            let button = document.getElementById("btnModalIdle") as HTMLButtonElement;
            button.click();
          }
        });
  }

  //job document list call method is used to get document all list related to jobid using fetchJobDocumentsDetails api
  jobDocumentsListCall() {
    var payLoad:any = {};



    if (this.fileTableData?.length == 0) {
      this.spinner.show();
        this.jobDocumentsListApiLoaded = false;
        if (this.isCredentialessLogin) {
            payLoad = {
                jobId: this.navigatedJobId,
                guid: this.credentialessLogin?.guid
            };
        } else {
            payLoad = {
                jobId: this.navigatedJobId,
                userId: this.loginUserId
            };
        }


      this.mapViewerDocumentsSubscribe = this.mapViewerService
        .fetchJobDocumentsDetails(payLoad)
        .subscribe((response: any) => {
          this.jobDocumentsListApiLoaded = true;
          this.jobDocumentsList = response;
          // this.setDocumentImageOnMap();
          this.fileTableData = response;
          if (!response) {
            // null returned, set the filetable data to empty array
            this.fileTableData = [];
          }
          this.fileTableData?.forEach((it) => {
            it['action'] = new fileListTableDataDC().actionButton;
            it['isEyeVisible'] = true;
            if (it.objectTypeName === "Folder") {
              it["nameIcon"] = 'fa-solid fa-folder';
            }
            else {
              it["nameIcon"] = 'fa-sharp fa-solid fa-file';
              it['docClick'] = '';
            }
          }, (err: any) => {
            this.toastr.error("Job Documents error: " + err.status, "", { timeOut: 3000 });
          });

          this.setDocumentImageOnMap();
          this.spinner.hide();
        }, (err) =>{
          this.jobDocumentsListApiLoaded = true;
          this.spinner.hide();
        });
    }
  }

  setDocumentImageOnMap(){
    let dprIconFile = this.jobDocumentsList?.filter((data: any)=> {
       return  ((data.name.split('.').pop()).toLowerCase() !== 'jpg' && (data.name.split('.').pop()).toLowerCase() !== 'png' && (data.name.split('.').pop()).toLowerCase() !== 'jpeg')
    });
    if (dprIconFile && dprIconFile.length > 0) {
      if (this.map.getLayer('sourceDocumentLayer') != undefined) {
        this.map.removeLayer('sourceDocumentLayer');
      }
      if (this.map.getSource('sourceDocument') != undefined) {
        this.map.removeSource('sourceDocument');
      }
      let temp: any = { type: 'FeatureCollection', features: [] };
      for (let i = 0; i < dprIconFile.length; i++) {
        const jobCoordinates = [dprIconFile[i].longitude,dprIconFile[i].latitude];
        temp.features.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': jobCoordinates
          },
          'properties': {
            'documentName': dprIconFile[i].name,
            'docName': dprIconFile[i].name.replace(/\s/g, '')
          }
        });
      }
      this.map.loadImage(
        `../../../../../../assets/images/GPRS_PNG/ico_file_on_map.png`,
        (error, image: any) => {
          let iconExits = this.map.hasImage('doc_map_icon');
          if (iconExits) return;
          this.map.addImage('doc_map_icon', image);
          if (error) {
            console.log('already exist' + error);
          }
        }
      );
      if (this.map.getSource('sourceDocument') === undefined) {
        this.map.addSource(`sourceDocument`, {
          type: 'geojson',
          data: temp
        });
      }
      if (this.map.getLayer('sourceDocumentLayer') === undefined) {
        this.map.addLayer({
          id: `sourceDocumentLayer`,
          type: 'symbol',
          source: `sourceDocument`,
          minzoom: 13.25,
          maxzoom: 23,
          layout: {
            'icon-image': 'doc_map_icon', // reference the image
            'icon-size': 1,
          }
        });
      }
      this.map.on('mouseenter', 'sourceDocumentLayer', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'sourceDocumentLayer', () => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('click', 'sourceDocumentLayer', (e: any) => {
        this.slideMenuOpenTools = false;
        this.slideMenuOpenLayerDetails = false;
        this.slideMenuOpenJobDescription = false;
        this.slideMenuOpenFolder = true;
        this.onHistorySlideoutClose(false);
        this.clickedDocFeatures = this._getClickedDoc(e);
        let temp = this.fileTableData.find((it:any)=> it.name == this.clickedDocFeatures[0].properties.documentName);
        if(this.clickedDocFeatures.length > 0 && temp) {
          temp.docClick = 'text-primary';
        }
      });
    }
  }

  _getClickedDoc(e:any){
    const bboxPadding = 3;
    let bbox : any = [];
    if(e.point){
      bbox = [
        [e.point.x - bboxPadding, e.point.y - bboxPadding],
        [e.point.x + bboxPadding, e.point.y + bboxPadding],
      ];
    }
    else {
      bbox = [
        [e.attributes.coordinate.long - bboxPadding, e.attributes.coordinate.lat - bboxPadding],
        [e.attributes.coordinate.long + bboxPadding, e.attributes.coordinate.lat + bboxPadding],
      ];
    }

    // Find features intersecting the bounding box.
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(bbox, {
      layers: ['sourceDocumentLayer'],
    });

    return clickedFeatures;
  }

  //loadAllJobsLayerFunctionalities method is used to load all data related to the screen like layer, source, create map etc
  loadAllJobsLayerFunctionalities() {
    this.addAllJobsSource();
    if (
      this.loginUserRole == 'Admin' ||
      this.loginUserRole == 'Project Manager'
    ) {
      this.addAllJobsLayerInMap();
      this.mapClickInputFunction();
      this.mouseEnterOnMap();
      this.mouseLeaveOnMap();
      this.zoomOnMap();
      this.addAllIconsInMap();
    }
  }

  //addAllIconsInMap method is used to add icon with feature name on the map
  addAllIconsInMap() {
    for (let i = 0; i < this.pointFeaturesNameArray.length; i++) {
      this.map.loadImage(
        `../../../../../../assets/images/GPRS_PNG/${this.pointFeaturesNameArray[i]}.png`,
        (error, image: any) => {
          if (error) {
            this.pointFeaturesImagesNotPresent.push(
              this.pointFeaturesNameArray[i]
            );
          }
          if(!this.map.hasImage(this.pointFeaturesNameArray[i])){
            this.map.addImage(this.pointFeaturesNameArray[i], image);
          }
        }
      );
    }
  }

  //addAllJobsSource method is used to add source in map
  addAllJobsSource() {
    if (
      this.loginUserRole != 'Admin' &&
      this.loginUserRole != 'Project Manager'
    ) {
      this.spinner.show();
      let userInfo = { userId: this.loginUserId };
      this.getAllJObSubscribe = this.dashboardservice
        .getAllJobLatLong(userInfo)
        .subscribe(
          (response) => {
            this.spinner.hide();
            this.apiAllJobResponse = response;

            this.geoJsonFromResponse = {
              type: 'FeatureCollection',
              features: this.apiAllJobResponse.features,
            };
            if(this.map.getSource('allJobs') === undefined) {
              this.map.addSource('allJobs', {
                type: 'geojson',
                data: this.geoJsonFromResponse,
              });
            }
            this.addAllJobsLayerInMap();
            this.mapClickInputFunction();
            this.mouseEnterOnMap();
            this.mouseLeaveOnMap();
            this.zoomOnMap();
            this.addAllIconsInMap();
            this.addVectorTilesLayersAndSourceNew();

            this.addGroupLayers();
            this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      if(this.map.getSource('allJobs') === undefined) {
        this.map.addSource('allJobs', {
          type: 'geojson',
          data: PortalAPI.DATA_SOURCE,
        });
      }

      this.addVectorTilesLayersAndSourceNew();
    }
  }

  //addVectorTilesLayerAndSource method is used to Add the source for all line and point for features
  addVectorTilesLayerAndSource() {
      // Add the source for all line features
      // Set the "mapJobId" as the "feature id"
      this.map.addSource('pgtiles-custom-lines', {
        type: 'vector',
        tiles: [ PortalAPI.CUSTOME_LINES ],
        promoteId: 'featureid',
      });

      // Add the source for all point features
      // Set the "mapJobId" as the "feature id"
      this.map.addSource('pgtiles-custom-points', {
        type: 'vector',
        tiles: [ PortalAPI.CUSTOME_POINTS ],
        promoteId: 'featureid',
      });

      this.map.addSource('pgtiles-custom-annotation-points', {
        type: 'vector',
        tiles: [ PortalAPI.CUSTOME_ANNOTATION_POINTS ],
        promoteId: 'featureid',
      });

      this.map.addSource('pgtiles-custom-annotation-lines', {
        type: 'vector',
        tiles: [ PortalAPI.CUSTOME_ANNOTATION_LINES ],
        promoteId: 'featureid',
      });


    this.map.addLayer({
      id: 'pgtiles-custom-annotation-lines-layer',
      type: 'line',
      source: 'pgtiles-custom-annotation-lines',
      'source-layer': 'default',
      minzoom: 13.25,
      maxzoom: 23,
      layout: {
        visibility: 'visible',
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#00FF00',
        'line-width': 2,
      },
    });

    this.map.addLayer({
      id: 'pgtiles-custom-annotation-points-layer',
      type: 'circle',
      source: 'pgtiles-custom-annotation-points',
      'source-layer': 'default',
      minzoom: 13.25,
      maxzoom: 23,
      paint: {
        'circle-color': '#00FF00',
        'circle-radius': 4,
      },
    });
  }

  //addVectorTilesLayersAndSourceNew method is used to Add the source and layer for all line and point for features
  addVectorTilesLayersAndSourceNew() {
    let url = null;

    for (const appLayerId of [1, 2, 3]) {
      let currentLineSourceId = `${appLayerId}-active-lines-annotations-by-layerid`;
      let currentPointSourceId = `${appLayerId}-active-points-annotations-by-layerid`;
      let currentPhotoSourceId = `${appLayerId}-active-photos-annotations-by-layerid`;
      this.commonMapService.setSourceForVectorTiles(this.map, currentLineSourceId, appLayerId, 'line');
      this.commonMapService.setSourceForVectorTiles(this.map, currentPointSourceId, appLayerId, 'point');
      this.commonMapService.setSourceForVectorTiles(this.map, currentPhotoSourceId, appLayerId, 'external');
    }
  }


  //addAllJobsLayerInMap method is used to add layer for all unclucstered point
  addAllJobsLayerInMap() {
    let visibility: any = 'visible';
    if (this.map.getZoom() > 14) {
      visibility = 'none';
    }
    if(this.map.getLayer('unclustered-point') === undefined) {
    this.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'allJobs',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#11b4da',
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
      },
      layout: {
        visibility: visibility,
      },
    });
  }
  }

  //mapClickInputFunction method is used to show the job detail popup when user click on blue dot
  mapClickInputFunction() {
    if (this.screenWidth <= 397) {
      this.map.on('touchend', 'unclustered-point', (e: any) => {
        var errCoordinate: any[2] = [];
        //const coordinates = e.features[0].geometry.coordinates.slice();
        this.clickedJobItemId = +e.features[0].properties.jobId;
        if (!this.isCredentialessLogin) {
          this.dashboardservice.fetchSharedWithUserList(this.clickedJobItemId, 1).subscribe((data) => {
            this.objSharedUserList = data;
          });
        }

        //this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
        this.spinner.show();
        this.mapViewerSubscribe = this.mapViewerService
          .fetchJobDetails(this.clickedJobItemId!, this.loginUserId)
          .subscribe(
            (jobDetailData: any) => {
              // this.mapViewerService.fetchJobDetails(this.clickedJobItemId!).subscribe((jobDetailData: any) => {
              // const jobName = jobDetailData.data.jobName;
              // const jobName =
              //   jobDetailData?.data?.groups?.features[0]?.attributes
              //     ?.workorderNumber +
              //   ' - ' +
              //   jobDetailData?.data?.groups?.features[0]?.attributes
              //     ?.customerName;
              const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.features[0]?.attributes?.customerName;

              this.clickedJobName = jobName;
              var siteContact = '';
              var email = '';
              if (jobDetailData?.features[0] != null) {
                siteContact = jobDetailData?.features[0]?.attributes.siteContact;
                email = jobDetailData?.features[0]?.attributes.email;
              }
              const lat = jobDetailData?.features[0]?.attributes.latitude;
              const long = jobDetailData?.features[0]?.attributes.longitude;
              const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
              const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

              const coordinates: any[2] = [long, lat];
              errCoordinate = [long, lat];
              this.clickedJobItemCoordinates = coordinates;
              this.isJobOwner = jobDetailData?.features[0]?.attributes.isShared; //isAssigned;
              if (!this.isJobOwner) {  // not assinged (not owner)
                this.isJobOwner = false;
              } else {
                this.isJobOwner = true;
              }

              if (this.loginUserRole == 'Admin' || this.loginUserRole == 'Project Manager') {
                this.isJobOwner = true;
              }

              const description = `
    <div class="custom-map-header">
      <h2>${jobName}</h2>
    </div>
    <div class="custom-map-tooltip">
      <div class="table-responsive">
        <table class="table table-bordered table-striped custom-table">
          <tr>
            <td>Location</td>
            <td>${coordinates[1]},${coordinates[0]}</td>
          </tr>
          <tr>
            <td>Site Contact</td>
            <td>${siteContact}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>${email}</td>
          </tr>
          <tr>
            <td>Job ID</td>
            <td>${netsuiteJobId}</td>
          </tr>
          <tr>
            <td>PO Number</td>
            <td>${netsuitePoNumber}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `;

              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              this.spinner.hide();

              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(this.map);
            },
            (error: any) => {
              const description = `
      <div class="custom-map-header">
        <h2>Error occurred while fetching data for this job</h2>
      </div>
      <div class="custom-map-tooltip">
      </div>
      <div class="custom-map-footer">
        <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
        <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
      </div>
    `;
              new mapboxgl.Popup()
                .setLngLat(errCoordinate)
                .setHTML(description)
                .addTo(this.map);
              this.spinner.hide();
            }
          );
      });
    } else {
      this.map.on('click', 'unclustered-point', (e: any) => {
        var errCoordinate: any[2] = [];
        //const coordinates = e.features[0].geometry.coordinates.slice();
        this.clickedJobItemId = +e.features[0].properties.jobId;
        if (!this.isCredentialessLogin) {
          this.dashboardservice.fetchSharedWithUserList(this.clickedJobItemId, 1).subscribe((data) => {
            this.objSharedUserList = data;
          });
        }

        //this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
        this.spinner.show();
        this.mapViewerSubscribe = this.mapViewerService
          .fetchJobDetails(this.clickedJobItemId!, this.loginUserId)
          .subscribe(
            (jobDetailData: any) => {
              // this.mapViewerService.fetchJobDetails(this.clickedJobItemId!).subscribe((jobDetailData: any) => {
              // const jobName = jobDetailData.data.jobName;
              // const jobName =
              //   jobDetailData?.data?.groups?.features[0]?.attributes
              //     ?.workorderNumber +
              //   ' - ' +
              //   jobDetailData?.data?.groups?.features[0]?.attributes
              //     ?.customerName;
              const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.features[0]?.attributes?.customerName;

              this.clickedJobName = jobName;
              var siteContact = '';
              var email = '';
              if (jobDetailData?.features[0] != null) {
                siteContact = jobDetailData?.features[0]?.attributes.siteContact;
                email = jobDetailData?.features[0]?.attributes.email;
              }
              const lat = jobDetailData?.features[0]?.attributes.latitude;
              const long = jobDetailData?.features[0]?.attributes.longitude;
              const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
              const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

              const coordinates: any[2] = [long, lat];
              errCoordinate = [long, lat];
              this.clickedJobItemCoordinates = coordinates;

              this.isJobOwner = jobDetailData?.features[0]?.attributes.isShared; //isAssigned;
              if (!this.isJobOwner) {  // not assinged (not owner)
                this.isJobOwner = false;
              } else {
                this.isJobOwner = true;
              }

              if (this.loginUserRole == 'Admin' || this.loginUserRole == 'Project Manager') {
                this.isJobOwner = true;
              }

              const description = `
  <div class="custom-map-header">
    <h2>${jobName}</h2>
  </div>
  <div class="custom-map-tooltip">
    <div class="table-responsive">
      <table class="table table-bordered table-striped custom-table">
        <tr>
          <td>Location</td>
          <td>${coordinates[1]},${coordinates[0]}</td>
        </tr>
        <tr>
          <td>Site Contact</td>
          <td>${siteContact}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>${email}</td>
        </tr>
        <tr>
          <td>Job ID</td>
          <td>${netsuiteJobId}</td>
        </tr>
        <tr>
          <td>PO Number</td>
          <td>${netsuitePoNumber}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="custom-map-footer">
    <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
    <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
  </div>
`;

              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              this.spinner.hide();

              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(this.map);
            },
            (error: any) => {
              const description = `
    <div class="custom-map-header">
      <h2>Error occurred while fetching data for this job</h2>
    </div>
    <div class="custom-map-tooltip">
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `;
              new mapboxgl.Popup()
                .setLngLat(errCoordinate)
                .setHTML(description)
                .addTo(this.map);
              this.spinner.hide();
            }
          );
      });
    }
  }


  //zoomOnMap method is used to zoom in or out the map functionality or show/hide the features on zoom in/out
  zoomOnMap() {
    this.map.on('zoom', (e: any) => {
      this.previousZoomLevel = this.currentZoomLevel;
      this.currentZoomLevel = this.map.getZoom();

     // console.log("zoom level " + this.map.getZoom());

      this.behaviourSubjectService.updateMapIntegrationCenterAndZoomLevelData(this.currentZoomLevel);

      if (this.currentZoomLevel >= this.userRenderScale) {
        this.showToggleSideBar = true;
        this.mapZoomLevelGreaterThanThreshold = true;
        this.referenceGISTilesData.forEach((item: any) => {
          item.setEyeActive = false;
          this.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'none');
        });
        this.isLayerEyeVisible = {
          SITE: true,
          GPRS: true,
          CLIENT: true,
          IMPORTED: true,
          EXTERNAL: true,
          REFERENCE: false
        };
        if (this.previousZoomLevel < this.userRenderScale) {
          this.resetMapsData();
          this.slideMenuOpenLayerDetails = false;
          this.slideMenuOpenFolder = false;
          let zoomedJObIdsFeatures: any = [];
          //this.zoomedJobIds = [];
          if (this.isSearchAppliedOnJobData) {
            zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
              layers: ['search-unclustered-point'],
            });
            this.map.setLayoutProperty(
              'search-unclustered-point',
              'visibility',
              'none'
            );
          } else {
            zoomedJObIdsFeatures = this.map.queryRenderedFeatures(e.point, {
              layers: ['unclustered-point'],
            });
            this.map.setLayoutProperty(
              'unclustered-point',
              'visibility',
              'none'
            );
          }
          this.spinner.show();
          this.zoomedFeaturesObservableArray = [];
          for (let i = 0; i < zoomedJObIdsFeatures.length; i++) {
            const zoomedJobId = zoomedJObIdsFeatures[i].properties!.jobId;
            this.zoomedJobIds.push(zoomedJobId);
            this.zoomedFeaturesObservableArray.push(
              this.mapViewerJobFeatureService
                .fetchFeaturesByJobId(zoomedJobId, this.loginUserId)
                .pipe(
                  catchError((err) => {
                    console.log('error occurred');

                    //passing empty array to prevent this from execution in forkjoin
                    return of([]);
                  })
                )
            );
            this.zoomedFeaturesObservableArray.push(this.commonMapService.getExternalContent(zoomedJobId, this.loginUserId).pipe(catchError((err) => {
              console.log("error occurred");

              //passing empty array to prevent this from execution in forkjoin
              return of([]);
            })));
          }

          var params={};
          if (this.isCredentialessLogin) {
            params = {
              "jobIds": this.zoomedJobIds,
              "guid": this.credentialessLogin?.guid
            };
          } else {
            params = {
              "jobIds": this.zoomedJobIds,
              "userId": this.loginUserId
            };
          }

          this.zoomedFeaturesObservableArray.push(this.commonMapService.getSiteContent(params).pipe(catchError((err) => {
            console.log("error occurred");

            //passing empty array to prevent this from execution in forkjoin
            return of([]);
          })));
          this.addGroupLayers();
          this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
        }
        this.spinner.hide();
      } else {
        this.fileTableData = [];
        this.commonMapService.removeAllLayer(this.pointLayerIds, this.lineLayerIds, this.map);
        this.referenceGISTilesData.forEach((item: any) => {
          item.setEyeActive = true;
          this.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'visible');
        });
        this.isLayerEyeVisible = {
          SITE: true,
          GPRS: true,
          CLIENT: true,
          IMPORTED: true,
          EXTERNAL: true,
          REFERENCE: true
        };
        this.showToggleSideBar = false;  // turn off the layer menu
        this.mapZoomLevelGreaterThanThreshold = false;
        this.openedFeatureAttributes = null;

        if (this.previousZoomLevel >= this.userRenderScale) {
          //comment becuase it's breaking the functionality and bug is 3809

          // if (!this.isMapViewerJobsZoomedOut) {

          //   this.zoomedLayerIdArray.forEach((layerId: string) => {
          //
          //     if (this.map.getLayer(layerId.toString()) != undefined) {
          //       this.map.removeLayer(layerId.toString());
          //     }
          //   });
          //   if (this.map.getSource('client-data-electric-line') != undefined) {
          //     this.map.removeSource('client-data-electric-line');
          //   }
          //   this.isMapViewerJobsZoomedOut = true;
          // }
          if (this.isSearchAppliedOnJobData) {
            this.map.setLayoutProperty(
              'search-unclustered-point',
              'visibility',
              'visible'
            );
          } else {
            this.map.setLayoutProperty(
              'unclustered-point',
              'visibility',
              'visible'
            );
          }
        }
      }
      if(this.previousZoomLevel < 13.24 && this.currentZoomLevel >= 13.25)
      {
        this.setDocumentImageOnMap();
      }
    });
  }

  //setValuesInRequiredMaps method is used set the data for toggle and map binding
  setValuesInRequiredMaps(data: any) {
    let featuresData = data.features;
    for (let i = 0; i < featuresData.length; i++) {
      let feature: any = {
        type: 'Feature',
        properties: {
          featureGroupId: featuresData[i].featureGroupId,
          featureGroup: featuresData[i].featureGroup,
          featureTypeId: featuresData[i].featureTypeId,
          featureType: featuresData[i].featureType,
          featureId: featuresData[i].featureId?.toString(),
          featureName: featuresData[i].feature,
          isAnnotation: featuresData[i].isAnnotation,
          fileId: featuresData[i].fileId,
          fileName: featuresData[i].fileName,
          importedByUserId: featuresData[i].importedByUserId,
          featureGeometryType:
            featuresData[i].geometry.type === 'LineString'
              ? 'LineString'
              : 'Point',
        },
        geometry: {
          type:
            featuresData[i].geometry.type === 'LineString'
              ? 'LineString'
              : 'Point',
          coordinates: null,
        },
      };

      if (this.featureGroupMap.has(data.layerName)) {
        this.addMainFeatureData(data, feature, 'hasValue');
        if (feature.properties?.importedByUserId && (feature.properties?.importedByUserId?.toString() === this.loginUserId?.toString())) {
          // this.isImportedLayerCanEdit = true;
        }
      } else {
        this.addMainFeatureData(data, feature, null);
      }
    }

    if (data.layerName === 'IMPORTED')
    {
      const layer = this.importedLayerInfo = this.fetchLayerInfo(data.layerName);
      this.layerEvents.next({ type: 'LayerChanged', data: { layer } })
    }
  }

  //addMainFeatureData method is a part of setValuesInRequiredMaps method
  addMainFeatureData(data: any, feature: any, isValue: any) {
    let featureTypeMap = new Map();
    let featurePropertyArray: any[] = [];
    if (isValue === 'hasValue') {
      featureTypeMap = this.featureGroupMap.get(data.layerName);
      if (featureTypeMap.has(feature.properties.featureGroup)) {
        featureTypeMap = this.featureGroupMap
          .get(data.layerName)
          .get(feature.properties.featureGroup);
        if (featureTypeMap.has(feature.properties.featureType)) {
          featurePropertyArray = [] = featureTypeMap.get(
            feature.properties.featureType
          );
          if (!featureTypeMap.get(feature.properties.featureType).find((it: any) => it.featureName === feature.properties.featureName)) {
            let featureTypeArr = this.featureGroupMap.get(data.layerName);
            featurePropertyArray.push(feature.properties);
            featureTypeMap.set(
              feature.properties.featureType,
              featurePropertyArray
            );
            featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
            this.featureGroupMap.set(data.layerName, featureTypeArr);

            //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
            let temp = new Map();
            temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
              data.layerName
            );
            let toggleGroup = new Map();
            toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
              .get(data.layerName)
              .get(feature.properties.featureGroup);
            let featureNameMap = new Map();
            featureNameMap = toggleGroup.get(feature.properties.featureType);
            featureNameMap.set(feature.properties.featureName, true);
            toggleGroup.set(feature.properties.featureType, featureNameMap);
            temp.set(feature.properties.featureGroup, toggleGroup);
            this.featureGroupTypeFeatureNameToggleStateMap.set(
              data.layerName,
              temp
            );
          }
        } else {
          featurePropertyArray = [];
          let featureTypeArr = this.featureGroupMap.get(data.layerName);
          featurePropertyArray.push(feature.properties);
          featureTypeMap.set(
            feature.properties.featureType,
            featurePropertyArray
          );
          featureTypeArr.set(feature.properties.featureGroup, featureTypeMap);
          this.featureGroupMap.set(data.layerName, featureTypeArr);

          //for featureGroupTypeToggleStateMap set true for toggle
          let temp = new Map();
          let toggleGroup = this.featureGroupTypeToggleStateMap.get(
            data.layerName
          );
          temp = this.featureGroupTypeToggleStateMap
            .get(data.layerName)
            .get(feature.properties.featureGroup);
          temp.set(feature.properties.featureType, true);
          toggleGroup.set(feature.properties.featureGroup, temp);
          this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

          //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
          temp = new Map();
          temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
            data.layerName
          );
          toggleGroup = new Map();
          toggleGroup = this.featureGroupTypeFeatureNameToggleStateMap
            .get(data.layerName)
            .get(feature.properties.featureGroup);
          let featureNameMap = new Map();
          featureNameMap.set(feature.properties.featureName, true);
          toggleGroup.set(feature.properties.featureType, featureNameMap);
          temp.set(feature.properties.featureGroup, toggleGroup);
          this.featureGroupTypeFeatureNameToggleStateMap.set(
            data.layerName,
            temp
          );
        }
      } else {
        featurePropertyArray = [];
        let featureTypeArr = new Map();
        featurePropertyArray.push(feature.properties);
        featureTypeArr.set(
          feature.properties.featureType,
          featurePropertyArray
        );
        featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
        this.featureGroupMap.set(data.layerName, featureTypeMap);

        //for featureGroupToggleStateMap set true fpr toggle
        let temp = this.featureGroupToggleStateMap.get(data.layerName);
        temp.set(feature.properties.featureGroup, true);
        this.featureGroupToggleStateMap.set(data.layerName, temp);

        //for featureGroupTypeToggleStateMap set true for toggle
        temp = new Map();
        temp = this.featureGroupTypeToggleStateMap.get(data.layerName);
        let toggleGroup = new Map();
        toggleGroup.set(feature.properties.featureType, true);
        temp.set(feature.properties.featureGroup, toggleGroup);
        this.featureGroupTypeToggleStateMap.set(data.layerName, temp);

        //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
        temp = new Map();
        temp = this.featureGroupTypeFeatureNameToggleStateMap.get(
          data.layerName
        );
        toggleGroup = new Map();
        let featureNameMap = new Map();
        featureNameMap.set(feature.properties.featureName, true);
        toggleGroup.set(feature.properties.featureType, featureNameMap);
        temp.set(feature.properties.featureGroup, toggleGroup);
        this.featureGroupTypeFeatureNameToggleStateMap.set(
          data.layerName,
          temp
        );
      }
    } else {
      let featureTypeArr = new Map();
      featurePropertyArray.push(feature.properties);
      featureTypeArr.set(feature.properties.featureType, featurePropertyArray);
      featureTypeMap.set(feature.properties.featureGroup, featureTypeArr);
      this.featureGroupMap.set(data.layerName, featureTypeMap);

      //for featureGroupToggleStateMap set true for toggle
      let temp = new Map();
      temp.set(feature.properties.featureGroup, true);
      this.featureGroupToggleStateMap.set(data.layerName, temp);

      //for featureGroupTypeToggleStateMap set true for toggle
      temp = new Map();
      let toggleGroup = new Map();
      temp.set(feature.properties.featureType, true);
      toggleGroup.set(feature.properties.featureGroup, temp);
      this.featureGroupTypeToggleStateMap.set(data.layerName, toggleGroup);

      //for featureGroupTypeFeatureNameToggleStateMap set true for toggle
      temp = new Map();
      toggleGroup = new Map();
      let featureNameMap = new Map();
      featureNameMap.set(feature.properties.featureName, true);
      temp.set(feature.properties.featureType, featureNameMap);
      toggleGroup.set(feature.properties.featureGroup, temp);
      this.featureGroupTypeFeatureNameToggleStateMap.set(
        data.layerName,
        toggleGroup
      );
    }
  }

  //addGroupLayers method is used to add layer for point and line
  addGroupLayers = async (callFrom?: any) => {
    this.pointLayerIds = [];
    this.lineLayerIds = [];


    if (this.isEditingModeIsOn) {
      await this.waitforme(2000);
    }
    // this.zoomedJobIds.forEach((jobId: any) => {
    // if (callFrom?.includes('pageLoad'))
    this.loadMVT();

    if (this.isEditingModeIsOn) {
      this.gotoMapEditor(this.editLayerName);
    }

    // Get all GPRS layers from Map style
    const regex = new RegExp('^[0-4]-');
    let gprsLayerIds: any = this.map.getStyle().layers.filter(layer => regex.test(layer.id)).map(layer => layer.id);

    // Mouse events - these are turned on/off every time we refresh the tiles (Save/Delete)
    // Mouse click on Sitmap layers - handles single- and double-click
    // Do we need special handling for Draw layers?
    [...gprsLayerIds].forEach((layerId: any) => {
      if (this.screenWidth <= 397) {
        this.map.off('touchend', layerId, this.handleFirstClick);
        this.map.on('touchend', layerId, this.handleFirstClick);
      } else {
        this.map.off('click', layerId, this.handleFirstClick);
        this.map.on('click', layerId, this.handleFirstClick);
      }
    });

    // Mouseenter on Sitmap layers
    [...gprsLayerIds].forEach((layerId: any) => {
      this.map.off('mouseenter', layerId, this.handleLayerMouseEnter);
      this.map.on('mouseenter', layerId, this.handleLayerMouseEnter);
    });

    // Mouseout on Sitmap layers
    [...gprsLayerIds].forEach((layerId: any) => {
      this.map.off('mouseout', layerId, this.handleLayerMouseOut);
      this.map.on('mouseout', layerId, this.handleLayerMouseOut);
    });
  }

  loadMVT() {
    // this.reteriveRasterTilesbyJobId(this.jobId);

    this.reteriveVectorTilesbyUser();
    this.reteriveGeoReferenceImagebyUser();
    let layerIds = [1, 2, 3]; //this.checkGracePeriod([1, 2, 3], 3);
    for (const appLayerId of layerIds) {
      for (const tileType of ['line', 'point']) {

        this.loadMVTSource(appLayerId, tileType);
        // this.commonMapService.loadMVTLayerV2(this.map, appLayerId, jobId, tileType, this.pointLayerIds, this.lineLayerIds);
      }
    }

    for (const tileType of ['photo', 'matterport', 'pointcloud', 'virtualtour', 'attachment', 'externallink']) {
      this.loadMVTSource(4, tileType);
    }
  }

  loadMVTSource(appLayerId: number, tileType: string) {
    let currentSourceId: any = null;
    currentSourceId = `${appLayerId}-${tileType}`;
    this.commonMapService.setSourceForVectorTilesV2(this.map, currentSourceId, appLayerId, tileType);
  }

  //getGroupFilterExpression method is used to filter the featuregroupmap data
  getGroupFilterExpression(featureGroup: string) {
    const expression = [
      'all',
      ['==', ['get', 'featureGroup'], featureGroup],
      ['in', ['get', 'mapJobId'], ['literal', this.zoomedJobIds]],
      ['!', ['in', ['get', 'featureName'], ['literal', this.hiddenFeatures]]],
    ];
    return expression;
  }

  /*
Generate the "paint" style required for LINE based on "feature_type_id"
Returns the entire "paint" style object
"case" syntax:
["case",
condition: boolean, output: OutputType,
condition: boolean, output: OutputType,
...,
fallback: OutputType
]
See: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
*/
  getLinePaintByGroupName(lineFeatureGroupName: string) {
    // This is the return style object
    // "line-color" and "line-width" will be set using Mapbox expressions with "case" operator
    let linePaint: any = {
      'line-color': ['case'],
      'line-width': ['case'],
    };

    // Iterate over each LINE style in the style schema
    // and push "condition" and "output" to "case" expression
    // for each style based on "feature_type_name"
    FeatureTypeStyleMapping.featureTypeStyleJson
      .filter(
        (featureStyle) =>
          featureStyle.feature_group_name === lineFeatureGroupName
      )
      .forEach((featureStyle) => {
        linePaint['line-color'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-color'].push(featureStyle.color);
        linePaint['line-width'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-width'].push(featureStyle.line_width);
      });

    // "case" requires a default/fallback value
    // and they are added at the end of the expression
    linePaint['line-color'].push('rgb(255,153,255)');
    linePaint['line-width'].push(2);


    return linePaint;
  }

  getLinePaint() {
    // This is the return style object
    // "line-color" and "line-width" will be set using Mapbox expressions with "case" operator
    let linePaint: any = {
      'line-color': ['case'],
      'line-width': ['case'],
    };

    // Iterate over each LINE style in the style schema
    // and push "condition" and "output" to "case" expression
    // for each style based on "feature_type_name"
    FeatureTypeStyleMapping.featureTypeStyleJson
      .filter((feature) => feature.geometry_type == 'LINE')
      .forEach((featureStyle: any) => {
        linePaint['line-color'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-color'].push(featureStyle.color);
        linePaint['line-width'].push([
          '==',
          ['get', 'featureType'],
          featureStyle.feature_type_name,
        ]);
        linePaint['line-width'].push(featureStyle.line_width);
      });

    // "case" requires a default/fallback value
    // and they are added at the end of the expression
    linePaint['line-color'].push('rgb(255,153,255)');
    linePaint['line-width'].push(2);


    return linePaint;
  }

  searchJobsData() {
    if (this.map.getLayer('search-unclustered-point') != undefined) {
      this.map.removeLayer('search-unclustered-point');
    }
    if (this.map.getSource('searchJobs') != undefined) {
      this.map.removeSource('searchJobs');
    }
    this.featureGroupMap.clear();
    let searchPayload = {
      request: {
        pageNumber: 1,
        pageSize: 0,
      },
      searchCategoryID: this.selectedSearchType,
      //"searchValue": this.searchStringInput,
      searchValue:
        this.selectedSearchType == 4
          ? this.latitudeSearhStringInput + ',' + this.longitudeSearhStringInput
          : this.searchStringInput,
      userId: this.loginUserId,
      securableObjectTypeId: 1,
    };
    this.spinner.show();
    this.finJobsSubscription = this.jobservice
      .searchJobs(searchPayload)
      .subscribe(
        (response) => {
          this.searchJobList = response;
          this.searchJobIds = [];

          if (this.searchJobList.totalRecord === 0) {
            this.spinner.hide();
            this.toastr.error('No matching results found.');
          } else {
            this.searchedJobGeoJson = {
              type: 'FeatureCollection',
              FeatureCollectionId: null,
              Name: null,
              features: [],
            };
            const searchedJobData = this.searchJobList.allJobs;
            for (let i = 0; i < searchedJobData.length; i++) {
              this.searchJobIds.push(searchedJobData[i].jobId);
              //this.popUpOnJobSearch(this.jobList.allJobs[i]);
              const coordinate = JSON.parse(searchedJobData[i].geometry);
              const jobCoordinates = coordinate.coordinates;
              // this.allcoordinates = JSON.parse(data[i].geometry.coordinates);
              // this.flyMap(this.allcoordinates[0], this.allcoordinates[1]);

              this.searchedJobGeoJson.features.push({
                type: 'Feature',
                properties: {
                  jobId: searchedJobData[i].jobId,
                },
                attributes: null,
                geometry: {
                  type: 'Point',
                  coordinates: jobCoordinates,
                },
              });
            }
            this.map.addSource('searchJobs', {
              type: 'geojson',
              data: this.searchedJobGeoJson,
            });

            this.map.setZoom(3);
            this.map.setLayoutProperty(
              'unclustered-point',
              'visibility',
              'none'
            );
            let visibility: any = 'visible';
            if (this.map.getZoom() >= 13.25) {
              visibility = 'none';
            }
            this.map.addLayer({
              id: 'search-unclustered-point',
              type: 'circle',
              source: 'searchJobs',
              filter: ['!', ['has', 'point_count']],
              paint: {
                'circle-color': '#b40219',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff',
              },
              layout: {
                visibility: visibility,
              },
            });
            if (this.searchJobList.totalRecord === 1) {
              this.popUpOnJobSearch(this.searchJobList.allJobs[0]);
            }
            this.fitBound(this.searchedJobGeoJson);
            this.isSearchAppliedOnJobData = true;
            this.mapClickInputFunctionAfterSearch(searchedJobData[0]);
            this.mouseEnterOnMapAfterSearch();
            this.mouseLeaveOnMapAfterSearch();
            this.spinner.hide();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  popUpOnJobSearch(e: any) {
    const coordinates: any[2] = [e.longitude, e.latitude];
    this.flyMap(coordinates[0], coordinates[1]); // fly map to lat long
    this.clickedJobItemId = e.jobId;
    this.clickedJobItemCoordinates = coordinates;
    this.spinner.show();
    this.mapViewerSubscribe = this.mapViewerService
      .fetchJobDetails(e.jobId, this.loginUserId)
      .subscribe((jobDetailData: any) => {
        this.spinner.hide();
        // const jobName = jobDetailData.data?.jobName;
        const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData.features[0]?.attributes?.customerName;
        this.clickedJobName = jobName;
        const siteContact = jobDetailData?.features[0]?.attributes.siteContact;
        const email = jobDetailData?.features[0]?.attributes.email;
        const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
        const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

        this.isJobOwner = jobDetailData?.features[0]?.attributes.isShared; //isAssigned;
        if (this.loginUserRole == 'Admin') {
          this.isJobOwner = true;
        }

        const description = `
    <div class="custom-map-header">
      <h2>${jobName}</h2>
    </div>
    <div class="custom-map-tooltip">
      <div class="table-responsive">
        <table class="table table-bordered table-striped custom-table">
          <tr>
            <td>Location</td>
            <td>${coordinates[1]},${coordinates[0]}</td>
          </tr>
          <tr>
            <td>Site Contact</td>
            <td>${siteContact}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>${email}</td>
          </tr>
          <tr>
            <td>Job ID</td>
            <td>${netsuiteJobId}</td>
          </tr>
          <tr>
            <td>PO Number</td>
            <td>${netsuitePoNumber}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `;
        // new mapboxgl.Marker({
        //   color: "#b40219"
        //   }).setLngLat(coordinates)
        //   .addTo(this.map);

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.map);
      });
  }

  //# fitbound method is used to show the features on particular lat and long
  fitBound(jobsFeature: any) {
    const bounds = new mapboxgl.LngLatBounds();
    jobsFeature.features.forEach((feature: any) => {
      bounds.extend(feature.geometry.coordinates);
    });
    if (this.zoomedJobIds.length === 1 && this.zoomedJobIds[0] === 27042) {
      this.map.fitBounds([
        [-97.2178614164008223, 27.8713669989418520], // southwestern corner of the bounds
        [-97.2160708791161170, 27.8729002579292882] // northeastern corner of the bounds
      ]);
    }
    else if (this.zoomedJobIds.length === 1 && this.zoomedJobIds[0] === 68852) {
      this.map.fitBounds([
        [-87.4975413241575666, 36.8595475937960302], // southwestern corner of the bounds
        [-87.4930455162079284, 36.8629185006229392] // northeastern corner of the bounds
      ]);
      this.map.fitBounds(bounds, {
        padding: 200,
        maxZoom: 10,
      });
    }
  }

  //# fly map to particular coordinates
  flyMap(long: any, lat: any) {
    this.map.flyTo({
      // center: [12.65147, 55.608166],
      center: [long, lat],
    });
  }


  //# mapClickInputFunctionAfterSearch method is used to show the popup on blue dot click after search
  mapClickInputFunctionAfterSearch(e: any) {
    this.map.on('click', 'search-unclustered-point', (e: any) => {
      var errCoordinate: any[2] = [];
      //const coordinates = e.features[0].geometry.coordinates.slice();
      this.clickedJobItemId = e.features[0].properties.jobId;
      // this.clickedJobItemCoordinates = e.features[0].geometry.coordinates.slice();
      this.spinner.show();
      this.mapViewerSubscribe = this.mapViewerService
        .fetchJobDetails(this.clickedJobItemId!, this.loginUserId)
        .subscribe(
          (jobDetailData: any) => {
            // const jobName = jobDetailData.data?.jobName;
            // const jobName =
            //   jobDetailData?.data?.groups?.features[0]?.attributes
            //     ?.workorderNumber +
            //   ' - ' +
            //   jobDetailData?.data?.groups?.features[0]?.attributes
            //     ?.customerName;
            const jobName = jobDetailData?.features[0]?.attributes?.workorderNumber + " - " + jobDetailData?.features[0]?.attributes?.customerName;

            this.clickedJobName = jobName;
            var siteContact = '';
            var email = '';
            if (jobDetailData?.features[0] != null) {
              siteContact = jobDetailData?.features[0]?.attributes.siteContact;
              email = jobDetailData?.features[0]?.attributes.email;
              this.isJobOwner = jobDetailData?.features[0]?.attributes.isShared; //isAssigned;
              if (this.loginUserRole == 'Admin') {
                this.isJobOwner = true;
              }
            }
            const lat = jobDetailData?.features[0]?.attributes.latitude;
            const long = jobDetailData?.features[0]?.attributes.longitude;
            const netsuiteJobId = jobDetailData?.features[0]?.attributes.netsuiteJobId || '';
            const netsuitePoNumber = jobDetailData?.features[0]?.attributes.netsuitePoNumber || '';

            const coordinates: any[2] = [long, lat];
            errCoordinate = [long, lat];
            this.clickedJobItemCoordinates = coordinates;
            const description = `
        <div class="custom-map-header">
          <h2>${jobName}</h2>
        </div>
        <div class="custom-map-tooltip">
          <div class="table-responsive">
            <table class="table table-bordered table-striped custom-table">
              <tr>
                <td>Location</td>
                <td>${coordinates[1]},${coordinates[0]}</td>
              </tr>
              <tr>
                <td>Site Contact</td>
                <td>${siteContact}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>${email}</td>
              </tr>
              <tr>
                <td>Job ID</td>
                <td>${netsuiteJobId}</td>
              </tr>
              <tr>
                <td>PO Number</td>
                <td>${netsuitePoNumber}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="custom-map-footer">
          <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
          <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
        </div>
      `;
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            this.spinner.hide();

            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(this.map);
          },
          (error) => {
            const description = `
    <div class="custom-map-header">
      <h2>Error occurred while fetching data for this job</h2>
    </div>
    <div class="custom-map-tooltip">
    </div>
    <div class="custom-map-footer">
      <button type="button" class="btn custom-btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="shareJobBtn">Share</button>
      <button type="button" class="btn custom-btn-primary" id="viewJobBtn">View Job</button>
    </div>
  `;
            new mapboxgl.Popup()
              .setLngLat(errCoordinate)
              .setHTML(description)
              .addTo(this.map);
            this.spinner.hide();
          }
        );
    });
  }

  //# registerClickEventOnZoomedLayerIds method is used to get details on click fetchLineFeatureDetail and fetchPointFeatureDetail api
  registerClickEventOnZoomedLayerIds() {
    if (this.screenWidth <= 397) {
      this.map.on('touchend', this.zoomedLayerIdArray, (e: any) => {
        this.spinner.show();
        if (e.features[0].geometry.type === 'LineString') {
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .fetchLineFeatureDetail(
              this.checkAnnotationLine(e.features[0].properties),
              parseInt(e.features[0].properties.featureId)
            )
            .subscribe((data: any) => {
              this.openedFeatureProperties = data.groups.features[0].properties;
              this.openedFeatureAttributes = data.groups.features[0].attributes;
              this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
              this.jobId = data.jobId;
              this.openedFeatureName =
                data.groups.features[0].properties.FeatureName;
              if (
                data.groups.features[0].attributes.addedLink != null ||
                undefined ||
                ''
              ) {
                let textLink = data.groups.features[0].attributes.addedLink;
                this.openedFeatureAttributesAddedLink = textLink.split(' , ');
              } else {
                this.openedFeatureAttributesAddedLink = [];
              }

              this.slideMenuOpenJobDescription = true;
              this.slideMenuOpenTools = false;
              this.slideMenuOpenFolder = false;
              this.slideMenuOpenLayerDetails = false;
              this.spinner.hide();
            });
        } else if (e.features[0].geometry.type === 'Point') {
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .fetchPointFeatureDetail(
              this.checkAnnotationPoint(e.features[0].properties),
              parseInt(e.features[0].properties.featureId)
            )
            .subscribe((data: any) => {
              this.openedFeatureProperties = data.groups.features[0].properties;
              this.openedFeatureAttributes = data.groups.features[0].attributes;
              this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
              this.jobId = data.jobId;
              this.openedFeatureName =
                data.groups.features[0].properties.FeatureName;
              if (
                data.groups.features[0].attributes.addedLink != null ||
                undefined ||
                ''
              ) {
                let textLink = data.groups.features[0].attributes.addedLink;
                this.openedFeatureAttributesAddedLink = textLink.split(' , ');
              } else {
                this.openedFeatureAttributesAddedLink = [];
              }
              this.slideMenuOpenJobDescription = true;
              this.slideMenuOpenTools = false;
              this.slideMenuOpenFolder = false;
              this.slideMenuOpenLayerDetails = false;
              this.spinner.hide();
            });
        }
      });
    } else {
      this.map.on('click', this.zoomedLayerIdArray, (e: any) => {
        this.spinner.show();
        if (e.features[0].geometry.type === 'LineString') {
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .fetchLineFeatureDetail(
              this.checkAnnotationLine(e.features[0].properties),
              parseInt(e.features[0].properties.featureId)
            )
            .subscribe((data: any) => {
              this.openedFeatureProperties = data.groups.features[0].properties;
              this.openedFeatureAttributes = data.groups.features[0].attributes;
              this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
              this.jobId = data.jobId;
              this.openedFeatureName =
                data.groups.features[0].properties.FeatureName;
              if (
                data.groups.features[0].attributes.addedLink != null ||
                undefined ||
                ''
              ) {
                let textLink = data.groups.features[0].attributes.addedLink;
                this.openedFeatureAttributesAddedLink = textLink.split(' , ');
              } else {
                this.openedFeatureAttributesAddedLink = [];
              }

              this.slideMenuOpenJobDescription = true;
              this.slideMenuOpenTools = false;
              this.slideMenuOpenFolder = false;
              this.slideMenuOpenLayerDetails = false;
              this.spinner.hide();
            });
        } else if (e.features[0].geometry.type === 'Point') {
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .fetchPointFeatureDetail(
              this.checkAnnotationPoint(e.features[0].properties),
              parseInt(e.features[0].properties.featureId)
            )
            .subscribe((data: any) => {
              this.openedFeatureProperties = data.groups.features[0].properties;
              this.openedFeatureAttributes = data.groups.features[0].attributes;
              this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
              this.jobId = data.jobId;
              this.openedFeatureName =
                data.groups.features[0].properties.FeatureName;
              if (
                data.groups.features[0].attributes.addedLink != null ||
                undefined ||
                ''
              ) {
                let textLink = data.groups.features[0].attributes.addedLink;
                this.openedFeatureAttributesAddedLink = textLink.split(' , ');
              } else {
                this.openedFeatureAttributesAddedLink = [];
              }
              this.slideMenuOpenJobDescription = true;
              this.slideMenuOpenTools = false;
              this.slideMenuOpenFolder = false;
              this.slideMenuOpenLayerDetails = false;
              this.spinner.hide();
            });
        }
      });
    }
    this.map.on('mouseenter', this.zoomedLayerIdArray, (e: any) => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    this.map.on('mouseleave', this.zoomedLayerIdArray, (e: any) => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  mouseEnterOnMapAfterSearch() {
    this.map.on('mouseenter', 'search-unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
  }

  //# mouse leave function  Change it back to a pointer when it leaves
  mouseLeaveOnMapAfterSearch() {
    this.map.on('mouseleave', 'search-unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  //# mouse enter function on map
  mouseEnterOnMap() {
    this.map.on('mouseenter', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
  }

  //# mouse leave function  Change it back to a pointer when it leaves
  mouseLeaveOnMap() {
    this.map.on('mouseleave', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  createMap() {
    const tileServerUrl = environment.TILES_MVT_BASE_URL; //this.mapViewerService.getTileServerUrl();
    const accessToken = sessionStorage.getItem("token");

    console.log("tileserver url :", tileServerUrl);

    /**
     * @desc Building map
     */
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'mapviewerjobs',
      style: this.style,
      zoom: 17,
      center: this.centerCoordinatesForMap as any,
      preserveDrawingBuffer: true,
      interactive: true,
      renderWorldCopies: false,
      transformRequest: (url: string, resourceType: string) => {
        if ((resourceType === 'Tile' || resourceType === 'Image') && url.indexOf(tileServerUrl) > -1) {
          //console.log("transformRequest If block url: " + url);
          return {
            url: url,
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          };
        } else {
          //console.log("transformRequest else block");
          return {
            url: url
          }
        }
      }
    });

    this.map.dragRotate.disable();

    this.draw = new MapboxDraw(this.drawOptions);
    this.drawHandlePrint = MapboxDraw;
    this.map.addControl(this.draw, 'top-left');

    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    // this.map.addControl(new MapboxExportControl(mapboxgl as any), 'top-left');

    // this.pageLoadFunctionCall();

    const scale = new mapboxgl.ScaleControl({
      unit: 'imperial',
    });
    this.map.addControl(scale, 'bottom-left');
    scale.setUnit('imperial');
    this.registerDragEventInMap();

    this.jobDocumentsListCall(); // Call only after map is created
  }

  validateJobAccess() {
    if (!this.isCredentialessLogin) {
      this.dashboardservice.fetchSharedWithUserList(this.navigatedJobId, 1).subscribe((data) => {
        const a = Array.isArray(data) ? data : [data];
        const obj = a.find((job:any)=> job.emailId === this.loginUserEmailId);
        if(obj) {
          if(obj?.accessName?.toLowerCase() === "view") {
            this.isCurrentUserHasEditAccessOnJob = false;
          }
        }
      });
    }

  }

  // Mouse event properties and methods
  // State values required for mouse clicks on Sitemap layers
  numberOfClicks: number = 0;
  clickTimeout: number = 300;

  private _getClickedFeatures(e: any) {
    const regex = new RegExp('^[0-4]-');
    let gprsLayerIds: any = this.map
      .getStyle()
      .layers.filter((layer) => regex.test(layer.id))
      .map((layer) => layer.id);
    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    let bbox : any = [];
    if(e.point){
      bbox = [
        [e.point.x - bboxPadding, e.point.y - bboxPadding],
        [e.point.x + bboxPadding, e.point.y + bboxPadding],
      ];
    }
    else {
      bbox = [
        [e.attributes.coordinate.long - bboxPadding, e.attributes.coordinate.lat - bboxPadding],
        [e.attributes.coordinate.long + bboxPadding, e.attributes.coordinate.lat + bboxPadding],
      ];
    }

    // Find features intersecting the bounding box.
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(bbox, {
      layers: [...gprsLayerIds],
    });


    return clickedFeatures;
  }

  private _getMultipleSelectionMsg(clickedFeatures: any[]) {
    let editingFeature = clickedFeatures[0];
    let editingFeatureName = editingFeature.properties.featureName;
    let editingFeatureType = editingFeature.properties.featureType;
    let editingFeatureGroup = editingFeature.properties.featureGroup;
    let confirmMessage: string = `It seems you have selected multiple features. Would you like proceed ahead with ${editingFeatureGroup} - ${editingFeatureType} - ${editingFeatureName}?`;

    return confirmMessage;
  }

  private _proceedWithClick(e: any) {
    // this.enableEditModelOnDblClick = false;
    this.handleLayerSingleClick(e);
    this.numberOfClicks = 0;
  }

  // Handle the first click on a Sitemap layer, then branch for single- or double-click
  handleFirstClick = (e: any) => {
    this.map.doubleClickZoom.disable();
    this.numberOfClicks++;

    let clickedFeatures = this._getClickedFeatures(e);
    setTimeout(() => {
      // added log to see how many click event generated by mapbox
      console.log("No of Clicks : ", this.numberOfClicks);

      // BUG 30763, removing check for multiple layers.  Always return the topmost feature.
      this._proceedWithClick(e);
    }, 500);
  }

  // Single-click
  handleLayerSingleClick = (e: any) => {
    this.currentFeaturesLayer = -1;
    const regex = new RegExp('^[0-4]-');
    let gprsLayerIds: any = this.map.getStyle().layers.filter(layer => regex.test(layer.id)).map(layer => layer.id);
    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    const bbox: any = [
      [e.point.x - bboxPadding, e.point.y - bboxPadding],
      [e.point.x + bboxPadding, e.point.y + bboxPadding],
    ];
    // Find features intersecting the bounding box.
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(
      bbox,
      {
        layers: [...gprsLayerIds],
      }
    );

    if (clickedFeatures.length > 0 && !this.isEditingModeIsOn) {
      this.spinner.show();
      this.matterportAddLinkObj = {
        featureId: parseInt(clickedFeatures[0].properties.featureid),
        featureTypeId: parseInt(
          clickedFeatures[0].properties.featureTypeId
        ),
        geometryType: clickedFeatures[0].geometry.type,
        allProperty: clickedFeatures[0].properties,
      };
      if (clickedFeatures[0].geometry.type === 'LineString' || clickedFeatures[0].geometry.type === 'MultiLineString') {
        this.mapViewerJobFeatureSubscribe =
          this.mapViewerJobFeatureService
            .fetchLineFeatureDetail(
              this.checkAnnotationLine(clickedFeatures[0].properties),
              parseInt(clickedFeatures[0].properties.featureid)
            )
            .subscribe((data: any) => {
              this.editDescriptionValue = data;
              this.currentFeaturesLayer = this.editDescriptionValue?.groups?.features?.[0]?.properties?.layerId;
              this.editFormFeatureDescriptionView = true;
              this.openedFeatureProperties = data.groups.features[0].properties;

              this.openedFeatureAttributes =
                data.groups.features[0].attributes;
              this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
              this.jobId = data.jobId;
              this.openedFeatureName =
                data.groups.features[0].properties.FeatureName;
              if (
                data.groups.features[0].attributes.addedLink != null ||
                undefined ||
                ''
              ) {
                let textLink =
                  data.groups.features[0].attributes.addedLink;
                this.openedFeatureAttributesAddedLink =
                  textLink.split(' , ');
              } else {
                this.openedFeatureAttributesAddedLink = [];
              }

              this.slideMenuOpenJobDescription = true;
              this.slideMenuOpenTools = false;
              this.slideMenuOpenFolder = false;
              this.slideMenuOpenLayerDetails = false;
              this.spinner.hide();
            });
      } else {
        this.spinner.show();
        this.matterportAddLinkObj = {
          featureId: parseInt(clickedFeatures[0].properties.featureid),
          featureTypeId: parseInt(
            clickedFeatures[0].properties.featureTypeId
          ),
          geometryType: clickedFeatures[0].geometry.type,
          allProperty: clickedFeatures[0].properties,
        };
        this.mapViewerJobFeatureSubscribe =
          this.mapViewerJobFeatureService
            .fetchPointFeatureDetail(
              this.checkAnnotationPoint(clickedFeatures[0].properties),
              parseInt(clickedFeatures[0].properties.featureId)
            )
            .subscribe((data: any) => {
              this.editDescriptionValue = data;
              this.currentFeaturesLayer = this.editDescriptionValue?.groups?.features?.[0]?.properties?.layerId;
              this.editFormFeatureDescriptionView = true;
              // this.editDescriptionValue = data, data.groups.features[0].geometry.type;

              if (clickedFeatures[0].properties.featureClass != this.commonMapService.externalContentIconImage[clickedFeatures[0].properties.featureClass]?.toLowerCase()) {
                this.openedFeatureProperties = data?.groups?.features[0]?.properties;
                this.openedFeatureAttributes =
                  data?.groups?.features[0]?.attributes;
                this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
                this.jobId = data.jobId;
                this.openedFeatureName =
                  data?.groups?.features[0]?.properties?.FeatureName;
                if (
                  data?.groups?.features[0]?.attributes?.addedLink != null ||
                  undefined ||
                  ''
                ) {
                  let textLink =
                    data.groups.features[0].attributes.addedLink;
                  this.openedFeatureAttributesAddedLink =
                    textLink.split(' , ');
                } else {
                  this.openedFeatureAttributesAddedLink = [];
                }

                this.slideMenuOpenJobDescription = true;
              }
              else {

                this.modalHeader = clickedFeatures[0].properties.featureGroup;

                if (this.modalHeader == "MATTERPORT") {
                  this.modalHeader = "VIRTUAL TOUR";
                };

                const tempLayerData = this.navigatedFeatures.find((it: any) => it.layerId === clickedFeatures[0].properties.layerId);
                const tempFeaturesDetail = tempLayerData.features.find((it: any) => it.featureId === clickedFeatures[0].properties.featureId && it.feature === clickedFeatures[0].properties.featureName);
                this.cdRef.detectChanges();

                let button = document.getElementById("btnModalPhoto") as HTMLButtonElement;
                button.click();
                let siteId = document.getElementById("siteId") as HTMLSpanElement;
                let woNo = document.getElementById("woNo") as HTMLSpanElement;

                this.spinner.hide();
                if (this.modalHeader === 'PHOTO') {
                  let img = document.getElementById("imgPhoto") as HTMLImageElement;
                  img.src = tempFeaturesDetail ? tempFeaturesDetail.addedLink + this.dprFileDownloadSAS : environment.STATIC_PHOTO_IMAGE;
                  siteId.innerText = tempLayerData.siteId;
                  woNo.innerText = tempLayerData.workOrdeNumber;
                } else {
                  this.externalContentTemplateData = {
                    siteId: tempLayerData.siteId,
                    woNo: tempLayerData.workOrdeNumber,
                    siteName: tempLayerData.siteName,
                    featureName: tempFeaturesDetail.feature,
                    featureLink: tempFeaturesDetail.addedLink,
                  };
                  if(tempFeaturesDetail.addedLink && this.modalHeader !== 'EXTERNAL LINK'){
                    if (this.modalHeader === "VIRTUAL TOUR") {
                      // PAW create the table for the vt links
                      let matterportObj = JSON.parse(clickedFeatures[0].properties.matterportLinks || '{}');
                      this.matterportLinkArray = matterportObj;
                      if (this.matterportLinkArray.length > 0){
                        this.matterportLinkArray.forEach((vtLink:any) => {
                          vtLink['action'] = new virtualTourListTableDataDC().actionOpenButton;
                        });
                      }
                    }
                    this.iframeExternalContentLink = true;
                    this.cdRef.detectChanges();
                    let iframeExternal = document.getElementById("externalContentLink") as HTMLIFrameElement;
                    iframeExternal.src = tempFeaturesDetail.addedLink ? tempFeaturesDetail.addedLink : '';
                    //window.open(tempFeaturesDetail.addedLink, "_blank");
                  } else {
                    this.iframeExternalContentLink = false;
                  }

                }
                this.slideMenuOpenJobDescription = false;
              }
              this.slideMenuOpenTools = false;
              this.slideMenuOpenFolder = false;
              this.slideMenuOpenLayerDetails = false;
              this.spinner.hide();
            });
      }

    } else if (this.isEditingModeIsOn && clickedFeatures.length > 0 && this.isCurrentUserHasEditAccessOnJob) {
      if (clickedFeatures[0].properties.layerId != 3) {
        // ignore imported layers (layerid = 3)
        this.handleEditingforPointorLine([clickedFeatures[0]]);
      }

    } else {
        this.toastr.error(`You do not have "Edit Access" on jobid ${this.navigatedJobId}`);
    }
  }

  gotolink(url:string) {
    if (confirm("You are leaving the Sitemap website to view external content.  Do you wish to continue?")) {
      window.open(url, "_blank");
    }
  }
  // Double-click
  handleLayerDblClick = (e: any) => {

    const regex = new RegExp('^[0-4]-');
    let gprsLayerIds: any = this.map
      .getStyle()
      .layers.filter((layer) => regex.test(layer.id))
      .map((layer) => layer.id);

    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    const bbox: any = [
      [e.point.x - bboxPadding, e.point.y - bboxPadding],
      [e.point.x + bboxPadding, e.point.y + bboxPadding],
    ];
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(
      bbox,
      {
        layers: [...gprsLayerIds],
      }
    );
    if (clickedFeatures.length > 0 && !this.isEditingModeIsOn) {
      // load layers data before making editmode enable when slideMenuOpenLayerDetails and isGroupMapCompleted is false
      // Then set both properties to true
      if(!this.slideMenuOpenLayerDetails) {
        this.slideMenuOpenLayerDetails = true;
        this.isGroupMapCompleted = true;
        this.loadFeature();
      }
      if (this.isGprsLayerCanEdit && clickedFeatures[0].properties.layerId === 1) {
        this._editLayer(clickedFeatures);
      }
      else if (this.isClientLayerCanEdit && clickedFeatures[0].properties.layerId === 2) {
        this._editLayer(clickedFeatures);
      }
      else if (this.isImportedLayerCanEdit && clickedFeatures[0].properties.layerId === 3) {
        this._editLayer(clickedFeatures);
      }
      else if (this.isExternalLayerCanEdit && clickedFeatures[0].properties.layerId === 4) {
        this._editLayer(clickedFeatures);
      }
      else {
        alert("You do not have access to edit features in " + this.getLayerName(clickedFeatures[0].properties.layerId) + " Layer");
      }

      setTimeout(() => {
        this.handleLayerSingleClick(e);
      }, 300);
    }
  }

  // Mouseenter - sets hover curso
  handleLayerMouseEnter = (e: any) => {
    const siteMapRegex = new RegExp('^[0-4]-');
    const siteMapPointRegex = new RegExp('^[0-4]-point');
    const siteMapLineRegex = new RegExp('^[0-4]-line');
    let gprsLayerIds: any = this.map.getStyle().layers.filter(layer => siteMapRegex.test(layer.id)).map(layer => layer.id);
    let gprsPointLayerIds: any = this.map.getStyle().layers.filter(layer => siteMapPointRegex.test(layer.id)).map(layer => layer.id);
    let gprsLineLayerIds: any = this.map.getStyle().layers.filter(layer => siteMapLineRegex.test(layer.id)).map(layer => layer.id);
    // Only change to point if we are not hovering over a draw feature
    // Set `bbox` as ?px reactangle area around clicked point.
    const bboxPadding = 3;
    const bbox: any = [
      [e.point.x - bboxPadding, e.point.y - bboxPadding],
      [e.point.x + bboxPadding, e.point.y + bboxPadding],
    ];

    let hoveredDrawFeatures = this.map.queryRenderedFeatures(bbox, {
      layers: [...this.drawLayerLineIds, ...this.drawLayerPointIds],
    });
    let hoveredSitemapFeatures = [];
    if (hoveredDrawFeatures.length === 0) {
      if (this.isDrawing) {
        // Disable all interactions
      } else if (this.disablePointInteractions) {
        // Allow only Line interactions
        hoveredSitemapFeatures = this.map.queryRenderedFeatures(bbox, {
          layers: [...gprsLineLayerIds],
        });
      } else {
        // Allow all interactions
        hoveredSitemapFeatures = this.map.queryRenderedFeatures(bbox, {
          layers: [...gprsLayerIds],
        });
      }

      if (hoveredSitemapFeatures.length > 0) {
        this.map.getCanvasContainer().style.cursor = 'pointer';
      }
    }
  }

  // Mouseout - removes hover cursor
  handleLayerMouseOut = (e: any) => {
    this.map.getCanvasContainer().style.cursor = '';
  }

  private _editLayer(clickedFeatures: any[]) {
    this.gotoMapEditor(this.getLayerName(clickedFeatures[0].properties.layerId));
    this.editSelectedFeature = clickedFeatures[0];
    this.showHideToggleGroup(clickedFeatures[0]);
    this.slideMenuOpenLayerDetails = true;
    this.slideMenuOpenTools = false;
    this.slideMenuOpenFolder = false;
    this.slideMenuOpenJobDescription = false;
  }

  registerDragEventInMap() {
    this.map.on('dragend', () => {
      if (this.map.getZoom() < 13.25) {
        this.resetMapsData();
      }
    });
  }

  resetMapsData() {
    this.featureGroupMap.clear();
    this.featureGroupToggleStateMap.clear();
    this.featureGroupTypeToggleStateMap.clear();
    this.featureGroupTypeFeatureNameToggleStateMap.clear();
    this.importedLayerFileId = undefined;
  }

  //pageLoadFunctionCall method is used to load everything on page load
  isHandlingStyleData = false

  handleStyleData = (e: any) => {
    if (!this.isHandlingStyleData) {
      this.isHandlingStyleData = true;
      let layers = this.map.getStyle().layers;
      let layersReady = true;
      for (const layerId of [1, 2, 3]) {
        for (const tileType of ['point', 'line']) {
          let currentLayerId = `${layerId}-${tileType}`;

          if (layers.filter(layer => layer.id === currentLayerId).length !== 1) {
            layersReady = false;
          }
        }
      }
      if (layersReady) {
        this.map.off('styledata', this.handleStyleData);
        this.isHandlingStyleData = false;
        this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
      } else {
        this.isHandlingStyleData = false;
      }
    }

  }

  pageLoadFunctionCall() {
    // this.map.on('load', () => {


    // });

    this.map.on('style.load', () => {
      this.map.once('idle', (e: any) => {
        // Load each point/line MVT source and layer separately
        const pointFeatureNameArray: string[] = Array.from(
          this.pointFeaturesNameSet
        );
        if (!this.isMapViewerJobsZoomedOut) {
          this.loadAllJobsLayerFunctionalities();
          if (
            this.loginUserRole == 'Admin' ||
            this.loginUserRole == 'Project Manager'
          ) {
            this.addGroupLayers();
            this.commonMapService.filterMVTOnZoomIn(this.zoomedJobIds, this.map);
          }
          // for (const featureData of this.navigatedFeatures) {
          //   if (!this.isBasemapToggle) {
          //     this.setValuesInRequiredMaps(featureData);
          //   } else {
          //     this.isBasemapToggle = false;
          //   }
          // }
          this.isGroupMapCompleted = true;
          this.map.on('styledata', this.handleStyleData);
          // setTimeout(() => {
          //   // this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
          // }, 200);
        }
      });
    });

    this.map.on('click', this.zoomedLayerIdArray, (e: any) => {
      this.matterportAddLinkObj = {
        featureId: parseInt(e.features[0].properties.featureId),
        featureTypeId: parseInt(e.features[0].properties.featureTypeId),
        geometryType: e.features[0].geometry.type,
        allProperty: e.features[0].properties,
      };
      this.spinnerService.show();
      if (e.features[0].geometry.type === 'LineString') {
        this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
          .fetchLineFeatureDetail(
            this.checkAnnotationLine(e.features[0].properties),
            parseInt(e.features[0].properties.featureId)
          )
          .subscribe((data: any) => {
            this.openedFeatureProperties = data.groups.features[0].properties;
            this.openedFeatureAttributes = data.groups.features[0].attributes;
            this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
            this.jobId = data.jobId;
            this.openedFeatureName =
              data.groups.features[0].properties.FeatureName;
            if (
              data.groups.features[0].attributes.addedLink != null ||
              undefined ||
              ''
            ) {
              let textLink = data.groups.features[0].attributes.addedLink;
              this.openedFeatureAttributesAddedLink = textLink.split(' , ');
            } else {
              this.openedFeatureAttributesAddedLink = [];
            }
            this.slideMenuOpenJobDescription = true;
            this.slideMenuOpenTools = false;
            this.slideMenuOpenFolder = false;
            this.slideMenuOpenLayerDetails = false;
            this.spinnerService.hide();
          });
      } else if (e.features[0].geometry.type === 'Point') {
        this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
          .fetchPointFeatureDetail(
            this.checkAnnotationPoint(e.features[0].properties),
            parseInt(e.features[0].properties.featureId)
          )
          .subscribe((data: any) => {
            this.openedFeatureProperties = data.groups.features[0].properties;
            this.openedFeatureAttributes = data.groups.features[0].attributes;
            this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
            this.jobId = data.jobId;
            this.openedFeatureName =
              data.groups.features[0].properties.FeatureName;
            if (
              data.groups.features[0].attributes.addedLink != null ||
              undefined ||
              ''
            ) {
              let textLink = data.groups.features[0].attributes.addedLink;
              this.openedFeatureAttributesAddedLink = textLink.split(' , ');
            } else {
              this.openedFeatureAttributesAddedLink = [];
            }
            this.slideMenuOpenJobDescription = true;
            this.slideMenuOpenTools = false;
            this.slideMenuOpenFolder = false;
            this.slideMenuOpenLayerDetails = false;
            this.spinnerService.hide();
          });
      }
    });

    this.map.on('mouseenter', this.zoomedLayerIdArray, (e: any) => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    this.map.on('mouseleave', this.zoomedLayerIdArray, (e: any) => {
      this.map.getCanvas().style.cursor = '';
    });

    this.createDrawEvents();
  }

  //#region removeExtraPopup method use to remove the extra open coordinate popup
  removeExtraPopup() {
    setTimeout(() => {
      let ele = document.querySelectorAll('.mapboxgl-popup-anchor-bottom');
      if (ele?.length > 0) {
        for (let i = 0; i < ele.length - 1; i++) {
          ele[i].remove();
        }
      }
    }, 0);
  }
  //#endregion

  //getlayerid method is used to get the id for layer id basis on layer name
  getLayerID(layer: string): number | null {
    let layerID = null;
    if (layer === 'GPRS') {
      layerID = 1;
    } else if (layer === 'CLIENT') {
      layerID = 2;
    } else if (layer === 'IMPORTED') {
      layerID = 3;
    } else if (layer === 'EXTERNAL') {
      layerID = 4;
    }
    // else if (layer.includes('SITE')) {
    //   layerID = 5;
    // }
    return layerID;
  }

  //toggleGPRSLayer method is used to show/hide the feature on click on eye and eyelash
  toggleGPRSLayer(layer: any) {
    let layerID = this.getLayerID(layer);

    if (layerID == 3) {
      const visible = this.isLayerEyeVisible[layer] = !this.isLayerEyeVisible[layer];
      this.layerEvents.next({ type: 'ChangeLayerVisibility', data: { visible } });
      return;
    }

    const layerKeys = Array.from(this.featureGroupMap?.keys());
    if (layerKeys.find((it) => it === layer)) {
      this.featureGroupMap.get(layer).delete('');
      const groupKeys: string[] = Array.from(
        this.featureGroupMap.get(layer).keys()
      );
      if (this.isLayerEyeVisible[layer]) {
        groupKeys.forEach((featureGroup) => {
          const pointLayerId = `${layerID}-${featureGroup}-point`;
          const lineLayerId = `${layerID}-${featureGroup}-line`;
          // this.map.setFilter(pointLayerId, false);
          // this.map.setFilter(lineLayerId, false);
          this.featureGroupToggleStateMap.get(layer).set(featureGroup, false);
          let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
            .get(layer)
            .get(featureGroup);
          let featureGroupFeatureNameToggleStateMap: any;
          featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
            featureGroupFeatureTypeMap.set(key, false);
            featureGroupFeatureNameToggleStateMap =
              this.featureGroupTypeFeatureNameToggleStateMap
                .get(layer)
                .get(featureGroup)
                .get(key);
            featureGroupFeatureNameToggleStateMap.forEach(
              (value1: boolean, key1: string) => {
                this.featureGroupTypeFeatureNameToggleStateMap
                  .get(layer)
                  .get(featureGroup)
                  .get(key)
                  .set(key1, false);
              }
            );
          });
          this.featureGroupTypeToggleStateMap
            .get(layer)
            .set(featureGroup, featureGroupFeatureTypeMap);
        });
        setTimeout(() => {
          // this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
          this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
        }, 200);
      } else {
        groupKeys?.forEach((featureGroup) => {
          const pointLayerId = `${layerID}-${featureGroup}-point`;
          const lineLayerId = `${layerID}-${featureGroup}-line`;
          // this.map.setFilter(
          //   pointLayerId,
          //   this.getGroupFilterExpression(featureGroup)
          // );
          // this.map.setFilter(
          //   lineLayerId,
          //   this.getGroupFilterExpression(featureGroup)
          // );
          this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);
          let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
            .get(layer)
            .get(featureGroup);
          let featureGroupFeatureNameToggleStateMap: any;
          featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
            featureGroupFeatureTypeMap.set(key, true);
            featureGroupFeatureNameToggleStateMap =
              this.featureGroupTypeFeatureNameToggleStateMap
                .get(layer)
                .get(featureGroup)
                .get(key);
            featureGroupFeatureNameToggleStateMap.forEach(
              (value1: boolean, key1: string) => {
                this.featureGroupTypeFeatureNameToggleStateMap
                  .get(layer)
                  .get(featureGroup)
                  .get(key)
                  .set(key1, true);
              }
            );
          });
          this.featureGroupTypeToggleStateMap
            .get(layer)
            .set(featureGroup, featureGroupFeatureTypeMap);
        });
        setTimeout(() => {
          // this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
          this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
        }, 200);
      }
      // this.isGPRSLayerVisible = !this.isGPRSLayerVisible;
      this.isLayerEyeVisible[layer] = !this.isLayerEyeVisible[layer];
      if (layerID == 3) {
        if (this.geoReferenceGISImagesData) {
          this.geoReferenceGISImagesData.forEach((item: any) => {

            item.setEyeActive = !this.isLayerEyeVisible[layer];
            this.toggleRasterItem(item);
          });
        }
      }
    }
  }

  // Handle featureGroup filter - turn off/on layers with Feature Group
  setGroupFilter(state: string, featureGroup: string) {
    let layerArr: any = [1, 2, 3];
    if (this.editLayerName) {
      const temp = this.getLayerID(this.editLayerName);
      layerArr = [temp]
    }
    for (const editingLayerId of layerArr) {
      for (let geomType of ['point', 'line']) {
        let layerId = `${editingLayerId}-${featureGroup}-${geomType}`;
        if (this.map.getLayer(layerId) === undefined) {

          break;
        }
        if (state === 'off') {
          this.map.setFilter(layerId, false);
        } else {
          let filterExpression = this.getGroupFilterExpression(featureGroup);
          this.map.setFilter(layerId, false);
          this.map.setFilter(layerId, filterExpression);
        }
      }
    }
  }

  mainLayerChangeCss(className: any, updatedClassName: any) {
    let ele = document.querySelectorAll('.' + className);
    if (ele?.length > 0) {
      ele.forEach((it: any) => {
        it?.classList?.remove(className);
        it?.classList?.add(updatedClassName);
      });
    }
  }

  gprsChangeCSSOnEveryChildClick() {
    let ele = document.querySelectorAll('.inner-group');
    let gprsLayer = document.getElementById('mainGPRSLayer');
    let count = 0;
    if (ele?.length > 0) {
      for (let j = 0; j < ele?.length; j++) {
        const classList = Array.from(ele[j].classList);
        if (classList.find((it: any) => it === 'fa-eye-slash')) {
          count += 1;
        }
      }
      if (ele?.length === count) {
        gprsLayer?.classList.remove('fa-eye');
        gprsLayer?.classList.add('fa-eye-slash');
      } else {
        gprsLayer?.classList.remove('fa-eye-slash');
        gprsLayer?.classList.add('fa-eye');
      }
    }
  }

  changeMaptypeEvent(e: any) {
    if (this.currentStyle != e.target.value) {
      this.ngOnDestroy();
      this.isBasemapToggle = true;
      this.mapViewerSubscribe?.unsubscribe();
      this.mapViewerJobFeatureSubscribe?.unsubscribe();
      this.headerLayerButtonStateSubscription?.unsubscribe();
      this.forkJoinMapFeaturesSubscription?.unsubscribe();
      this.map.setStyle('mapbox://styles/mapbox/' + e.target.value);
      this.currentStyle = e.target.value;
      this.counter = 0;
      this.printTypeSubscription = this.behaviourSubjectService.printTriggerHeader.subscribe((printType) => {
      // console.log(printType, '000000000000')
       this.printType = printType;
       console.log(' this.printType',  this.printType);

       if(this.printType =='print'){
          this.onPrintShowPrintBox(this.currentEvent);
          this.printState = false;
          if(this.counter == 0){
            this.counter = 1;
          }
        }else{
         this.counter = 0;
        }
      });
    }
    // let currentStyleUrl = this.map.getStyle().sprite;
    // let baseMapId = currentStyleUrl ? 'mapbox/' + currentStyleUrl.substring(currentStyleUrl.lastIndexOf('/') + 1) : 'mapbox/satellite-streets-v11';
    // console.log('baseMapId', baseMapId);

  }

  changeRenderScaleEvent(e: any) {
    //console.log("changed rendering scale to " + e.target.value);

    if (this.currentRenderScale != e.target.value) {
      sessionStorage.setItem("userRenderingScale",  e.target.value);
      this.userRenderScale = this.renderScaleService.getMinLevelForScale();

      if (this.currentZoomLevel >= this.userRenderScale) {
        this.showToggleSideBar = true;
      } else {
        this.showToggleSideBar=false;
      }

      this.loadMVT();

      this.currentRenderScale = e.target.value;

      let payload = {
        userId: sessionStorage.getItem("loginUserId"),
        renderingScale: this.currentRenderScale
      };

      this.apiService.updateUserRenderScale(payload).subscribe((response) => {
        console.log("updated scale in db: " + response);
      });
    }

  }

  onGetImageUrl(e: any) {
    this.imgSrc = e.srcElement.src;
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    this.currentEvent = event;
    if (event.target.id == 'viewJobBtn') {
      this.route.params.subscribe(params => {

        if (params['id'] == this.clickedJobItemId) {
          this.reloadCurrentRoute();
        } else {
          this.router.navigate([`/mapviewerjobs/${this.clickedJobItemId}`]);
        }

      });

    } if (event.target.id == 'shareJobBtn') {
    }
  }

  @HostListener('document:keydown.delete', ['$event'])
  onDeleteComponent(event: KeyboardEvent) {
    //alert(this.edit);
    // if (this.editingHistoryIndex === 0){ this.draw.deleteAll();}
    this.draw?.delete(this.editSelectedFeature?.id);

  }

  //# close share modal
  closeShareObjModal() {
    this.accessFormControl.patchValue('1');
    //this.objSharedUserList = [];
    this.emailFormControl.setValue("");
  }

  //#select email from fetched email list
  selectEmailFromFetchedEmails(obj: any) {
    this.emailFormControl.setValue(obj);
    this.shouldFetchedEmailsBeDisplayed = false;
  }

  //# Share jobs to concerned person with view/edit access
  shareObj(isRadioClicked=false) {

    if (this.emailFormControl.value == "") {
      this.toastr.error("Please enter email address");
      return;
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailFormControl.value) == false) {
      this.toastr.error("Please enter a valid Email Address.");
      return;
    }

    this.spinner.show();
    this.dashboardservice.fetchEditEmails(this.emailFormControl.value).subscribe((data) => {

      this.spinner.hide();
      this.tierIDData = data;
      let userTierID = "";
      let loginUserRole = "";
      if (this.tierIDData.length > 0) {

        userTierID = this.tierIDData[0].tierId;
        loginUserRole = this.tierIDData[0].roleName;
      }

      if (this.tierIDData.length === 0 && this.accessFormControl.value == '2') {
        userTierID = "";
        this.toastr.error("Unrigistered User does not have access to edit the content");
        return;
      }


      if (loginUserRole != "Project Manager" && loginUserRole != "Admin") {

        if (this.accessFormControl.value == '2' && userTierID == '1') {
          this.toastr.error("Free account User does not have access to edit the content");
          return;
        }

      }

      if(isRadioClicked){
        return;
      }

      if (!this.isJobOwner) {  // not owner for job
        this.toastr.error("You must be the owner of the job in order to share content");
      } else {
        let tierId=sessionStorage.getItem('tierId');
        let role=sessionStorage.getItem('loginUserRole');
        if(role=='Client' && tierId=='1' && this.accessFormControl.value =='2'){
          this.toastr.error('You are not authorized to share the job.');
          return;
        }
        if (this.emailFormControl.value == "") {
          this.toastr.error("Please enter email address");

        } else {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailFormControl.value)) {
            this.spinner.show();
            let payLoad = {
              "sharedWithEmailId": this.emailFormControl.value,
              "sharedByUserId": this.loginUserId,
              "objectId": this.clickedJobItemId,
              "objectTypeId": 1, // 1 for job
              "securableObjectPermissionTypeId": parseInt(this.accessFormControl.value),
            }

            this.dashboardSubscribe = this.dashboardservice.shareObject(payLoad).subscribe((response) => {
              this.sharedObjResponse = response;
              this.spinner.hide();
              this.emailFormControl.reset();
              this.accessFormControl.patchValue('1');
              this.emailFormControl.patchValue('');
              if (this.sharedObjResponse.processingStatus.status === "Failure") {
                this.toastr.error(this.sharedObjResponse.processingStatus.message);
              } else {
                this.toastr.success("Job shared successfully");

              }
            });
          } else {
            this.toastr.error("Please enter a valid Email Address.");
          }
        }
      }
    });
  }

  measureArea() {
    // check Measurment Tools is enable or disable as per set my prefrences
    if (this.IsMeasurmentToolActive === 'TRUE') {
      this.draw.changeMode('draw_polygon');
    }
    else {
      let div = document.getElementById("measureAreaId") as HTMLDivElement;
      div.setAttribute('data-bs-toggle', "modal");
      div.setAttribute("data-bs-target", "#MeasurementsModal");
      div.click();

    }
  }

  measureLength() {
    // check Measurment Tools is enable or disable as per set my prefrences
    if (this.IsMeasurmentToolActive === 'TRUE') {
      this.draw.changeMode('draw_line_string');
    }
    else {
      let div = document.getElementById("measureLengthId") as HTMLDivElement;
      div.setAttribute('data-bs-toggle', "modal");
      div.setAttribute("data-bs-target", "#MeasurementsModal");
      div.click();

    }
  }

  //fetchFeatureGroupArray method is used to bind the group name in html
  fetchFeatureGroupArray(layer: any): string[] {
    // return Array.from(this.featureGroupMap.keys());
    const layerKeys = Array.from(this.featureGroupMap?.keys());
    let featureGroupMap: Map<string, any>;
    let data: any[] = [];
    if (layerKeys.find((it) => it === layer)) {
      featureGroupMap = this.featureGroupMap.get(layer);
      data = Array.from(featureGroupMap?.keys());
    }
    return data;
  }


  checkGracePeriod(data: any, val: any) {
    const index = data.findIndex((it: any) => it == val);
    if (this.subscriptionEditAccess.getGracePeriod() && index === -1) {
      data.push(val);
    } else if (!this.subscriptionEditAccess.getGracePeriod() && index > -1) {
      data.splice(index, 1)
    }
    return data;
  }

  //fetchLayerArray method is used to bind the layer name in html
  fetchLayerArray() {
    //let layerKeys = this.checkGracePeriod(['GPRS', 'CLIENT', 'IMPORTED', 'EXTERNAL', 'REFERENCE'], 'IMPORTED');
    // PAW only limiting the layers to these for now
    let layerKeys = ['GPRS', 'CLIENT', 'EXTERNAL', 'IMPORTED', 'REFERENCE'];
    return layerKeys;
  }

  //fetchFeatureTypeArray method is used to bind the featuretype name in html
  fetchFeatureTypeArray(groupKey: any, layer: any): string[] {
    const featureGroupMap: Map<string, any> = this.featureGroupMap
      .get(layer)
      .get(groupKey);
      return Array.from(featureGroupMap.keys());
  }

  fetchLayerInfo(layerName: string): LayerInfo {
    return {
      layerName: layerName,
      layerId: this.getLayerID(layerName) as number,
      groups: this.fetchFeatureGroupArray(layerName).map(groupName => {

        const types = this.fetchFeatureTypeArray(groupName, layerName).map(typeName => {
          const features = this.fetchFeaturesArray(groupName, typeName, layerName).map(x => ({...x, featureMode: '', type: x.featureGeometryType}));
          return {
            typeId: features.length > 0 ? features[0].featureTypeId : 0,
            typeName: typeName,
            features
          };
        });

        return {
          groupId: types.length > 0 && types[0].features.length > 0 ? types[0].features[0].featureGroupId : 0,
          groupName: groupName,
          types
        };
      }),
    }
  }

  //fetchFeaturesArray method is used to bind the feature name in html
  fetchFeaturesArray(groupKey: string, featureTypeKey: string | null, layer: string): FeatureArrayItem[] {
    const featureGroupMap: Map<string, any> = this.featureGroupMap
      .get(layer)
      .get(groupKey);

    return featureGroupMap.get(featureTypeKey as string);
  }

  layerVisible(group: any, index: any) {
    let element = document.getElementsByClassName(
      'group-' + group
    ) as HTMLCollection;
    if (element.length > 0) {
      for (let i = 0; i < element[0].classList.length; i++) {
        if (element[0].classList[i] === 'fa-eye') {
          element[0]?.classList?.remove('fa-eye');
          element[0]?.classList?.add('fa-eye-slash');
          break;
        } else if (element[0].classList[i] === 'fa-eye-slash') {
          element[0]?.classList?.remove('fa-eye-slash');
          element[0]?.classList?.add('fa-eye');
          break;
        }
      }
    }
  }

  eyeLashNotVisible(
    mainEleClass: any,
    removeClass?: string,
    addClass?: string
  ) {
    removeClass = removeClass ? removeClass : 'fa-eye-slash';
    addClass = addClass ? addClass : 'fa-eye';
    let element = document.getElementsByClassName(
      'group-' + mainEleClass
    ) as HTMLCollection;
    if (element.length > 0) {
      for (let i = 0; i < element[0].classList.length; i++) {
        if (element[0].classList[i] === removeClass) {
          element[0]?.classList?.remove(removeClass);
          element[0]?.classList?.add(addClass);
          break;
        }
      }
    }
  }

  //toggleGroup method is used to show/hide the group on click on eye and eyelash
  toggleGroup(featureGroup: any, layer: any, fromLocation?: any) {
    const layerID = this.getLayerID(layer);
    let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
      .get(layer)
      .get(featureGroup);
    if (this.featureGroupToggleStateMap.get(layer).get(featureGroup)) {
      const pointLayerId = `${layerID}-${featureGroup}-point`;
      const lineLayerId = `${layerID}-${featureGroup}-line`;
      // this.map.setFilter(pointLayerId, false);
      // this.map.setFilter(lineLayerId, false);
      this.featureGroupToggleStateMap.get(layer).set(featureGroup, false);
      let featureGroupFeatureNameToggleStateMap: any;
      featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
        featureGroupFeatureTypeMap.set(key, false);
        featureGroupFeatureNameToggleStateMap =
          this.featureGroupTypeFeatureNameToggleStateMap
            .get(layer)
            .get(featureGroup)
            .get(key);
        featureGroupFeatureNameToggleStateMap.forEach(
          (value1: boolean, key1: string) => {
            this.featureGroupTypeFeatureNameToggleStateMap
              .get(layer)
              .get(featureGroup)
              .get(key)
              .set(key1, false);
          }
        );
      });
      this.featureGroupTypeToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);
      this.featureGroupTypeToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);
      setTimeout(() => {
        if (fromLocation != 'toggleSite') {

          // this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
          this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
        }
      }, 200);
    } else {
      const pointLayerId = `${layerID}-${featureGroup}-point`;
      const lineLayerId = `${layerID}-${featureGroup}-line`;
      // this.map.setFilter(
      //   pointLayerId,
      //   this.getGroupFilterExpression(featureGroup)
      // );
      // this.map.setFilter(
      //   lineLayerId,
      //   this.getGroupFilterExpression(featureGroup)
      // );
      this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);
      let featureGroupFeatureNameToggleStateMap: any;
      featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
        featureGroupFeatureTypeMap.set(key, true);
        featureGroupFeatureNameToggleStateMap =
          this.featureGroupTypeFeatureNameToggleStateMap
            .get(layer)
            .get(featureGroup)
            .get(key);
        featureGroupFeatureNameToggleStateMap.forEach(
          (value1: boolean, key1: string) => {
            this.featureGroupTypeFeatureNameToggleStateMap
              .get(layer)
              .get(featureGroup)
              .get(key)
              .set(key1, true);
          }
        );
      });
      this.featureGroupTypeToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);
      setTimeout(() => {
        if (fromLocation != 'toggleSite') {

          // this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
          this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
        }
      }, 200);
      //for layer
      this.isLayerEyeVisible[layer] = true;
    }
  }

  toggleSiteGroup(featureGroup: any, layer: any) {
    const layerID = this.getLayerID(layer);
    if (layerID === 5) {
      this.toggleGPRSLayer('GPRS');
      this.toggleGPRSLayer('CLIENT');
      this.toggleGPRSLayer('IMPORTED');
      this.toggleGPRSLayer('EXTERNAL');
    }
  }

  toggleSiteType(featureGroup: any, featureType: any, layer: any) {
    const layerID = this.getLayerID(layer);
    if (layerID === 5) {
      const tempLayerData = this.navigatedFeatures.find((it: any) => it.layerId === layerID);
      const jobId = tempLayerData.features.find((it: any) => it.featureType === featureType);
      const temp = [jobId.featureTypeId];

      const siteLayerGroup = this.navigatedFeatures.filter((it: any) => it.jobId === temp[0]);
      siteLayerGroup?.forEach((feature: any) => {

        feature?.features?.forEach((element: any) => {
          this.toggleGroup(element.featureGroup, feature.layerName, 'toggleSite');
        });
      });



      // this.commonMapService.filterMVTFromToggle(temp, this.map);
      this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
    }
  }

  //toggleFeatureType method is used to show/hide the feature type on click on eye and eyelash
  toggleFeatureType(featureGroup: any, featureType: any, layer: any) {
    const layerID = this.getLayerID(layer);
    let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
      .get(layer)
      .get(featureGroup);
    let featureGroupFeatureNameToggleStateMap: any;
    if (featureGroupFeatureTypeMap.get(featureType)) {
      featureGroupFeatureTypeMap.set(featureType, false);
      let featureGroupFeatureNameToggleStateMap =
        this.featureGroupTypeFeatureNameToggleStateMap
          .get(layer)
          .get(featureGroup)
          .get(featureType);
      featureGroupFeatureNameToggleStateMap.forEach(
        (value1: boolean, key1: string) => {
          this.featureGroupTypeFeatureNameToggleStateMap
            .get(layer)
            .get(featureGroup)
            .get(featureType)
            .set(key1, false);
        }
      );
      this.featureGroupTypeToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);
    } else {
      featureGroupFeatureTypeMap.set(featureType, true);
      let featureGroupFeatureNameToggleStateMap =
        this.featureGroupTypeFeatureNameToggleStateMap
          .get(layer)
          .get(featureGroup)
          .get(featureType);
      featureGroupFeatureNameToggleStateMap.forEach(
        (value1: boolean, key1: string) => {
          this.featureGroupTypeFeatureNameToggleStateMap
            .get(layer)
            .get(featureGroup)
            .get(featureType)
            .set(key1, true);
        }
      );
      this.featureGroupTypeToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);

      //for group
      this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);

      //for layer
      this.isLayerEyeVisible[layer] = true;
    }
    // First get the featureGroup part of the filter
    // let groupFilterExpression = this.getGroupFilterExpression(featureGroup);
    // // Then get the featuretype part of the expressio
    // let typeFilterExpression = this.getTypeFilterExpression(
    //   featureGroup,
    //   featureType,
    //   layer
    // );

    // Only add featuretype expression if at least one is hidden
    // if (typeFilterExpression.length > 0) {
    //   groupFilterExpression.push(typeFilterExpression);
    // }
    // Apply the filter to the featureGroup layer
    // this.map.setFilter(
    //   layerID + '-' + featureGroup + '-point',
    //   groupFilterExpression
    // );
    // this.map.setFilter(
    //   layerID + '-' + featureGroup + '-line',
    //   groupFilterExpression
    // );
    setTimeout(() => {
      // this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
      this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
    }, 200);
  }
  // Function to return the "featuretype" part of a filter expression
  // Implies featureGroup expression as well
  getTypeFilterExpression(
    featureGroup: string,
    featureType: string,
    layer: any
  ) {
    let featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
      .get(layer)
      .get(featureGroup);
    let visibleFeatueTypeArrayForGivenGroup: string[] = [];
    featureGroupFeatureTypeMap.forEach((value: boolean, key: string) => {
      if (value) {
        visibleFeatueTypeArrayForGivenGroup.push(key);
      }
    });
    let expression = [
      'in',
      ['get', 'featureType'],
      ['literal', visibleFeatueTypeArrayForGivenGroup],
    ];
    return expression;
  }

  // Function to return the "featureid" part of a filter expression
  // Implies featureGroup and/or featuretype expression(s) as well
  getFeatureFilterExpression(
    featureGroup: string,
    featureType: StringMap,
    layer: any
  ) {
    let featureGroupFeatureTypeMap =
      this.featureGroupTypeFeatureNameToggleStateMap
        .get(layer)
        .get(featureGroup);
    let visibleFeatueNameArray: string[] = [];
    featureGroupFeatureTypeMap.forEach((val: boolean, keyForType: string) => {
      let featureTypeFeatureNameMap =
        featureGroupFeatureTypeMap.get(keyForType);
      featureTypeFeatureNameMap.forEach((value: boolean, key: string) => {
        if (value) {
          visibleFeatueNameArray.push(key);
        }
      });
    });
    let expression = [
      'in',
      ['get', 'feature'],
      ['literal', visibleFeatueNameArray],
    ];
    return expression;
  }

  //toggleFeature method is used to show/hide the feature type on click on eye and eyelash
  toggleFeature(
    featureGroup: any,
    featureType: any,
    featureName: any,
    layer: any
  ) {
    const layerID = this.getLayerID(layer);
    let featureGroupFeatureTypeMap =
      this.featureGroupTypeFeatureNameToggleStateMap
        .get(layer)
        .get(featureGroup);
    let featureTypeFeatureNameMap = featureGroupFeatureTypeMap.get(featureType);

    if (featureTypeFeatureNameMap.get(featureName)) {
      featureTypeFeatureNameMap.set(featureName, false);
      featureGroupFeatureTypeMap.set(featureType, featureTypeFeatureNameMap);
      this.featureGroupTypeFeatureNameToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);
    } else {
      //for feature
      featureTypeFeatureNameMap.set(featureName, true);
      featureGroupFeatureTypeMap.set(featureType, featureTypeFeatureNameMap);
      this.featureGroupTypeFeatureNameToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);

      //for feature type
      featureGroupFeatureTypeMap = this.featureGroupTypeToggleStateMap
        .get(layer)
        .get(featureGroup);
      featureGroupFeatureTypeMap.set(featureType, true);
      this.featureGroupTypeToggleStateMap
        .get(layer)
        .set(featureGroup, featureGroupFeatureTypeMap);

      //for group
      this.featureGroupToggleStateMap.get(layer).set(featureGroup, true);

      //for layer
      this.isLayerEyeVisible[layer] = true;
    }
    // First get the featureGroup part of the filter
    // let groupFilterExpression = this.getGroupFilterExpression(featureGroup);
    // // Then get the featuretype part of the expressio
    // let typeFilterExpression: any
    // if (featureType)
    //   typeFilterExpression = this.getTypeFilterExpression(
    //     featureGroup,
    //     featureType,
    //     layer
    //   );

    // // Then get the Feature Id part of the expression
    // let featureFilterExpression = this.getFeatureFilterExpression(
    //   featureGroup,
    //   featureType,
    //   layer
    // );
    // // Only add featuretype expression if at least one is hidden
    // if (featureType)
    //   if (typeFilterExpression.length > 0) {
    //     groupFilterExpression.push(typeFilterExpression);
    //   }
    // if (featureFilterExpression.length > 0) {
    //   groupFilterExpression.push(featureFilterExpression);
    // }
    // // Apply the filter to the featureGroup layer
    // this.map.setFilter(
    //   layerID + '-' + featureGroup + '-point',
    //   groupFilterExpression
    // );
    // if (this.map.getFilter(layerID + '-' + featureGroup + '-line') != undefined) {
    //   this.map.setFilter(
    //     layerID + '-' + featureGroup + '-line',
    //     groupFilterExpression
    //   );
    // }
    setTimeout(() => {
      // this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map);
      this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
    }, 200);
  }

  //fetchFeatureTypeColor method is used to get the color for the line and point
  fetchFeatureTypeColor(featureTypeId: string | number): string {
    if (this.featureTypeStyleMap.get(parseInt(featureTypeId as string)) == undefined) {
      return '#808080';
    } else {
      return this.featureTypeStyleMap.get(parseInt(featureTypeId as string)).color;
    }
  }

  //# matterport link add or update by feature id
  matterportLinkAddUpdate(linkvalue: any) {
    //https://my.matterport.com/show/?m=xkYCeVLXd4M
    if (linkvalue.trim()) {
      let linkAdded =
        this.openedFeatureAttributes.addedLink != null || ''
          ? this.openedFeatureAttributes.addedLink + ' , ' + linkvalue
          : linkvalue;
      this.spinner.show();
      let payLoad = {
        featureId: this.matterportAddLinkObj.featureId,
        featureTypeId: this.matterportAddLinkObj.featureTypeId,
        geometryType: this.matterportAddLinkObj.geometryType,
        addedLink: linkAdded,
      };

      this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
        .matterportLinkAdd(payLoad)
        .subscribe((response) => {
          const matterlinkaddedResponse: any = response;
          this.spinner.hide();

          if (matterlinkaddedResponse.status === 'Failure') {
            this.toastr.error('Link Not Added');
          } else {
            this.toastr.success('Link added successfully');
            this.afterLinkrefresh();
          }
          let linkText = document.getElementById(
            'addLinksInput'
          ) as HTMLInputElement;
          linkText.value = '';
        });
    }
  }

  afterLinkrefresh() {
    this.spinnerService.show();
    if (this.matterportAddLinkObj.geometryType === 'LineString') {
      this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
        .fetchLineFeatureDetail(
          this.checkAnnotationLine(this.matterportAddLinkObj.allProperty),
          this.matterportAddLinkObj.featureId
        )
        .subscribe((data: any) => {
          this.openedFeatureProperties = data.groups.features[0].properties;
          this.openedFeatureAttributes = data.groups.features[0].attributes;
          this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
          this.jobId = data.jobId;
          this.openedFeatureName =
            data.groups.features[0].properties.FeatureName;
          if (
            data.groups.features[0].attributes.addedLink != null ||
            undefined ||
            ''
          ) {
            let textLink = data.groups.features[0].attributes.addedLink;
            this.openedFeatureAttributesAddedLink = textLink.split(' , ');
          } else {
            this.openedFeatureAttributesAddedLink = [];
          }
          this.slideMenuOpenJobDescription = true;
          this.slideMenuOpenTools = false;
          this.slideMenuOpenFolder = false;
          this.slideMenuOpenLayerDetails = false;
          this.spinnerService.hide();
        });
    } else if (this.matterportAddLinkObj.geometryType === 'Point') {
      this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
        .fetchPointFeatureDetail(
          this.checkAnnotationPoint(this.matterportAddLinkObj.allProperty),
          this.matterportAddLinkObj.featureId
        )
        .subscribe((data: any) => {
          this.openedFeatureProperties = data.groups.features[0].properties;
          this.openedFeatureAttributes = data.groups.features[0].attributes;
          this.openedFeatureAttributes.coordinate = this.createCoordinates(data.groups.features[0].geometry);
          this.jobId = data.jobId;
          this.openedFeatureName =
            data.groups.features[0].properties.FeatureName;
          if (
            data.groups.features[0].attributes.addedLink != null ||
            undefined ||
            ''
          ) {
            let textLink = data.groups.features[0].attributes.addedLink;
            this.openedFeatureAttributesAddedLink = textLink.split(' , ');
          } else {
            this.openedFeatureAttributesAddedLink = [];
          }
          this.slideMenuOpenJobDescription = true;
          this.slideMenuOpenTools = false;
          this.slideMenuOpenFolder = false;
          this.slideMenuOpenLayerDetails = false;
          this.spinnerService.hide();
        });
    }

    // this.map.on('click', this.zoomedLayerIdArray, (e: any) => {
    //   this.matterportAddLinkObj = {
    //     "featureId": parseInt(e.features[0].properties.featureId),
    //     "featureTypeId": parseInt(e.features[0].properties.featureTypeId),
    //     "geometryType": e.features[0].geometry.type,

    //   }
    //   this.spinnerService.show();


    //   if (e.features[0].geometry.type === "LineString") {
    //     this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService.fetchLineFeatureDetail(parseInt(e.features[0].properties.featureId)).subscribe((data: any) => {
    //       this.openedFeatureAttributes = data.groups.features[0].attributes;
    //       this.openedFeatureName = data.groups.features[0].properties.FeatureName;
    //       this.slideMenuOpenJobDescription = true;
    //       this.slideMenuOpenTools = false;
    // this.slideMenuOpenFolder = false;
    //       this.slideMenuOpenLayerDetails = false;
    //       this.spinnerService.hide();
    //     });
    //   } else if (e.features[0].geometry.type === "Point") {
    //     this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService.fetchPointFeatureDetail(parseInt(e.features[0].properties.featureId)).subscribe((data: any) => {
    //       this.openedFeatureAttributes = data.groups.features[0].attributes;
    //       this.openedFeatureName = data.groups.features[0].properties.FeatureName;
    //       this.slideMenuOpenJobDescription = true;
    //       this.slideMenuOpenTools = false;
    // this.slideMenuOpenFolder = false;
    //       this.slideMenuOpenLayerDetails = false;
    //       this.spinnerService.hide();
    //     });
    //   }
    // });
  }

  ngOnDestroy() {
    this.behaviourSubjectService.printShareTrigger('');
    this.printTypeSubscription?.unsubscribe();
    this.getAllJObSubscribe?.unsubscribe;
    this.mapViewerJobFeatureSubscribe?.unsubscribe;
    this.featureDeleteSubscribe?.unsubscribe;
    this.mapViewerDocumentsSubscribe?.unsubscribe();
    this.mapViewerSubscribe?.unsubscribe;
    this.featureGroupSearchSubscribe?.unsubscribe();
    this.featureTypeSearchSubscribe?.unsubscribe();
    this.getAllVectorsSubscribe?.unsubscribe();
    this.getAllRasterSubscribe?.unsubscribe();
    this.headerLayerButtonStateSubscription.unsubscribe();
    this.headerFolderButtonStateSubscription.unsubscribe();
    this.headerMapToolButtonStateSubscription.unsubscribe();
   // this.qaEnvValSubscription.unsubscribe();
    this.routeParamSubscription?.unsubscribe();
    this.finJobsSubscription?.unsubscribe();
    this.searchTypeSubscription?.unsubscribe();
    this.searchInputSubscription?.unsubscribe();
    this.headerSearchSubscrition?.unsubscribe();
    this.forkJoinMapFeaturesSubscription?.unsubscribe();
    this.myPreferenceSubscription.unsubscribe();
    this.editingHistoryUndoArray = [];
    this.editingHistoryRedoArray = [];
    this.changesTobeCommitted = [];
    this.changesTobeReverted = [];
    this.isRefreshRequiredForCommittedChange = true;
    this.isSingleFeatureSave = false
    this.mapEditService.mapEditorChangesToBeCommitted = [];
    this.commitChangesSubscription?.unsubscribe();

    // leaving mapviewer job, removed the credentialess login info
    this.isCredentialessLogin = false;
    this.credentialessLogin = {};
    this.beahviourSubjectService.changeCredentialessLogin(this.credentialessLogin);

  }

  getFinalGrp(group: any) {
    let t = group?.split(' ').join('-');
    if (group.includes('.')) {
      t = t.split('.').join('-');
    }
    return t;
  }

  //# generate export link
  generateExportLink() {
    this.polygonGeoJson = {
      type: 'Polygon',
      coordinates: this.exportDrawPolygonCordinates,
    };
    this.polygonGeoJson = JSON.stringify(this.polygonGeoJson);
    var mapjobid = this.navigatedJobId;
    if (
      this.exportDrawPolygonCordinates == undefined ||
      this.exportDrawPolygonCordinates.length == 0
    ) {
      this.polygonGeoJson = 'string';
    } else {
      mapjobid = 0;
    }

    let payLoad = {
      submittedByUserId: this.loginUserId,
      mapJobId: mapjobid,
      polygonGeoJson: this.polygonGeoJson,
      fileFormatTypeId: this.exportForm.value.filetype,
      exportFileName: this.exportForm.value.filename,
    };

    this.toastr.success('Request for export is submitted ');
    this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
      .generateExportLink(payLoad)
      .subscribe((response) => {
        const linkaddedResponse: any = response;
        this.spinner.hide();
        if (linkaddedResponse.processingStatus.status === 'Success') {
          sessionStorage.setItem(
            'exportJobId',
            linkaddedResponse.importExportJobId
          );
          // this.exportForm.reset(this.exportForm.value);
          this.exportForm.value.filename = '';

          setTimeout(() => {
            this.toastr.clear();
            this.toastr.success(
              'The export process has started! Link for download will be available once complete.'
            );
          }, 3000);
        } else {
          this.toastr.error('Error in export');
        }
      });
  }

  measeureLength(length: any, latLong: any) {
    if (length >= 5280) {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(latLong)
          .setHTML(
            `<strong>Length of drawn string is : ${(length / 5280).toFixed(
              2
            )} mi.</strong>`
          )
          .addTo(this.map);
      }, 0);
    } else {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(latLong)
          .setHTML(
            `<strong>Length of drawn string is : ${length} feet</strong>`
          )
          .addTo(this.map);
      }, 0);
    }
  }

  measureCalcArea(calcArea: any, lantLong: any) {
    if (calcArea >= 43560) {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(lantLong)
          .setHTML(
            `<strong>Area of drawn polygon is : ${(calcArea / 43560).toFixed(
              4
            )} acres.</strong>`
          )
          .addTo(this.map);
      }, 0);
    } else {
      setTimeout(() => {
        new mapboxgl.Popup()
          .setLngLat(lantLong)
          .setHTML(
            `<strong>Area of drawn polygon is : ${calcArea} sq. feet</strong>`
          )
          .addTo(this.map);
      }, 0);
    }
  }

  //# Map editor Code Start //
  // Points
  // Adding a new Point
  handleAddPoint() {
    // this.draw.changeMode('sitemap_draw_point');
    this.draw.changeMode('sitemap_draw_point', {
      mapLayerId: this.currentEditinglayerId,
      navigatedFeatures: this.navigatedFeatures,
      isSnappingToVertex: this.isSnappingToVertex,
      isSnappingToLine: this.isSnappingToLine});
  }
  // Updating a Point
  // Need to fetch the feature's GeoJSON from API
  // If more than one feature is selected, prompt user to confirm which feature they want to edit
  handleUpdatePoint() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'updating_point';
  }
  // Deleting a Point
  // The featureId and featureClass are in the data
  // Confirm deletion and then send POST request to API with featureId
  handleDeletePoint() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'deleting_point';
  }

  // Lines
  // Adding a new Line
  // Ask use if they want to create a "regular line" or an "annotation line"
  handleAddLine() {
    // Set drawing mode
    this.draw.changeMode('sitemap_draw_line_string', {
      mapLayerId: this.currentEditinglayerId,
      navigatedFeatures: this.navigatedFeatures,
      isSnappingToVertex: this.isSnappingToVertex,
      isSnappingToLine: this.isSnappingToLine});
    // alert('Would you like to create a "regular line" or an "annotation line?');
    let answer = 'line';
    if (answer === 'line') {
      this.currentDrawingState = 'adding_line';
    } else {
      this.currentDrawingState = 'adding_annotation_line';
    }
  }
  // Updating a Line
  // Need to fetch the feature's GeoJSON from API
  // If more than one feature is selected, prompt user to confirm which feature they want to edit
  handleUpdateLine() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'updating_line';
  }
  // Deleting a Line
  // The featureId and featureClass are in the data
  // Confirm deletion and then send POST request to API with featureId
  handleDeleteLine() {
    // Set state
    this.draw.changeMode('sitemap_simple_select');
    this.currentDrawingState = 'deleting_line';
  }

  //gotoMapEditor method is used to enable edit feature's when user click on the edit icon
  gotoMapEditor(layer: any,layerPermission?:any) {
    if (!this.editCheck) {
      this.permissionService.showErrormessageForPersonal();
      return;
    }
    if(!this.isCurrentUserHasEditAccessOnJob) {
      this.toastr.error(`You do not have "Edit Access" on jobid ${this.navigatedJobId}`);
      return;
    }
    if (this.zoomedJobIds.length === 1) {
      this.isLayerEyeVisible = {
        GPRS: true,
        CLIENT: true,
        IMPORTED: true,
        EXTERNAL: true,
        REFERENCE: false
      };
      this.isEditLayer = false;
      this.editLayerName = layer;
      this.hideShowLayer(layer);
      if (layer.toUpperCase() !== 'EXTERNAL' || layer.toUpperCase() !== 'REFERENCE') {
        this.bindGroupDropdown();
      }

      this.currentEditinglayerId = this.getLayerID(layer)?.toString();
      this.isEditingModeIsOn = true;
      this.isLayerEyeVisible[layer] = false;
      ['GPRS', 'CLIENT', 'IMPORTED', 'EXTERNAL', 'REFERENCE'].forEach(element => {
        this.toggleGPRSLayer(element);
      });
      // this.toggleGPRSLayer('GPRS');
      // this.toggleGPRSLayer('CLIENT');
      // this.toggleGPRSLayer('IMPORTED');
      if(this.isEditingModeIsOn) {
        this.onHistorySlideoutClose(false);
      }

      if (layer.toUpperCase() === 'EXTERNAL') {
        this.getExternalContentTypes();
        this.viewExternalComponentsByJobId();
      }
      if (layer.toUpperCase() === 'REFERENCE') {
        this.reteriveVectorTilesbyUser();
      }
    }
    else if (this.zoomedJobIds.length > 1) {
      this.toastr.error("Please select single job for editing.", "", { timeOut: 2000 })
    }
    // Setup environment for all ediiting
    this.toggleEditingState(true, layer);
  }

  //bindGroupDropdown method is used to bind the add group dropdown
  bindGroupDropdown() {
    this.groupDropDownData = [];
    FeatureTypeStyleMapping.featureTypeStyleJson.forEach((element) => {
      const val = {
        featureGroupId: element.feature_group_id,
        featureGroup: element.feature_group_name,
      };
      if (this.groupDropDownData?.length > 0) {
        if (
          this.groupDropDownData?.find(
            (it) => it.featureGroupId != element.feature_group_id
          )
        )
          this.groupDropDownData.push(val);
      } else {
        this.groupDropDownData.push(val);
      }
    });
    this.groupDropDownData = this.groupDropDownData.filter(
      (array, i, self) =>
        i === self.findIndex((t) => t.featureGroupId === array.featureGroupId)
    );
    this.groupToSelect = this.groupDropDownData[0].featureGroupId;
    this.bindFeatureTypeDropdown(this.groupDropDownData[0].featureGroupId);
  }

  //bindFeatureTypeDropdown method is used to bind the add feature type dropdown
  bindFeatureTypeDropdown(groupId: any, isEditValue?: any) {
    this.featureTypeDropDownData = [];
    let featureTypeData = FeatureTypeStyleMapping.featureTypeStyleJson.filter(
      (it) => it.feature_group_id === groupId
    );
    featureTypeData?.forEach((element) => {
      const val = {
        featureTypeId: element.feature_type_id,
        featureType: element.feature_type_name,
      };
      if (this.featureTypeDropDownData?.length > 0) {
        if (
          this.featureTypeDropDownData?.find(
            (it) => it.featureTypeId != element.feature_type_id
          )
        )
          this.featureTypeDropDownData.push(val);
      } else {
        this.featureTypeDropDownData.push(val);
      }
    });
    let imgName = null;
    if (isEditValue) {
      this.featureTypeToSelect = isEditValue?.properties.featureTypeId;
      this.featureTypeForBtnVisible = isEditValue?.properties.featureType;
      imgName = isEditValue?.properties.featureType;

    }
    else {
      this.featureTypeToSelect = this.featureTypeDropDownData[0].featureTypeId;
      this.featureTypeForBtnVisible = this.featureTypeDropDownData[0].featureType;
      imgName = this.featureTypeDropDownData[0].featureType;
    }
    const featureGeometryType =
      FeatureTypeStyleMapping.featureTypeStyleJson.find(
        (it: any) => it.feature_type_id === parseInt(this.featureTypeToSelect)
      )?.geometry_type;
    if (featureGeometryType?.toLowerCase() === 'point') {
      this.featureImg =
        '../../../../../../assets/images/GPRS_PNG/' +
        imgName +
        '.png';
    }
    else {
      this.featureImg = "";
    }
  }

  //onGroupChange method is used to change the group in dropdown
  onGroupChange(target: any) {
    if (target?.value) {
      this.bindFeatureTypeDropdown(parseInt(target.value));
      this.groupToSelect = this.groupDropDownData
        .find((it) => it.featureGroupId === parseInt(target.value))
        ?.featureGroupId?.toString();

    }
  }

  //onFeatureTypeChange method is used to change the feature type in dropdown
  onFeatureTypeChange(target: any) {

    this.featureTypeToSelect = this.featureTypeDropDownData
      .find((it) => it.featureTypeId === parseInt(target.value))
      ?.featureTypeId?.toString();
    this.featureTypeForBtnVisible = this.featureTypeDropDownData.find(
      (it) => it.featureTypeId === parseInt(target.value)
    )?.featureType;

    const featureGeometryType =
      FeatureTypeStyleMapping.featureTypeStyleJson.find(
        (it: any) => it.feature_type_id === parseInt(this.featureTypeToSelect)
      )?.geometry_type;
    if (featureGeometryType?.toLowerCase() === 'point') {
      this.featureImg =
        '../../../../../../assets/images/GPRS_PNG/' +
        this.featureTypeDropDownData.find(
          (it) => it.featureTypeId === parseInt(target.value)
        )?.featureType +
        '.png';
    }
    else {
      this.featureImg = "";
    }

  }

  //hideShowLayer method is used to show/hide the layer when user in edit mode
  hideShowLayer(layer: any) {
    for (const key of Object.keys(this.isLayerEditVisible[0])) {
      if (key != layer) {
        this.isLayerEditVisible[0][key] = false;
      } else {
        this.isLayerEditVisible[0][key] = true;
      }
    }
  }

  //showAllLayer method is used to show all layer when close/exit the edit layer
  showAllLayer() {
    for (const key of Object.keys(this.isLayerEditVisible[0])) {
      this.isLayerEditVisible[0][key] = true;
    }
  }

  //checkEditLayerVisible method is used return the layer visble or not
  checkEditLayerVisible(layer: any) {
    return this.isLayerEditVisible[0][layer];
  }

  //checkEyeLayerVisible method is used return the layer visble or not
  checkEyeLayerVisible(layer: any) {
    return this.isLayerEyeVisible[layer];
  }

  //checkEyeGroupVisible method is used return the group visble or not
  checkEyeGroupVisible(featureGroup: any, layer: any) {
    const featureGroupVal = this.featureGroupToggleStateMap
      .get(layer)
      .get(featureGroup);
    return featureGroupVal;
  }

  //checkEyeFeatureTypeVisible method is used return the feature type visble or not
  checkEyeFeatureTypeVisible(layer: any, featureGroup: any, featureType: any) {
    const featureGroupVal = this.featureGroupTypeToggleStateMap
      .get(layer)
      .get(featureGroup)
      .get(featureType);
    return featureGroupVal;
  }

  //checkEyeFeatureNameVisible method is used return the feature Name visble or not
  checkEyeFeatureNameVisible(
    layer: any,
    featureGroup: any,
    featureType: any,
    featureName: any
  ) {
    const featureGroupVal = this.featureGroupTypeFeatureNameToggleStateMap
      .get(layer)
      .get(featureGroup)
      .get(featureType)
      .get(featureName);
    return featureGroupVal;
  }

  //addGroupFeatureType method is used add selected feature whatever last user selected
  addGroupFeatureType(layer: any,mergeItem?:any) {
    // resetting selected draw feature
    this.selectedDrawFeature = undefined;
    let drawFeatures = this.draw.getAll().features.filter((feature: any) => feature.id !== this.snapFeatureLayerId);
    if (drawFeatures?.length > 0 && !drawFeatures[drawFeatures?.length - 1].properties.featureName) {

      alert("Please add the last selected feature to map before you proceed.");
    }
    else {

      let data = { layerName: layer };
      const featureGeometryType =
        FeatureTypeStyleMapping.featureTypeStyleJson.find(
          (it: any) => it.feature_type_id === parseInt(this.featureTypeToSelect)
        )?.geometry_type;
        if(mergeItem) {
          this.newAddedFeatureName = mergeItem.properties.featureName;
        }
        else {
      this.newAddedFeatureName = `temp_${this.featureTypeDropDownData.find(
        (it) => it.featureTypeId === parseInt(this.featureTypeToSelect)
      )?.featureType
        }_${new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })}`;
        this.firstSecondLineFeatureFrMergedArr = []; //clear array

      }
      let feature: any = {
        type: 'Feature',
        properties: {
          featureGroupId: parseInt(this.groupToSelect),
          featureGroup: this.groupDropDownData.find(
            (it) => it.featureGroupId === parseInt(this.groupToSelect)
          )?.featureGroup,
          featureTypeId: parseInt(this.featureTypeToSelect),
          featureType: this.featureTypeDropDownData.find(
            (it) => it.featureTypeId === parseInt(this.featureTypeToSelect)
          )?.featureType,
          featureId: this.getLayerID(layer)?.toString(),
          featureName: this.newAddedFeatureName,
          featureGeometryType: featureGeometryType,
        },
        geometry: {
          type: null,
          coordinates: null,
        },
      };

      this.newAddedFeatureInLayer = data;
      this.newlyAddedFeature.push(feature);

      if (this.featureGroupMap.has(data.layerName)) {
        this.addMainFeatureData(data, feature, 'hasValue');
      } else {
        this.addMainFeatureData(data, feature, null);
      }
      this.addFeatureForMapEditor(
        this.groupDropDownData.find(
          (it) => it.featureGroupId === parseInt(this.groupToSelect)
        ).featureGroup,
        this.featureTypeDropDownData.find(
          (it) => it.featureTypeId === parseInt(this.featureTypeToSelect)
        )?.featureType,
        layer
      );
      this.editSelectedFeature = feature;
      this.showHideToggleGroup(feature);
      if (featureGeometryType === 'POINT') {
        this.handleAddPoint();
      } else if (featureGeometryType === 'LINE') {
        this.handleAddLine();
      }
    }
  }

  //addFeatureForMapEditor method is used added new feature in edit mode
  addFeatureForMapEditor(group: any, featureType: any, layer: any) {
    let featureTypeData = FeatureTypeStyleMapping.featureTypeStyleJson.filter(
      (it) => it.feature_type_name === featureType
    );
    this.addNewFeatureGrpFrMapEditor = group;
    this.addNewFeatureTypeFrMapEditor = featureType ? featureType : group;
    this.addNewFeatureGrpIdFrMapEditor = featureType ? featureTypeData[0].feature_group_id : this.externalContentDropdownData.find((it: any) => it.exernalComponentName === this.selectOptionsExternal).externalComponentTypeId;
    this.addNewFeatureTypeIdFrMapEditor = featureType ? featureTypeData[0].feature_type_id : null;
  }

  //isGroupData method is used get the group array for the layer
  isGroupData(layer: any, group: any) {
    if (group) return this.featureGroupMap.get(layer).get(group);
    else {
      return false;
    }
  }

  //isEyeViewVisibleForEmpty method is used to show eye icon or not
  isEyeViewVisibleForEmpty(layer: any) {
    const layerKeys = Array.from(this.featureGroupMap?.keys());
    let data: any;
    if (layerKeys.find((it) => it === layer)) {
      data = Array.from(this.featureGroupMap?.get(layer)?.keys())?.filter(
        (elem) => elem
      );
    }
    return data?.length > 0 ? true : false;
  }

  addFeatureContainerFrEditor(layer: any) {
    this.showAddFeatureContainerFrMapEditor = true;
    this.showAddFeatureButton = false;
  }

  public deleteFeatureFile(layerName: string, file: FileGroup) {
    const confirmMsg = 'Are you sure you want to delete?';
    if (this.deleteAccessCheck(layerName)) {
      if (confirm(confirmMsg)) {
        this.deleteFeatureFileAPI(layerName, file);
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }

  public deleteFeatureFileAPI(layerName: string, file: FileGroup) {
    const layer = this.fetchLayerInfo(layerName);
    this.spinner.show();
    this.featureDeleteSubscribe = this.mapViewerJobFeatureService
      .deleteFeatureFileByJobId(
        this.navigatedJobId,
        parseInt(layer.layerId as string),
        this.loginUserId,
        file.fileId!
      )
      .subscribe(
        (data: any) => {
          if (data.status == 'Success') {
            this.toastr.success('Deleted Successfully');
            this.layerEvents.next({ type: 'DeleteFile', data: { layer, file } });
            this.commonMapService.resetSourceForLayer(this.map, parseInt(layer.layerId as string));
          } else {
            this.toastr.error(data.status + ' : ' + data.message);
          }
          this.spinner.hide();
        },
        (err: any) => {
          this.toastr.error('Error');
          this.spinner.hide();
        }
      );
  }

  deleteFeatureGroup(layer: string, featureGroup: string, fileId?: number) {
    let layerId: any = '';
    if (layer.toUpperCase() == 'GPRS') {
      layerId = 1;
    } else if (layer.toUpperCase() == 'CLIENT') {
      layerId = 2;
    } else if (layer.toUpperCase() == 'IMPORTED') {
      layerId = 3;
    } else if (layer.toUpperCase() == 'EXTERNAL') {
      layerId = 4;
    }
    let delMessage = 'Are you sure you want to delete?';
    if (layer.toUpperCase() === 'EXTERNAL') {
      delMessage = 'Are you sure you want to delete the component?';
    }
    //   Layer ID for GPRS layer is 1, for Client layer is 2, for Imported layer is 3
    if (this.deleteAccessCheck(layer, featureGroup) && layerId != '') {
      if (confirm(delMessage)) {
        this.deleteFeatureGroupAPI(layerId, featureGroup, layer, fileId);
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }

  deleteFeatureGroupAPI(layerId: number, featureGroup: string, layer: string, fileId?: number) {
    let featureUrl = '';
    if (layer.toUpperCase() != 'EXTERNAL') {
      let fetchFeaturesType = this.fetchFeatureTypeArray(featureGroup, layer);
      let featuresArray = this.fetchFeaturesArray(
        featureGroup,
        fetchFeaturesType[0],
        layer
      );
      if (featuresArray?.length != 0) {
        this.spinner.show();
        if (!featureGroup?.toLowerCase().includes('photo')) {
          this.featureDeleteSubscribe = this.mapViewerJobFeatureService
            .deleteFeatureGroupByJobId(
              parseInt(featuresArray[0].featureGroupId as string),
              this.navigatedJobId,
              layerId,
              this.loginUserId,
              fileId
            )
            .subscribe(
              (data: any) => {
                if (data.status == 'Success') {
                  this.toastr.success('Deleted Successfully');
                  const layerInfo = this.fetchLayerInfo(layer);
                  const _group = layerInfo.groups.find(g => g.groupName === featureGroup)!;
                  this.layerEvents.next({ type: 'DeleteGroup', data: { group: _group, layer: layerInfo } });
                  this.commonMapService.resetSourceForLayer(this.map, parseInt(layerInfo.layerId as string));
                } else {
                  this.toastr.error(data.status + ' : ' + data.message);
                }
                this.spinner.hide();
              },
              (err: any) => {
                this.toastr.error('Error');
                this.spinner.hide();
              }
            );
        }
        else {
          const data = {
            userId: this.loginUserId,
            mapLayerId: layerId,
            jobId: this.navigatedJobId
          };
          this.restService.options.body = data;
          this.restService.delete(PortalAPI.DELETE_PHOTO_BY_LAYER_ID, data).subscribe(
            (data: any) => {
              if (data.status == 'Success') {
                this.toastr.success('Deleted Successfully');
                this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
                  this.navigatedFeatures = dataWithLayer;
                  for (const featureData of dataWithLayer) {
                    if (!this.isBasemapToggle) {
                      this.setValuesInRequiredMaps(featureData);
                    } else {
                      this.isBasemapToggle = false;
                    }
                  }

                  this.refreshMapStyle();
                });
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );
        }
      }
    } else if (layer.toUpperCase() == 'EXTERNAL') {
      if (featureGroup == "EXTERNAL LINK") {
        featureUrl = 'DeleteExternalLinkByJobId';
      }
      else if (featureGroup == "ATTACHMENT") {
        featureUrl = 'DeleteAttachmentByJobId';
      }
      else if (featureGroup == "MATTERPORT") {
        featureUrl = 'DeleteMatterportByJobId';
      }
      else if (featureGroup == "POINTCLOUD") {
        featureUrl = 'DeletePointCloudByJobId';
      }
      else if (featureGroup == "VIRTUAL TOUR") {
        featureUrl = 'DeleteVirtualTourByJobId';
      }



      if (featureUrl != '') {
        this.spinner.show();
        this.featureDeleteSubscribe = this.mapViewerJobFeatureService
          .deleteMultipleExternalLayer(
            featureUrl, this.navigatedJobId, this.loginUserId
          )
          .subscribe(
            (data: any) => {
              if (data.status == 'Success') {
                this.toastr.success('Deleted Successfully');
                // this.reloadCurrentRoute();
                // this.refreshMapStyle();
                // if(this.loginUserRole.toLowerCase() === 'client') {
                //   setTimeout(() => {
                //     this.trashFeatureName(layer,featureGroup,featureType,feature.featureName);
                //   }, 2000);
                // }
                // else {
                //   this.trashFeatureName(layer,featureGroup,featureType,feature.featureName);
                // }
                this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
                  this.commonMapService.getExternalContent(this.navigatedJobId, this.loginUserId).subscribe((item: any) => {
                    if (item) {
                      dataWithLayer = [...dataWithLayer, ...item];
                    }
                    this.navigatedFeatures = dataWithLayer;
                    for (const featureData of dataWithLayer) {
                      if (!this.isBasemapToggle) {
                        this.setValuesInRequiredMaps(featureData);
                      } else {
                        this.isBasemapToggle = false;
                      }
                    }

                    this.refreshMapStyle();
                  },
                    (err: any) => {
                      this.toastr.error('Error in getExternalContent');
                      this.spinner.hide();
                    });
                });
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );

      }


    }
  }

  deleteFeatureType(layer: string, featureGroup: string, featureType: string, fileId?: number) {
    let layerId: any = '';
    if (layer.toUpperCase() == 'GPRS') {
      layerId = 1;
    } else if (layer.toUpperCase() == 'CLIENT') {
      layerId = 2;
    } else if (layer.toUpperCase() == 'IMPORTED') {
      layerId = 3;
    } else if (layer.toUpperCase() == 'EXTERNAL') {
      layerId = 4;
    }
    let delMessage = 'Are you sure you want to delete?';
    if (layer.toUpperCase() === 'EXTERNAL') {
      delMessage = 'Are you sure you want to delete the component?';
    }
    //   Layer ID for GPRS layer is 1, for Client layer is 2, for Imported layer is 3
    if (this.deleteAccessCheck(layer) && layerId != '') {
      if (confirm(delMessage)) {
        this.deleteFeatureTypeAPI(layerId, featureGroup, featureType, layer, fileId);
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }

  deleteFeatureTypeAPI(
    layerId: number,
    featureGroup: string,
    featureType: string,
    layer: string,
    fileId?: number
  ) {
    let featuresArray = this.fetchFeaturesArray(
      featureGroup,
      featureType,
      layer
    );
    if (featuresArray?.length != 0) {
      this.spinner.show();
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteFeatureTypeByJobId(
          parseInt(featuresArray[0].featureTypeId as string),
          this.navigatedJobId,
          layerId,
          parseInt(featuresArray[0].featureGroupId as string),
          this.loginUserId,
          fileId
        )
        .subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.toastr.success('Deleted Successfully');
              const layerInfo = this.fetchLayerInfo(layer);
              const _group = layerInfo.groups.find(g => g.groupName === featureGroup)!;
              const _type = _group.types.find(t => t.typeName === featureType)!;
              this.layerEvents.next({ type: 'DeleteType', data: { type: _type, group: _group, layer: layerInfo } });
              this.commonMapService.resetSourceForLayer(this.map, parseInt(layerInfo.layerId as string));
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );
    }
  }

  deleteParticularFeatureAPI(layer: string, featureGroup: string, featureType: string, feature: Feature) {
    if (!(this.loginUserRole == 'Admin' ||
    this.loginUserRole == 'Project Manager')) {
      this.toastr.error("You do not have access to the following feature");
      return
    };

    let featureUrl = '';
    let featureTypeRequest = '';
    if (layer.toUpperCase() === "EXTERNAL") {
      if (featureGroup == "EXTERNAL LINK") {
        featureUrl = 'DeleteExternalLink';
        featureTypeRequest = 'externalLinkId';
      }
      else if (featureGroup == "ATTACHMENT") {
        featureUrl = 'DeleteAttachment';
        featureTypeRequest = 'attachmentId';
      }
      else if (featureGroup == "MATTERPORT") {
        featureUrl = 'DeleteMatterport';
        featureTypeRequest = 'matterportId';
      }
      else if (featureGroup == "POINTCLOUD") {
        featureUrl = 'DeletePointCloud';
        featureTypeRequest = 'pointCloudId';
      }
      else if (featureGroup == "VIRTUAL TOUR") {
        featureUrl = 'DeleteVirtualTour';
        featureTypeRequest = 'virtualTourId';
      }

    } else {
      if (feature.featureGeometryType == 'LineString' && !feature.isAnnotation) {
        featureUrl = 'DeleteLine';
        featureTypeRequest = 'lineId';
      } else if (
        feature.featureGeometryType == 'LineString' &&
        feature.isAnnotation
      ) {
        featureUrl = 'DeleteAnnotationLine';
        featureTypeRequest = 'annotationLineId';
      } else if (feature.featureGeometryType == 'Point' && feature.isAnnotation) {
        featureUrl = 'DeleteAnnotationPoint';
        featureTypeRequest = 'annotationPointId';
      } else if (
        feature.featureGeometryType == 'Point' &&
        !feature.isAnnotation
      ) {
        featureUrl = 'DeletePoint';
        featureTypeRequest = 'pointId';
      }
    }
    if (featureUrl != '') {
      this.spinner.show();
      if (!featureGroup.toLowerCase().includes('photo')) {
        this.featureDeleteSubscribe = this.mapViewerJobFeatureService
          .deleteMultipleFeaturesByFeatureId(
            featureUrl,
            parseInt(feature.featureId as string), this.navigatedJobId, this.loginUserId, featureTypeRequest
          )
          .subscribe(
            (data: any) => {
              if (data.status == 'Success') {
                this.toastr.success('Deleted Successfully');
                const layerInfo = this.fetchLayerInfo(layer);
                this.layerEvents.next({ type: 'DeleteFeature', data: { item: feature, layer: layerInfo } });
                this.commonMapService.resetSourceForLayer(this.map, parseInt(layerInfo.layerId as string));
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );
      }
      else {
        const data = {
          userId: this.loginUserId,
          photoId: parseInt(feature.featureId as string),
          jobId: this.navigatedJobId
        };
        this.restService.options.body = data;
        this.restService.delete(PortalAPI.DELETE_PHOTO, data).subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.toastr.success('Deleted Successfully');
              this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
                this.navigatedFeatures = dataWithLayer;
                for (const featureData of dataWithLayer) {
                  if (!this.isBasemapToggle) {
                    this.setValuesInRequiredMaps(featureData);
                  } else {
                    this.isBasemapToggle = false;
                  }
                }

                this.refreshMapStyle();
              });
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );
      }
    }
  }

  deleteAccessCheck(layer: any, featureGroup?: any) {
    if (layer.toUpperCase() == 'GPRS') {
      if (
        this.loginUserRole == 'Admin' ||
        this.loginUserRole == 'Project Manager'
      ) {
        return true;
      }
    } else if (layer.toUpperCase() == 'CLIENT') {

      if (this.loginUserRole == 'Client' && (!featureGroup?.toLowerCase().includes('photo'))) {
        return true;
      }
    } else if (layer.toUpperCase() == 'IMPORTED' && (!featureGroup?.toLowerCase().includes('photo'))) {
      if (this.loginUserRole == 'Client' || this.loginUserRole == 'Admin') {
        return true;
      }
    } else if (layer.toUpperCase() == 'EXTERNAL') {
      if (this.loginUserRole == 'Client' || this.loginUserRole == 'Project Manager' || this.loginUserRole == 'Admin') {
        return true;
      }
    }
    return false;
  }

  checkAnnotationLine(feature: any) {
    if (this.isCredentialessLogin){
      if (feature.featureClass && feature.featureClass.includes('annotation')) {
        return 'GetAnnotationLineFeatureByIdAndGuid';
      } else {
        return 'RetrieveLineDetailsByLineIdAndGuid';
      }
    } else {
      if (feature.featureClass && feature.featureClass.includes('annotation')) {
        return 'GetAnnotationLineFeatureById';
      } else {
        return 'RetrieveLineDetailsByLineId';
      }
    }

  }

  checkAnnotationPoint(feature: any) {
    if (this.isCredentialessLogin){
      if (feature.featureClass && feature.featureClass.includes('annotation')) {
        return 'GetAnnotationPointFeatureByIdAndGuid';
      } else {
        return 'RetrievePointDetailsByPointIdAndGuid';
      }
    } else {
      if (feature.featureClass && feature.featureClass.includes('annotation')) {
        return 'GetAnnotationPointFeatureById';
      } else {
        return 'RetrievePointDetailsByPointId';
      }
    }

  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  // Drawing interations
  // Paint style for "Draw" Lines
  getLineStyle(paintProperty: string) {
    let paintProp: any = ['case'];

    let lineStyle: any = FeatureTypeStyleMapping.featureTypeStyleJson
      .filter((featureStyle) => featureStyle.geometry_type === 'LINE')
      .forEach((featureStyle: any) => {
        paintProp.push([
          'all',
          ['==', ['get', 'user_featureGroup'], featureStyle.feature_group_name],
          ['==', ['get', 'user_featureType'], featureStyle.feature_type_name],
        ]);
        if (paintProperty === 'line-color') {
          paintProp.push(featureStyle.color);
        } else if (paintProperty === 'line-width') {
          paintProp.push(featureStyle.line_width);
        }
      });

    if (paintProperty === 'line-color') {
      paintProp.push('rgb(255,153,255)');
    } else if (paintProperty === 'line-width') {
      paintProp.push(2);
    }

    return paintProp;
  }

  // When user clicks a feature to edit on the map and it is "valid"  
  handleEditingforPointorLine(clickedFeatures: any) {
    // Valid features are in current editing layer and are not being edited already
    // Get all Draw feature names

    // this.map.setFilter(clickedFeatures[0].layer.id, ['!=', ['get', 'featureid'], clickedFeatures[0].properties.featureid])
    const drawFeatureCollection = this.draw.getAll();
    let currentDrawFeatureNames = drawFeatureCollection.features.map((feature: any) => feature.properties.featureName)
    // Filter from the clicked features only that which are in the correct editing layer
    // and are not already in being edited - i.e. the feature name does not exist in the
    // drawFeatureCollection
    let potentialEditingFeatures = clickedFeatures.filter(
      (feature: any) =>
        feature.properties.layerId === parseInt(this.currentEditinglayerId) &&
        currentDrawFeatureNames.indexOf(feature.properties.featureName) === -1
    );
    // Handle 0, 1 and 2+ features clicked
    let isEdit = true;
    if (potentialEditingFeatures.length > 0) {
      let editingFeature = potentialEditingFeatures[0]
      let editingFeatureName = editingFeature.properties.featureName
      let editingFeatureType = editingFeature.properties.featureType
      let editingFeatureGroup = editingFeature.properties.featureGroup
      if (potentialEditingFeatures.length > 1) {
        if (!confirm(`You have selected multiple features. Would you like to edit ${editingFeatureGroup} - ${editingFeatureType} - ${editingFeatureName}?`)) {
          isEdit = false
        }
      }
      if (isEdit) {
        if (editingFeature.geometry.type === 'Point') {
          // Don't allow Point interactions on MVT features when in Line editing modes (this.disablePointInteractions = true)
          // or when in a drawing mode (sitemap_draw_point, sitemap_draw_line_string, etc.)
          if (!this.disablePointInteractions &&
            !this.isDrawing) {
            this.getFeatureDetailsByFeatureIdForMapEditor(editingFeature);
          }
        } else {
          // Disallow Line interactions in "Add Vertex" mode, if a Line is already selected for the process.
          if (this.editingState.editingModes.isAddingVertex) {
            if (this.addVertexLineId === '') {
              this.getFeatureDetailsByFeatureIdForMapEditor(editingFeature);
            }
          }
          // Disable MVT Line interactions when in a drawing mode (sitemap_draw_point, sitemap_draw_line_string, etc.)
          else if (!this.isDrawing) {
            this.getFeatureDetailsByFeatureIdForMapEditor(editingFeature);
          }
        }
      }
    } else {
      // ???
    }
  }

  handleGetFeatureDetails(retrievedFeature: any, geomType: string) {
    let geometry: any = retrievedFeature.geometry;
    let drawFeature: any = {};
    drawFeature.id = '999' + Math.random().toString().slice(2, 10)//Math.random().toString(36).slice(2) //; //retrievedFeature.properties.featureName.replace('.', '_'); //Math.random().toString().slice(2, 10); //
    drawFeature.type = 'Feature';
    // coords variable for manage External layer and other layers data seperately
    let coords = '';
    // coords = JSON.parse(geometry.coordinates);


    if (this.externalLayerItems.includes(retrievedFeature.properties.featureGroup.toUpperCase().replace(/\s/g, ""))) {
      coords = geometry.coordinates;
    } else {
      if (typeof(geometry) === 'string') {
        geometry = JSON.parse(geometry);
        coords = geometry.coordinates;
      } else {
        geometry.coordinates = geometry.coordinates.replace('STRING, ', '');
        geometry.coordinates = geometry.coordinates.replace('MULTI, ', '');
        coords = JSON.parse(geometry.coordinates);
      }

    }


    if (geomType === 'Point') {
      //  drawFeature.geometry variable for manage External layer and other layers data seperately

      // drawFeature.geometry = { type: 'Point', coordinates: coords[0] }
      if (this.externalLayerItems.includes(retrievedFeature.properties.featureGroup.toUpperCase().replace(/\s/g, ""))) {
        drawFeature.geometry = { type: 'Point', coordinates: coords }
      } else {
        drawFeature.geometry = { type: 'Point', coordinates: coords[0] }
      }
      // this.draw.changeMode('draw_point');
      // this.draw.changeMode('sitemap_draw_point', {
      //   mapLayerId: this.currentEditinglayerId,
      //   navigatedFeatures: this.navigatedFeatures,
      //   isSnappingToVertex: this.isSnappingToVertex,
      //   isSnappingToLine: this.isSnappingToLine});
    } else {
      drawFeature.geometry = { type: 'LineString', coordinates: coords }
      // if (!this.editingState.editingModes.isMergingLine) {
        // this.draw.changeMode('draw_line_string');
        // this.draw.changeMode('sitemap_draw_line_string', {
      //   this.draw.changeMode('sitemap_simple_select', {
      //     featureId: drawFeature.id,
      //     mapLayerId: this.currentEditinglayerId,
      //     navigatedFeatures: this.navigatedFeatures,
      //     isSnappingToVertex: this.isSnappingToVertex,
      //     isSnappingToLine: this.isSnappingToLine});
      // }
    }
    let drawId = this.draw.add(drawFeature);
    this.draw.setFeatureProperty(
      drawId,
      'featureGroup',
      retrievedFeature.properties.featureGroup
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureType',
      retrievedFeature.properties.featureType
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureName',
      retrievedFeature.properties.featureName
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureClass',
      retrievedFeature.properties.featureClass
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureGroupId',
      retrievedFeature.properties.featureGroupId
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureTypeId',
      retrievedFeature.properties.featureTypeId ? retrievedFeature.properties.featureTypeId : retrievedFeature.properties.featureGroupId
    );
    this.draw.setFeatureProperty(
      drawId,
      'featureId',
      retrievedFeature.properties.featureId
    );
    this.draw.setFeatureProperty(
      drawId,
      'mapJobId',
      retrievedFeature.properties.map_job_id ? retrievedFeature.properties.map_job_id : retrievedFeature.properties.mapJobId
    );
    this.draw.setFeatureProperty(
      drawId,
      'mapLayerId',
      retrievedFeature.properties.layerId
    );
    if (geomType === 'Point') {
      if (retrievedFeature.properties.featureClass === 'point') {
        this.draw.setFeatureProperty(
          drawId,
          'mapPointId',
          retrievedFeature.properties.featureId
        );
      } else if (retrievedFeature.properties.featureClass === 'annotation_point') {
        this.draw.setFeatureProperty(
          drawId,
          'mapAnnotationPointId',
          retrievedFeature.properties.featureId
        );
      } else if (this.externalLayerItems.includes(retrievedFeature.properties.featureClass.toUpperCase().replace(/\s/g, ""))) {
        this.draw.setFeatureProperty(
          drawId,
          'mapPointId',
          retrievedFeature.properties.featureId
        );
      }
    } else if (geomType === 'LineString' || geomType === 'MultiLineString') {
      if (retrievedFeature.properties.featureClass === 'line') {
        this.draw.setFeatureProperty(
          drawId,
          'mapLineId',
          retrievedFeature.properties.featureId
        );
      } else if (retrievedFeature.properties.featureClass === 'line_annotation') {
        this.draw.setFeatureProperty(
          drawId,
          'mapAnnotationLineId',
          retrievedFeature.properties.featureId
        );
      }
    }

    // Handle editing modes if needed
    if (geomType === 'Point') {
      // this.draw.changeMode('simple_select', { featureIds: [drawId] });
      this.draw.changeMode('sitemap_simple_select', {
      featureIds: drawId,
      mapLayerId: this.currentEditinglayerId,
      navigatedFeatures: this.navigatedFeatures,
      isSnappingToVertex: this.isSnappingToVertex,
      isSnappingToLine: this.isSnappingToLine
      })
    } else if (geomType === 'LineString' || geomType === 'MultiLineString') {
      let lineFeature = this.draw.get(drawId);
      let editingLineMode;
      let simpleSelectFeatureIds: Array<string> = [];
      if (this.editingState.editingModes.isMergingLine) {
        editingLineMode = 'merge_line';
        // Check for a selected Line - if there is one, then we can merge the pre-existing Line with this new Line
        if (this.mergeParentLineId !== '') {
          this.mergeChildLineId = lineFeature.id;
          this.draw.changeMode('sitemap_simple_select', {
            featureIds: [this.mergeParentLineId, this.mergeChildLineId],
          });
        } else {
          this.mergeParentLineId = lineFeature.id;
          this.draw.changeMode('sitemap_simple_select', {
            featureIds: [this.mergeParentLineId],
          });
        }
      } else if (this.editingState.editingModes.isAddingVertex) {
        editingLineMode = 'add_vertex';
        simpleSelectFeatureIds = [lineFeature.id];
      } else if (this.editingState.editingModes.isMovingVertex) {
        editingLineMode = 'move_vertex';
      } else if (this.editingState.editingModes.isDeletingVertex) {
        editingLineMode = 'delete_vertex';
      } else {
        editingLineMode = 'sitemap_direct_select';
      }
      if (this.editingState.editingModes.isAddingVertex) {
        this.draw.changeMode(editingLineMode, {
          featureIds: simpleSelectFeatureIds,
          mapLayerId: this.currentEditinglayerId,
          navigatedFeatures: this.navigatedFeatures,
          isSnappingToVertex: this.isSnappingToVertex,
          isSnappingToLine: this.isSnappingToLine
        });
      } else if (!this.editingState.editingModes.isMergingLine) {
        this.draw.changeMode(editingLineMode, {
          featureId: lineFeature.id,
          mapLayerId: this.currentEditinglayerId,
          navigatedFeatures: this.navigatedFeatures,
          isSnappingToVertex: this.isSnappingToVertex,
          isSnappingToLine: this.isSnappingToLine
        });
      }
    }
    // Hide the associated feature from the MVT
    this.hiddenFeatures.push(retrievedFeature.properties.featureName);
    // Need to set the feature filter
    // this.setGroupFilter('on', retrievedFeature.properties.featureGroup);
    // Rebuild all MVT Layer filters
    this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
  }

  //# get features details for map editor
  getFeatureDetailsByFeatureIdForMapEditor(editFeature: any, isUpdateDescription?: any) {

    if (editFeature.properties.matterportLinks) {
      this.matterportLinkArray = JSON.parse(editFeature.properties.matterportLinks);
    }
    // Handle external layers features seperate without fetch feature details from API
    if (this.externalLayerItems.includes(editFeature.properties.featureGroup.toUpperCase().replace(/\s/g, "")) && !isUpdateDescription) {
      this.handleGetFeatureDetails(editFeature, editFeature.geometry.type)
      return
    }
    let geomType = editFeature.geometry.type;
    let editFeatureId = editFeature.properties.featureId;

    switch (geomType) {
      case 'Point':
        this.spinner.show();
        this.mapViewerJobFeatureSubscribe =
          this.mapViewerJobFeatureService
            .fetchPointFeatureDetail(
              this.checkAnnotationPoint(editFeature.properties),
              parseInt(editFeatureId)
            ).subscribe((response: any) => {

              const linkaddedResponse: any = response;

              if (response.groups.features.length === 1) {
                //this.refreshDescriptionData(response);

                if (!isUpdateDescription) {
                  let retrievedFeature = response.groups.features[0];
                  // this.handleGetFeatureDetails(retrievedFeature, geomType,);
                  this.handleGetFeatureDetails(retrievedFeature, geomType);
                }
              } else {
                // ???
              }
              this.spinner.hide();
            });

        break;
      case 'LineString':
        this.spinner.show();
        this.mapViewerJobFeatureSubscribe =
          this.mapViewerJobFeatureService
            .fetchLineFeatureDetail(
              this.checkAnnotationLine(editFeature.properties),
              parseInt(editFeatureId)
            ).subscribe((response: any) => {
              const linkaddedResponse: any = response;

              if (response.groups.features.length === 1) {
                //this.refreshDescriptionData(response);
                if (!isUpdateDescription) {
                  let retrievedFeature = response.groups.features[0];
                  // this.handleGetFeatureDetails(retrievedFeature, geomType);
                  this.handleGetFeatureDetails(retrievedFeature, geomType);
                }
              } else {
                // ???
              }
              this.spinner.hide();
            });
        break;
      case 'MultiLineString':
        this.spinner.show();
        this.mapViewerJobFeatureSubscribe =
          this.mapViewerJobFeatureService
            .fetchLineFeatureDetail(
              this.checkAnnotationLine(editFeature.properties),
              parseInt(editFeatureId)
            ).subscribe((response: any) => {
              const linkaddedResponse: any = response;

              if (response.groups.features.length === 1) {
                //this.refreshDescriptionData(response);
                if (!isUpdateDescription) {
                  let retrievedFeature = response.groups.features[0];
                  // this.handleGetFeatureDetails(retrievedFeature, geomType);
                  this.handleGetFeatureDetails(retrievedFeature, geomType);
                }
              } else {
                // ???
              }
              this.spinner.hide();
            });
        break;
      default:
        break;
    }
  }

  waitforme(milisec: any) {
    return new Promise(resolve => {
      setTimeout(() => { resolve('') }, milisec);
    })
  }
  // Create new features for map editor
  async saveFeaturesByFeatureType(group: any, featureType: any, layer: any) {
    if (confirm('Are you sure you want to save?')) {

      const data = this.draw.getAll();
      // Get all drawing features with same featureGroup and featureType
      var saveFeatures = data.features.filter(
        (feature: any) =>
          feature.properties.featureType === featureType &&
          feature.properties.featureGroup === group
      );

      // saveFeatures.forEach(async (saveFeature: any) => {
      for (const saveFeature of saveFeatures) {


        this.saveFeature(saveFeature);
        // Remove the drawing feature from the map
        this.draw.delete(saveFeature.id);


        this.newlyAddedFeature?.splice(this.newlyAddedFeature.findIndex(it => it.properties.featureName === saveFeature.properties.featureName), 1);
        // Unhide the saved feature
        // Unhide the saved feature
        this.unhideEditingFeature(saveFeature)
        await this.waitforme(1000);
      }
      // });
       //update features (added settimeout as making two calls Upsert and getUpdate features almost
      // in paraller manner hence sometimes not getting updated result from backend)
      setTimeout(() => {
          this.navigatedFeatures = null;
          this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
          this.navigatedFeatures = dataWithLayer;
          for (const featureData of dataWithLayer) {
            if (!this.isBasemapToggle) {
              this.setValuesInRequiredMaps(featureData);
            } else {
              this.isBasemapToggle = false;
            }
          }
          // remove changes from en masse list
          this._removeCommitChanges();
          this.editSelectedFeature = null;
          this.addNewFeatureInExisting();
          this.refreshMapStyle();
        });
    }, 1000);

      // setTimeout(() => {
      //   this.refreshMapStyle();
      // }, 1500);
    }
  }

 //#region saveSingleFeature: click on the save icon then save a single feature
  saveSingleFeature(layer: any, feature: any) {
    if (confirm('Are you sure you want to save?')) {

      const drawFeatures = this.draw.getAll();
      // Get the drawing feature by featureName
      var saveFeatures = drawFeatures.features.filter(
        (drawFeature: any) =>
          drawFeature.properties.featureName === feature.featureName
      );

      if (saveFeatures.length === 1) {
        let saveFeature = saveFeatures[0];
        // if(layer.toUpperCase()=="External"){
        //   // for External layer appi
        // }else{
        this.saveFeature(saveFeature);
        // }
        // Remove the drawing feature from the map
        this.draw.delete(saveFeature.id);
        // this.newlyAddedFeature?.splice(this.newlyAddedFeature?.findIndex(it => it?.properties?.featureName === saveFeature?.properties?.featureName), 1);
        // Unhide the saved feature
        this.unhideEditingFeature(saveFeature);
        //update features (added settimeout as making two calls Upsert and getUpdate features almost
        // in paraller manner hence sometimes not getting updated result from backend)
        setTimeout(() => {
          this.getUpdatedFeatures("", saveFeature, "").subscribe((dataWithLayer: any) => {
            this.navigatedFeatures = dataWithLayer;
            for (const featureData of dataWithLayer) {
              if (!this.isBasemapToggle) {
                this.setValuesInRequiredMaps(featureData);
              } else {
                this.isBasemapToggle = false;
              }
            }
            // remove changes from en masse list
            this._removeCommitChanges();
            this.editSelectedFeature = null;
            this.addNewFeatureInExisting();
            this.refreshMapStyle();
          });
        }, 1000);
        // setTimeout(() => {
        //   this.navigatedFeatures = null;
        //   this.getUpdatedFeatures("",saveFeature,"");
        //   setTimeout(() => {
        //     // We need to load the current style here - how do we get that?
        //     this.refreshMapStyle();
        //   }, 1000);
        // }, 1500);
        // We need to reset the filter so previously hidden features are now visible
        // This should be done in style.load
        // this.setGroupFilter('on', saveFeature.properties.featureGroup);

        // Refresh MVT for this source/layer that changed
        // this.refreshTiles(
        //   saveFeature.geometry.type,
        //   saveFeature.properties.layerId
        // );

      }
    }
  }

  //saveFeature method is used to save feature what ever we add or edit in map using savePointFeatureDetailFrMapEditor and saveLineFeatureDetailFrMapEditor api
  saveFeature(saveFeature: any) {
    // const layerName = this.getLayerName(parseInt(saveFeature.properties.layerId));
    let wktGeom: string = '';
    let payLoad: any = {
      mapJobId: saveFeature.properties.mapJobId,
      mapFeatureTypeId: saveFeature.properties.featureTypeId,
      mapGroupId: saveFeature.properties.featureGroupId,
      mapLayerId: saveFeature.properties.layerId ? saveFeature.properties.layerId : saveFeature.properties.mapLayerId,
      userId: this.loginUserId,
      swmapsLayer:this.metadataService.featureType[saveFeature.properties.featureTypeId]

    };
    // Need to handle annotations here too
    if (saveFeature.geometry.type === 'LineString') {

      wktGeom = 'LINESTRING(';
      saveFeature.geometry.coordinates.forEach(function (p: any, i: number) {
        if (i < saveFeature.geometry.coordinates.length - 1)
          wktGeom = wktGeom + p[0] + ' ' + p[1] + ' 0, ';
        else wktGeom = wktGeom + p[0] + ' ' + p[1] + ' 0)';
      });
      if(this.firstSecondLineFeatureFrMergedArr.length > 0) {
        payLoad.mapLineId = 0;
        payLoad.isAnnotation = false;
        payLoad.mapLayerId = saveFeature.properties.mapLayerId
      }
      else {
        payLoad.mapLineId = saveFeature.properties.mapLineId;
      }
    } else if (saveFeature.geometry.type === 'Point') {

      wktGeom =
        wktGeom = `POINT(${saveFeature.geometry.coordinates[0]} ${saveFeature.geometry.coordinates[1]} 0)`;

      payLoad.mapPointId = saveFeature.properties.mapPointId;
    }
    payLoad.geom = wktGeom;
    // Save to REST

    switch (saveFeature.geometry.type) {
      case 'Point':
        if (!saveFeature.properties.isAnnotation) {
          this.spinner.show();
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .savePointFeatureDetailFrMapEditor(payLoad)
            .subscribe((response) => {

              const linkaddedResponse: any = response;
              // this.reloadCurrentRoute();
              // const layerName = this.getLayerName(parseInt(saveFeature.properties.layerId));
              // this.getUpdatedFeatures(response, saveFeature, layerName);
              // this.showHideToggleGroup(saveFeature);
              // const index = this.featureGroupMap.get(layerName).get(saveFeature.properties.featureGroup).get(saveFeature.properties.featureType).findIndex((it:any)=>it.featureName === saveFeature.properties.featureName);
              // this.featureGroupMap.get(layerName).get(saveFeature.properties.featureGroup).get(saveFeature.properties.featureType)[index].featureId = linkaddedResponse?.featureId;
              // this.featureGroupMap.get(layerName).get(saveFeature.properties.featureGroup).get(saveFeature.properties.featureType)[index].featureName = 'public.'+(saveFeature.properties.featureType).toLowerCase()+"-"+linkaddedResponse?.featureId;
              this.featureTypeForBtnVisible = null;
              this.editSelectedFeature = null;
              this.editingHistoryUndoArray = [];
              this.editingHistoryRedoArray = [];
              this.spinner.hide();
            });
        } else {
          // REST to UpsertAnnotationPoint
        }
        break;
      case 'LineString':
        if (!saveFeature.properties.isAnnotation) {
          // console.log("payLoad",payLoad);
          this.spinner.show();
          this.mapViewerJobFeatureSubscribe = this.mapViewerJobFeatureService
            .saveLineFeatureDetailFrMapEditor(payLoad)
            .subscribe((response) => {
              const linkaddedResponse: any = response;
              this.featureTypeForBtnVisible = null;
              this.editSelectedFeature = null;
              this.spinner.hide();

              this.deleteMergedlineFeatures(); // function call for delete merged lines
              this.editingState.firstMergeLineId = undefined;
              this.editingState.secondMergeLineId = undefined;
              this.editingHistoryUndoArray = [];
              this.editingHistoryRedoArray = [];
            });

        } else {
          // REST to UpsertAnnotationLine
        }
        break;
      default:
        break;
    }
    this.editingHistoryUndoArray = this.editingHistoryUndoArray.filter((x: any) => x.properties?.featureId != saveFeature.featureId);
    this.editingHistoryRedoArray = this.editingHistoryRedoArray.filter((x: any) => x.properties?.featureId != saveFeature.featureId);

    // We should receive the featureName as a result, so it can be displayed in the toggle
    // TODO: add feature to toggle
  }

  // click on the cancel icon then cancel a single feature what ever he did or not for particular cancel feature
  cancelFeature(layer: any, feature: any) {
    if (confirm('Are you sure you want to cancel?')) {
      this._cancelSingleFeature(feature, layer);
    }
  }

  private _cancelSingleFeature(feature: any, layer: any) {
    if (this.isEditingModeIsOn) {
      let ft = null;
      // Just need to trash the draw feature
      let drawFeatures = this.draw
        .getAll()
        .features.filter(
          (drawFeature: any) => drawFeature.properties.featureName === feature.featureName
        );
      if (drawFeatures.length === 1) {
        this.draw.delete(drawFeatures[0].id);
        // Unhide the saved feature
        const index = this.hiddenFeatures.indexOf(
          drawFeatures[0].properties.featureName
        );
        ft = drawFeatures[0];
        if (index > -1) {
          this.hiddenFeatures.splice(index, 1);
          // Need to set the feature filter
          // this.setGroupFilter('on', drawFeatures[0].properties.featureGroup);
          this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
        }
        const featureNameIndex = this.newlyAddedFeature?.findIndex(it => it?.properties?.featureName === feature.featureName);
        this.newlyAddedFeature?.splice(featureNameIndex, 1);
        this.editSelectedFeature = null;
        this.isFirstEditDetected = false;

        if (featureNameIndex > -1)
          this.trashFeatureName(layer, drawFeatures[0].properties.featureGroup, drawFeatures[0].properties.featureType, drawFeatures[0].properties.featureName);
      }
      this.editingHistoryUndoArray = this.editingHistoryUndoArray.filter((x: any) => x.properties?.featureName != feature.featureName);
      this.editingHistoryRedoArray = this.editingHistoryRedoArray.filter((x: any) => x.properties?.featureName != feature.featureName);

      // reverting en masse changes
      if(this.changesTobeReverted?.length) {
        this.mapEditService.committedChangesCountAndSaveSub.next({remove:true, data:this.changesTobeReverted})
      } else if(this.changesTobeReverted?.length === 0 && ft !== null) {
        const commitChanges = [this.mapEditService.constructCommitObject(ft, false)];
        const gp = commitChanges[0]?.groups[0];
        const type = commitChanges[0]?.groups[0]?.types[0];
        const ftr = commitChanges[0]?.groups[0]?.types[0]?.features[0];
        this.changesTobeReverted = this._getCommitChangesObject(commitChanges[0], gp, type, ftr);
        this.mapEditService.committedChangesCountAndSaveSub.next({remove:true, data:this.changesTobeReverted})
      }
    }
  }

    // getting object from commit change to remove feature from En-masse Commit Changes list
    private _getCommitChangesObject(layer: CommitChanges, group: FeatureGroup, featureType: FeatureType | null, feature: Feature): any[] {
      const changeObj:any = {};
      this.mapEditService.mapEditorChangesToBeCommitted.forEach((change:CommitChanges)=> {
        if(+change.layerId === layer.layerId) {
          changeObj['layer'] = layer;
          const grp = change?.groups.find((fgrp:FeatureGroup)=> fgrp.groupId === group.groupId);
          changeObj['group'] = grp ? grp : undefined;
          const typ = grp?.types.find((ftyp:FeatureType)=> ftyp.typeId === featureType?.typeId);
          changeObj['type'] = typ ? typ : undefined;
          const ft = typ?.features.find((ftr:Feature)=> ftr.featureName === feature.featureName);
          changeObj['feature'] = ft ? ft : undefined;
        }
      })
      return [changeObj];
    }

  //trashFeatureName method is used to cancel whatever we add or edit in map
  trashFeatureName(layer: any, featureGroup: any, featureType: any, featureName: any) {
    const data = Array.from(this.featureGroupMap.get(layer).get(featureGroup).get(featureType));
    if (data?.length > 1) {
      const index = this.featureGroupMap.get(layer).get(featureGroup).get(featureType)?.findIndex((it: any) => it.featureName === featureName);
      if (index > -1) {
        this.featureGroupMap.get(layer).get(featureGroup).get(featureType).splice(index, 1);
      }
    }
    else {
      if (data?.length === 1) {
        if (Array.from(this.featureGroupMap.get(layer).get(featureGroup)).length > 1) {
          this.featureGroupMap.get(layer).get(featureGroup).delete(featureType);
        }
        else {
          if (Array.from(this.featureGroupMap.get(layer).get(featureGroup)).length === 1) {
            this.featureGroupMap.get(layer).delete(featureGroup);
          }
        }
      }
    }
  }

  //trashFeatureType method is used to cancel whatever we add or edit in map
  trashFeatureType(layer: any, featureGroup: any, featureType: any) {
    const data = Array.from(this.featureGroupMap.get(layer).get(featureGroup));
    if (data?.length > 1) {
      this.featureGroupMap.get(layer).get(featureGroup).delete(featureType);
    }
    else {
      if (data?.length === 1) {
        this.featureGroupMap.get(layer).delete(featureGroup);
      }
    }
  }

  //cancelContainerFrEditor method is used to cancel the edit mode
  cancelContainerFrEditor(layer: any) {
    let isCancel = false;
    if (document.querySelector('#divTools ul li a.active') !== null) {
      document.querySelector('#divTools ul li a.active')?.classList.remove('active');
    }
    if (this.isEditingModeIsOn) {
      // Just need to trash the draw feature
      let drawFeatures = this.draw.getAll().features;
      if (drawFeatures.length > 0) {
        if (confirm('If you leave the editing session now, you will lose any unsave work.')) {
          drawFeatures?.forEach((element: any) => {
            this.draw.delete(element.id);
            // Unhide the saved feature
            const index = this.hiddenFeatures.indexOf(
              element.properties.featureName
            );
            if (index > -1) {
              this.hiddenFeatures.splice(index, 1);
              // Need to set the feature filter
              // this.setGroupFilter('on', element.properties.featureGroup);
            }
          });
          isCancel = true;
        }
      } else {
        isCancel = true
      }
      if (isCancel) {
        this.toggleEditingState(false, this.editLayerName);
        this.newAddedFeatureInLayer = { layerName: String };
        this.newlyAddedFeature = [];
        this.isEditLayer = true;
        this.editLayerName = null;
        this.editSelectedFeature = null;
        this.isFirstEditDetected = false;
        this.showAllLayer();
        this.isEditingModeIsOn = false;
        this.showAddFeatureContainerFrMapEditor = false;
        this.showAddFeatureButton = true;
        this.toggleGPRSLayer('GPRS');
        this.toggleGPRSLayer('CLIENT');
        this.toggleGPRSLayer('IMPORTED');
        this.isLayerEyeVisible = {
          SITE: true,
          GPRS: true,
          CLIENT: true,
          IMPORTED: true,
          EXTERNAL: true,
          REFERENCE: false
        };
        this.modalExternalAddHeader = '';
        this.selectOptionsExternalEnable = false;
        this.externalContentForm.reset(this.externalContentFormOriginal);
        this.previousFeature = null;
        this.editingHistoryUndoArray = [];
        this.editingHistoryRedoArray = [];
        this.hiddenFeatures = [];
        this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
      }
    }
  }

  deleteParticularFeature(
    layer: any,
    featureGroup: any,
    featureType: any,
    feature: any
  ) {
    let delMessage = 'Are you sure you want to delete?';
    if (layer.toUpperCase() === 'EXTERNAL') {
      delMessage = 'Are you sure you want to delete the component?';
    }

    if (this.deleteAccessCheck(layer, featureGroup)) {
      if (confirm(delMessage)) {
        this.deleteParticularFeatureAPI(layer, featureGroup, featureType, feature);
      }
    } else {
      this.toastr.warning("You don't have access to edit this layer");
    }
  }

  refreshMapStyle() {
    this.isBasemapToggle = true;
    // let currentMapStyle: mapboxgl.Style = this.map.getStyle();
    // let stylesheet = currentMapStyle.  .stylesheet.id
    // let currentMapStyle = `mapbox://styles/mapbox/${this.map.style.stylesheet.id}`;
    const style = this.map.getStyle();
    this.map.setStyle('');
    if(style){
      this.map.setStyle(style);
    }

    // this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
  }

  hideEditingFeature(feature: any) {

  }

  unhideEditingFeature(feature: any) {
    const index = this.hiddenFeatures.indexOf(
      feature.properties.featureName
    );
    if (index > -1) {
      this.hiddenFeatures.splice(index, 1);
    }
  }

  refreshTiles(geomType: string, editingLayerId: string) {
    // Set the source Id to refresh based on currentEditinglayerId and geomType/featureClass
    let currentSourceId: string = '';
    // Set the layer Ids to refresh based on saveFeature.properties.layerId and geomType/featureClass
    let currentLayerIds: any;
    // Get loaded feature groups for
    let currentFeatureGroups: any = [];

    if (geomType === 'Point') {
      currentSourceId = `${editingLayerId}-active-points-annotations-by-layerid`;
      let allLoadedPointLayerIds = this.getLoadedLayers(geomType);
      currentLayerIds = allLoadedPointLayerIds.filter((layerId: string) =>
        layerId.startsWith(editingLayerId)
      );
      currentFeatureGroups = this.getLoadedFeatureGroups(
        currentLayerIds,
        geomType
      );
    } else if (geomType === 'LineString') {
      currentSourceId = `${editingLayerId}-active-lines-annotations-by-layerid`;
      let allLoadedLineLayerIds = this.getLoadedLayers(geomType);
      currentLayerIds = allLoadedLineLayerIds.filter((layerId: string) =>
        layerId.startsWith(editingLayerId)
      );
      currentFeatureGroups = this.getLoadedFeatureGroups(
        currentLayerIds,
        geomType
      );
    }

    // Remove all Layers that need to be refreshed
    for (const layerId of currentLayerIds) {

      this.map.removeLayer(layerId);
    }

    // Refresh the source
    this.refreshSource(currentSourceId, geomType, editingLayerId);

    // Refresh the layers
    for (const featureGroup of currentFeatureGroups) {
      this.refreshLayers(
        currentSourceId,
        editingLayerId,
        featureGroup,
        geomType
      );
    }
  }

  //# refresh source for map editor
  refreshSource(
    currentSourceId: string,
    geomType: string,
    editingLayerId: string
  ) {
    // Remove the single Source that needs to be refreshed

    this.map.removeSource(currentSourceId);
    let tileUrl: string = '';
    if (geomType === 'Point') {
      tileUrl = `${PortalAPI.POINT_TILES}${editingLayerId}/{z}/{x}/{y}.mvt`;
    } else if (geomType === 'LineString') {
      tileUrl = `${PortalAPI.LINE_TILES}${editingLayerId}/{z}/{x}/{y}.mvt`;
    }
    this.map.addSource(currentSourceId, {
      type: 'vector',
      tiles: [tileUrl],
      promoteId: 'featureNameDraw',
    });
  }

  //# refresh layers for map editor
  refreshLayers(
    currentSourceId: string,
    editingLayerId: string,
    featureGroup: string,
    geomType: string
  ) {
    let currentLayerId;

    if (geomType === 'Point') {
      currentLayerId = `${editingLayerId}-${featureGroup}-point`;
      this.map.addLayer({
        id: currentLayerId,
        type: 'symbol',
        source: currentSourceId,
        'source-layer': 'default',
        minzoom: 10,
        maxzoom: 23,
        layout: {
          'icon-allow-overlap': true,
          visibility: 'visible',
          'icon-image': ['get', 'featureType'], // expects "featuretype" to have same name as the loaded icon
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10.25, .1, 17, .3],
        },
        filter: this.getGroupFilterExpression(featureGroup),
      });
    } else if (geomType === 'LineString') {
      currentLayerId = `${editingLayerId}-${featureGroup}-line`;
      this.map.addLayer({
        id: currentLayerId,
        type: 'line',
        source: currentSourceId,
        'source-layer': 'default',
        minzoom: 10,
        maxzoom: 23,
        layout: {
          visibility: 'visible',
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: this.getLinePaintByGroupName(featureGroup),
        filter: this.getGroupFilterExpression(featureGroup),
      });
    }
  }

  //# get loaded layer for map editor
  getLoadedLayers(geomType: string) {
    // What point/line layers are loaded?
    let mapStyle = this.map.getStyle();
    let mapLayers = mapStyle.layers;
    let allLayerIds = mapLayers.map((layer: any) => layer.id);
    let layerRegex: RegExp;
    if (geomType === 'Point') {
      layerRegex = /[1-3]-(.+)-point$/;
    } else if (geomType === 'LineString') {
      layerRegex = /[1-3]-(.+)-line$/;
    }

    let loadedLayerIds: any = allLayerIds.filter(
      (layerId) =>
        layerId.match(layerRegex) !== null &&
        layerId.startsWith(this.currentEditinglayerId)
    );
    return loadedLayerIds;
  }

  //# get loaded feature groups for map editor
  getLoadedFeatureGroups(currentLayerIds: any, geomType: string) {
    let layerRegex: RegExp;
    if (geomType === 'Point') {
      layerRegex = /[1-3]-(.+)-point$/;
    } else if (geomType === 'LineString') {
      layerRegex = /[1-3]-(.+)-line$/;
    }
    return currentLayerIds.map((layerId: any) => layerId.match(layerRegex)[1]);
  }

  setEditingHistory(action: string, id: string) {
    if (
      action === 'create' ||
      action === 'move' ||
      action === 'change_coordinates' ||
      action === 'delete'
    ) {
      // Reset all future states
      if (this.editingHistory > 0) {
        this.editingHistory = this.editingHistory.slice(
          0,
          this.editingHistoryIndex
        );
      }

      // Get the currecnt drawing state - a Feature Collection of all draw graphics
      let drawState = this.draw.getAll();
      // Create a history state
      let historyState = {
        action: action,
        featureId: id,
        drawState: drawState,
      };
      if ( action === 'create' || action === 'move' || action === 'change_coordinates' ) {

        // save en masse related code change
        const currentFeatureToCommit = {...this.draw.get(id)};
        const layerId = action === 'create' ? currentFeatureToCommit?.properties?.layerId : currentFeatureToCommit?.properties?.mapLayerId;
        if(currentFeatureToCommit?.geometry?.type !== "Polygon" && layerId) {
          this.isFirstEditDetected = true;
          this.selectedDrawFeature = currentFeatureToCommit;
          if(action === 'create'){
            this.mapEditService.constructCommitObject({...currentFeatureToCommit}, true);
          } else if(action === "move") {
            this.mapEditService.constructCommitObject({...currentFeatureToCommit}, false);
          }  else if(action === "change_coordinates") {
            this.mapEditService.constructCommitObject({...currentFeatureToCommit}, false, true);
          }
        }
        if(this.editingHistoryUndoArray.filter((ft:any)=> JSON.stringify(ft.geometry.coordinates) == JSON.stringify(this.draw.get(id).geometry.coordinates)).length === 0) {
          this.editingHistoryUndoArray.push(this.draw.get(id));
        }
        this.editingHistoryRedoArray = this.editingHistoryRedoArray.filter((x: any) => x.id != id);
      }

      // Add that to the history
      this.editingHistory.push(historyState);
      // Move history index to current state
      this.editingHistoryIndex++;


    }
  }

  globalDrawCreate = (e: any) => {
    //please confirm with chris
    // if (!this.isEditingModeIsOn || !this.isPrintSession)
    if (!this.isEditingModeIsOn) {
      const data = this.draw.getAll();
      if (
        data.features[data.features.length - 1].geometry.type == 'LineString'
      ) {
        var coordinates =
          data.features[
            data.features.length - 1
          ].geometry.coordinates[0].slice();
        const length = turf.length(data.features[data.features.length - 1]);
        var rounded_length = Math.round(length * 3280.8398950131);
        if (!this.isEditingModeIsOn) {
          this.measeureLength(rounded_length, coordinates);
        }
      } else if (
        data.features[data.features.length - 1].geometry.type == 'Polygon'
      ) {
        this.exportDrawPolygonCordinates =
          data.features[data.features.length - 1].geometry.coordinates;
        if (this.exportDrawPolygonCordinates) {
          for (var i = 0; i < this.exportDrawPolygonCordinates.length; i++) {
            if (i == 0) {
              this.exportDrawPolygonCordinates[i].splice(i, 1);
            }
          }
        }
        var coordinates =
          data.features[
            data.features.length - 1
          ].geometry.coordinates[0][0].slice();
        const area = turf.area(data.features[data.features.length - 1]);
        var rounded_area = Math.round(area * 10.7639);
        this.measureCalcArea(rounded_area, coordinates);
      }
    } else {
      // console.log(e, 'draw.create');
      let drawFeatureId = e.features[0].id;
      let drawFeature = this.draw.get(drawFeatureId);
      // // Handle Lines specially.
      // // They have a hard-coded id = sitemap_draw_line_string_id that must be changed.
      // // Clicking on a snap tool while drawing a Line causes a draw.create event - ignore if we are still drawing.
      // if (drawFeature.geometry.type === 'LineString') {
      //   // Delete the existing Line - it will have Id = sitemap_draw_line_string_id
      //   this.draw.delete(drawFeatureId);
      //   // Make a fake Id and set it
      //   drawFeatureId = '999' + Math.random().toString().slice(2, 10);
      //   drawFeature.id = drawFeatureId;
      //   // Add this new Line
      //   this.draw.add(drawFeature);
      // }
      let newDrawId: string;
      if (drawFeature.geometry.type === 'Point') {
        newDrawId = 'sitemap_draw_point_id';
      } else {
        newDrawId = 'sitemap_draw_line_string_id'
      }
      if (drawFeatureId === newDrawId) {
        // Delete the existing Point/Line - it's newly added
        this.draw.delete(drawFeatureId);
        // Make a fake Id and set it
        drawFeatureId = '999' + Math.random().toString().slice(2, 10);
        drawFeature.id = drawFeatureId;
        // Add this new Line
        this.draw.add(drawFeature);
      }

      // Set properties for the Draw feature
      // Create a temp featureName
      this.draw.setFeatureProperty(
        drawFeatureId,
        'featureName',
        this.newAddedFeatureName
        // `${this.addNewFeatureTypeFrMapEditor}${Math.random()
        //   .toString()
        //   .slice(2, 10)}`
      );
      this.draw.setFeatureProperty(
        drawFeatureId,
        'featureType',
        this.addNewFeatureTypeFrMapEditor
      );
      this.draw.setFeatureProperty(
        drawFeatureId,
        'featureGroup',
        this.addNewFeatureGrpFrMapEditor
      );
      this.draw.setFeatureProperty(
        drawFeatureId,
        'featureGroupId',
        this.addNewFeatureGrpIdFrMapEditor
      );
      this.draw.setFeatureProperty(
        drawFeatureId,
        'featureTypeId',
        this.addNewFeatureTypeIdFrMapEditor
      );
      this.draw.setFeatureProperty(
        drawFeatureId,
        'layerId',
        this.currentEditinglayerId
      );
      this.draw.setFeatureProperty(
        drawFeatureId,
        'mapJobId',
        this.navigatedJobId
      );

      this.draw.setFeatureProperty(drawFeatureId, 'drawId', drawFeatureId);

      // console.log(drawFeature);
      if (drawFeature.geometry.type === 'Point') {
        this.draw.setFeatureProperty(drawFeatureId, 'mapPointId', 0);
        // this.draw.changeMode('simple_select');
        this.draw.changeMode('sitemap_simple_select', {
          featureIds: [drawFeatureId],
          mapLayerId: this.currentEditinglayerId,
          navigatedFeatures: this.navigatedFeatures,
          isSnappingToVertex: this.isSnappingToVertex,
          isSnappingToLine: this.isSnappingToLine
        })
      } else if (drawFeature.geometry.type === 'LineString') {
        this.draw.setFeatureProperty(drawFeatureId, 'mapLineId', 0);
      }

      // A create action removes all future states, if any exist
      this.setEditingHistory('create', drawFeatureId);
    }
  }

  createDrawEvents() {
    this.map.on('draw.create', this.globalDrawCreate);

    this.map.on('draw.delete', (e) => {

      this.counter = 0;
      this.exportDrawPolygonCordinates = [];
      this.polygonGeoJson = {};
      const data = this.draw.getAll();
      const popups = document.getElementsByClassName('mapboxgl-popup');
      if (popups.length) {
        popups[0].remove();
      }
      if (data.features.length > 0 && !this.isEditingModeIsOn) {
        const coordinates = data.features[0].geometry.coordinates[0][0].slice();
        const area = turf.area(data);
        // Restrict the area to 2 decimal points.
        const rounded_area = Math.round(area * 10.7639);
        this.measureCalcArea(rounded_area, coordinates);
      }
      // Get the draw action for history
      let drawAction = e.action;
      // An update action removes all future states, if any exist
      this.setEditingHistory(drawAction, e.features[0].id);
    });

    this.map.on('draw.update', (e) => {
      // if (this.counter == 1 && e?.action === "change_coordinates") {
      //   this.draw.deleteAll()
      //   this.counter = 0
      //   this.onPrintShowPrintBox(e)
      // }
      let selectedFeature = this.draw.getSelected().features[0];
      this.featureTypeForBtnVisible = e?.features[0]?.properties?.featureType;
      // this.showAddFeatureContainerFrMapEditor = true;
      this.editSelectedFeature = selectedFeature;

      if (!this.externalLayerItems.includes(selectedFeature.properties?.featureGroup?.toUpperCase().replace(/\s/g, ""))) {
        if (selectedFeature?.properties?.featureGroupId) {
          this.groupToSelect = e?.features[0]?.properties.featureGroupId.toString();
          this.bindFeatureTypeDropdown(parseInt(this.groupToSelect), this.editSelectedFeature);
        }
      }
      const drawFeatureCollection = this.draw.getAll();
      if (selectedFeature.geometry.type == 'LineString') {
        var coordinates = selectedFeature.geometry.coordinates[0].slice();
        const length = turf.length(selectedFeature);
        var rounded_length = Math.round(length * 3280.8398950131);
        if (!this.isEditingModeIsOn) {
          this.measeureLength(rounded_length, coordinates);
          this.removeExtraPopup();
        }
      } else if (selectedFeature.geometry.type == 'Polygon') {
        var coordinates = selectedFeature.geometry.coordinates[0][0].slice();
        const area = turf.area(selectedFeature);
        var rounded_area = Math.round(area * 10.7639);
        this.measureCalcArea(rounded_area, coordinates);
        this.removeExtraPopup();
      }
      // Get the draw action for history
      let drawAction = e.action;
      // An update action removes all future states, if any exist
      this.setEditingHistory(drawAction, selectedFeature.id);
    });

    this.map.on('draw.selectionchange', (e) => {

      if(e?.features[0]?.properties?.isPrintBox) {
        this.drawHandlePrint.modes.direct_select.dragVertex = function(state:any, e:any){

        }
      }
      if(e?.features?.length){
        this.editSelectedFeature = e?.features[0];
      }

      if (!this.externalLayerItems.includes(e?.features[0]?.properties.featureGroup.toUpperCase().replace(/\s/g, ""))) {
        if (e?.features[0]?.properties?.featureGroupId) {
          this.groupToSelect = e?.features[0]?.properties.featureGroupId.toString();
          this.bindFeatureTypeDropdown(parseInt(this.groupToSelect), this.editSelectedFeature);
          this.featureTypeForBtnVisible = e?.features[0]?.properties?.featureType;
        }
      }
      // this.showAddFeatureContainerFrMapEditor = true;
      // Highlight the feature that is selected on the side panel
      if (e.features.length > 0) {
        if(e.features && this.editingHistoryUndoArray.filter((x:any)=>x.id==e.features[0].id).length == 0){
          this.editingHistoryUndoArray.push(e.features[0]);
        }
        e.features.forEach((feature: any) => {
          this.showHideToggleGroup(feature);

          //   `The selected feature: ${this.getLayerName(
          //     feature.properties.layerId
          //   )} -- ${feature.properties.featureGroup} -- ${feature.properties.featureType
          //   } -- ${feature.properties.featureName}`
          // );
          this.previousFeature = feature;
        });
      } else {
        // Remove highlight from feature in the side panel
        console.log('No feature selected.');
      }
    });

    this.map.on('click', (e) => {      
      this.fileTableData?.map((it:any)=> it.docClick = '');
      let selectedFeature = this.draw.getSelected().features[0];
      //Added line as not getting feature in draw.getSelected()
      selectedFeature = selectedFeature ? selectedFeature : this.selectedDrawFeature;
      if (selectedFeature?.properties) {
        // this.groupToSelect = selectedFeature?.properties.featureGroupId.toString();
        if (!this.externalLayerItems.includes(selectedFeature?.properties.featureGroup.toUpperCase().replace(/\s/g, ""))) {
          this.editSelectedFeature = selectedFeature;
          if (selectedFeature?.properties?.featureGroupId) {
            this.groupToSelect = selectedFeature?.properties.featureGroupId.toString();
            this.bindFeatureTypeDropdown(parseInt(this.groupToSelect), this.editSelectedFeature);
          }
        }
        // if (selectedFeature?.properties?.featureGroup.toLowerCase() === 'pointcloud' ||
        // selectedFeature?.properties?.featureGroup.toLowerCase() === 'virtualtour'||
        // selectedFeature?.properties?.featureGroup.toLowerCase() === 'externallink'||
        // selectedFeature?.properties?.featureGroup.toLowerCase() === 'matterport'||
        // selectedFeature?.properties?.featureGroup.toLowerCase() === 'attachment'
        // )

        let tempModalExternalAddHeader = this.modalExternalAddHeader;
        if (this.modalExternalAddHeader.toUpperCase() == "VIRTUAL TOUR") {
          tempModalExternalAddHeader = "Matterport";
        }

        if (this.selectOptionsExternalEnable && (tempModalExternalAddHeader === this.selectOptionsExternal)) {
          // (this.modalExternalAddHeader.toLowerCase().replace(/\s/g, "")) === ('pointcloud' || 'virtualtour' || 'externallink' || 'matterport' || 'attachment')){
          this.latitudeExternalContent = selectedFeature.geometry.coordinates[1];
          this.longitudeExternalContent = selectedFeature.geometry.coordinates[0];
          this.externalContentId = selectedFeature.id;
          let button = document.getElementById("btnExternalContent") as HTMLButtonElement;
          button.click();
        }
      }
      else {
        this.editSelectedFeature = null;
      }
      if (selectedFeature?.geometry?.type == 'LineString') {
        var coordinates = selectedFeature.geometry.coordinates[0].slice();
        const length = turf.length(selectedFeature);
        var rounded_length = Math.round(length * 3280.8398950131);
        if (!this.isEditingModeIsOn) {
          this.measeureLength(rounded_length, coordinates);
        }
        this.removeExtraPopup();
      } else if (selectedFeature?.geometry?.type === 'Polygon') {
        var coordinates = selectedFeature.geometry.coordinates[0][0].slice();
        const area = turf.area(selectedFeature);
        var rounded_area = Math.round(area * 10.7639);
        this.measureCalcArea(rounded_area, coordinates);
        this.removeExtraPopup();
      }
    });
  }

  //showHideImageInImportLayer method is used to show/hide image under the import layer
  showHideImageInImportLayer(layerId: any) {
    this.isImportLayerImageVisible[layerId] = !this.isImportLayerImageVisible[layerId];
    if (this.isImportLayerImageVisible[layerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
    else {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  editingHistoryUndoRedo: any = {};
  editingHistoryUndoArray: any[] = [];
  editingHistoryRedoArray: any[] = [];

  isUndoDisabled(feature: any):boolean{
    const undoLength = this.editingHistoryUndoArray.filter((x:any) => x.properties?.featureName == feature.featureName);
    return undoLength.length == 1 ? true : false;
  }

  isRedoDisabled(feature: any):boolean{
    const redoLength = this.editingHistoryRedoArray.filter((x:any) => x.properties?.featureName == feature.featureName);
    return redoLength.length == 0 ? true : false;
  }

  handleUndo(layer: any, feature: any) {

      const featureHistoryData = this.editingHistoryUndoArray.filter((x:any) => x.properties?.featureName == feature.featureName);

      this.editingHistoryUndoRedo[feature.featureName] = featureHistoryData.length-1;

      if(this.editingHistoryUndoRedo[feature.featureName] > -1){
        this.draw.add({
          type: 'FeatureCollection',
          features: [featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]-1]]
        });
      }else if(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]]){
        //this.draw.delete(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]].id);
        // Unhide the saved feature
        const index = this.hiddenFeatures.indexOf(
          feature.featureName
        );
        // if (index > -1) {
        //   this.hiddenFeatures.splice(index, 1);
        //   // Need to set the feature filter
        //   this.commonMapService.filterMVTFromToggle(this.zoomedJobIds, this.map, undefined, this.hiddenFeatures);
        // }
      }

      if(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]]){
        const undoIndex = this.editingHistoryUndoArray.indexOf(featureHistoryData[this.editingHistoryUndoRedo[feature.featureName]]);
        this.editingHistoryRedoArray.push(...this.editingHistoryUndoArray.splice(undoIndex, 1));
      }
  }

  handleRedo(layer: any, feature: any) {
    let featureHistoryData = this.editingHistoryRedoArray.filter((x:any) => x.properties?.featureName == feature.featureName);
    featureHistoryData = featureHistoryData.reverse()
    if(typeof this.editingHistoryUndoRedo[feature.featureName] == 'number')
    {
      if(featureHistoryData[0]){
        this.draw.add({
          type: 'FeatureCollection',
          features: [featureHistoryData[0]]
        });
        const redoIndex = this.editingHistoryRedoArray.indexOf(featureHistoryData[0]);
        this.editingHistoryUndoArray.push(...this.editingHistoryRedoArray.splice(redoIndex, 1));
        this.editingHistoryUndoRedo[feature.featureName] = this.editingHistoryUndoRedo[feature.featureName]+1;
      }
    }
  }

  // handleUndo() {
  //
  //   if (this.editingHistoryIndex > 0) {
  //     this.editingHistoryIndex--;
  //     this.draw.set(this.editingHistory[this.editingHistoryIndex].drawState);
  //   } else if (this.editingHistoryIndex === 0) {
  //     this.draw.deleteAll();
  //   }
  // }

  // handleRedo() {
  //   if (this.editingHistoryIndex < this.editingHistory.length - 1) {
  //     this.editingHistoryIndex++;
  //     this.draw.add(this.editingHistory[this.editingHistoryIndex].drawState);
  //
  //
  //   }
  // }

  //getLayerName method is used to get the layer name basis on layer id
  getLayerName(layerId: any) {
    let layerName = '';
    if (layerId === 1) {
      layerName = 'GPRS';
    } else if (layerId === 2) {
      layerName = 'CLIENT';
    } else if (layerId === 3) {
      layerName = 'IMPORTED';
    } else if (layerId === 4) {
      layerName = 'EXTERNAL';
    } else if (layerId === 5) {
      layerName = 'SITE';
    }
    return layerName;
  }


  showHideToggleGroup(feature: any) {
    // if(this.previousFeature?.properties?.featureType != feature?.properties?.featureType && this.previousFeature?.properties?.featureGroup != feature?.properties?.featureGroup) {
    if (
      this.previousFeature?.properties?.featureGroup !=
      feature?.properties?.featureGroup
    ) {
      this.refactorShowHideToggleGroup(feature);
      // if(this.previousFeature?.properties?.featureGroup != feature?.properties?.featureGroup) {
      if (this.previousFeature?.properties?.featureGroup) {
        this.refactorShowHideToggleGroup(this.previousFeature);
      }
    } else {
      if (
        this.previousFeature?.properties?.featureType !=
        feature?.properties?.featureType
      ) {
        this.showHideFeatureTypeToggle(feature);
        this.showHideFeatureTypeToggle(this.previousFeature);
      }
    }
  }

  refactorShowHideToggleGroup(layerData: any) {
    const btnGroup = document.getElementById(
      'btn' + this.getFinalGrp(layerData?.properties?.featureGroup)
    );

    if (btnGroup?.getAttribute('aria-expanded') == 'false') {
      btnGroup?.click();
      const ele = document.getElementById(this.getFinalGrp(layerData?.properties?.featureGroup)+'-content'+this.getLayerName(layerData?.properties?.mapLayerId));
      if(ele) {
        ele.classList.add('show');
        // btnGroup.ariaExpanded = 'true';
        btnGroup?.setAttribute('aria-expanded','true')
      }
    }
    this.showHideFeatureTypeToggle(layerData);
  }

  showHideFeatureTypeToggle(layerData: any) {
    const btnFeatureTypeGroup = document.getElementById(
      'btn' + layerData?.properties?.featureType
    );

    if (btnFeatureTypeGroup?.getAttribute('aria-expanded') == 'false') {
      btnFeatureTypeGroup?.click();

      // const ele = document.getElementById('collapse-0-contentCLIENT-AIR_LINE');
      let featureClassCode = layerData?.properties?.featureClass==="point" ? 1 : 0
      // console.log('collapse-' + featureClassCode + '-content' + this.getLayerName(layerData?.properties?.mapLayerId) + '-' + layerData?.properties?.featureType)
      const ele = document.getElementById('collapse-' + featureClassCode + '-content' + this.getLayerName(layerData?.properties?.mapLayerId) + '-' + layerData?.properties?.featureType);
      if(ele) {
        ele.classList.add('show');
        // btnFeatureTypeGroup.ariaExpanded = 'true';

        btnFeatureTypeGroup?.setAttribute('aria-expanded','true')
      }
    }
  }

  btnClickdownloadMyFile(event: any) {
    if (event.type == "Download") {
      let userInteractionTypeId = 12; // For files Download interaction its value 12
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      if (event.data.fileLink.includes('swmaps')) {
        link.setAttribute('href', event.data.fileLink + this.dprFileSWAPSDownloadSAS);
      } else {
        link.setAttribute('href', event.data.fileLink + this.dprFileDownloadSAS);
      }
      link.setAttribute('download', event.data.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.commonService.docInteractionFrRecentAccessFiles(event?.data?.id, event?.data?.objectTypeId, this.loginUserId, userInteractionTypeId);
    } else {
      this.toggleDocuemnt(event.data)
    }
  }

  toggleDocuemnt(data: any) {
    data.isEyeVisible = !data.isEyeVisible;
    if(data.isEyeVisible) {
      data.action[1].svgIconSrc = "../../../../assets/images/ico_visibility.svg"
    } else {
      data.action[1].svgIconSrc = "../../../../assets/images/ico_visibility_off.svg"
    }
    this._toggleDoc();
  }

  _toggleDoc(){
    let filterExp: any = ['all'];
    let hiddenFeatures: any[] = [];
    this.fileTableData?.forEach((item: any) => {
      if (!item.isEyeVisible) {
        hiddenFeatures.push(item.name.replace(/\s/g, ''));
      }
    });
    filterExp.push(['!', ['in', ['get', 'docName'], ['literal', hiddenFeatures]]])
    this.map.setFilter('sourceDocumentLayer',filterExp);
  }

  //getUpdatedFeatures method used to get latest feature from fetchFeaturesByJobId api
  getUpdatedFeatures(response: any, saveFeature: any, layer: any): Observable<any> {
    if (saveFeature) {
      this.newlyAddedFeature?.splice(this.newlyAddedFeature?.findIndex(it => it?.properties?.featureName === saveFeature?.properties?.featureName), 1);
    }
    this.isBasemapToggle = false;
    this.featureGroupMap = new Map;
    this.featureGroupToggleStateMap = new Map;
    this.featureGroupTypeToggleStateMap = new Map;
    this.featureGroupTypeFeatureNameToggleStateMap = new Map;
    return this.mapViewerJobFeatureService
    .fetchFeaturesByJobId(this.navigatedJobId!, this.loginUserId).pipe(
      switchMap((data:any) => this.commonMapService.getExternalContent(this.navigatedJobId, this.loginUserId).pipe(
        map((item:any) => ([ ...data, ...item ]))
      )));
  }

  //addNewFeatureInExisting method used to add the feature's in after saving add or edit map features
  addNewFeatureInExisting() {
    this.newlyAddedFeature?.forEach((feature: any) => {
      if (this.featureGroupMap.has(this.newAddedFeatureInLayer.layerName)) {
        this.addMainFeatureData(this.newAddedFeatureInLayer, feature, 'hasValue');
      } else {
        this.addMainFeatureData(this.newAddedFeatureInLayer, feature, null);
      }
    });
  }

  // File Selection for Imported file , and detect file type and name
  onFileSelected(type: any, event: any) {

    const file: File = event.target.files[0];
    this.importSubmitted = false;
    if (file) {
      this.importedFileArr = file;
      this.importedFileName = file.name;
      let fileExtension = this.importedFileName.substr((this.importedFileName.lastIndexOf('.') + 1));

      this.importedFileType = fileExtension.toLowerCase();
      let validFileCheck = false;
      if (type === 'importfile') {
        validFileCheck = this.validateFile();
      } else if (type === 'pointcloud') {
        validFileCheck = this.validatePointCloudFile();
      } else {
        validFileCheck = this.validateFile();
      }

      if (validFileCheck) {
        this.importSubmitted = true;
      } else {
        this.importSubmitted = false;
        this.myImportFileVariable.nativeElement.value = "";
        this.importGISForm.patchValue({
          importSelectFormControl: ''
        });
        this.importedFileName = '';
        this.importedFileType = '';
        this.nonGeoreferenceImgDivActive = false;
        this.commonMapService.normalItMapZoom(this.map);



      }
    }
  }

  validateFile() {
    let blnValid = false;
    for (let j = 0; j < this._validFileExtensions.length; j++) {
      let sCurExtension = this._validFileExtensions[j];
      if (this.importedFileName.substr(this.importedFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }

    if (!blnValid) {
      alert("Sorry, " + this.importedFileName + " is invalid, allowed extensions are: " + this._validFileExtensions.join(", "));
      return false;
    }
    return true;
  }

  validatePointCloudFile() {
    let blnValid = false;
    for (let j = 0; j < this._validFileExtensionsPointCloud.length; j++) {
      let sCurExtension = this._validFileExtensionsPointCloud[j];
      if (this.importedFileName.substr(this.importedFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }

    if (!blnValid) {
      alert("Sorry, " + this.importedFileName + " is invalid, allowed extensions are: " + this._validFileExtensionsPointCloud.join(", "));
      return false;
    }
    return true;
  }

  // Check file type for import
  checkImportFileType() {
    if (this._rasterFileExtensions.includes('.' + this.importedFileType.toLowerCase())) {
      this.checkImportFileTypeRaster = true;
    } else {
      this.checkImportFileTypeRaster = false;
    }

    if (this.isImportingReferenceLayer)
    {
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = true;
      this.isSubmitNonGeoReferenceImage = false;
      this.importGISForm.patchValue({
        importSelectFormControl: 'referenceGIS'
      });
      this.publishUploadFile('withoutCustomGISReference');
    } else {
      const btnImportPointsLines = document.getElementById('btnimportPointsLines') as HTMLButtonElement
      btnImportPointsLines.click();
    }
  }

  // Publish Code method for Import file
  publishUploadFile(customStatus: any) {
    this.isGeoReferencedRaster = false;
    this.customImportFeaturesType = [];
    this.customImportFeaturesGroup = [];
    this.selectImportFeatureType = '';
    this.selectImportFeatureGroup = '';

    let rasterImageCorrdinateObj = {};
    let rasterImageId = 0;
    let isRasterEdit = false;
    if (this.importSubmitted) {
      this.spinner.show();
      let importedFileTypeURL = '';

      const current = new Date();
      const timestamp = current.getTime();
      let match: any = this.removeExtension(this.importedFileName) + '-' + timestamp + '_' + this.navigatedJobId +
        '_' + this.loginUserId + '.' + this.importedFileType.toLowerCase();
      if (this.importedFileType.toLowerCase() === 'las' || this.importedFileType.toLowerCase() === 'laz') {
        match = this.removeExtension(this.importedFileName) + '_' + timestamp + '_' + this.navigatedJobId +
          '_' + this.loginUserId + '.' + this.importedFileType.toLowerCase();
      }



      this.importUploadingFileName = match;
      if (customStatus === 'Custom') {
        importedFileTypeURL = 'ImportGetSchemaMapper'
        let frmArray = this.cadCustomForm.get('cadFeatures') as FormArray;
        frmArray.clear();
        // importedFileTypeURL = 'ImportGetSchemaMapperforTesting' // For Testing
      } else if (customStatus === 'withoutCustomGISReference') {
        importedFileTypeURL = 'ImportGISMVTReference'
      } else {
        if (this._rasterFileExtensions.includes('.' + this.importedFileType.toLowerCase())) {
          if (customStatus === 'nonGeoReferenceImageWithoutPNG') {
            importedFileTypeURL = 'ImportRasterTranslate';
          } else if (customStatus === 'nonGeoReferenceImage') {
            this.isGeoReferencedRaster = false;
            importedFileTypeURL = 'ImportRaster';
          } else {
            this.isGeoReferencedRaster = true;
            importedFileTypeURL = 'ImportGISRasterFile';
          }
        } else {
          importedFileTypeURL = 'ImportGISVectorFile'
        }
      }

      // Azure Blob Method to upload file on blob
      this.blobService.uploadImage(this.importedContainerSAS, this.importedContainer, this.importedFileArr, match, () => {

        this.importSubmitted = false;
        this.myImportFileVariable.nativeElement.value = "";
        this.importGISForm.patchValue({
          importSelectFormControl: ''
        });
        this.importedFileName = '';
        this.importedFileType = '';
        let div = document.getElementById("cancelImport") as HTMLDivElement;
        div.click();

        //  Non GeoReference Image Coordinate fetch start
        if (customStatus === 'nonGeoReferenceImage') {
          let imgElement = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
          let mapElement = document.getElementById("mapviewerjobs") as HTMLDivElement;
          let imgRect = imgElement.getBoundingClientRect();
          let mapRect = mapElement.getBoundingClientRect();

          let pixelXMin = imgRect.left - mapRect.left;
          let pixelXMax = imgRect.right - mapRect.left;
          let pixelYMin = imgRect.top - mapRect.top;
          let pixelYMax = imgRect.bottom - mapRect.top;

          let nw = this.map.unproject([pixelXMin, pixelYMin]);
          let ne = this.map.unproject([pixelXMax, pixelYMin]);
          let se = this.map.unproject([pixelXMax, pixelYMax]);
          let sw = this.map.unproject([pixelXMin, pixelYMax]);

          rasterImageCorrdinateObj = {
            xMin: nw.lng,
            xMax: se.lng,
            yMin: se.lat,
            yMax: ne.lat,
          }

        }
        let importLog = {
          "fileName": match,
          "fileSize": this.importedFileArr.size
        }
        this.mapViewerJobFeatureService.generateImportLog(importLog).subscribe((responseimportLog: any) => {
          if (responseimportLog?.processingStatus?.status.toLowerCase() == 'success') {
            //  Non GeoReference Image Coordinate fetch end
            const fileId: number = responseimportLog.fileId;
            this.importUploadingFileId = fileId;
            this.mapViewerJobFeatureService.getFetchDataImport(match, importedFileTypeURL, this.isGeoReferencedRaster, rasterImageId, rasterImageCorrdinateObj, isRasterEdit, this.rangeSliderGeoReferenceImage, fileId, this.importedIsPublic).subscribe(response => {
              let completeImportLog = {
                "importExportJobId": responseimportLog?.importExportJobId,
                "isCompleted": true,
                "errorLog": "",
                "fileName": match
              }
              if (response.status) {
                this.toastr.success("File uploaded Successfully.");
                this.nonGeoreferenceImgDivActive = false;
                this.commonMapService.normalItMapZoom(this.map);
                this.spinner.hide();
                let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
                cancelImportGIS.click();

                this.mapViewerJobFeatureService.completeImportLogAPICall(completeImportLog);
                if (response?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
                  setTimeout(() => {
                    completeImportLog.isCompleted = false;
                    completeImportLog.errorLog = "Error : Can not import records that does not related to this Job";
                    this.mapViewerJobFeatureService.completeImportLogAPICall(completeImportLog);
                    this.toastr.error('Error : Can not import records that does not related to this Job');
                  }, 2000);
                }
                if (customStatus == 'Custom') {
                  let btnCustomCADData = document.getElementById("btnCustomCADData") as HTMLButtonElement;
                  btnCustomCADData.click();

                  let gisMapSchemadata: any = Object.keys(response.gisFileSchemaMapper);
                  for (let i = 0; i < gisMapSchemadata.length; i++) {
                    if (gisMapSchemadata[i].substr((gisMapSchemadata[i].lastIndexOf('.') + 1)).toLowerCase() === 'dxf' || true) {

                      this.importCustomFileName = gisMapSchemadata[i];


                      let layerEntities: any = Object.values(response.gisFileSchemaMapper)[i];
                      if (layerEntities?.entities?.layerValues.length > 0) {
                        this.crsDropDownData = layerEntities?.entities?.crsOptions;

                        if (this.crsDropDownData?.length > 0) {
                          this.crsDropDownData.sort(function (a: any, b: any) {
                            if (a.description.toLowerCase() < b.description.toLowerCase()) { return -1; }
                            if (a.description.toLowerCase() > b.description.toLowerCase()) { return 1; }
                            return 0;
                          });
                          this.cadCustomForm.patchValue({
                            coordinateCRSName: this.crsDropDownData[0]
                          });
                        }

                        let layerValuesCAD = layerEntities?.entities?.layerValues.sort(function (a: any, b: any) {
                          if (a.toLowerCase() < b.toLowerCase()) { return -1; }
                          if (a.toLowerCase() > b.toLowerCase()) { return 1; }
                          return 0;
                        });
                        this.featuresLoaded(layerValuesCAD);
                      } else {
                        this.toastr.warning("No layer entities in this layer");
                      }
                      break;
                    }

                  }

                } else if (customStatus === 'nonGeoReferenceImageWithoutPNG') {
                  if (response?.dataUrl) {
                    this.editGeoReferenceImageType = 'add';
                    this.rasterImageFeature = '';
                    this.callNonGeoReferenceModal(response?.dataUrl, '' as any);
                  } else {
                    this.toastr.warning("Error: Image is not loading");
                  }
                } else {
                  this.mapRefreshAfterImport();
                }
              } else {
                this.spinner.hide();
                completeImportLog.isCompleted = false;
                if (response?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
                  this.toastr.error('Error : Can not import records that does not related to this Job');
                  completeImportLog.errorLog = "Error : Can not import records that does not related to this Job";
                } else {
                  if (response?.errorMessages?.length > 0) {
                    this.toastr.error('Error' + response?.errorMessages[0]);
                    completeImportLog.errorLog = 'Error' + response?.errorMessages[0];
                  } else if (response?.warningMessages?.length > 0) {
                    this.toastr.error('Error' + response?.warningMessages[0]);
                    completeImportLog.errorLog = 'Error' + response?.warningMessages[0];
                  } else {
                    this.toastr.error('Error');
                    completeImportLog.errorLog = 'Error';
                  }
                }
                this.mapViewerJobFeatureService.completeImportLogAPICall(completeImportLog);
                let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
                cancelImportGIS.click();
              }

            }, (err) => {
              console.log(err);
              if (err?.error?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
                this.toastr.error('Error : Can not import records that does not related to this Job');
              } else {
                if (err?.error?.errorMessages?.length > 0) {
                  this.toastr.error('Error' + err?.error?.errorMessages[0]);
                } else if (err?.error?.warningMessages?.length > 0) {
                  this.toastr.error('Error' + err?.error?.warningMessages[0]);
                } else {
                  this.toastr.error('Error');
                }
              }
              console.log('Error retrieving Data ...');
              this.spinner.hide();
              this.importGISForm.patchValue({
                importSelectFormControl: ''
              });
              this.nonGeoreferenceImgDivActive = false;
              this.commonMapService.normalItMapZoom(this.map);
              let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
              cancelImportGIS.click();
            });
          } else {
            this.spinner.hide();
            if (responseimportLog?.processingStatus?.status?.toLowerCase() == 'failure') {
              this.toastr.error('Error' + responseimportLog?.processingStatus?.message);
            } else {
              this.toastr.error('Error');
            }
            let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
            cancelImportGIS.click();
          }

        }, (err) => {
          console.log(err);
          if (err?.error?.warningMessages[0]?.toLowerCase().includes('cannot import feature')) {
            this.toastr.error('Error : Can not import records that does not related to this Job');
          } else {
            if (err?.error?.errorMessages?.length > 0) {
              this.toastr.error('Error' + err?.error?.errorMessages[0]);
            } else if (err?.error?.warningMessages?.length > 0) {
              this.toastr.error('Error' + err?.error?.warningMessages[0]);
            } else {
              this.toastr.error('Error');
            }
          }
          console.log('Error retrieving Data ...');
          this.spinner.hide();
          this.importGISForm.patchValue({
            importSelectFormControl: ''
          });
          this.nonGeoreferenceImgDivActive = false;
          this.commonMapService.normalItMapZoom(this.map);
          let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
          cancelImportGIS.click();
        });
      }).catch(() => this.spinner.hide());
    } else {
      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importedFileName = '';
      this.importedFileType = '';
      this.nonGeoreferenceImgDivActive = false;
      this.commonMapService.normalItMapZoom(this.map);
      this.spinner.hide();
      this.toastr.error("Please upload a valid file.");
    }
  }


  // used for loading different feature entities in form
  featuresLoaded(fetauresCad: any) {
    let fetauresCad1 = [];
    // Comment for get all data
    // fetauresCad1 = fetauresCad.slice(0,5);
    fetauresCad1 = fetauresCad;

    fetauresCad1.forEach((fetaureCad: any, index: any) => {

      this.addCADFeature(fetaureCad, index);
    })
  }

  // Used for get CAD Form values
  cadFeatures(): FormArray {
    return this.cadCustomForm.get('cadFeatures') as FormArray;
  }

  // Used for bind Feature Group in CAD form
  featureGroupDataBind() {
    this.importFeatureGroup = [];
    FeatureTypeStyleMapping.featureTypeStyleJson.forEach((element) => {
      const val = {
        featureGroupId: element.feature_group_id,
        featureGroup: element.feature_group_name,
      };
      if (this.importFeatureGroup?.length > 0) {
        if (
          this.importFeatureGroup?.find(
            (it) => it.featureGroupId != element.feature_group_id
          )
        ) {
          this.importFeatureGroup.push(val);
        }

      } else {
        this.importFeatureGroup.push(val);
      }
    });

    this.importFeatureGroup = this.importFeatureGroup.filter(
      (array, i, self) =>
        i === self.findIndex((t) => t.featureGroupId === array.featureGroupId)
    );
  }

  // Used for bind feature type in CAD form based on Feature Group id
  featureTypeDataBind(groupId: any) {
    this.importFeatureType = [];
    this.featureTypeSearchSubscribe = this.mapViewerJobFeatureService.searchFeatureTypeApi(groupId).subscribe((data: any) => {

      this.importFeatureType = data;
    });
  }

  // used for set selected value of FeatureGroup details in respective field
  onselectFeatureGroupImport(item: any, cadIndex: any) {

    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(cadIndex).patchValue({
      featureGroup: item.mapGroupName,
      featureGroupId: item.mapGroupId,
      featureType: '',
      featureTypeId: '',
    });
    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(cadIndex)?.get("featureType")?.enable();
    this.importFeatureGroupSelectedIndex = null;
    this.importFeatureTypeSelectedIndex = null;

    if (item.mapGroupId !== this.previousfeatureGroupIdSe) {
      this.featureTypeDataBind(item.mapGroupId);
      this.previousfeatureGroupIdSe = item.mapGroupId;
    }
  }

  // used for set selected value of FeatureType details in respective field
  onselectFeatureTypeImport(item: any, cadIndex: any) {

    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(cadIndex).patchValue({
      featureType: item.mapFeatureTypeName,
      featureTypeId: item.mapFeatureTypeId,
    });
    this.importFeatureGroupSelectedIndex = null;
    this.importFeatureTypeSelectedIndex = null;
  }

  // Used for to create row for Entities layer
  newCADData(fetaureCad: any): FormGroup {
    return this.fb.group({
      featureName: this.fb.control({ value: fetaureCad, disabled: true }, [Validators.required]),
      featureGroup: this.fb.control('', Validators.required),
      featureType: this.fb.control({ value: '', disabled: true }, [Validators.required]),
      featureGroupId: this.fb.control('', Validators.required),
      featureTypeId: this.fb.control('', Validators.required),
    });
  }

  // On Submit of CAD Custom form
  onCADCustomSubmit() {

    let objCr = {
      crs: '',
      layerMappings: []
    }

    let layerrequest1: any = [];

    this.cadCustomForm.getRawValue().cadFeatures.forEach((number: any, index: any) => {
      let leter = {
        layerName: number.featureName,
        map_feature_type_id: number.featureTypeId,
        map_group_id: number.featureGroupId,
      }

      // layerrequest1.push({[leter]: number});

      layerrequest1.push(leter);


    });
    objCr.layerMappings = layerrequest1;
    objCr.crs = this.cadCustomForm.getRawValue().coordinateCRSName;

    let layername3 = {
      "gisFileSchemaMapper": { [this.importCustomFileName]: { 'entities': objCr } }
    };

    // layername3.push({'layerValue': objCr});
    // layername3.push({[this.importCustomFileName]:{'entities': objCr}});



    let cadCustomRequest: any = layername3;




    if (this.cadCustomForm.valid) {
      this.toastr.success('CAD Custom form value filled Successfully');
    } else {
      this.toastr.error('Please insert all values in form');
    }

    // ImportGISVectorCustom
    // gisFileName=?? and isCAD=true

    if (this.cadCustomForm.valid) {
      this.spinner.show();
      this.mapViewerJobFeatureService.submitCustomCADData(this.importUploadingFileName, 'ImportVectorCustom', cadCustomRequest, true, this.importUploadingFileId).subscribe(response => {

        if (response.status) {
          this.toastr.success("Success");
          this.spinner.hide();
          let cancelImportGIS = document.getElementById("cancelCustomCADForm") as HTMLDivElement;
          cancelImportGIS.click();
          this.mapRefreshAfterImport();
        } else {
          this.spinner.hide();
          this.toastr.error('Error' + response?.errorMessages[0]);
        }

      }, (err) => {
        console.log(err);
        this.toastr.error('Error' + err);
        console.log('Error retrieving Data ...');
        this.spinner.hide();
      });
    }

  }

  mapRefreshAfterImport() {
    this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
      this.navigatedFeatures = dataWithLayer;
      for (const featureData of dataWithLayer) {
        if (!this.isBasemapToggle) {
          this.setValuesInRequiredMaps(featureData);
        } else {
          this.isBasemapToggle = false;
        }
      }

      this.refreshMapStyle();
      this.reteriveGeoReferenceImagebyUser();
    });
  }

  addCADFeature(fetaureCad: any, index: any) {
    this.cadFeatures().push(this.newCADData(fetaureCad));
  }

  // Search feature group value on runtime
  searchFeatureGroup(index: number) {
    let arrayControll: any = this.cadCustomForm.get('cadFeatures') as FormArray;

    let valueFeatureGroup = arrayControll.at(index).get('featureGroup').value;
    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(index).patchValue({

      featureGroupId: '',
      featureType: '',
      featureTypeId: '',
    });
    arrayControll.at(index).get("featureType").disable();
    if (valueFeatureGroup.length > 1) {
      this._filterFeatureGroup(valueFeatureGroup, index);
    } else {
      this.importFeatureGroupSelectedIndex = null;
    }
  }

  // Search feature type value on runtime
  searchFeatureType(index: number) {
    let arrayControll: any = this.cadCustomForm.get('cadFeatures') as FormArray;

    let valueFeatureType = arrayControll.at(index).get('featureType').value;
    let featureGroupIdSe = arrayControll.at(index).get('featureGroupId').value;
    (<FormArray>this.cadCustomForm.controls['cadFeatures'])?.at(index).patchValue({
      featureTypeId: '',
    });
    if (featureGroupIdSe !== this.previousfeatureGroupIdSe) {
      this.featureTypeDataBind(featureGroupIdSe);
      this.previousfeatureGroupIdSe = featureGroupIdSe;
    }

    if (valueFeatureType.length > 1) {
      this.filteredOptionsFeatureType = this._filterFeatureType(valueFeatureType, featureGroupIdSe);
      if (this.filteredOptionsFeatureType.length !== 0) {
        this.importFeatureTypeSelectedIndex = index;
      }
    } else {
      this.importFeatureTypeSelectedIndex = null;
    }
  }

  ManageNameControl(index: number) {
    let arrayControll: any = this.cadCustomForm.get('cadFeatures') as FormArray;
    let valueFeatureGroup = arrayControll.at(index).get('featureGroup').valueChanges;

    this.filteredOptionsFeatureGroup[index] = valueFeatureGroup
      .pipe(
        startWith<string>(''),
        map((name: any) => name ? this._filterFeatureGroup(name, index) : this.groupDropDownData.slice())
      );

  }

  // Used for filter feature group value
  private _filterFeatureGroup(searchText: string, index: number) {
    this.featureGroupSearchSubscribe = this.mapViewerJobFeatureService.searchFeatureGroupApi(searchText).subscribe((data: any) => {

      // return data
      this.filteredOptionsFeatureGroup = data;
      if (this.filteredOptionsFeatureGroup.length !== 0) {
        this.importFeatureGroupSelectedIndex = index;
      }
    });
  }

  // Used for filter feature type value
  private _filterFeatureType(name: string, featureGroupId: any) {
    const filterValue = name.toLowerCase();
    return this.importFeatureType.filter((option: any) => option.mapFeatureTypeName.toLowerCase().includes(filterValue));
  }

  // Use method for Remove Extension
  removeExtension(filename: any) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }

  // Used to save the Imported Layer File name changed
  saveImportedLayerName() {
    if (this.importedLayerFileName) {
      if (confirm('Are you sure you want to save?')) {
        this.spinner.show();
        this.featureDeleteSubscribe = this.mapViewerJobFeatureService
          .saveImportedFileNameByJobId(
            parseInt(this.importedLayerFileId),
            this.importedLayerFileName,
            2,
            this.loginUserId
          )
          .subscribe(
            (data: any) => {
              if (data.processingStatus.status == 'Success') {
                this.toastr.success('Layer name successfully changed');
                this.importedFileNameChangeCondition = false;
                this.importedLayerFileNameStore = this.importedLayerFileName;
                this.getUpdatedFeatures('', '', '').subscribe(
                  (dataWithLayer: any) => {
                    this.navigatedFeatures = dataWithLayer;
                    for (const featureData of dataWithLayer) {
                      if (!this.isBasemapToggle) {
                        this.setValuesInRequiredMaps(featureData);
                      } else {
                        this.isBasemapToggle = false;
                      }
                    }

                    this.refreshMapStyle();
                  }
                );
              } else {
                this.toastr.error(data.status + ' : ' + data.message);
              }
              this.spinner.hide();
            },
            (err: any) => {
              this.toastr.error('Error');
              this.spinner.hide();
            }
          );
      }
    } else {
      this.toastr.error("Please enter valid layer name")
    }
  }

  cancelImportedLayerName() {
    this.importedFileNameChangeCondition = false;
    this.importedLayerFileName = this.importedLayerFileNameStore;
  }

  importCustomFormData() {
    console.log("Feature Group ", this.selectImportFeatureGroup, "Feature Type", this.selectImportFeatureType)
  }

  // For Detect Import Layer File Name is change
  importedFileChange(event: any) {
    if (event != this.importedLayerFileNameStore) {
      this.importedFileNameChangeCondition = true;
    } else {
      this.importedFileNameChangeCondition = false;
    }
    if (event == '') {
      this.importedBlankCheck = false
    } else {
      this.importedBlankCheck = true
    }
  }

  // Navigation to present location Error
  showError(error: any) {
    let self = this;
    switch (error.code) {
      case error.PERMISSION_DENIED:
        self.toastr.error("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        self.toastr.error("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        self.toastr.error("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        self.toastr.error("An unknown error occurred.");
        break;
    }
  }

  // Navigation to present location Coordinates
  storeCoordinates(position: any) {
    let self = this;
    // self.flyMaptoCurrentLocation(position.coords.longitude, position.coords.latitude)
    this.commonMapService.flyMaptoCurrentLocation(this.map, this.renderer, position.coords.longitude, position.coords.latitude, 'markerCurrentLocation');
  }

  // Navigation to present location
  navigateToCurrentLocation() {
    let self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(self.storeCoordinates.bind(self), self.showError.bind(this), { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 });
      // },{ timeout:5000, enableHighAccuracy:true});
      // maximumAge:60000,
      // },self.showError,{maximumAge:60000, timeout:5000, enableHighAccuracy:true});
    } else {
      this.toastr.error("Location is disabled.")
    }
  }

  //# fly map to particular coordinates
  flyMaptoCurrentLocation(long: any, lat: any) {
    this.map.flyTo({
      center: [long, lat],
      zoom: 15
    });

    const recaptchaContainer = this.renderer.createElement('div');
    this.renderer.setProperty(recaptchaContainer, 'id', 'recaptcha-container');
    this.renderer.appendChild(document.body, recaptchaContainer);
    this.renderer.setAttribute(recaptchaContainer, 'class', 'marker');

    const marker1 = new mapboxgl.Marker(recaptchaContainer)
      .setLngLat([long, lat])
      .addTo(this.map);
  }

  /***
   * Map Print model start
   */
  printFormGenerate() {
    this.printForm = this.fb.group({
      psCRS: ['SPCS', Validators.required],
      psScale: ['25', [Validators.min(1),
      Validators.max(13000),
      Validators.minLength(1),
      Validators.maxLength(5),
      Validators.pattern(/\d/)]],
      psSize: ['letter', Validators.required],
      psOrientation: ['landscape', Validators.required],
      psFormat: ['PDF', Validators.required],
      psPrintGrid: [false, Validators.required],
    })

  }

  createScaleBar() {
    let scaleBarLabelDOM: any = document.getElementById('scale-bar-label') as HTMLElement;
    // if (scaleBarLabelDOM) {
    //   scaleBarLabelDOM.textContent = `Scale: 1" = ${this.printForm.value.psScale}'`;
    // }
    let scaleBarTickIncrements: number = parseFloat((this.printForm.value.psScale / 4).toFixed(1));    let firstBarLabelDOM: any = document.getElementById('first-bar-label') as HTMLElement;
    if (firstBarLabelDOM) {
      firstBarLabelDOM.textContent = '0';
    }
    let secondBarLabelDOM: any = document.getElementById('second-bar-label') as HTMLElement;
    if (secondBarLabelDOM) {
      secondBarLabelDOM.textContent = `${scaleBarTickIncrements}'`;
    }
    let thirdBarLabelDOM: any = document.getElementById('third-bar-label') as HTMLElement;
    if (thirdBarLabelDOM) {
      thirdBarLabelDOM.textContent = `${scaleBarTickIncrements * 2}'`;
    }
    let fourthBarLabelDOM: any = document.getElementById('fourth-bar-label') as HTMLElement;
    if (fourthBarLabelDOM) {
      fourthBarLabelDOM.textContent = `${scaleBarTickIncrements * 3}'`;
    }
    let fifthBarLabelDOM: any = document.getElementById('fifth-bar-label') as HTMLElement;
    if (fifthBarLabelDOM) {
      fifthBarLabelDOM.textContent = `${this.printForm.value.psScale}'`;
    }
  }
// function for variable length of scalebar bars
  // createScaleBar() {
  //
  //   const totalScale = this.printForm.value.psScale; // Total scale (e.g., 100 for "1 inch = 100ft")
  // console.log(this.printForm.value, 'print form');

  //
  //   let scaleBarTickIncrement = Math.floor(totalScale / 4);

  //
  //   let scaleBarLabelDOM = document.getElementById('scale-bar-label');
  //   if (scaleBarLabelDOM) {
  //     scaleBarLabelDOM.textContent = `Scale: 1" = ${totalScale}'`;
  //   }

  //
  //   const baseLength = 24; // Base length for the bars

  //
  //   const labels = [
  //     { id: 'first-bar-label', increment: 0 },
  //     { id: 'second-bar-label', increment: scaleBarTickIncrement },
  //     { id: 'third-bar-label', increment: scaleBarTickIncrement * 2 },
  //     { id: 'fourth-bar-label', increment: scaleBarTickIncrement * 3 },
  //     { id: 'fifth-bar-label', increment: totalScale } // Represents the total scale
  //   ];

  //   let totalLength = 0;

  //   labels.forEach((label, index) => {
  //     const labelDOM = document.getElementById(label.id);
  //     if (labelDOM) {
  //       labelDOM.textContent = `${label.increment}'`;
  //     }

  //
  //     if (index < labels.length - 1) {
  //       const barDOM = document.querySelector(`.${label.id.replace('-label', '')}`);
  //       if (barDOM instanceof HTMLElement) {
  //         let lengthAdjustment = baseLength;
  //         if (index === 3) {
  //           let remainingIncrement = totalScale - (scaleBarTickIncrement * 3);
  //           lengthAdjustment = baseLength * (remainingIncrement / scaleBarTickIncrement);
  //         }
  //         barDOM.style.width = `${lengthAdjustment}px`;
  //         totalLength += lengthAdjustment;

  //         console.log(`Bar #${index + 1} (ID: ${label.id.replace('-label', '')}): Value = ${label.increment}', Length = ${lengthAdjustment}px`);
  //       }
  //     } else {
  //
  //       console.log(`Label for Bar #${index + 1} (ID: ${label.id}): Value = ${label.increment}' (No corresponding bar)`);
  //     }
  //   });
  //   const lastLabelDOM = document.getElementById('fifth-bar-label');
  // if (lastLabelDOM && lastLabelDOM instanceof HTMLElement) {
  //   lastLabelDOM.style.left = `${totalLength}px`;
  // }
  // }

  onPrintClick() {
    this.scaleError = "";
    if(this.printForm.value.psScale  > 13000){
      return;
    }
    if(!this.printForm.value.psScale){
      this.scaleError = "Scale is required";
      return;
    }
    this.printState = true;

    // Create the scale bar
    this.createScaleBar();

    // Get the extent of the Print box
    const testdata = this.draw.getAll();
    let printBounds = turf.bbox(this.draw.getAll().features[0])

    // [-87.49852616685753, 36.859163957189054, -87.49128902620205, 36.86466840010627]

    // Get the Map Wrapper DOM
    const mwDom: HTMLElement | null = document.getElementById('map-wrapper');
    mwDom!.style.visibility = 'visible';


    // Get the dimensions of map-wrapper
    let mwWidth: number | undefined = mwDom?.clientWidth;
    let mwHeight: number | undefined = mwDom?.clientHeight;

    // Create a PDF document

    // let format;
    // if (
    //   this.printForm.value.psSize === 'ansi-c' ||
    //   this.printForm.value.psSize === 'ansi-d' ||
    //   this.printForm.value.psSize === 'ansi-e') {
    //   if (this.printForm.value.psOrientation === 'portrait') {
    //     format = [mwWidth, mwHeight]
    //   } else {
    //     format = [mwHeight, mwWidth]
    //   }
    // } else {
    //   format = this.printForm.value.psSize
    // }

    // ANSI paper types are not built into jsPDF so we specify dimensions based on map-wrapper
    let pdfPaperFormat: any;
    if (this.printForm.value.psSize.startsWith('ansi')) {
      console.log(this.printForm.value.psOrientation, this.printForm.value.psSize, mwWidth, mwHeight);
      if (this.printForm.value.psOrientation === 'portrait') {
        pdfPaperFormat = [mwWidth, mwHeight];
      } else {
        pdfPaperFormat = [mwHeight, mwWidth];
      }
    } else {
      pdfPaperFormat = this.printForm.value.psSize;
    }

    var docHtml = new jsPDF({
      orientation: this.printForm.value.psOrientation,
      unit: 'px',
      // format: format,
      format: pdfPaperFormat,
      putOnlyUsedFonts: true,
      floatPrecision: 16
    });

    // Get the dimensions of PDF doc being created
    let pageWidth = docHtml.internal.pageSize.width;
    let pageHeight = docHtml.internal.pageSize.height;

    // Scale map-wrapper to the lower of the ratios of PDF / DOM
    let scale: number = [pageHeight / mwHeight!, pageWidth / mwWidth!].sort()[0];

    // Get Print Box bounds in Lng/Lat
    const lowerLeftPnt: any = [printBounds[0], printBounds[1]];
    const upperRightPnt: any = [printBounds[2], printBounds[3]];

    // Get Print Box bounds in X/Y (pixels)
    const lowerLeftPixel = this.map.project(lowerLeftPnt);
    const upperRightPixel = this.map.project(upperRightPnt)

    // Create a bbox to pass to queryRenderedFeatures
    let queryBbox: any = [lowerLeftPixel, upperRightPixel];
    // Get all features in the bbox and build a Feature Collection
    let mapLayersAll = this.map.getStyle().layers;
    let printLayersRegex = new RegExp('^[1234]-.*');
    let mapLayersPrint: any = mapLayersAll.filter((layer: any) => printLayersRegex.test(layer.id)).map((layer: any) => layer.id)
    let allFeatures = this.map.queryRenderedFeatures(queryBbox, { layers: [...mapLayersPrint] }).filter(feature => feature.geometry.type !== 'Polygon')
    // let allFeatures = this.map.queryRenderedFeatures(queryBbox, { layers: ['1-point', '1-line', '2-point', '2-line', '3-point', '3-line', '4-photo', '4-matterport', '4-pointcloud', '4-virtualtour', '4-attachment', '4-externallink'] }).filter(feature => feature.geometry.type !== 'Polygon')
    // if (allFeatures.length === 0) {
    //   this.toastr.error("Click on print when feature is loaded.");
    //   return
    // }
    let featureCollection: any = {
      type: 'FeatureCollection',
      features: []
    }
    allFeatures.forEach((feature: any) => {
      let currentFeature: any = {
        type: 'Feature',
        properties: {}
      };
      currentFeature.properties.featureType = feature.properties.featureType;
      currentFeature.properties.featureName = feature.properties.featureName;
      currentFeature.geometry = feature.geometry;
      featureCollection.features.push(currentFeature);
    })
    let featureCollectionJSON: any = JSON.stringify(featureCollection);

    this.featureTypeSymbologyMap = this.processFeatures(allFeatures)
    console.log('featureTypeMap', this.featureTypeSymbologyMap);
    this.pointSymbology = [
      ...(this.featureTypeSymbologyMap.point ?? []),
      ...(this.featureTypeSymbologyMap.photo ?? [])
  ]
    this.processLineSymbology()

    // Image dimensions
    let printImgContainerDom = document.querySelector('.print-img-container');
    let printImgDom = document.getElementById('print-img');
    let imgWidth = printImgContainerDom!.clientWidth;
    let imgHeight = printImgContainerDom!.clientHeight;

    let printedDateDOM = document.getElementById('printed-date');
    printedDateDOM!.textContent = new Date().toLocaleDateString();
    let printedByDOM = document.getElementById('printed-by');
    printedByDOM!.textContent = this.loginUserEmailId

    // Add project info - need to get this from API
    // Get any Job in the viewport - not sure what else to do here
    let printJobId = this.navigatedJobId;

    let currentStyleUrl = this.map.getStyle().sprite;
    let baseMapId = currentStyleUrl ? 'mapbox/' + currentStyleUrl.substring(currentStyleUrl.lastIndexOf('/') + 1) : 'mapbox/satellite-streets-v11';

    // console.log('baseMapId', baseMapId);


    var payload = {
      printBounds: printBounds,
      xDim: imgWidth,
      yDim: imgHeight,
      featureCollection: featureCollection,
      crs: this.printForm.value.psCRS,
      printGrid: this.printForm.value.psPrintGrid,
      mapboxBaseMapId: baseMapId
    }

    let printFinalFunctionObj = {
      printImgDom: printImgDom,
      imgHeight: imgHeight,
      imgWidth: imgWidth,
      docHtml: docHtml,
      mwDom: mwDom,
      scale: scale
    }

    let projectNameDOM = document.getElementById('project-name');
    let projectAddressDOM = document.getElementById('project-address');
    let projectCityDOM = document.getElementById('project-city');
    if (printJobId) {
      this.restService.get(PortalAPI.GET_JOB_INFORMATION + `/${printJobId}`).subscribe(response => {
        projectNameDOM!.textContent = this.navigatedJobName;
        projectAddressDOM!.textContent = response?.address1;
        projectCityDOM!.textContent = response?.city;
        this.printFinalFunction(payload, printFinalFunctionObj);
      }, (err: any) => {
        console.log("Error", err);
        projectNameDOM!.textContent = 'No Project Name';
        projectAddressDOM!.textContent = 'No Project Address';
        projectCityDOM!.textContent = 'No Project City';
        this.printFinalFunction(payload, printFinalFunctionObj);
      });
    } else {
      projectNameDOM!.textContent = 'No Project Name';
      projectAddressDOM!.textContent = 'No Project Address';
      projectCityDOM!.textContent = 'No Project City';
      this.printFinalFunction(payload, printFinalFunctionObj);
    }
  }

  // Print Final API Call
  printFinalFunction(payload : any, printFinalFunctionObj:any){
    this.spinner.show();
    this.commonService.postPrintData(payload).subscribe(response => {
      printFinalFunctionObj.printImgDom!.setAttribute('src', `data:image/png;base64,${response.data}`);
      printFinalFunctionObj.printImgDom!.setAttribute('width', printFinalFunctionObj.imgWidth.toString());
      printFinalFunctionObj.printImgDom!.setAttribute('height', printFinalFunctionObj.imgHeight.toString());
      // Update the CRS
      let crsDivDom = document.getElementById('crs-div');
      crsDivDom!.textContent = response.crsText;

      if (this.printForm.value.psFormat === 'PDF') {
        printFinalFunctionObj.docHtml.html(printFinalFunctionObj.mwDom!, {
          callback: (docHtml:any) => {
            let pageCount = docHtml.getNumberOfPages();
            if (pageCount > 1) {
              docHtml.deletePage(pageCount);
            }
            docHtml.save('GPRSReport.pdf');
            printFinalFunctionObj.mwDom!.style.visibility = 'hidden';
            printFinalFunctionObj.printImgDom!.setAttribute('src', '');
            printFinalFunctionObj.printImgDom!.setAttribute('height', '0px');
            printFinalFunctionObj.printImgDom!.setAttribute('width', '0px');
            this.printSessionEnd();
            this.spinner.hide();
            // this.printBoxDelete();
            this.printState = false;
          },
          margin: 0,
          html2canvas: {
            scale: printFinalFunctionObj.scale,
          },
          x: 0,
          y: 0,
        });
      } else if (this.printForm.value.psFormat === 'JPG') {
        html2canvas(printFinalFunctionObj.mwDom!, {}).then((canvas: any) => {
          let canvasImg = canvas.toDataURL('image/jpg');
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'GPRSReport.jpg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvasImg);
          xhr.send();
          printFinalFunctionObj.mwDom!.style.visibility = 'hidden';
          printFinalFunctionObj.printImgDom!.setAttribute('src', '');
          this.printSessionEnd();
          this.spinner.hide();
          // this.printBoxDelete();
          this.printState = false;

        });
      }
      // this.printBoxDelete();
      // setTimeout(() => {
      //   this.spinner.hide();
      // }, 2000);
      // this.printState = false;
    }, err => {
      console.log('Error retrieving image ...');
      console.log(err);
      this.spinner.hide();
    });
  }

  resetCounter() {
    this.counter = 0;
  }

  onPrintShowPrintBox(e: Event) {
    this.printState = false;
    if(this.counter == 0){
      this.counter =  1;
    }
    if (this.counter === 1) {
      this.printSessionStart();
    } else {
      this.toastr.error('Printbox already added');
    }

  }

  onPrintClickScale() {
    this.printBoxUpdate();
  }

  onPrintClickPaperSize() {

    this.setMapWrapperCSS()
    this.printBoxUpdate()
  }

  onPrintClickOrientation() {
    this.setMapWrapperCSS()
    this.printBoxUpdate()
  }

  onGridPrintClick() {

  }

  setMapWrapperCSS() {
    let mapWrapperDOM = document.getElementById('map-wrapper')
    let classList = []
    for (let index = 0; index < mapWrapperDOM!.classList.length; index++) {
      let css = mapWrapperDOM!.classList.item(index)
      classList.push(css)

    }
    console.log(classList)
    classList.forEach(css => {

      mapWrapperDOM!.classList.remove(css!)
    })
    mapWrapperDOM!.classList.add(this.printForm.value.psOrientation)
    mapWrapperDOM!.classList.add(this.printForm.value.psSize)
  }

  getMapWidthInMeters(latlng1: any, latlng2: any) {
    // Uses spherical law of cosines approximation.
    const R = 6371000;

    const rad = Math.PI / 180,
        lat1 = latlng1.lat * rad,
        lat2 = latlng2.lat * rad,
        a = Math.sin(lat1) * Math.sin(lat2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.cos((latlng2.lng - latlng1.lng) * rad);

    const xWidthInMeters = R * Math.acos(Math.min(a, 1));
    return xWidthInMeters;
  }

  getMapDistanceInMeters(lnglat1: any, lnglat2: any){
    let point1 = turf.point([lnglat1.lng, lnglat1.lat]);
    let point2 = turf.point([lnglat2.lng, lnglat2.lat]);

    let distance = turf.distance(point1, point2, {units: "kilometers"}) * 1000;

    return distance;
  }

  printBoxCreate(centerCoordinates: any) {
    console.log(this.map, this.draw, turf);
    // Draw the print box
    // First get the map's current scale
    // Assume from center of map
    let mapContainer = this.map.getContainer();
    const mapYMidInPixels = mapContainer.clientHeight / 2;
    const mapWidthInPixels = mapContainer.clientWidth;

    // Create two points from left to right and in the middle of the map
    let latlng1 = this.map.unproject([0, mapYMidInPixels]);
    let latlng2 = this.map.unproject([mapWidthInPixels, mapYMidInPixels]);
    // Get the width of the map in meters
    let mapWidthInMeters = this.getMapWidthInMeters(latlng1, latlng2);
    // Assume 96 pixels per inch
    const ppi = 96;
    let mapScale = ppi * (mapWidthInMeters/mapWidthInPixels) * 39.3701; // scale in inches, e.g. 43.4; there are 39.3701 inches in a meter
    // Then get the scale value in the form
    let scaleValue = this.printForm.value.psScale * 12;
    // Compare scale of map with scale selected by user
    let scaleRatio = scaleValue / mapScale;
    // console.log(`The map width in meters is ${mapWidthInPixels} and the scale is 1:${mapScale} with a scale ratio of ${scaleRatio}.`);
    // Get dimensions of the webmap image container from the DOM
    let imgDOM = document.querySelector('.print-img-container');
    let imgWidth = imgDOM?.clientWidth;
    let imgHeight = imgDOM?.clientHeight;

    // Map coords in LntLat and Pixel space
    let mapCenterLngLat
    if (centerCoordinates === undefined) {
      mapCenterLngLat = this.map.getCenter()
    } else {
      mapCenterLngLat = { lng: centerCoordinates[0], lat: centerCoordinates[1] }
    }
    // Get the pixel X, Y coordinates
    let mapCenterXY = this.map.project(mapCenterLngLat)

    let xMin = mapCenterXY.x - (imgWidth! / 2);
    let xMax = mapCenterXY.x + (imgWidth! / 2);
    let yMin = mapCenterXY.y - (imgHeight! / 2);
    let yMax = mapCenterXY.y + (imgHeight! / 2);
    //
    let printBoxWidthInPixels = xMax - xMin;
    let printBoxWidthInMeters = this.getMapWidthInMeters(this.map.unproject([xMin, yMin]), this.map.unproject([xMax, yMin]));
    // let printBoxScale = printBoxWidthInMeters / printBoxWidthInPixels;
    // console.log(`The PrintBox width in meters is ${printBoxWidthInPixels} and the scale is 1:${printBoxScale}.`);
    // Find PrintBox dimensions to satisfy scale requirements
    imgWidth = imgWidth! * scaleRatio;
    imgHeight = imgHeight! * scaleRatio;
    xMin = mapCenterXY.x - (imgWidth! / 2);
    xMax = mapCenterXY.x + (imgWidth! / 2);
    yMin = mapCenterXY.y - (imgHeight! / 2);
    yMax = mapCenterXY.y + (imgHeight! / 2);
    let feature = {
      type: 'Polygon',
      coordinates: [
        [
          [this.map.unproject([xMin, yMin]).lng, this.map.unproject([xMin, yMin]).lat],
          [this.map.unproject([xMin, yMax]).lng, this.map.unproject([xMin, yMax]).lat],
          [this.map.unproject([xMax, yMax]).lng, this.map.unproject([xMax, yMax]).lat],
          [this.map.unproject([xMax, yMin]).lng, this.map.unproject([xMax, yMin]).lat],
          [this.map.unproject([xMin, yMin]).lng, this.map.unproject([xMin, yMin]).lat],
        ]
      ]
    };
    var featureIds = this.draw.add(feature);
    // MapboxDraw.modes.direct_select.dragVertex = function(state:any, e:any){

    // }
    this.draw.setFeatureProperty(featureIds[0], 'isPrintBox', true);
    let mapLayers: any = this.map.getStyle().layers;
    let printBoxLayers: any = mapLayers.filter((mapLayer: any) => /.*print-box.*/.test(mapLayer.id));
    printBoxLayers.forEach((printBoxLayer: any) => this.map.moveLayer(printBoxLayer.id));

  }

  printBoxUpdate() {
    // Get the current Print Box
    let printBox = this.draw.getAll().features.filter((feature: any) => feature.properties.isPrintBox)
    if (printBox.length > 0) {
      printBox = printBox[0];
      // Get the center of the Print Box
      let center = turf.center(printBox);

      // Remove any pre-exsting Print boxes
      this.draw.deleteAll();
      // Update the Print Box
      this.printBoxCreate(center.geometry.coordinates)
    }
  }

  printBoxDelete() {
    this.printType = "";
    this.draw.deleteAll();
    this.resetCounter();
    this.printSessionEnd();
    this.printForm.reset();
    this.printFormGenerate();
    this.onPrintClickOrientation();

  }

  printDrawSelectionChange(e: any) {


    let drawFeature = this.draw.getSelected()
    if (drawFeature.features.length > 0) {
      this.draw.changeMode('sitemap_direct_select', { featureId: drawFeature.features[0].id });
    }
  }

  handleZoomInPrint =(e: any) => {
    console.log(`Zooming while printing; zoom = ${this.map.getZoom()}`);
    this.printBoxUpdate();
  }

  printSessionStart() {
    // Set the printing session to true
    this.isPrintSession = true;
    // Handle zoom
    this.map.on('zoom', this.handleZoomInPrint);
    // Start listening for Draw selectionchange events
    // this.map.on('draw.selectionchange', this.printDrawSelectionChange);
    // Draw the inital Print box
    this.printBoxCreate(undefined)
  }

  printSessionEnd() {
    // Remove all Draw features
    // this.draw.deleteAll();
    // Remove Zoom handler
    this.map.off('zoom', this.handleZoomInPrint);
    // Set the printing session to false
    this.isPrintSession = false;
  }
  /***
   * Map Print model end
   */
  loadFeature() {

    if (this.slideMenuOpenLayerDetails) {
      if (this.zoomedJobIds.length === 1) {
        this.spinner.show();
        for (const featureData of this.navigatedFeatures) {
          if (!this.isBasemapToggle) {
            this.setValuesInRequiredMaps(featureData);
          } else {
            this.isBasemapToggle = false;
          }
          this.spinner.hide();
        }
      }
      else if (this.zoomedJobIds.length > 1) {
        if (this.zoomedFeaturesObservableArray.length > 0) {
          this.spinner.show();
          this.forkJoinMapFeaturesSubscription = forkJoin(
            this.zoomedFeaturesObservableArray
          ).subscribe(
            (result: any[]) => {

              // const temp = [this.jobsD];
              // result = [...temp,...result];

              this.navigatedFeatures = [];
              this.spinner.hide();
              for (let i = 0; i < result.length; i++) {
                if (result[i].length != 0) {
                  // let featuresData = result[i][0].features;
                  for (let j = 0; j < result[i]?.length; j++) {
                    let featuresData = result[i][j];
                    if (featuresData.layerName === 'SITE COMPONENT') {
                      featuresData.layerName = "SITE";
                    }
                    this.navigatedFeatures.push(featuresData);
                    this.zoomedJobIdLayerIdMap.set(
                      this.zoomedJobIds[i]?.toString(),
                      []
                    );
                    this.setValuesInRequiredMaps(featuresData);
                  }
                }
              }
              this.jobDetailsForGPRS = this.navigatedFeatures.filter(
                (feature: any) => feature.layerId === 1
              );
              this.isGroupMapCompleted = true;
              this.spinner.hide();
            },
            (error: any[]) => {
              console.log(error);
              this.spinner.hide();
            }
          );
        }
      }
    }
  }

  // Import radio button Function
  changeImportRadio(e: any) {
    if (this.importGISForm.value.importSelectFormControl === 'importDataGIS') {
      this.isSubmitButtonVisible = true;
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'customGIS') {
      this.isSubmitButtonVisible = false;
      this.isSelectButtonVisible = true;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'referenceGIS') {
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = true;
      this.isSubmitNonGeoReferenceImage = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'importGeoReferenceImage') {
      this.isSubmitButtonVisible = true;
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = false;
      // this.nonGeoreferenceImgDivActive = false;
    } else if (this.importGISForm.value.importSelectFormControl === 'importGeoReferenceImageManually') {
      this.isSubmitButtonVisible = false;
      this.isSelectButtonVisible = false;
      this.isSubmitButtonVisibleGISReference = false;
      this.isSubmitNonGeoReferenceImage = true;
      // this.nonGeoreferenceImgDivActive = true;
      // this.editGeoReferenceImageType = 'add';
    }
  }

  // Load NonGeoreference Image over the map
  loadingNonGeoReferenceImage() {

    // _rasterFileExtensions = [".tiff", ".tif", ".geotiff", ".pdf", ".gif", ".png", ".jpg", ".jpeg"];
    this.editGeoReferenceImageType = 'add';
    if (this._rasterFileExtensionsWithoutPNG.includes('.' + this.importedFileType.toLowerCase())) {
      this.nonGeoReferenceWithoutPNGCheck = 'nonGeoReferenceImageWithoutPNG';
      this.publishUploadFile('nonGeoReferenceImageWithoutPNG');
    } else {
      this.spinner.show();
      this.nonGeoreferenceImgDivActive = true;
      this.commonMapService.slowItMapZoom(this.map);
      this.nonGeoReferenceWithoutPNGCheck = 'nonGeoReferenceImageWithPNG';
      setTimeout(() => {

        let image = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;

        if (image) {
          image.src = URL.createObjectURL(this.importedFileArr);
          // image.draggable();
          this.rangeSliderGeoReferenceImage = 100;
        }
        let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
        cancelImportGIS.click();
      }, 0);
      this.spinner.hide();
    }


  }

  // Loading Image after conversion to PNG/JPG
  callNonGeoReferenceModal(imageUrl: string, feature: IRasterImage) {
    this.nonGeoreferenceImgDivActive = true;
    this.commonMapService.slowItMapZoom(this.map);
    this.spinner.show();
    setTimeout(() => {

      let image = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
      let nonGeoreference = document.getElementById("nonGeoreference") as HTMLDivElement;

      if (this.editGeoReferenceImageType == 'edit') {

        let topLeftPixel = this.map.project([feature.xMin, feature.yMin]);
        let bottomRightPixel = this.map.project([feature.xMax, feature.yMax]);
        let mapElement = document.getElementById("mapviewerjobs") as HTMLDivElement;
        let mapRect = mapElement.getBoundingClientRect()
        image.style.width = 'auto';
        image.style.height = (topLeftPixel.y - bottomRightPixel.y).toString() + 'px';
        nonGeoreference.style.top = (bottomRightPixel.y + mapRect.top).toString() + 'px';
        nonGeoreference.style.left = topLeftPixel.x.toString() + 'px';

        } else {
          nonGeoreference.style.top = '200px';
          nonGeoreference.style.left = '100px';
        }
        if (image) {
          // image.src = URL.createObjectURL(image);
          image.src = imageUrl;
          this.rangeSliderGeoReferenceImage = (feature.opacity)*100;
          this.nonGeoReferenceImportedFile(imageUrl);
          // image.draggable();
        }
        let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
        cancelImportGIS.click();
        this.spinner.hide();
      }, 0);

  }

  // Save NonGeo Modal Image
  saveNonGeoReferenceImage(type:any,rasterImageFeature:any) {
    // alert(type);
    if(type == 'edit'){
      this.publishUploadNonGeoReferenceImage(type,rasterImageFeature,'nonGeoReferenceImage');
    } else{
      if(this.nonGeoReferenceWithoutPNGCheck === 'nonGeoReferenceImageWithoutPNG'){
        this.publishUploadNonGeoReferenceImage(type,rasterImageFeature,'nonGeoReferenceImage');
      }else{
        this.publishUploadFile('nonGeoReferenceImage');
      }
    }
  }

  // Non GeoReference File type
  nonGeoReferenceImportedFile(fileName: string) {
    // var testStr = "sometext-20202"
    // var splitStr = testStr.substring(testStr.indexOf('-') + 1);
    if(fileName.includes('importdata')){
      this.importedFileName = fileName.split('importdata/')[1];
    } else if(fileName.includes('rasterimages')){
      this.importedFileName = fileName.split('rasterimages/')[1];
    } else{
      this.importedFileName = fileName
    }

    let fileExtension = this.importedFileName.substr((this.importedFileName.lastIndexOf('.') + 1));

    this.importedFileType = fileExtension.toLowerCase();
    let validFileCheck = this.validateFile();

    if (validFileCheck) {
      this.importSubmitted = true;
    } else {
      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importGISForm.patchValue({
        importSelectFormControl: ''
      });
      this.importedFileName = '';
      this.importedFileType = '';
      this.nonGeoreferenceImgDivActive = false;
      this.commonMapService.normalItMapZoom(this.map);
    }
  }

  // Publish nonGeoReference  Image
  publishUploadNonGeoReferenceImage(type: any, rasterImageFeature: any, customStatus: any) {
    this.isGeoReferencedRaster = false;
    this.customImportFeaturesType = [];
    this.customImportFeaturesGroup = [];
    this.selectImportFeatureType = '';
    this.selectImportFeatureGroup = '';

    let rasterImageCorrdinateObj = {
      xMin: 0,
      xMax: 0,
      yMin: 0,
      yMax: 0,
    }
    let rasterImageId = 0;
    let isRasterEdit = false;
    console.log("rasterImageFeature",rasterImageFeature);

    if (type == 'edit') {
      rasterImageId = rasterImageFeature.rasterimageId;
      isRasterEdit = true;
    }
    if (this.importSubmitted) {
      this.spinner.show();
      let importedFileTypeURL: any = '';

      const current = new Date();
      let match: any = this.removeExtension(this.importedFileName) + '.' + this.importedFileType.toLowerCase();


      this.importUploadingFileName = match;

      if (this._rasterFileExtensions.includes('.' + this.importedFileType.toLowerCase())) {
        if (customStatus === 'nonGeoReferenceImageWithoutPNG') {
          importedFileTypeURL = 'ImportRasterTranslate';
        } else if (customStatus === 'nonGeoReferenceImage') {
          this.isGeoReferencedRaster = false;
          importedFileTypeURL = 'ImportRaster';
        } else {
          this.isGeoReferencedRaster = true;
          importedFileTypeURL = 'ImportGISRasterFile';
        }
      }

      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importGISForm.patchValue({
        importSelectFormControl: ''
      });
      this.importedFileName = '';
      this.importedFileType = '';


      //  Non GeoReference Image Coordinate fetch start
      if (customStatus === 'nonGeoReferenceImage') {


        let imgElement = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
        let mapElement = document.getElementById("mapviewerjobs") as HTMLDivElement;
        let imgRect = imgElement.getBoundingClientRect();
        let mapRect = mapElement.getBoundingClientRect();

        let pixelXMin = imgRect.left - mapRect.left;
        let pixelXMax = imgRect.right - mapRect.left;
        let pixelYMin = imgRect.top - mapRect.top;
        let pixelYMax = imgRect.bottom - mapRect.top;

        let nw = this.map.unproject([pixelXMin, pixelYMin]);
        let ne = this.map.unproject([pixelXMax, pixelYMin]);
        let se = this.map.unproject([pixelXMax, pixelYMax]);
        let sw = this.map.unproject([pixelXMin, pixelYMax]);

        rasterImageCorrdinateObj = {
          xMin: nw.lng,
          xMax: se.lng,
          yMin: se.lat,
          yMax: ne.lat,
        }

      }

      //  Non GeoReference Image Coordinate fetch end

      if (this.editGeoReferenceImageType == 'edit') {
        const upsertRaster =
        {
          rasterimageId: rasterImageFeature.rasterimageId,
          mapJobId: rasterImageFeature.mapJobId,
          userId: this.loginUserId,
          xMin: rasterImageCorrdinateObj?.xMin,
          xMax: rasterImageCorrdinateObj?.xMax,
          yMin: rasterImageCorrdinateObj?.yMin,
          yMax: rasterImageCorrdinateObj?.yMax,
          opacity: this.rangeSliderGeoReferenceImage / 100,
          fileName: rasterImageFeature.fileName,
          fileLink: rasterImageFeature.fileLink
        }

        this.getAllRasterSubscribe = this.mapViewerJobFeatureService.editRasterImage(upsertRaster).subscribe((response: any) => {
          console.log(":Response Raster Image", response);
          if (response?.processingStatus?.status.toLowerCase() == 'success') {
            this.commonMapService.setSourceForGeoReferenceImages(this.map, upsertRaster);
            this.nonGeoreferenceImgDivActive = false;
            this.commonMapService.normalItMapZoom(this.map);
            this.spinner.hide();
            let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
            cancelImportGIS.click();
          } else {
            this.spinner.hide();
            this.toastr.error('Error');
          }

        }, (err: any) => {
          this.spinner.hide();
          this.importGISForm.patchValue({
            importSelectFormControl: ''
          });
          this.nonGeoreferenceImgDivActive = false;
          this.commonMapService.normalItMapZoom(this.map);
        });
      } else {
        this.mapViewerJobFeatureService.getFetchDataImport(match, importedFileTypeURL, this.isGeoReferencedRaster, rasterImageId, rasterImageCorrdinateObj, isRasterEdit, this.rangeSliderGeoReferenceImage).subscribe(response => {
          // console.log('response', response);
          if (response.status) {
            // this.toastr.success("File uploaded Successfully.");
            this.nonGeoreferenceImgDivActive = false;
            this.commonMapService.normalItMapZoom(this.map);
            this.spinner.hide();
            let cancelImportGIS = document.getElementById("cancelImportGIS") as HTMLDivElement;
            cancelImportGIS.click();
            this.mapRefreshAfterImport();


          } else {
            this.spinner.hide();
            this.toastr.error('Error');
          }

        }, (err) => {
          console.log(err);

          console.log('Error retrieving Data ...');
          //  this.toastr.error('Error');
          this.spinner.hide();
          this.importGISForm.patchValue({
            importSelectFormControl: ''
          });
          this.nonGeoreferenceImgDivActive = false;
          this.commonMapService.normalItMapZoom(this.map);
        });
      }


    } else {
      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importedFileName = '';
      this.importedFileType = '';
      this.nonGeoreferenceImgDivActive = false;
      this.commonMapService.normalItMapZoom(this.map);
      this.spinner.hide();
      this.toastr.error("Please upload a valid file.");
    }
  }

  // cancel Import data in mid of process
  importMajorCancel() {
    this.nonGeoreferenceImgDivActive = false;
    this.commonMapService.normalItMapZoom(this.map);
    this.importSubmitted = false;
    this.myImportFileVariable.nativeElement.value = "";
    this.importedFileName = '';
    this.importedFileType = '';
    this.importGISForm.patchValue({
      importSelectFormControl: ''
    });
  }

  // Cancel Non GeoReference Image without save the data
  cancelNonGeoReferenceImage(type: any, rasterImageFeature: any) {
    this.nonGeoreferenceImgDivActive = false;
    this.commonMapService.normalItMapZoom(this.map);
    this.importSubmitted = false;
    this.myImportFileVariable.nativeElement.value = "";
    this.importedFileName = '';
    this.importedFileType = '';
    this.importGISForm.patchValue({
      importSelectFormControl: ''
    });
  }

  // Set Opacity level for Edit Non Georeference Image
  visibilityNonGeoReferenceImages(event: any) {
    let val = parseInt(event.target.value);
    let image = document.getElementById('nonGeoreferenceImg') as HTMLImageElement;
    image.style.opacity = ((val) / 100).toString();
  }

  checkImportedData(layer: any) {
    if (layer === 'IMPORTED') {
      if (this.fetchFeatureGroupArray(layer).length > 0) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  //use to set External Content image on dropdown
  getSelectedExternalContentType() {
    this.externalContentIcon =
      '../../../../../assets/images/GPRS_PNG/' +
      this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") +
      '.png';

    if (this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") === 'POINTCLOUD') {
      // this.externalContentForm.controls['linkExternal'].setValidators([Validators.maxLength(200)]);

      this.externalContentForm.controls["linkExternal"].clearValidators();
      this.externalContentForm.controls["linkExternal"].updateValueAndValidity();
    }
  }

  //to get all external content type names
  getExternalContentTypes() {

    this.spinner.show();
    this.externalContentDropdownData = []
    this.mapViewerService.getExternalContentTypes().subscribe((response: any) => {
      if (response?.length > 0) {
        this.externalContentDropdownData = response.filter((ec:any) => (ec.exernalComponentName === "Matterport" || ec.exernalComponentName === "External Link" || ec.exernalComponentName==="Pointcloud")); //response;

        // PAW adding an alias name to the externalContentDropdownData.  The alias name will be shown in the drop down.
        for (var i=0; i < this.externalContentDropdownData.length; i++){
          if (this.externalContentDropdownData[i].exernalComponentName.toUpperCase() == "MATTERPORT") {
            this.externalContentDropdownData[i].alias = "Virtual Tour";
          } else
          {
            this.externalContentDropdownData[i].alias = this.externalContentDropdownData[i].exernalComponentName;
          }
        }

        this.selectOptionsExternal = this.externalContentDropdownData[0].exernalComponentName;
        this.externalContentIcon =
          '../../../../../assets/images/GPRS_PNG/' +
          this.externalContentDropdownData[0].exernalComponentName.toUpperCase().replace(/\s/g, "") +
          '.png';
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });

  }

  //to add external content when user click on Add button in External layer
  addExtenalContent(layer: any) {
    this.externalLayerAddEdit = 'Add';
    if (this.selectOptionsExternal.toUpperCase() == 'MATTERPORT')
    {
      this.modalExternalAddHeader = "Virtual Tour";
    } else {
      this.modalExternalAddHeader = this.selectOptionsExternal;
    }

    this.matterportLinkArray = [];

    this.selectOptionsExternalEnable = true;
    // this.externalContentForm.patchValue({
    //   featureGroup: this.selectOptionsExternal
    // });
    if (this.modalExternalAddHeader == "Virtual Tour") {
      this.externalContentForm.patchValue({
        linkExternal: 'https://www.google.com',
        featureGroup: this.selectOptionsExternal
      });
    } else {
      this.externalContentForm.patchValue({
        linkExternal: '',
        featureGroup: this.selectOptionsExternal
      });
    }
 

    // this.newAddedFeatureName = `temp_${this.selectOptionsExternal}_${new Date().toLocaleTimeString([], {
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   hour12: false,
    // })}`;
    // let data = { layerName: layer };
    // let feature: any = {
    //   type: 'Feature',
    //   properties: {
    //     featureGroupId: this.externalContentDropdownData.find((it: any) => it.exernalComponentName === this.selectOptionsExternal).externalComponentTypeId,
    //     featureGroup: this.selectOptionsExternal.toUpperCase().replace(/\s/g, ""),
    //     featureTypeId: null,
    //     featureType: null,
    //     featureId: this.getLayerID(layer)?.toString(),
    //     featureName: this.newAddedFeatureName,
    //     featureGeometryType: 'Point',
    //   },
    //   geometry: {
    //     type: null,
    //     coordinates: null,
    //   },
    // };

    // this.newAddedFeatureInLayer = data;
    // // this.newlyAddedFeature.push(feature);
    //need to work on this because feature type not available and not add in the featuregroupmap variable
    //  if (this.featureGroupMap.has(data.layerName)) {
    //   this.addMainFeatureData(data, feature, 'hasValue');
    // } else {
    //   this.addMainFeatureData(data, feature, null);
    // }

    this.addFeatureForMapEditor(
      this.selectOptionsExternal.toUpperCase().replace(/\s/g, ""),
      null,
      layer
    );
    // this.editSelectedFeature = feature;
    this.handleAddPoint();
    if (this.editingHistory.length > 1) {
      return;
    }
  }

  // use to save content in both case of Add/Edit
  onExternalContentSave(form: FormGroup, type: any) {
    if (form.value.featureGroup.toUpperCase().replace(/\s/g, "") === 'MATTERPORT') {
      this.externalContentForm.patchValue({
        linkExternal: 'https://www.google.com',
        featureGroup: 'Matterport'
      });
    }

    if (form.valid) {
      if (this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") === 'POINTCLOUD' && this.importedFileType) {
        alert("Loading point cloud data is in process.  Depending on the file size and your internet connection, this could take some time.  The content will appear in the menu once the upload is complete.");
        this.uploadPointCloudFile(form, type);
      } else {
        this.onExternalContentSaveSubmit(form, type);
      }
    } else {
      this.toastr.warning("Please fill details correctly.")
    }
  }

  // use to submit data of external layer on database
  onExternalContentSaveSubmit(form: FormGroup, type: any) {
    this.spinner.show();
    this.externalPayloadReturn(this.externalContentForm.value['featureGroup']);
    let payload = this.externalPayload;

    this.mapViewerService.upsertExternalContent(this.extContentApiUrl, payload).subscribe((response: any) => {
      if (response && response.processingStatus.status === "Success") {
        if (this.selectOptionsExternal.toUpperCase().replace(/\s/g, "") === 'POINTCLOUD') {
            // call the tileserver to import the point cloud.  this process will update the point_cloudlink column in the db when finished.
              this.mapViewerJobFeatureService.importPointCloudAsyncApi('ImportPointCloudAsync').subscribe(response => {
                console.log('response from importPointCloudAsync ', response);
            }, err => {
              console.log('err', err);
            });

        }
        if (this.externalContentForm.value.editEnable) {
          this.toastr.success("Update external content successfully.");
          if (type === "fromIconMove") {
            let featureDetailEdit = JSON.parse(this.externalContentForm.value.featureDetailEdit);
            // Remove the drawing feature from the map
            this.draw.delete(featureDetailEdit.id); // Unhide the saved feature
            this.unhideEditingFeature(featureDetailEdit);
            //update features
            this.getUpdatedFeatures("", featureDetailEdit, "").subscribe((dataWithLayer: any) => {
              this.navigatedFeatures = dataWithLayer;
              for (const featureData of dataWithLayer) {
                if (!this.isBasemapToggle) {
                  this.setValuesInRequiredMaps(featureData);
                } else {
                  this.isBasemapToggle = false;
                }
              }
              this.addNewFeatureInExisting();
            });
          }
          this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
            this.navigatedFeatures = dataWithLayer;
            for (const featureData of dataWithLayer) {
              if (!this.isBasemapToggle) {
                this.setValuesInRequiredMaps(featureData);
              } else {
                this.isBasemapToggle = false;
              }
            }
          });

          this.refreshMapStyle();
          this.featureTypeForBtnVisible = null;
          this.editSelectedFeature = null;
        } else {
          this.toastr.success("Added external content successfully.");
          this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
            this.navigatedFeatures = dataWithLayer;
            for (const featureData of dataWithLayer) {
              if (!this.isBasemapToggle) {
                this.setValuesInRequiredMaps(featureData);
              } else {
                this.isBasemapToggle = false;
              }
            }

            this.refreshMapStyle();
          });
        }
        this._removeCommitChanges()
        this.spinner.hide();
        this.modalExternalAddHeader = '';
        this.selectOptionsExternalEnable = false;
        this.externalContentForm.reset(this.externalContentFormOriginal);
        this.viewExternalComponentsByJobId();
        let button = document.getElementById("closeExtModal") as HTMLButtonElement;
        button.click();
      }
      else {
        // this.toastr.error("Could not add selected external content.");
        this.toastr.error(response?.processingStatus?.message);
        this.modalExternalAddHeader = '';
        this.selectOptionsExternalEnable = false;
        this.externalContentForm.reset(this.externalContentFormOriginal);
        let button = document.getElementById("closeExtModal") as HTMLButtonElement;
        button.click();
        this.spinner.hide();
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.modalExternalAddHeader = '';
      this.selectOptionsExternalEnable = false;
      this.externalContentForm.reset(this.externalContentFormOriginal);
      this.toastr.error("Error" + err);
      this.refreshMapStyle();
      let button = document.getElementById("closeExtModal") as HTMLButtonElement;
      button.click();
    });


  }
  // use to cancel External layer edit data
  onExternalContentCancel() {
    let button = document.getElementById("closeExtModal") as HTMLButtonElement;
    button.click();
    this.matterportLinkArray=[];
    this.modalExternalAddHeader = '';
    this.selectOptionsExternalEnable = false;
    this.externalContentForm.reset(this.externalContentFormOriginal);
    this.editSelectedFeature = null;
    let drawFeatures = this.draw.getAll().features;
    if (drawFeatures.length > 0) {

      drawFeatures?.forEach((element: any) => {
        this.draw.delete(element.id);
        // Unhide the saved feature
        const index = this.hiddenFeatures.indexOf(
          element.properties.featureName
        );
        if (index > -1) {
          this.hiddenFeatures.splice(index, 1);
          // Need to set the feature filter
          this.setGroupFilter('on', element.properties.featureGroup);
        }
      });
    }

  }

  // get payload for External Content
  externalPayloadReturn(externalTypeName: any) {
    let externalTypeId = this.externalContentDropdownData.find((it: any) => it.exernalComponentName.toUpperCase().replace(/\s/g, "") === externalTypeName.toUpperCase().replace(/\s/g, "")).externalComponentTypeId;

    switch (externalTypeId) {
      case 1:
      case 6: {
        this.externalPayload = {
          mapAttachmentId: this.externalContentForm.value.featureId,
          attachmentDisplayName: this.externalContentForm.value.displayName,
          attachmentLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber,
          mapJobId: this.navigatedJobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertAttachment';
        break;
      }
      case 2:
      case 7: {
        this.externalPayload = {
          externalLinkId: this.externalContentForm.value.featureId,
          externalLinkDisplayName: this.externalContentForm.value.displayName,
          externalLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber,
          mapJobId: this.navigatedJobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertExternalLink';
        break;
      }
      case 3:
      case 8: {
        // need to remove any table entries without url or description
        this.matterportLinkArray =this.matterportLinkArray.filter((vtLink:any) => vtLink["url"].length > 0 && vtLink["displayName"].length > 0);

        this.externalPayload = {
          matterportId: this.externalContentForm.value.featureId,
          matterportDisplayName: this.externalContentForm.value.displayName,
          //matterportLink: this.externalContentForm.value.linkExternal,
          links: this.matterportLinkArray,
          externalWorkorderNumber: this.externalContentForm.value.WANumber,
          mapJobId: this.navigatedJobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertMatterport';
        break;
      }
      case 4:
      case 9: {
        this.externalPayload = {
          pointCloudId: this.externalContentForm.value.featureId,
          pointcloudDisplayName: this.externalContentForm.value.displayName,
          pointcloudLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber,
          mapJobId: this.navigatedJobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId,
          filename: this.pointCloudFileName,
        };
        this.extContentApiUrl = 'UpsertPointCloud';
        break;
      }
      case 5:
      case 10: {
        this.externalPayload = {
          virtualTourId: this.externalContentForm.value.featureId,
          virtualTourDisplayName: this.externalContentForm.value.displayName,
          virtualTourLink: this.externalContentForm.value.linkExternal,
          externalWorkorderNumber: this.externalContentForm.value.WANumber,
          mapJobId: this.navigatedJobId,
          longitude: this.longitudeExternalContent.toString(),
          latitude: this.latitudeExternalContent.toString(),
          userId: this.loginUserId
        };
        this.extContentApiUrl = 'UpsertVirtualTour';
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  // External Layer Data based on Job Id
  viewExternalComponentsByJobId() {
    this.mapViewerService.getExternalComponentsByJobId(this.navigatedJobId, this.loginUserId).subscribe((response: any) => {
      if (response?.length > 0) {
        this.externalLayerDatajobIdFeatures = response;

        for (const content of response) {
          this.setValuesInRequiredMaps(content);
        }

        this.addNewFeatureInExisting();
        // this.refreshMapStyle();
      }
    }, err => {
      this.toastr.error(err);
    });
  }

  //SaveExternalEditFeatures: On click on save icon from right slide out
  saveExternalLayerEdit(layer: any, feature: any) {
    if (confirm('Are you sure you want to save?')) {

      const drawFeatures = this.draw.getAll();
      // Get the drawing feature by featureName
      var saveFeatures = drawFeatures.features.filter(
        (drawFeature: any) =>
          drawFeature.properties.featureName === feature.featureName
      );

      if (saveFeatures.length === 1) {
        let saveFeature = saveFeatures[0];
        let clickExternalDetails: any = [];
        this.externalLayerDatajobIdFeatures?.forEach((featuresJob: any) => {
          let clickExternaldata = [];
          clickExternaldata = featuresJob.features.filter(
            (feature: any) =>
              feature.featureId === saveFeature.properties.featureId
          );
          clickExternalDetails = [...clickExternalDetails, ...clickExternaldata]
        });

        if (clickExternalDetails.length == 1) {
          this.modalExternalAddHeader = clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup.toLowerCase() : '';
          this.latitudeExternalContent = saveFeature.geometry.coordinates[1];
          this.longitudeExternalContent = saveFeature.geometry.coordinates[0];
          this.externalContentId = saveFeature.properties.featureId;
          this.externalContentForm.patchValue({
            linkExternal: clickExternalDetails[0]?.addedLink ? clickExternalDetails[0]?.addedLink : '',
            WANumber: clickExternalDetails[0]?.externalWorkorderNumber ? clickExternalDetails[0]?.externalWorkorderNumber : '',
            displayName: clickExternalDetails[0]?.feature ? clickExternalDetails[0]?.feature : '',
            editEnable: true,
            featureId: clickExternalDetails[0]?.featureId ? clickExternalDetails[0]?.featureId : 0,
            featureDetailEdit: saveFeature?.id ? JSON.stringify(saveFeature) : '',
            featureGroup: clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup : '',
          });
          // let button = document.getElementById("btnExternalContent") as HTMLButtonElement;
          // button.click();

          this.onExternalContentSave(this.externalContentForm, 'fromIconMove');
        }
      }
    }
  }

  addVTRow() {
    let matterportObj = {displayName: '',
                         url: '',
                         matterportId: this.externalContentId,
                         action: new virtualTourListTableDataDC().actionButton};

    this.matterportLinkArray.push(matterportObj);
  }

  btnDeleteVTLinkUrl(event:any){
    //console.log("delete vt link:",event);
    let linkIndex = this.matterportLinkArray.indexOf(event.data);
    if (linkIndex !== -1){
      this.matterportLinkArray.splice(linkIndex, 1);
    }
  }

  btnShowVT(event:any) {
    //console.log("show vt :", event.data.url);
    this.iframeExternalContentLink = true;
    this.cdRef.detectChanges();
    let iframeExternal = document.getElementById("externalContentLink") as HTMLIFrameElement;
    iframeExternal.src = event.data.url;
  }
  //SaveExternalEditFeatures: On click on save icon from right slide out
  saveExternalLayerEditInfo(layer: any, feature: any) {
    this.externalLayerAddEdit = 'Edit';
    let clickExternalDetails: any = [];



    this.externalLayerDatajobIdFeatures?.forEach((featuresJob: any) => {
      let clickExternaldata = [];
      clickExternaldata = featuresJob.features.filter(
        (featured: any) =>
          featured.featureId.toString() === feature.featureId
      );
      clickExternalDetails = [...clickExternalDetails, ...clickExternaldata]
    });

    // PAW create the table for the vt links
    let matterportObj = clickExternalDetails[0].links;
    this.matterportLinkArray = [...matterportObj];

    if (this.matterportLinkArray.length > 0){
      this.matterportLinkArray.forEach((vtLink:any) => {
        vtLink['matterportId'] = vtLink.featureId;
        vtLink['action'] = new virtualTourListTableDataDC().actionButton;
      });
    }

    if (clickExternalDetails.length == 1) {
      let cordinategeom = clickExternalDetails[0]?.geometry.coordinates;
      if (feature.featureGroup.toUpperCase() == 'MATTERPORT')
      {
        this.modalExternalAddHeader = "Virtual Tour";
      } else {
        this.modalExternalAddHeader = clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup.toLowerCase() : '';
      }

      this.latitudeExternalContent = cordinategeom[1];
      this.longitudeExternalContent = cordinategeom[0];
      this.externalContentId = clickExternalDetails[0]?.featureId;
      this.externalContentForm.patchValue({
        linkExternal: clickExternalDetails[0]?.addedLink ? clickExternalDetails[0]?.addedLink : '',
        WANumber: clickExternalDetails[0]?.externalWorkorderNumber ? clickExternalDetails[0]?.externalWorkorderNumber : '',
        displayName: clickExternalDetails[0]?.feature ? clickExternalDetails[0]?.feature : '',
        editEnable: true,
        featureId: clickExternalDetails[0]?.featureId ? clickExternalDetails[0]?.featureId : 0,
        featureDetailEdit: '',
        featureGroup: clickExternalDetails[0]?.featureGroup ? clickExternalDetails[0]?.featureGroup : '',
      });

      if (feature.featureGroup == "MATTERPORT")
      {
        // need to autofill the url, to pass form validation
        this.externalContentForm.patchValue({
          linkExternal: 'https://www.google.com',
          featureGroup: 'Matterport'
        });
      }

      let button = document.getElementById("btnExternalContent") as HTMLButtonElement;
      button.click();
    }
  }

  //#endregion

  // Reterieve Vector Tiles by user id
  reteriveVectorTilesbyUser() {
    // this.loginUserId
    this.referenceGISTilesData = [];
    let self = this;
    // PAW If credentialess login then do not need imported layers
    if (this.isCredentialessLogin) {
      return;
    }

    this.getAllVectorsSubscribe = this.mapViewerJobFeatureService.retrieveVectorTiles(this.loginUserId).subscribe((response: any) => {
      if (response?.vectorTileEntities?.length > 0) {
        this.referenceGISTilesData = response.vectorTileEntities;

        let polygons = this.referenceGISTilesData.filter(item => /POLYGON/.test(item.geomType));
        polygons.sort((a, b) => (a.layerName.localeCompare(b.layerName)));
        let points = this.referenceGISTilesData.filter(item => /POINT/.test(item.geomType));
        points.sort((a, b) => (a.layerName.localeCompare(b.layerName)));
        let lines = this.referenceGISTilesData.filter(item => /LINE/.test(item.geomType));
        lines.sort((a, b) => (a.layerName.localeCompare(b.layerName)));
        let sortedArray = points.concat(lines).concat(polygons);

        // this.referenceGISTilesData.forEach((item: any) => {
        this.referenceGISTilesData = sortedArray;
        this.referenceGISTilesData.forEach((item: any) => {
          item.setEyeActive = false;
          self.commonMapService.setSourceForReferenceLayerVectorTiles(this.map, item);

          self.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'none');
          this.referenceLayerItems = referenceLayerDataToMenuItems(this.referenceGISTilesData);
        });
        this.isLayerEyeVisible = {
          SITE: true,
          GPRS: true,
          CLIENT: true,
          IMPORTED: true,
          EXTERNAL: true,
          REFERENCE: false
        };
      } else {
        // this.toastr.warning("No Data in Reference GIS layer");
      }

    }, (err: any) => {
      console.log("Error", err);
      this.spinner.hide();
    });
  }

  toggleReferenceLayerItem(item: LayerMenuItemData) {
    const items = this.getRefernceLayerItemsFromLayerMenuItem(item);
    items.forEach(element => {
      const dataId = element.mvtId + '-reference';
      let visibility = "none";
      if (element.setEyeActive) {
        visibility = "visible";
      }
      this.map.setLayoutProperty(dataId, 'visibility', visibility);
    });
  }

  // Toggle Reference Layer Header
  toggleReferenceLayer(layer: any) {
    if (this.isLayerEyeVisible[layer]) {
      this.referenceGISTilesData.forEach((item: any) => {
        item.setEyeActive = false;
        this.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'none');
      });
    } else {
      this.referenceGISTilesData.forEach((item: any) => {
        item.setEyeActive = true;
        this.map.setLayoutProperty(item.mvtId + '-reference', 'visibility', 'visible');
      });
    }
    this.referenceLayerItems = referenceLayerDataToMenuItems(this.referenceGISTilesData);
    this.isLayerEyeVisible[layer] = !this.isLayerEyeVisible[layer];
  }

  // Toggle Reference Particular layer Item
  toggleReferenceLayerFeature(layer: any, data: any, index: any) {
    let dataId = data.mvtId + '-reference';
    if (data.setEyeActive) {
      this.map.setLayoutProperty(dataId, 'visibility', 'none');
    } else {
      this.map.setLayoutProperty(dataId, 'visibility', 'visible');
    }
    data.setEyeActive = !data.setEyeActive;
  }


  deleteReferenceLayerItem(item: LayerMenuItemData) {
    const items = this.getRefernceLayerItemsFromLayerMenuItem(item);
    // items.forEach(element => {

    // });
    // const referenceLayerItem = this.referenceGISTilesData.find(x => x.mvtId === item.id);
    if(items && items.length > 0) {
      this.deleteReferenceLayer(items);
    }
  }

  getRefernceLayerItemsFromLayerMenuItem(menuItem: LayerMenuItemData): ReferenceGISTilesData[] {
    let result: ReferenceGISTilesData[] = [];
    const referenceLayerItem = this.referenceGISTilesData.find(x => x.mvtId === menuItem.id);
    if(referenceLayerItem) {
      referenceLayerItem.setEyeActive = menuItem.isEyeVisible;
      result.push(referenceLayerItem);
    }
    if(menuItem.children){
      menuItem.children.forEach(child => {
        const children = this.getRefernceLayerItemsFromLayerMenuItem(child);
        if(children.length > 0) {
          result = result.concat(children);
        }
      });
    }
    return result;
  }

  deleteReferenceLayer(features: ReferenceGISTilesData[]) {
    let delMessage = 'Are you sure you want to delete?';
    if (confirm(delMessage)) {
      const featureIds = features.map(x => x.mvtId);
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteMultipleFeaturesByFeatureId(
          'DeleteVectorTilesById',
          featureIds, -1, this.loginUserId, 'mvtIds'
        ).subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.toastr.success('Deleted Successfully');
              this.reteriveVectorTilesbyUser();
              this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
                this.navigatedFeatures = dataWithLayer;
                for (const featureData of dataWithLayer) {
                  if (!this.isBasemapToggle) {
                    this.setValuesInRequiredMaps(featureData);
                  } else {
                    this.isBasemapToggle = true;
                  }
                }

                this.refreshMapStyle();
              });
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );

    }
    // } else {
    //   this.toastr.warning("You don't have access to edit this layer");
    // }
  }

  // Use for upload Potree file in ImportContainer
  uploadPointCloudFile(form: FormGroup, type: any) {
    const current = new Date();
    const timestamp = current.getTime();
    let match: any = '';
    if (this.importedFileType === 'las' || this.importedFileType === 'laz') {
      match = this.removeExtension(this.importedFileName) + '_' + timestamp + '_' + this.navigatedJobId +
        '_' + this.loginUserId + '.' + this.importedFileType;
    } else {
      this.toastr.warning("Please select only .las and .laz file formats for Point Cloud");
      return;
    }

    this.importUploadingFileName = match;
    this.spinner.show();
    // Azure Blob Method to upload file on blob
    this.blobService.uploadImage(this.importedContainerSAS, this.importedContainer, this.importedFileArr, match, () => {
      this.pointCloudFileName = match;
      this.importSubmitted = false;
      this.myImportFileVariable.nativeElement.value = "";
      this.importedFileName = '';
      this.importedFileType = '';
      let div = document.getElementById("cancelImport") as HTMLDivElement;
      div.click();
      this.spinner.hide();
      this.onExternalContentSaveSubmit(form, type);
    });
  }

  // Reterieve Reference Image by user id
  reteriveGeoReferenceImagebyUser() {
    this.loginUserId
    this.geoReferenceGISImagesData = [];
    const self = this;
    // PAW If credentialess login then do not need imported layers
    if (this.isCredentialessLogin){
      return;
    }
    const getRasterObservables = [
      this.mapViewerJobFeatureService.retrieveGeoReferenceImage(this.loginUserId, this.navigatedJobId),
      this.mapViewerJobFeatureService.retrieveRasterTiles(this.loginUserId, this.navigatedJobId)
    ];
    forkJoin(getRasterObservables).subscribe(([imagesResponse, rasterTilesResponse]) => {
      if (imagesResponse?.rasterImageEntities?.length > 0) {
        this.geoReferenceGISImagesData = imagesResponse.rasterImageEntities.map((item: any) => {
          return {...item, type: "image"};
        });
      }

      if (rasterTilesResponse?.rasterTileEntities?.length > 0) {
        this.geoReferenceGISImagesData = [...this.geoReferenceGISImagesData, ...rasterTilesResponse.rasterTileEntities.map((item: any) => {
          return {...item, type: "tile", rasterimageId: item.rastertileId};
        })
      ];
      }

      if (this.geoReferenceGISImagesData.length > 0) {
        this.geoReferenceGISImagesData.forEach((item: any) => {
          item.setEyeActive = true;
          if(item.type === "image"){
            self.commonMapService.setSourceForGeoReferenceImages(this.map, item);
          }else if(item.type === "tile"){
            self.commonMapService.setSourceForRasterTiles(this.map, item);
          }
          self.map.setLayoutProperty(item.rasterimageId + '-rasterImage', 'visibility', 'visible');
        });
      } else {
        // this.toastr.warning("No Data in Reference GIS layer");
      }

    }, (err: any) => {
      console.log("Error", err);
    });

  }

  toggleRasterItem(data: RasterImageItem) {
    let dataId = data.rasterimageId + '-rasterImage';
    if (data.setEyeActive) {
      this.map.setLayoutProperty(dataId, 'visibility', 'visible');
    } else {
      this.map.setLayoutProperty(dataId, 'visibility', 'none');
    }
  }

   // Edit Geo Reference Image modal data loading
   editGeoReferenceImageLayer(feature: IRasterImage){
     this.editGeoReferenceImageType = 'edit';
     this.rasterImageFeature = feature;

     // https://sitemapsadev.blob.core.windows.net/importdata/translated_faa_utm18n_nad83_64178_99.png
     if (feature.fileLink) {
       const imageUrl = this.commonMapService.getRasterImageSourceUrl(feature);
       this.callNonGeoReferenceModal(imageUrl, feature);
     } else {
       this.toastr.warning("Error: Image is not loading");
     }

      // alert( feature.rasterimageId);
      // this.rasterImageIdEdit = feature.rasterimageId;
  }

  deleteRasterItem(feature: RasterImageItem  ) {
    if(feature.type === "image"){
      this.deleteGeoReferenceImageLayer(feature);
    }else if(feature.type === "tile"){
      this.deleteRasterTileLayer(null, feature);
    }
  }

  // Geo Reference Image Delete
  deleteGeoReferenceImageLayer(
    feature: IRasterImage
  ) {
    let delMessage = 'Are you sure you want to delete?';
    if (confirm(delMessage)) {
      this.spinner.show();
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteMultipleFeaturesByFeatureId(
          'DeleteRasterImage',
          feature.rasterimageId, feature.mapJobId, this.loginUserId, 'rasterimageId'
        ).subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.geoReferenceGISImagesData = this.geoReferenceGISImagesData.filter(item => item.rasterimageId != feature.rasterimageId);
              const layerSourceName = `${feature.rasterimageId}-rasterImage`;
              const map = this.map;
              const source = map.getSource(layerSourceName);
              if (source !== undefined) {
                map.removeLayer(layerSourceName);
                map.removeSource(layerSourceName);
              }

              this.toastr.success('Deleted Successfully');
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );

    }
  }

  deleteRasterTileLayer(
    layer: any,
    feature: any
  ) {
    let delMessage = 'Are you sure you want to delete?';
    if (confirm(delMessage)) {
      this.spinner.show();
      this.featureDeleteSubscribe = this.mapViewerJobFeatureService
        .deleteRasterTileByTileId(
          feature.rastertileId, feature.mapJobId, this.loginUserId
        ).subscribe(
          (data: any) => {
            if (data.status == 'Success') {
              this.geoReferenceGISImagesData = this.geoReferenceGISImagesData.filter(item => item.rastertileId != feature.rastertileId);
              const layerSourceName = `${feature.rastertileId}-rasterImage`;
              const map = this.map;
              const source = map.getSource(layerSourceName);
              if (source !== undefined) {
                map.removeLayer(layerSourceName);
                map.removeSource(layerSourceName);
              }

              this.toastr.success('Deleted Successfully');
            } else {
              this.toastr.error(data.status + ' : ' + data.message);
            }
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error('Error');
            this.spinner.hide();
          }
        );

    }
  }

  changeRasterItemOpacity({item, value}: {item: RasterImageItem, value: number}) {
    if(item.type === "image"){
      this.visibilityReferenceImages({item, value});
    }else if(item.type === "tile"){
      this.visibilityRasterTile({item, value});
    }
  }

  visibilityReferenceImages({ item, value }: { item: RasterImageItem, value: number}) {
    let dataId = item.rasterimageId + '-rasterImage';
    this.map.setPaintProperty(dataId, "raster-opacity", value / 100);
  }

  visibilityRasterTile({ item, value }: { item: RasterImageItem, value: number}) {
    let dataId = item.rasterimageId + '-rasterImage';
    this.map.setPaintProperty(dataId, "raster-opacity", value / 100);
  }

  updateFeatureDescriptionFormGenerate() {
    this.updateFeatureDescriptionForm = this.commonMapService.updateFeatureDescriptionFormGenerate();
  }

  updatePointLineDescription(saveFeature: any, payLoad: any) {
    let data = this.commonMapService.updatePointLineDescription(saveFeature, payLoad);
    if (data) {
      data.subscribe((response: any) => {
        if (response && response.processingStatus.status == "Success") {
          this.getFeatureDetailsByFeatureIdForMapEditor(saveFeature, true);
          this.toastr.success(`${saveFeature.geometry.type} Description Updated Successfully`);
          this.editFormFeatureDescriptionView = true;
        } else {
          if (response && response.processingStatus.status == "Failure") {
            this.toastr.error(response.processingStatus.message);
          }
        }
      }, err => {
        this.toastr.error(err.error.ProcessingStatus.Error);
      });
    }
  }

  //Refresh Description Data
  refreshDescriptionData(response: any) {
    this.editFormFeatureDescriptionView = true;
    this.openedFeatureAttributes =
      response.groups.features[0].attributes;
    this.openedFeatureAttributes.coordinate = this.createCoordinates(response.groups.features[0].geometry);
    this.jobId = response.jobId;
    this.openedFeatureName =
      response.groups.features[0].properties.FeatureName;
    if (
      response.groups.features[0].attributes.addedLink != null ||
      undefined ||
      ''
    ) {
      let textLink =
        response.groups.features[0].attributes.addedLink;
      this.openedFeatureAttributesAddedLink =
        textLink.split(' , ');
    } else {
      this.openedFeatureAttributesAddedLink = [];
    }
    this.slideMenuOpenJobDescription = true;
    this.slideMenuOpenTools = false;
    this.slideMenuOpenFolder = false;
    this.slideMenuOpenLayerDetails = false;
    this.editDescriptionValue = response;
    this.spinner.hide();
  }
  //Refresh Description Data End

  updateCoordinates(featureDescription:any){
    this.openedFeatureAttributes.coordinate.long = +this.updateFeatureDescriptionForm.value.coordinatelongtitude;
    this.openedFeatureAttributes.coordinate.lat = +this.updateFeatureDescriptionForm.value.coordinatelatitude;
    let payLoad: any = {
       mapJobId: featureDescription.mapJobId,
       mapFeatureTypeId: featureDescription.mapFeatureTypeId,
       mapGroupId: featureDescription.mapGroupId,
       mapLayerId: featureDescription.mapLayerId,
       userId: this.loginUserId,
       swmapsLayer:this.metadataService.featureType[featureDescription.mapFeatureTypeId]
      };
       let coordinate = featureDescription.geom.split('(')[1].slice(0,-1).split(',');
       coordinate.pop();
       coordinate.push(`${this.openedFeatureAttributes.coordinate.long} ${this.openedFeatureAttributes.coordinate.lat} 0`);
       coordinate = featureDescription.geom.split('(')[0] + ' Z'+ '('+coordinate.join(',') + ')';
       payLoad.geom = coordinate;
       featureDescription.geom = coordinate;
       this.updatePointLineDescription(this.editDescriptionValue.groups.features[0], featureDescription);
  }

  /* update featues */
  async updateFeaturesDescition() {
    const regex = /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/ ;
    if(!this.updateFeatureDescriptionForm.value.coordinatelongtitude.toString().match(regex) ||
    !this.updateFeatureDescriptionForm.value.coordinatelatitude.toString().match(regex)){
      alert('Please enter coordinates in number format');
      return;
    }
    this.commonMapService.loginUserId = this.loginUserId;
    let featureDescription = this.commonMapService.updateFeaturesDescition(this.editDescriptionValue, this.updateFeatureDescriptionForm)

    const enteredcoordinates = [+this.updateFeatureDescriptionForm.value.coordinatelongtitude, +this.updateFeatureDescriptionForm.value.coordinatelatitude];
    let isCoordsInsideJobs
    this.mapViewerSubscribe = this.mapViewerService
      .fetchJobDetails(this.navigatedJobId!, this.loginUserId)
      .subscribe(
        (jobDetailData: any) => {
          const coordinatesPolygon = JSON.parse(jobDetailData.features[0].geometry.coordinates);
          let poly = turf.polygon([coordinatesPolygon]) //poly_coords - Jobs Polygon Coordinates e.g [[[-5, 52], [-4, 56], [-2, 51], [-7, 54], [-5, 52]]]
          let pt = turf.point(enteredcoordinates); //point_coords - Entered Coordinates [Lng, Lat]
          let buffer_10ft = turf.buffer(poly, 0.003048);
          isCoordsInsideJobs = turf.booleanPointInPolygon(pt, buffer_10ft);
          if (!isCoordsInsideJobs) {
            alert("This move is outside the relative job area.");

            this.updateFeatureDescriptionForm.controls['coordinatelongtitude'].setValue(this.openedFeatureAttributes.coordinate.long);
            this.updateFeatureDescriptionForm.controls['coordinatelatitude'].setValue(this.openedFeatureAttributes.coordinate.lat);
            this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
              this.navigatedFeatures = dataWithLayer;
              for (const featureData of dataWithLayer) {
                if (!this.isBasemapToggle) {
                  this.setValuesInRequiredMaps(featureData);
                } else {
                  this.isBasemapToggle = false;
                }
              }
              this.refreshMapStyle();
            });
          }
          else if (confirm('Are you sure you want to update?')) {
            this.spinner.show();
            this.updateCoordinates(featureDescription);
            this.getUpdatedFeatures("", "", "").subscribe((dataWithLayer: any) => {
              this.navigatedFeatures = dataWithLayer;
              for (const featureData of dataWithLayer) {
                if (!this.isBasemapToggle) {
                  this.setValuesInRequiredMaps(featureData);
                } else {
                  this.isBasemapToggle = false;
                }
              }

              this.refreshMapStyle();
            });

          }
          this.openedFeatureAttributes.depthToTop = this.updateFeatureDescriptionForm.controls.depthToTop.value;
          this.openedFeatureAttributes.description = this.updateFeatureDescriptionForm.controls.description.value;
          this.openedFeatureAttributes.notes = this.updateFeatureDescriptionForm.controls.notes.value;
          this.spinner.hide();

        })

  }

  /** Uptate features **/
  editFormFeatureDescriptionOpen() {
    if (!this.editCheck) {
      this.permissionService.showErrormessageForPersonal();
      return;
    }
    this.editFormFeatureDescriptionView = false;
    this.updateFeatureDescriptionForm = this.commonMapService.editFormFeatureDescriptionOpen(this.openedFeatureAttributes);
  }
  editFormFeatureDescriptionClose() {
    this.editFormFeatureDescriptionView = true;
  }
  createCoordinates(geometry: any) {
    let geoCord = {};
    if (typeof geometry === 'object') {
      const coordinates = JSON.parse(geometry.coordinates).pop();
      geoCord = { long: coordinates[0], lat: coordinates[1] };
    }
    else {
      const featureGeometry = JSON.parse(geometry);
      if (featureGeometry.type == 'LineString') {
        const coordinates = featureGeometry.coordinates[0]; 
        geoCord = { long: coordinates[1], lat: coordinates[0] };
      } else {
        const coordinates = featureGeometry.coordinates; 
        geoCord = { long: coordinates[1], lat: coordinates[0] };
      }
    }

    return geoCord;
  }

  /* Setting up the Map Editing Environment */
  // Snapping event functions
  // On keypress, set ALT key state in order to disable snapping if the ALT key is pressed
  keyPressEditingMode = (e: any) => {
    this.editingState.isAltPressed = e.altKey;
  }
  // Set mousedown state - in editing mode mousedown + a feature selected = moving/dragging feature
  mouseDownEditingMode = (e: any) => {

  }
  // Capture mouseup for two actions:
  // Snap "subject" and "object" features when snapping is enabled
  // Perform edit functions, such as "Add Vertex", etc.
  mouseUpEditingMode = (e: any) => {

  }
  mousePreClickEditingMode = (e: any) => {
    // console.log('Mouse Preclick triggered on', e);
    // console.log('Mouse Preclick triggered on', e);
  }
  //Not using mouse click right now ...
  mouseClickEditingMode = (e: any) => {

  }
  // Track mouse movement over map for snapping purposes
  // Throttle this event to occur no more than mouseMoveEventsPerSecond
  mouseMoveEventsPerSecond: number = 4;
  mouseMoveWait: boolean = false;
  mouseMoveEditingMode = (e: any) => {
    // Don't handle events when one has just occurred
    if (!this.mouseMoveWait) {

      // fire the event
      this.mouseMoveEditingModeWait(e);
      // stop any further events
      this.mouseMoveWait = true;
      // after a fraction of a second, allow events again
      setTimeout(() => {
        this.mouseMoveWait = false;
      }, 1000 / this.mouseMoveEventsPerSecond);
    }
  }
  // Use mousemove for snapping
  mouseMoveEditingModeWait(e: any) {
    let currentDrawMode = this.draw.getMode();
    if (currentDrawMode === 'draw_point' || currentDrawMode === 'sitemap_draw_point' || currentDrawMode === 'draw_line_string' || currentDrawMode === 'sitemap_draw_line_string') {
      this.isDrawing = true;
    } else {
      this.isDrawing = false;
    }
  }
  // Track Draw mode in editing mode
  drawModeChangeEditingMode = (e: any) => {
    console.warn(`draw.modechange`, e)
    this.editingState.previousDrawMode = this.editingState.currentDrawMode;
    this.editingState.currentDrawMode = e.mode;
  }
  drawSelectionChangeEditingMode = (e: any) => {
    console.warn(`draw.selectionchange`, e);

    if (this.editingState.editingModes.isMergingLine) {
      // Clicking on an MVT: handleGetFeatureDetails is sometimes called (first time??) before drawSelectionChangeEditingMode.
      // If clicking on MVT Line, then selected Line will be a feature and this.mergeParentLineId will have a value set from handleGetFeatureDetails.
      // Clicking on Draw Line, the selected Line will feature clicked on. this.mergeParentLineId not set by handleGetFeatureDetails.
      // If clicking on Map with a Draw Line selected, selectedLine is undefined.
      let selectedLine = getSelectedLineFeature(this.draw);
      if (this.mergeParentLineId === '') {
        // Keep track of selected Line - useful for Merge
        if (selectedLine !== undefined) {
          this.mergeParentLineId = selectedLine.id;
        }
      } else {
        if (selectedLine !== undefined) {
          if (this.mergeParentLineId !== selectedLine.id) {
            this.mergeChildLineId = selectedLine.id;
          }
        }
        if (this.mergeChildLineId !== '') {
          let parentLine = this.draw.get(this.mergeParentLineId);
          let childLine = this.draw.get(this.mergeChildLineId)
          if((parentLine.properties.mapLineId > 0 && childLine.properties.mapLineId > 0) && parentLine.properties.mapLineId != childLine.properties.mapLineId) {
            this.draw.changeMode('merge_line', {
              featureIds: [this.mergeParentLineId, this.mergeChildLineId],
              mapLayerId: this.currentEditinglayerId,
              navigatedFeatures: this.navigatedFeatures,
              isSnappingToVertex: this.isSnappingToVertex,
              isSnappingToLine: this.isSnappingToLine
            });
          } else {
            this.toastr.error("Please save the features first before merge");
            this.resetEditingModes();
          }
        }
        else if (selectedLine === undefined) {
          this.draw.changeMode('sitemap_simple_select', { featureIds: [this.mergeParentLineId] });
        }
      }
    } else {
      if (this.editingState.editingModes.isAddingVertex) {
        if (this.addVertexLineId === '') {
          let selectedLine = getSelectedLineFeature(this.draw);
          if (selectedLine !== undefined) {
            this.addVertexLineId = selectedLine.id;
          }
        }
      }
    }

    // Get current Draw mode
    let currentDrawMode = this.draw.getMode();
    // Get the selected feature(s) and brind either Point or Line layers to top
    let features = e.features;
    if (features.length > 0) {
      let feature = features[0];
      let geomType = feature.geometry.type;
      if (geomType === 'Point') {
        // Whenever a Point is selected, bring Point layers to the top
        bringPointLayersToTop(this.map, this.siteMapMVTPointLayerIds);
        if (this.editingState.editingModes.isAddingVertex || this.editingState.editingModes.isMovingVertex || this.editingState.editingModes.isDeletingVertex || this.editingState.editingModes.isMergingLine) {
          // Disable Point selection when adding a vertex
          this.draw.changeMode('sitemap_simple_select');
        }
      } else if (geomType === 'LineString' || geomType === 'MultiLineString') {
        // Whenever a Line is selected, bring Line layers to the top
        bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
      }
    }
    setSiteMapDrawMode(e, this.draw, this.editingState, this.currentEditinglayerId, this.navigatedFeatures, this.isSnappingToVertex, this.isSnappingToLine);

  }
  drawUpdateEditingMode = (e: any) => {
    console.warn(`draw.update`, e);
    if (this.editingState.editingModes.isMergingLine) {
      let status = e.status;
      if (status !== undefined) {
        let message = e.message;
        if (!status) {
          setTimeout(() => {
            this.draw.changeMode('sitemap_simple_select', { featureIds: [this.mergeParentLineId] });
          }, 500);
          this.mergeChildLineId = '';
          this.toastr.error(message);
        } else {
          let mergedLine = e.features[0];
          // setTimeout(() => {
          //   this.draw.changeMode('sitemap_simple_select', { featureIds: [mergedLine.id] });
          // }, 500);
          let firstsecondlineArr = e.mergedlines;
          this.firstSecondLineFeatureFrMergedArr = [];
          // if((firstsecondlineArr[0].properties.mapLineId > 0 && firstsecondlineArr[1].properties.mapLineId > 0) || (firstsecondlineArr[0].properties.mapLineId != firstsecondlineArr[1].properties.mapLineId)){
          this.addActiveClass("");
          this.addGroupFeatureType(this.getLayerName(mergedLine.properties.mapLayerId),mergedLine);
          this.firstSecondLineFeatureFrMergedArr.push(firstsecondlineArr[0]);
          this.firstSecondLineFeatureFrMergedArr.push(firstsecondlineArr[1]);
          setTimeout(() => {
            this.draw.changeMode('sitemap_simple_select', { featureIds: [mergedLine.id] });
          }, 500);
          this.mergeParentLineId = mergedLine.id;
          this.mergeChildLineId = '';
          this.toastr.success(message);
          // }else{
          //   this.toastr.error("Please save the features first before merge");
          // }
        }
      }

    }
  }
  drawCreateEditingMode = (e: any) => {
    console.warn(`draw.create`, e);
  }
  drawDeleteEditingMode = (e: any) => {
    console.warn(`draw.delete`, e);
  }


  // Edit toolbar functions
  handleSelect() {
    // Right now this tool is being used as a simple deselect tool.
    // This is useful in "Add Vertex" and "Merge Line" modes - allows user to select a new line and continue working in respective mode.
    this.draw.changeMode('sitemap_simple_select');
    this.map.getCanvasContainer().style.cursor = '';
    this.editingState.firstMergeLineId = undefined;
    this.editingState.secondMergeLineId = undefined;
  }
  handleMoveVertex() {
    let isMovingVertex = !this.editingState.editingModes.isMovingVertex;
    if (!isMovingVertex) {
      console.log('Stopping "Move Vertex".');
      // Turn off this editing mode
      this.editingState.editingModes.isMovingVertex = isMovingVertex;
      // Turn off any other running editing modes
      this.resetEditingModes();
      // Reenable Point interactions
      this.disablePointInteractions = false;
      // Change Draw mode to "simple_select"
      this.draw.changeMode('sitemap_simple_select');
    } else {
      console.log('Starting "Move Vertex".');
      // Turn off any other editing modes
      this.resetEditingModes();
      // Turn on this edtiing mode
      this.editingState.editingModes.isMovingVertex = isMovingVertex;
      // Deselect any selected points and disable interactions
      deselectPointsForVertexEditing(this.draw);
      this.disablePointInteractions = true;
      // Move all line layers to the top of the map
      bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
      // If there is a single line selected, enter "move_vertex" Draw mode
      let selectedLineFeature = getSelectedLineFeature(this.draw);
      if (selectedLineFeature !== undefined) {
        this.draw.changeMode('move_vertex', {
          featureId: selectedLineFeature.id,
          mapLayerId: this.currentEditinglayerId,
          navigatedFeatures: this.navigatedFeatures,
          isSnappingToVertex: this.isSnappingToVertex,
          isSnappingToLine: this.isSnappingToLine});
      } else {
        // Otherwise enter custom simple select mode
        this.draw.changeMode('sitemap_simple_select');
      }
    }
  }
  handleAddVertex() {
    // Set state
    let isAddingVertex = !this.editingState.editingModes.isAddingVertex;
    if (!isAddingVertex) {
      console.log('Stopping "Add Vertex".');
      // Turn off this editing mode
      this.editingState.editingModes.isAddingVertex = isAddingVertex;
      // Turn off any other running editing modes
      this.resetEditingModes();
      // Reenable Point interactions
      this.disablePointInteractions = false;
      // Reset
      this.addVertexLineId = '';
      // Change Draw mode to "simple_select"
      this.draw.changeMode('sitemap_simple_select');
      // Make sure to reset cursor
      this.map.getCanvasContainer().style.cursor = '';
    } else {
      console.log('Starting "Add Vertex".');
      // Turn off any other editing modes
      this.resetEditingModes();
      // Turn on this editing mode
      this.editingState.editingModes.isAddingVertex = isAddingVertex;
      // Deselect any selected points and disable interactions
      deselectPointsForVertexEditing(this.draw);
      this.disablePointInteractions = true;
      // Move all line layers to the top of the map
      bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
      // Check for a selected Line and update currentlySelectedLine
      let selectedLineFeature = getSelectedLineFeature(this.draw);
      if (selectedLineFeature !== undefined) {
        // Keep track of the Line selected for the "Add Vertex" mode
        this.addVertexLineId = selectedLineFeature.id;
        // Change to custom Draw mode for the "Add Vertex" tool
        this.draw.changeMode('add_vertex', {
          featureIds: [selectedLineFeature.id],
          mapLayerId: this.currentEditinglayerId,
          navigatedFeatures: this.navigatedFeatures,
          isSnappingToVertex: this.isSnappingToVertex,
          isSnappingToLine: this.isSnappingToLine});
      }
    }
  }
  handleDeleteVertex() {
    // When this mode is activated, the user can click on vertices of lines to delete them.
    // There will be no prompt to confirm.
    // Undo/Redo should be enabled.
    // The action is handle in mouseup handler.

    // Set the mode for this tool and reset modes for all tools
    let isDeletingVertex = !this.editingState.editingModes.isDeletingVertex;
    if (!isDeletingVertex) {
      console.log('Stopping "Delete Vertex".');
      // Turn off this editing mode
      this.editingState.editingModes.isDeletingVertex = isDeletingVertex;
      // Turn off any other running editing modes
      this.resetEditingModes();
      // Reenable Point interactions
      this.disablePointInteractions = false;
      // Change Draw mode to "simple_select"
      this.draw.changeMode('sitemap_simple_select');
    } else {
      console.log('Starting "Delete Vertex".');
      // Turn off any other editing modes
      this.resetEditingModes();
      // Turn on this edtiing mode
      this.editingState.editingModes.isDeletingVertex = isDeletingVertex;
      // Deselect any selected points and disable interactions
      deselectPointsForVertexEditing(this.draw)
      this.disablePointInteractions = true;
      // First move all line layers to the top of the map
      bringLineLayersToTop(this.map, this.siteMapMVTLineLayerIds);
      // If there is a line selected, enter "delete_vertex" Draw mode
      let selectedLineFeature = getSelectedLineFeature(this.draw);
      if (selectedLineFeature !== undefined) {
        this.draw.changeMode('delete_vertex', { featureId: selectedLineFeature.id });
      }
    }
  }
  handleEndLine() {
    this.draw.changeMode('sitemap_simple_select');
    this.editingState.isDrawing = false;
  }
  handleMergeLine() {
    // Set state
    let isMergingLine = !this.editingState.editingModes.isMergingLine;
    if (!isMergingLine) {
      console.log('Stopping MergeLine.');
      // Turn off this editing mode
      this.editingState.editingModes.isMergingLine = isMergingLine;
      // Turn off any other running editing modes
      this.resetEditingModes();
      // Reenable Point interactions
      this.disablePointInteractions = false;
      // Make sure to reset cursor
      this.map.getCanvasContainer().style.cursor = '';
      // Reset Merge Line Ids
      this.mergeParentLineId = '';
      this.mergeChildLineId = '';
    } else {
      console.log('Starting MergeLine.');
      // Turn off any other editing modes
      this.resetEditingModes();
      // Turn on this edtiing mode
      this.editingState.editingModes.isMergingLine = isMergingLine;
      // Deselect any selected points and disable interactions
      deselectPointsForVertexEditing(this.draw)
      this.disablePointInteractions = true;
      // Check for a selected Line and update mergeParentLineId and mergeChildLineId
      let selectedLine = getSelectedLineFeature(this.draw);
      if (selectedLine !== undefined) {
        this.mergeParentLineId = selectedLine.id;
      } else {
        this.mergeParentLineId = '';
      }
      this.mergeChildLineId = '';
    }
  }
  handleSnapping(e: any) {
    // Need to re/enable Vertex/Line snapping
    setSiteMapDrawMode(e, this.draw, this.editingState, this.currentEditinglayerId, this.navigatedFeatures, this.isSnappingToVertex, this.isSnappingToLine);
  }
  handleSnappingToVertex(e: any) {
    // Clicking a snapping tool while drawing causes a draw.create event.
    // This disupts Line creation, especially. So turn if off temporarily.
    if (this.isDrawing) {
      this.map.off('draw.create', this.globalDrawCreate);
    }
    this.isSnappingToVertex = !this.isSnappingToVertex;
    if (this.isSnappingToVertex) {
      console.log('Starting Snap to Vertex');
      this.handleSnapping(undefined);
    } else {
      console.log('Stopping Snap to Vertex');
      this.handleSnapping(undefined);
    }
    // Turn this event handler back on.
    if (this.isDrawing) {
      this.map.on('draw.create', this.globalDrawCreate);
    }

  }
  handleSnappingToLine() {
    // Clicking a snapping tool while drawing causes a draw.create event.
    // This disupts Line creation, especially. So turn if off temporari
    if (this.isDrawing) {
      this.map.off('draw.create', this.globalDrawCreate);
    }
    this.isSnappingToLine = !this.isSnappingToLine;
    if (this.isSnappingToLine) {
      console.log('Starting Snap to Line');
      this.handleSnapping(undefined);
    } else {
      console.log('Stopping Snap to Line');
      this.handleSnapping(undefined);
    }
    // Turn this event handler back on.
    if (this.isDrawing) {
      this.map.on('draw.create', this.globalDrawCreate);
    }
  }

  // Reset all editing modes to false when a user deselects a tool or activates a new one
  resetEditingModes() {
    // Reset the mouse pointer
    this.map.getCanvasContainer().style.cursor = '';
    this.disablePointInteractions = false;
    Object.keys(this.editingState.editingModes).forEach(mode => {
      if (this.editingState.editingModes[mode]) {
        switch (mode) {
          case 'isAddingVertex':
            this.handleAddVertex();
            break;
          case 'isDeletingVertex':
            this.handleDeleteVertex()
            break;
          case 'isMovingVertex':
            this.handleMoveVertex();
            break;
          case 'isMergingLine':
            this.handleMergeLine()
            break;
          default:
            break;
        }
      }
    })
  }

  // Adds or removes functionality required by editing
  toggleEditingState(bool: boolean, layerName: any) {
    let mapLayerId = this.getLayerID(layerName);
    if (bool) {
      // Set the editing state to keep track of editing variables
      this.editingState = {
        editingModes: {
          isAddingVertex: false,
          isMovingVertex: false,
          isDeletingVertex: false,
          isMergingLine: false
        },
        firstMergeLineId: undefined,
        secondMergeLineId: undefined
      }

      this.siteMapMVTLineLayerIds = this.map.getStyle().layers.filter(layer => layer.id.startsWith(`${mapLayerId}-`) && layer.id.endsWith('line')).map(layer => layer.id);
      this.siteMapMVTPointLayerIds = this.map.getStyle().layers.filter(layer => layer.id.startsWith(`${mapLayerId}-`) && layer.id.endsWith('point')).map(layer => layer.id);

      // Start editing-realted event listeners
      // Keypresses bound to "body"
      // document.getElementsByTagName('body')[0].addEventListener('keydown', this.keyPressEditingMode);
      // document.getElementsByTagName('body')[0].addEventListener('keyup', this.keyPressEditingMode);
      // Mouse events bounds to Map
      this.map.on('mousedown', this.mouseDownEditingMode);
      this.map.on('mouseup', this.mouseUpEditingMode);
      this.map.on('mousemove', this.mouseMoveEditingMode);
      // Draw event to keep track of Draw modes
      this.map.on('draw.modechange', this.drawModeChangeEditingMode);
      this.map.on('draw.selectionchange', this.drawSelectionChangeEditingMode);
      this.map.on('draw.update', this.drawUpdateEditingMode);
      this.map.on('draw.create', this.drawCreateEditingMode);
      this.map.on('draw.delete', this.drawDeleteEditingMode);
    } else {
      this.addActiveClass("");
      // Quit all editing modes
      this.resetEditingModes();
      // Enter select mode
      this.draw.changeMode('sitemap_simple_select');
      // Turn off all editing modes and snapping
      this.isSnappingToLine = false;
      this.isSnappingToVertex = false;
      this.mergeParentLineId = '';
      this.mergeChildLineId = '';
      this.disablePointInteractions = false;
      // Turn off all editing-related event listeners
      // Keypresses bound to "body"
      // document.getElementsByTagName('body')[0].removeEventListener('keydown', this.keyPressEditingMode);
      // document.getElementsByTagName('body')[0].removeEventListener('keyup', this.keyPressEditingMode);
      // Mouse events bounds to Map
      this.map.off('mousedown', this.mouseDownEditingMode);
      this.map.off('mouseup', this.mouseUpEditingMode);
      this.map.off('mousemove', this.mouseMoveEditingMode);
      // Draw event to keep track of Draw modes
      this.map.off('draw.modechange', this.drawModeChangeEditingMode);
      this.map.off('draw.selectionchange', this.drawSelectionChangeEditingMode);
      this.map.off('draw.update', this.drawUpdateEditingMode);
      this.map.off('draw.create', this.drawCreateEditingMode);
      this.map.off('draw.delete', this.drawDeleteEditingMode);
    }
  }

  addActiveClass(e:any) {
    // if (document.querySelector('#divTools ul li a.active') !== null) {
    //   document.querySelector('#divTools ul li a.active')?.classList.remove('active');
    // }

    // if (e) {
    //   if(e.target.tagName === 'IMG') {
    //     if (e.target.parentElement.className == '') {
    //       e.target.parentElement.className = "active";
    //     } else {
    //       e.target.parentElement.className = "";

    //     }
    //   } else {
    //     if (e.target.className == '') {
    //       e.target.className = "active";

    //     } else {
    //       e.target.className = "";

    //     }
    //   }
    // }
    // else {
    //   if (document.querySelector('#divTools ul li a.active') !== null) {
    //     document.querySelector('#divTools ul li a.active')?.classList.remove('active');
    //   }
    // }
  }


  /** Delete merged lines features in DB */
  deleteMergedlineFeatures(){
   if(this.firstSecondLineFeatureFrMergedArr.length > 0){
      const firstlineArr = this.firstSecondLineFeatureFrMergedArr[0];
      const secondlineArr = this.firstSecondLineFeatureFrMergedArr[1];
      var firstlineFeatureGrp = {featureId:'',featureGeometryType:'',isAnnotation:false} as Feature;
      firstlineFeatureGrp.featureId = firstlineArr.properties.featureId;
      firstlineFeatureGrp.featureGeometryType = firstlineArr.type;
      var secondlineFeatureGrp = {featureId:'',featureGeometryType:'',isAnnotation:false} as Feature;
      secondlineFeatureGrp.featureId = secondlineArr.properties.featureId;
      secondlineFeatureGrp.featureGeometryType = secondlineArr.type;
      setTimeout(() => {
        this.deleteParticularFeatureAPI('', '', '', firstlineFeatureGrp);
      }, 1000);
      setTimeout(() => {
        this.deleteParticularFeatureAPI('', '', '', secondlineFeatureGrp);
      }, 1000);
      this.firstSecondLineFeatureFrMergedArr = []; //clear array

    }
  }

  //******************* HISTORY CODE ************************* */

  featureGroupHistoryClick(layer:any, group:any){
    this.featuresForRevision = [];
    this.isHistoryOpend = true;
    this.isHistoryBtnClicked = true;
    this.isHistoryGroupClicked = true;
    this.isHistoryTypeClicked = layer === "EXTERNAL" ? false : true;
    this.historyMetaData = this._constructDataForHistory(layer, group);
  }

  featureTypeHistoryClick(layer:any, group:any, featureType:any){
    this.featuresForRevision = [];
    this.isHistoryOpend = true;
    this.isHistoryBtnClicked = true;
    this.isHistoryTypeClicked = true;
    this.historyMetaData = this._constructDataForHistory(layer, group, featureType);
  }

  featureHistoryClick(layer:any, group:any, featureType:any, feature:any){
    this.featuresForRevision = [];
    this.historyMetaData = [];
    this.isHistoryBtnClicked = true;
    this.isHistoryFeatureClicked = true;
    this.isHistoryOpend = true;
    this.historyMetaData = this._constructDataForHistory(layer, group, featureType, feature);
  }

  private _constructDataForHistory(layer:any, group:any, type?:any, feature?:any) {
    const data = [{
      layerName:layer,
      groupName:group,
      types: this._getTypesArray(layer, group, type, feature)
    }];
    return data;
  }

  private _getTypesArray(layer: any, group: any, type?:any, feature?:any) {
    const types:any[] = [];
    this.fetchFeatureTypeArray(group, layer)?.forEach((type)=>{
      types.push({
        typeName : type,
        features: this._getFeatures(group, type, layer, feature)
      })
    });
    return type ? types.filter((t)=> t.typeName === type) : types;
  }

  private _getFeatures(group: any, type: string, layer: any, feature?:any) {
    let f:any = this.fetchFeaturesArray(group, type, layer);
    f =  ( feature ? f.filter((ft:any)=> ft?.featureId === feature?.featureId) : f);
    if(f?.length) {
      this.featuresForRevision.push(...f);
    }
    return  f;
  }

  onHistorySlideoutClose(isHistorySlideOpen:boolean){
    this.isHistoryOpend = isHistorySlideOpen;
    this.isHistoryFeatureClicked = isHistorySlideOpen;
    this.isHistoryTypeClicked = isHistorySlideOpen;
    this.isHistoryGroupClicked = isHistorySlideOpen;
    this.isHistoryBtnClicked = isHistorySlideOpen;
  }

  onRevertFeatureToRevision(obj:any ){
    this.navigatedJobId = this.navigatedJobId ? this.navigatedJobId : obj?.mapJobId;
    this._refreshAfterSave();
    setTimeout(() => {
      this.onHistorySlideoutClose(false);
    }, 200);
  }
  importConditionForPersonal() {
    this.isImportingReferenceLayer = false;
    if (!this.editCheck) {
      this.permissionService.showErrormessageForPersonal();
      return;
    }

    if(!this.isCurrentUserHasEditAccessOnJob) {
      this.toastr.error(`You do not have "Edit Access" on jobid ${this.navigatedJobId}`);
      return;
    }

    this.isSelectButtonVisible = false;
    this.isSubmitButtonVisible = false;
    this.isSubmitButtonVisibleGISReference = false;
    this.isSubmitNonGeoReferenceImage = false;

    const addImportCondition = document.getElementById("btnImportFile") as HTMLButtonElement;
    addImportCondition.click();
  }

  //******************* HISTORY CODE END ************************* */

  importReferenceLayer() {

    this.isImportingReferenceLayer = true;
    this.isSelectButtonVisible = false;
    this.isSubmitButtonVisible = false;
    this.isSubmitButtonVisibleGISReference = false;
    this.isSubmitNonGeoReferenceImage = false;

    const addImportCondition = document.getElementById("btnImportFile") as HTMLButtonElement;
    addImportCondition.click();
  }

  public onDeleteFeature(layer: string, $event: {feature: FeatureItem, featureType: FeatureTypeItem, group: FeatureGroupItem}) {
    this.deleteParticularFeature(layer, $event.group.groupName, $event.featureType.typeName, $event.feature);
  }

  public onDeleteType(layer: string, $event: {featureType: FeatureTypeItem, group: FeatureGroupItem, file: FileGroup}) {
    this.deleteFeatureType(layer, $event.group.groupName!, $event.featureType.typeName!, $event.file.fileId);
  }

  public  onDeleteGroup(layer: string, $event: {group: FeatureGroupItem, file: FileGroup}) {
    this.deleteFeatureGroup(layer, $event.group.groupName!, $event.file.fileId);
  }

  public onDeleteFile(layer: string, $event: FileGroup) {
    this.deleteFeatureFile(layer, $event);
  }

  public onToggleLayerGroup(layerName: string, $event: { hiddenFeatures: FeatureItem[], hiddenImages: RasterImageItem[] }) {
    const _layerId = this.getLayerID(layerName);
    if (!_layerId) {
      return;
    }
    applyFilterOnLayer(this.map, _layerId, this.zoomedJobIds, $event.hiddenFeatures);

    for (const image of this.geoReferenceGISImagesData || []) {
      this.toggleRasterItem(image);
    }

  }

processFeatures(allFeatures: any[]) {
  const featureTypeMap: Record<string, string[]> = {};

  allFeatures.forEach((feature) => {
    if (feature.properties) {
      const featureClass = feature.properties.featureClass;
      const featureType = feature.properties.featureType;

      if (!featureTypeMap[featureClass]) {
        featureTypeMap[featureClass] = [];
    }

      if (!featureTypeMap[featureClass].includes(featureType)) {
        featureTypeMap[featureClass].push(featureType);
      }
    }
  });

  return featureTypeMap;
}

processLineSymbology(): void {
  const featureTypeStyle = FeatureTypeStyleMapping.featureTypeStyleJson;

  const uniqueSymbologySet = new Set<string>();

  this.lineSymbology = [
    ...(this.featureTypeSymbologyMap.line ?? []),
    ...(this.featureTypeSymbologyMap.line_annotation ?? [])
]
  .map(lineItem => featureTypeStyle.find(style => style.feature_type_name === lineItem))
  .filter(style => !!style)
  .map(style => {
    const symbologyItem = { name: style?.feature_group_name, color: style?.color };

    const symbologyString = JSON.stringify(symbologyItem);

    if (!uniqueSymbologySet.has(symbologyString)) {
      uniqueSymbologySet.add(symbologyString);
      return symbologyItem;
    }

    return null;
  })
  .filter(style => !!style);
}

formatIconName(iconName: string): string {
  return iconName.replace(/_/g, ' ');
}



}

interface FeatureArrayItem {
  featureGroupId:      number | string;
  featureGroup:        string;
  featureTypeId:       number | string;
  featureType:         string;
  featureId:           number | string;
  featureName:         string;
  isAnnotation:        boolean;
  fileId:              number;
  fileName:            string;
  importedByUserId:    number;
  featureGeometryType: string;
  mapJobId: string | number
}
